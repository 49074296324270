import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse, HttpResponse
} from "@angular/common/http";
import { Observable } from 'rxjs';

import { throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class eventService {
  baseUrl = environment.baseUrl;
  constructor(private httpClient: HttpClient) { }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  EventList(params: any, token: string) {
    const headers = new HttpHeaders({
      'Authorization': token
    });
    return this.httpClient.
      post(` ${this.baseUrl}/v1/Master/PostEventsList`
        // post(`https://api.dnxconnect.com/v1/Master/postOrganisationList`
        , params, { headers })
      .pipe(catchError(this.handleError));
  }
  postInsertEvent(params: any, token: string) {
    const headers = new HttpHeaders({
      'Authorization': token
    });
    return this.httpClient.post(` ${this.baseUrl}/v1/Master/postEventInsert`, params, { headers })
      .pipe(catchError(this.handleError));

  }
  postUpdateEvent(params: any, token: string) {
    const headers = new HttpHeaders({
      'Authorization': token
    });
    return this.httpClient.post(` ${this.baseUrl}/v1/Master/postEventUpdate`, params, { headers })
      .pipe(catchError(this.handleError));

  }



}
