import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { concat, Observable, of, Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import {
  catchError,
  distinctUntilChanged,
  switchMap,
  tap,
  delay
} from "rxjs/operators";
import Swal from "sweetalert2";

// Local Imports
import { MemberService } from "../shared/services/member.service";
import { States } from "../shared//models/mock-states";
import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";

declare var $: any;

export class Service {
  serviceId: Number;
  categoryId: Number;
  parentId: Number;
  serviceName: string;
}

@Component({
  selector: "app-member-view",
  templateUrl: "./member-view.component.html",
  styleUrls: ["./member-view.component.css"]
})
export class MemberViewComponent implements OnInit {
  states = States;
  currentUsertype:string;
  currentUsertypeId:number;
  submitted = false;
  isLoading: boolean;
  memberDetail: boolean;
  interestFormValidator: boolean;
  skillFormValidator: boolean;
  experienceEdit: boolean;
  educationEdit: boolean;
  addInterest: boolean;
  addSkills: boolean;
  addExperience: boolean;
  onselect :boolean=false;
  addEducation: boolean;
  basicInfoEdit: boolean=false;
  profileEdit: boolean;
  modalHeader: string;
  memberEducationForm: FormGroup;
  memberExperienceForm: FormGroup;
  addEducationForm: FormGroup;
  addExperienceForm: FormGroup;
  basicInfoForm: FormGroup;
  profileEditForm: FormGroup;
  usertype:string;
  validTypes=  ['image/jpeg', 'image/png', 'image/gif'];
  customvalidator:boolean=false;
  interest$: Observable<Service[]>;
  interestLoading = false;
  interestInput$ = new Subject<string>();
  selectedInterests: Service[] = <any>[];

  skills$: Observable<Service[]>;
  skillLoading = false;
  skillInput$ = new Subject<string>();
  selectedSkills: Service[] = <any>[];
  roles:any;
  currentMember: any;
  currentMemberId: Number;
  currentOrganizationId:Number;
  servicesList: Service[];
  token: string;
  roleList: any;
  roleTypes: any;
  organizationName:string;
  date =new Date();
  catagoryDropDown: any[];
  subCatagoryDropDown:any[]=[];
  isPresent: boolean=false;
  subCatagoryDropDowns: any;
  subcategoryDropDowns: any[];
  categoryName: any;
  subCategoryName: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private memberService: MemberService,
    private router: Router,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.currentMemberId = +this.route.snapshot.paramMap.get("id");
    this.onselect=false;
    let user = JSON.parse(localStorage.getItem("currentUser"));
    // this.organizationName=user.organaisationName;
    this.token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();
    this.getMemberAllDetails();
    this.getCatogoryList();
    this.servicesList = [
      {
        serviceId: 1,
        categoryId: 1,
        parentId: 0,
        serviceName: "Machine Learning"
      }
    ];
    this.basicInfoForm = this.formBuilder.group({
      memberId: [this.currentMemberId],
      firstName: ['', [Validators.required, WhiteSpaceValidator,Validators.pattern(/^[a-zA-Z0-9]{2,}$/)]],
      lastName: ['', [Validators.required, WhiteSpaceValidator,Validators.pattern(/^[a-zA-Z0-9]{1,}$/)]],
      title: ['', [Validators.required, WhiteSpaceValidator]],
      organaisationName: [this.organizationName],
      phoneNo: [
        '',
        [Validators.required, Validators.pattern("/^[1-9][0-9]*$/")]
      ],
      // address: [member.address, [Validators.required, WhiteSpaceValidator]],
      // city: [member.city, [Validators.required, WhiteSpaceValidator]],
      roleType: [''],
      subCategoryId:[''],
      parentCategoryId:[''],
      // zipCode: [member.zipCode, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
    });
    // this.memberService.getServicesList(this.token).subscribe(
    //   (data: { serviceList: Service[] }) => {
    //     this.servicesList = data.serviceList;
    //   },
    //   err => {
    //     if (err.status === 401) {
    //       this.unauthorizedException();
    //     }
    //   }
    // );
    this.getRoles()
  }

  getCatogoryList(){
    this.memberService
    .getCatogoryList( this.token)
    .subscribe(
      (data: any) => {
        if (data.statusCode === 200) {
          this.catagoryDropDown=data.categoryLists;
          this.catagoryDropDown=this.catagoryDropDown.map(value=>({
            categoryName:value.categoryName,
            categoryId:parseInt(value.categoryId),
          })
          );
        
          this.subCatagoryDropDowns = data.categoryLists.map((value=>value.subCategoryList));
          
          this.subCatagoryDropDowns = data.categoryLists
          .map(value => value.subCategoryList) // Extract subCategoryList arrays
          .flat() // Flatten the array of arrays into a single array
          .map(value => ({
            categoryName: value.categoryName,
            categoryId: parseInt(value.categoryId),
            parentCategoryId: parseInt(value.parentCategoryId)
          })); 
          
        
        console.log('usb',this.subCatagoryDropDowns)

          // this.groupList= data.vendorGroupList.map(group => ({
          //   id: group.groupId,
          //   name: group.name,
          // }));
         console.log('data -------> ',this.catagoryDropDown);
          return;
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  oncatogorySelect(event:any){
    let subcatogoryList=  this.subCatagoryDropDown;
    this.isPresent=false;
    console.log('s',this.subCatagoryDropDowns);
    console.log('Event',(event.target as HTMLSelectElement).value);
    console.log('Subcatogory',this.subCatagoryDropDown);
    this.subCatagoryDropDown=  this.subCatagoryDropDowns.filter(
    (x) => x.parentCategoryId == (event.target as HTMLSelectElement).value
    );
    subcatogoryList=this.subcategoryDropDowns;
    // subcatogoryList=this.subCatagoryDropDown;
    
    console.log('subcatogory',this.subCatagoryDropDown);
    this.isPresent=  this.subCatagoryDropDown.length==0?false:true;
  }
  onSubcatogorySelect(Event:any){

  }
  onListSelect(event: any) {
    this.onselect=true;
    const selectedOption = (event.target as HTMLSelectElement).value;//gold  silver 
    console.log(selectedOption);
    var selectedkey=this.roleTypes.map((key)=>{
      console.log('role',key.role)
      if(key.role==selectedOption){
        this.usertype=key.id;
        console.log(this.usertype);
      }
    });
    

    // const selectedvalue=this.roleTypes.find(item => item.role === selectedOption);
    // const selectedKey=selectedvalue?selectedKey.id:null;
  
    console.log('userType',this.usertype);

    
  }
 
  getRoles() {
    if (this.roleList) {
      console.log(this.roleList);
      return this.roleList;
    }

    this.memberService
      .getRoleValues(this.token)
      .subscribe(
        data => {
          this.roleList = data;
          console.log(data);
          this.roleTypes = this.roleList.roleType
          this.roles=this.roleTypes.map(item => item.role);
          console.log( this.roleTypes);
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }
  // Basic Info Edit
  basicInfoModal(member: any) {
  console.log(member);
   if(member.categoryId!=0){
 
     this.subCatagoryDropDown=  this.subCatagoryDropDowns.filter(
      (x) => x.parentCategoryId == member.categoryId
      );
      this.isPresent=true;
   }
   else{
    this.isPresent=false;
   }
    console.log( 'Check',member.userType);
    $("#memberEditModel").modal("show");
    this.basicInfoEdit = true;
    this.profileEdit = false;
    this.modalHeader = "Basic Information Edit";
    this.basicInfoForm = this.formBuilder.group({
      memberId: [this.currentMemberId],
      firstName: [member.firstName, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z ]{2,}$/)]],
      lastName: [member.lastName, [Validators.required, WhiteSpaceValidator,Validators.pattern(/^[a-zA-Z\s]*$/)]],
      title: [member.designation, [Validators.required, WhiteSpaceValidator,Validators.pattern(/^[a-zA-Z0-9,._ -]*$/),Validators.minLength(2)]],
      organaisationName: [this.organizationName, [Validators.required, WhiteSpaceValidator,Validators.pattern(/^[a-zA-Z0-9,._ -]*$/),Validators.minLength(2)]],
      phoneNo: [
        member.phone,
        [Validators.required, Validators.pattern(/^[1-9][0-9]*$/)]
      ],
      // address: [member.address, [Validators.required, WhiteSpaceValidator]],
      // city: [member.city, [Validators.required, WhiteSpaceValidator]],
      roleType: [this.currentUsertype],
      parentCategoryId:[member.categoryId==undefined?0:member.categoryId],
      subCategoryId:[member.subCategoryId==undefined?0:member.subCategoryId]
      // zipCode: [member.zipCode, [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
    });
    console.log('Form RoleType',this.currentUsertype)
  }

  // convenience getter for easy access to basic info form fields
  get basicInfoControls() {
    return this.basicInfoForm.controls;
  }
  handleKeyDown(event: KeyboardEvent): void {
    console.log('Key pressed:', event.key);
    if(event.key=='Backspace' && this.basicInfoForm.get('phoneNo').value.length==1){
    
      this.basicInfoForm.controls['phoneNo'].setValue(''); 
      
    }
    // Add your logic here based on the key pressed
  }

  stripMask(phoneNumber) {
  
    const areaCode = phoneNumber.slice(0, 3);
    const centralOfficeCode = phoneNumber.slice(3, 6);
    const lineNumber = phoneNumber.slice(6, 10);
  
    return `(${areaCode})-${centralOfficeCode}-${lineNumber}`;
  }
  basicInfoSubmit() {
    this.submitted = true;

    if (this.basicInfoForm.invalid) {
      return;
    }
    console.log('roletype',this.basicInfoForm.get('roleType').value);
    this.submitted = false;
    this.isLoading = true;
    
    this.spinnerService.show();
    let params = {
      "memberId": this.basicInfoForm.value.memberId,
      "roleType": this.onselect?this.usertype:this.currentUsertypeId,
      "firstName": this.basicInfoForm.value.firstName,
      "lastName": this.basicInfoForm.value.lastName,
      "title": this.basicInfoForm.value.title,
      "organaisationName": this.organizationName,
      "phoneNo": this.basicInfoForm.value.phoneNo.toString(),
      "parentCategoryId":this.basicInfoForm.value.parentCategoryId==''?0:parseInt(this.basicInfoForm.value.parentCategoryId),
      "subCategoryId":!this.isPresent?0:parseInt(this.basicInfoForm.value.subCategoryId)
    }
    console.log('params ',params);
    this.memberService
      .memberBasicInfoEdit(params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if(data.statusCode==200){
            this.spinnerService.hide();
            this.isLoading = false;
            Swal.fire({
              icon: "success",
              title: "Basic Information Updated Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              $("#memberEditModel").modal("hide");
              this.basicInfoEdit = false;
            
              this.getMemberAllDetails();
            });
            return;
          }
          else{
            this.isLoading = false;
            this.spinnerService.hide();
            Swal.fire({
              icon: 'warning',
              title: data.statusMessage,
              showCloseButton: true,
              allowOutsideClick: false
            })

          }
        }
        ,
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  // Open Profile Edit Form
  openProfileEdit(member: any) {
    this.customvalidator=false;
    $("#memberEditModel").modal("show");
    this.profileEdit = true;
    this.basicInfoEdit=false;
    this.modalHeader = "Profile Update";
    this.profileEditForm = this.formBuilder.group({
      memberId: [this.currentMemberId],
      files: ['', [Validators.required]],
      fileSource: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to basic info form fields
  get profileEditControls() {
    return this.profileEditForm.controls;
  }
  checkAllFieldsEntered(): boolean {
    return Object.values(this.profileEditForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }
 

  onProfileChange(event: any) {
    if(this.submitted==true||this.validTypes.includes(event.target.files[0].type)){
      this.customvalidator= ! this.validTypes.includes(event.target.files[0].type);
      }
     
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileEditForm.patchValue({
        fileSource: file
      });
    }
  }

  profileUrl(val: any) {
    if (val === null) {
      return "assets/img/profile-img.png";
    }
    if (val === "") {
      return "assets/img/profile-img.png";
    }
    return val;
  }

  profileEditSubmit() {
    this.submitted = true;

    if (this.profileEditForm.invalid) {
      return;
    }
    this.submitted = false;
    let file=this.profileEditForm.get('fileSource').value;
    if(file!=null){
      if(! this.validTypes.includes(file.type)){
        this.customvalidator= ! this.validTypes.includes(file.type);
        return;
      }
    }
    const formData = new FormData();
    this.spinnerService.show();
    formData.append('files', this.profileEditForm.get('fileSource').value);
    formData.append('memberId', this.profileEditForm.value.memberId);
    this.isLoading = true;
    this.memberService
      .updateMemberProfile(formData,this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            this.spinnerService.hide();
            this.isLoading = false;
            Swal.fire({
              icon: "success",
              title: "Profile Updated Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              $("#memberEditModel").modal("hide");
              this.profileEdit = false;
              this.getMemberAllDetails();
            });
            return;
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  //  Add Interest
  addInterestModal() {
    $("#memberEditModel").modal("show");
    this.addInterest = true;
    this.modalHeader = "Add Interest";
    this.loadInterest();
  }

  private loadInterest() {
    this.interest$ = concat(
      of([]), // default items
      this.interestInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.interestLoading = true)),
        switchMap(term =>
          this.getInterest(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.interestLoading = false))
          )
        )
      )
    );
  }

  getInterest(term: string = null): Observable<Service[]> {
    let items = this.servicesList;
    if (term) {
      items = items.filter(
        x =>
          x.serviceName.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) >
          -1
      );
    }
    return of(items).pipe(delay(500));
  }

  trackByInterestFn(item: Service) {
    return item.serviceId;
  }

  interestSubmit() {
    if (this.selectedInterests.length === 0) {
      this.interestFormValidator = true;
      return;
    }
    this.interestFormValidator = false;
    this.isLoading = true;
    // console.log(this.selectedInterests);
  }

  // Delete Interest
  deleteInterestService(serviceId: any) {
    Swal.fire({
      title: "Delete Interest",
      text: "Are you sure? Do you want to delete interest?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        $("#progressModel").modal("show");
        this.memberService
          .deleteMemberInterest(
            { memberId: this.currentMemberId, serviceId: serviceId },
            this.token
          )
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                $("#progressModel").modal("hide");
                this.getMemberAllDetails();
                Swal.fire({
                  title: "Deleted!",
                  text: "Interest is deleted now",
                  icon: "success",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    });
  }

  // Add Skills
  addSkillsModal() {
    $("#memberEditModel").modal("show");
    this.addSkills = true;
    this.modalHeader = "Add Skills";
    this.loadSkill();
  }

  private loadSkill() {
    this.skills$ = concat(
      of([]), // default items
      this.skillInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.skillLoading = true)),
        switchMap(term =>
          this.getSkill(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.skillLoading = false))
          )
        )
      )
    );
  }

  getSkill(term: string = null): Observable<Service[]> {
    let items = this.servicesList;
    if (term) {
      items = items.filter(
        x =>
          x.serviceName.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) >
          -1
      );
    }
    return of(items).pipe(delay(500));
  }

  trackBySkillFn(item: Service) {
    return item.serviceId;
  }

  skillSubmit() {
    if (this.selectedSkills.length === 0) {
      this.skillFormValidator = true;
      return;
    }
    this.skillFormValidator = false;
    this.isLoading = true;
    // console.log(this.selectedSkills);
  }

  // Delete Interest
  deleteSkillService(serviceId: any) {
    Swal.fire({
      title: "Delete Skill",
      text: "Are you sure? Do you want to delete skill?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        $("#progressModel").modal("show");
        this.memberService
          .deleteMemberSkill(
            { memberId: this.currentMemberId, serviceId: serviceId },
            this.token
          )
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                $("#progressModel").modal("hide");
                this.getMemberAllDetails();
                Swal.fire({
                  title: "Deleted!",
                  text: "Skill is deleted now",
                  icon: "success",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    });
  }

  // Add Experience
  openAddExperienceModal() {
    this.addExperience = true;
    this.modalHeader = "Add Experience";
    $("#memberEditModel").modal("show");
    this.addExperienceForm = this.formBuilder.group(
      {
        designation: ["", [Validators.required]],
        employmentType: ["", [Validators.required]],
        organizationName: ["", [Validators.required]],
        address: ["", [Validators.required]],
        isCurrentlyWorking: [false],
        startDate: ["", [Validators.required]],
        endDate: ["", [Validators.required]]
      },
      {
        validator: this.dateValidator("startDate", "endDate")
      }
    );
  }

  // convenience getter for easy access to experience form fields
  get addExperienceFormControls() {
    return this.addExperienceForm.controls;
  }

  addExperienceFormSubmit() {
    this.submitted = true;

    if (this.addExperienceForm.invalid) {
      return;
    }
    this.submitted = false;
    this.isLoading = true;
    // console.log(this.addExperienceForm.value);
  }

  // Experience Edit
  openExperienceModal(e: any) {
    this.experienceEdit = true;
    this.modalHeader = "Member Experience Edit";
    $("#memberEditModel").modal("show");
    this.memberExperienceForm = this.formBuilder.group(
      {
        memberId: [this.currentMemberId],
        experienceId: [e.experienceId],
        designation: [e.designation, [Validators.required]],
        employmentType: [e.employmentType, [Validators.required]],
        organizationName: [e.organizationName, [Validators.required]],
        address: [e.address, [Validators.required]],
        isCurrentlyWorking: [this.getBooleanValue(e.isCurrentlyWorking)],
        startDate: [this.getFormattedDate(e.startDate), [Validators.required]],
        endDate: [this.getFormattedDate(e.endDate), [Validators.required]]
      },
      {
        validator: this.dateValidator("startDate", "endDate")
      }
    );
  }

  // convenience getter for easy access to experience form fields
  get experienceFormControls() {
    return this.memberExperienceForm.controls;
  }
  // checkAllFieldsEntereds(): boolean {
  //   return Object.values(this.basicInfoForm.controls).every(control => {
  //     return control.value !== '' && control.value !== null;
  //   });
  
  
  // }
  checkAllFieldsEntereds(): boolean {
    return Object.keys(this.basicInfoForm.controls).every(controlName => {
     
       if(controlName=='parentCategoryId' && !this.isPresent ){
        return true;
      }
      else if(controlName=='subCategoryId' && this.isPresent){
        const control = this.basicInfoForm.get(controlName);
        if(control.value==0){
          return false;
        }
        return true;
        }
       
      
      const control = this.basicInfoForm.get(controlName);
      return control.value !== '' && control.value !== null;
    });
  }

  experienceFormSubmit() {
    this.submitted = true;

    if (this.memberExperienceForm.invalid) {
      return;
    }
    this.submitted = false;
    this.isLoading = true;
    let params = this.formatExperienceParams(this.memberExperienceForm.value);
    this.memberService.memberExperienceEdit(params, this.token).subscribe(
      (data: { statusCode: Number; statusMessage: string }) => {
        if (data.statusCode === 200) {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Experience Updated Successfully",
            showCloseButton: true,
            allowOutsideClick: false
          }).then(() => {
            $("#memberEditModel").modal("hide");
            this.experienceEdit = false;
            this.getMemberAllDetails();
          });
          return;
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  // Add Education
  openAddEducationModal() {
    this.addEducation = true;
    this.modalHeader = "Add Education";
    $("#memberEditModel").modal("show");
    this.addEducationForm = this.formBuilder.group(
      {
        school: ["", [Validators.required]],
        degree: ["", [Validators.required]],
        fieldOfStudy: ["", [Validators.required]],
        startYear: ["", [Validators.required]],
        endYear: ["", [Validators.required]]
      },
      {
        validator: this.dateValidator("startYear", "endYear")
      }
    );
  }

  // convenience getter for easy access to education form fields
  get addEducationFormControls() {
    return this.addEducationForm.controls;
  }

  addEducationFormSubmit() {
    this.submitted = true;

    if (this.addEducationForm.invalid) {
      return;
    }
    this.submitted = false;
    this.isLoading = true;
    // console.log(this.addEducationForm.value);
  }

  // Education Edit
  openEducationModal(e: any) {
    this.educationEdit = true;
    this.modalHeader = "Member Education Edit";
    $("#memberEditModel").modal("show");
    this.memberEducationForm = this.formBuilder.group(
      {
        memberId: [this.currentMemberId],
        educationId: [e.educationId],
        school: [e.school, [Validators.required]],
        degree: [e.degree, [Validators.required]],
        fieldOfStudy: [e.fieldOfStudy, [Validators.required]],
        startYear: [this.getFormattedDate(e.startYear), [Validators.required]],
        endYear: [this.getFormattedDate(e.endYear), [Validators.required]]
      },
      {
        validator: this.dateValidator("startYear", "endYear")
      }
    );
  }

  // convenience getter for easy access to education form fields
  get educationFormControls() {
    return this.memberEducationForm.controls;
  }

  educationFormSubmit() {
    this.submitted = true;

    if (this.memberEducationForm.invalid) {
      return;
    }
    this.submitted = false;
    this.isLoading = true;
    this.memberService
      .memberEducationEdit(this.memberEducationForm.value, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            this.isLoading = false;
            Swal.fire({
              icon: "success",
              title: "Education Updated Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              $("#memberEditModel").modal("hide");
              this.educationEdit = false;
              this.getMemberAllDetails();
            });
            return;
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  // Common Functions
  // Modal Close event
  hide() {
    // this.profileEditForm.patchValue({
    //     fileSource: null
    //   });
    this.customvalidator=false;
    $("#memberEditModel").modal("hide");
    this.basicInfoEdit = false;
    this.profileEdit = false;
    this.addInterest = false;
    this.addSkills = false;
    this.addExperience = false;
    this.experienceEdit = false;
    this.addEducation = false;
    this.educationEdit = false;
    this.submitted = false;
    this.isLoading = false;
    this.isPresent=false;
    $("#memberEditModel").modal("hide");
  }

  getBooleanValue(value: any) {
    if (value === 1) {
      return true;
    }
    if (value === 0) {
      return false;
    }
    if (value) {
      return 1;
    }
    if (!value) {
      return 0;
    }
  }

  dateValidator(from: string, to: string) {
    return (formGroup: FormGroup) => {
      const fromDate = formGroup.controls[from];
      const toDate = formGroup.controls[to];
      if (toDate.errors && !toDate.errors.dateValidator) {
        return;
      }
      if (fromDate.value > toDate.value || fromDate.value === toDate.value) {
        toDate.setErrors({ dateValidator: true });
      } else {
        toDate.setErrors(null);
      }
    };
  }

  getMemberAllDetails() {
    
    this.spinnerService.show();
    this.memberDetail = true;
    this.isPresent=false;
    this.memberService
      .getMemberDetails(this.token, this.currentMemberId)
      .subscribe(
        (data:any )=> {
          this.spinnerService.hide();
          console.log('data',data);
          this.currentMember = data;
          console.log(data);
          this.organizationName=data.organizationName;
          this.currentUsertype=this.currentMember.userType;
          this.currentUsertypeId=this.currentMember.userTypeId;
          console.log('Current Member',this.currentMember.userType);
          console.log('current member',this.currentMember);
          console.log('Current User Type Id ',this.currentMember.profileImageUrl )
          this.categoryName=data.categoryName;
          this.subCategoryName=data.subCategoryName;
          
          this.memberDetail = false;
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }

  getFormattedDate(date: string) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    return year + "-" + month + "-" + day;
  }

  getAppendValue(value: any) {
    if (value <= 9) {
      return `0${value}`;
    }
    return value;
  }

  formatExperienceParams(data: any) {
    return {
      memberId: data.memberId,
      experienceId: data.experienceId,
      designation: data.designation,
      employmentType: data.employmentType,
      organizationName: data.organizationName,
      address: data.address,
      isCurrentlyWorking: this.getBooleanValue(data.isCurrentlyWorking),
      starDate: data.startDate,
      endDate: data.endDate,
      headline: ""
    };
  }
}
