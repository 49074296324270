<div class="main">

  <ngx-spinner
    bdColor="rgba(0, 0, 0, .6)"
    class="spin-img"
    template="<img src='assets/img/logo-D.gif' />"
  >
  </ngx-spinner>


  <div style="display: flex;">
    <img src="assets/img/icon.png" style="width: 100px;
    height: 100px;
    margin: auto;" />
  </div>
  <div class="row mt-4" *ngIf="screen === 1" style="margin: auto;">
    <div class="col-sm"></div>
    <div class="col-sm">

      <div class="card signup-screen">
        <div class="signup-text text-center">TMZ New Members Registration</div>
        <form [formGroup]="signupForm" (ngSubmit)="signupSubmit()">
          <div class="row signup-mb">
            <div class="col-md-6 col-12">
              <input type="text" class="form-control signup-input" id="firstName" placeholder="First Name"
                formControlName="firstName" />
              <div *ngIf="signupSubmitted && signupControl.firstName.errors" class="ml-2">
                <div *ngIf="signupControl.firstName.errors.required" class="text-danger">
                  First Name required
                </div>
                <div *ngIf="signupControl.firstName.errors.noWhiteSpace" class="text-danger">
                  {{ signupControl.firstName.errors.noWhiteSpace }}
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12 signup-mb-multi">
              <input type="text" class="form-control signup-input" id="lastName" placeholder="Last Name"
                formControlName="lastName" />
              <div *ngIf="signupSubmitted && signupControl.lastName.errors" class="ml-2">
                <div *ngIf="signupControl.lastName.errors.required" class="text-danger">
                  Last Name required
                </div>
                <div *ngIf="signupControl.lastName.errors.noWhiteSpace" class="text-danger">
                  {{ signupControl.lastName.errors.noWhiteSpace }}
                </div>
              </div>
            </div>
          </div>
          <div class="signup-mb">
            <input type="text" class="form-control signup-input" id="title" placeholder="Title"
              formControlName="title" />
            <div *ngIf="signupSubmitted && signupControl.title.errors" class="ml-2">
              <div *ngIf="signupControl.title.errors.required" class="text-danger">
                Title required
              </div>
              <div *ngIf="signupControl.title.errors.noWhiteSpace" class="text-danger">
                {{ signupControl.title.errors.noWhiteSpace }}
              </div>
            </div>
          </div>
          <div class="signup-mb">
            <input type="text" class="form-control signup-input" id="organization" placeholder="Organization Name"
              formControlName="organaisationName" />
            <div *ngIf="signupSubmitted && signupControl.organaisationName.errors" class="ml-2">
              <div *ngIf="signupControl.organaisationName.errors.required" class="text-danger">
                Organization Name required
              </div>
              <div *ngIf="signupControl.organaisationName.errors.noWhiteSpace" class="text-danger">
                {{ signupControl.organaisationName.errors.noWhiteSpace }}
              </div>
            </div>
          </div>
          <div class="signup-mb">
            <input type="email" class="form-control signup-input" id="email" placeholder="Email"
              formControlName="email" />
            <div *ngIf="signupSubmitted && signupControl.email.errors" class="ml-2">
              <div *ngIf="signupControl.email.errors.required" class="text-danger">
                Email required
              </div>
              <div *ngIf="signupControl.email.errors.pattern" class="text-danger">
                Enter valid email address
              </div>
            </div>
          </div>
          <div class="signup-mb">
            <input type="text" class="form-control signup-input" (input)="formatPhoneNumber($event)" id="phoneNo"
              placeholder="Phone" formControlName="phoneNo" />
            <div *ngIf="signupSubmitted && signupControl.phoneNo.errors" class="ml-2">
              <div *ngIf="signupControl.phoneNo.errors.required" class="text-danger">
                Phone required
              </div>
              <div *ngIf="signupControl.phoneNo.errors.pattern" class="text-danger">
                Enter valid phone number
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-color btn-lg btn-block btn-sign-up signup-mb">
            SIGN UP
          </button>
        </form>
      </div>
    </div>
    <div class="col-sm"></div>
  </div>
  <div class="row mt-4" *ngIf="screen === 2" style="margin: auto">
    <div class="col-sm"></div>
    <div class="col-sm">
      <div class="card plan-screen">
        <div class="signup-text text-center">
          Please select a plan to get started
        </div>
        <div class="d-flex justify-content-center payment-plan-mb">
          <div [ngClass]="{
              'bold-text': !toggle,
              'normal-text': toggle,
              'mr-2': true
            }">
            Annual
          </div>
          <div class="switch" (click)="toggleSwitch()">
            <div class="switch-button-left" *ngIf="!toggle"></div>
            <div class="switch-button-right" *ngIf="toggle"></div>
          </div>
          <div [ngClass]="{
              'bold-text': toggle,
              'normal-text': !toggle,
              'ml-2': true
            }">
            Monthly
          </div>
        </div>
        <div class="dollor-text text-center payment-mb">${{ payValue }}</div>
        <div class="year-month-text text-center" *ngIf="!toggle">
          per year
        </div>
        <div class="year-month-text text-center" *ngIf="toggle">
          per month
        </div>
        <button class="btn plan-btn mt-3" (click)="screenChange(3)">
          SELECT PLAN
        </button>
        <div class="d-flex felx-row" style="margin-top: 15px;
        padding: 10px 40px;">
          <input type="text" class="form-control signup-input" id="coupon" placeholder="Coupon" [(ngModel)]="coupon"
            (keyup)="couponKeyUp($event.target.value)" />
          <button type="button" class="btn btn-warning" style="padding: 13px 20px;
             margin-left: 5px;" (click)="applyCoupon(true)" [disabled]="couponLoading">
            Apply
          </button>
        </div>
        <div *ngIf="couponError.length > 0" class="text-danger" style="padding: 0px 45px;">
          required
        </div>
        <div class="change-plan-text mt-3">
          <u (click)="screenChange(1)">BACK</u>
        </div>
      </div>
    </div>
    <div class="col-sm"></div>
  </div>
  <div class="row mt-4" *ngIf="screen === 3" style="margin: auto">
    <div class="col-sm"></div>
    <div class="col-sm-8">
      <div class="card payment-screen">
        <form [formGroup]="paymentForm" (ngSubmit)="paymentFormSubmit()">
          <div class="row">
            <div class="col-sm">
              <div class="payment-info-text">Payment Information</div>
              <div class="input-group payment-mb">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    <img class="mr-2" src="assets/img/card.jpg" style="height: 20px" />Card
                  </span>
                </div>
                <input type="text" class="form-control signup-input" aria-describedby="basic-addon1"
                  formControlName="accountNumber" placeholder="XXXXXXXXXXXXXXXX" />
              </div>
              <div *ngIf="paymentSubmitted && paymentControl.accountNumber.errors" class="ml-2">
                <div *ngIf="paymentControl.accountNumber.errors.required" class="text-danger">
                  Card required
                </div>
                <div *ngIf="paymentControl.accountNumber.errors.pattern" class="text-danger">
                  Enter valid card number
                </div>
              </div>
              <div class="row payment-mb">
                <div class="col-md-6 col-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon2">
                        <img class="mr-2" src="assets/img/date.jpg" style="height: 20px" />
                        EXP
                      </span>
                    </div>
                    <input type="text" class="form-control signup-input" aria-describedby="basic-addon2"
                      formControlName="exp" mask="00/0000" placeholder="MM/YYYY" />
                  </div>
                  <div *ngIf="paymentSubmitted && paymentControl.exp.errors" class="ml-2">
                    <div *ngIf="paymentControl.exp.errors.required" class="text-danger">
                      Expiration date required
                    </div>
                    <div *ngIf="paymentControl.exp.errors.pattern" class="text-danger">
                      Enter valid expiration date
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12 payment-mb-multi">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon3">
                        <img class="mr-2 lock-icon" src="assets/img/cvc.jpg" />
                        CVC
                      </span>
                    </div>
                    <input type="password" class="form-control signup-input" aria-describedby="basic-addon3"
                      formControlName="cvc" placeholder="CVC" />
                  </div>
                  <div *ngIf="paymentSubmitted && paymentControl.cvc.errors" class="ml-2">
                    <div *ngIf="paymentControl.cvc.errors.required" class="text-danger">
                      CVC required
                    </div>
                    <div *ngIf="paymentControl.cvc.errors.pattern" class="text-danger">
                      Enter valid cvc
                    </div>
                  </div>
                </div>
              </div>
              <div class="payment-info-text payment-mb">
                Billing Information
              </div>
              <div class="payment-mb">
                <input type="text" class="form-control signup-input" id="name" placeholder="Name"
                  formControlName="name" />
                <div *ngIf="paymentSubmitted && paymentControl.name.errors" class="ml-2">
                  <div *ngIf="paymentControl.name.errors.required" class="text-danger">
                    Name required
                  </div>
                  <div *ngIf="paymentControl.name.errors.noWhiteSpace" class="text-danger">
                    {{ paymentControl.name.errors.noWhiteSpace }}
                  </div>
                </div>
              </div>
              <div class="payment-mb">
                <input type="email" class="form-control signup-input" id="billingEmail" placeholder="Email"
                  formControlName="billingEmail" />
                <div *ngIf="paymentSubmitted && paymentControl.billingEmail.errors" class="ml-2">
                  <div *ngIf="paymentControl.billingEmail.errors.required" class="text-danger">
                    Email required
                  </div>
                  <div *ngIf="paymentControl.billingEmail.errors.pattern" class="text-danger">
                    Enter valid email address
                  </div>
                </div>
              </div>
              <div class="payment-mb">
                <input type="text" class="form-control signup-input" id="address" placeholder="Address"
                  formControlName="address" />
                <div *ngIf="paymentSubmitted && paymentControl.address.errors" class="ml-2">
                  <div *ngIf="paymentControl.address.errors.required" class="text-danger">
                    Address required
                  </div>
                  <div *ngIf="paymentControl.address.errors.noWhiteSpace" class="text-danger">
                    {{ paymentControl.address.errors.noWhiteSpace }}
                  </div>
                </div>
              </div>
              <div class="payment-mb">
                <input type="text" class="form-control signup-input" id="city" placeholder="City"
                  formControlName="city" />
                <div *ngIf="paymentSubmitted && paymentControl.city.errors" class="ml-2">
                  <div *ngIf="paymentControl.city.errors.required" class="text-danger">
                    City required
                  </div>
                  <div *ngIf="paymentControl.city.errors.noWhiteSpace" class="text-danger">
                    {{ paymentControl.city.errors.noWhiteSpace }}
                  </div>
                </div>
              </div>
              <div class="row payment-mb">
                <div class="col-md-6 col-12">
                  <select class="form-control" formControlName="state" id="state" placeholder="state" style="height: 50px;
                      padding-left: 15px;">
                    <option value="">Select State</option>
                    <option *ngFor="let state of states" value="{{ state }}">{{
                      state
                      }}</option>
                  </select>
                  <div *ngIf="paymentSubmitted && paymentControl.state.errors" class="ml-2">
                    <div *ngIf="paymentControl.state.errors.required" class="text-danger">
                      State required
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 payment-mb-multi">
                  <input type="text" class="form-control signup-input" id="zipCode" placeholder="Zip"
                    formControlName="zipCode" mask="00000" />
                  <div *ngIf="paymentSubmitted && paymentControl.zipCode.errors" class="ml-2">
                    <div *ngIf="paymentControl.zipCode.errors.required" class="text-danger">
                      Zip required
                    </div>
                    <div *ngIf="paymentControl.zipCode.errors.pattern" class="text-danger">
                      Enter valid zip
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div class="dollor-text text-center payment-mb1">
                ${{ payValue }}
              </div>
              <div class="year-month-text text-center" *ngIf="!toggle">
                per year
              </div>
              <div class="year-month-text text-center" *ngIf="toggle">
                per month
              </div>
              <div class="text-center payment-mb1">
                <button type="submit" class="btn plan-btn mb-4" [disabled]="isLoading">
                  <div class="spinner-border spinner-border-sm text-light mr-2" role="status" *ngIf="isLoading">
                    <span class="sr-only">Loading...</span>
                  </div>
                  CONFIRM MEMBERSHIP
                </button>
              </div>
              <div class="change-plan-text">
                <u (click)="screenChange(2)">BACK</u>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-sm"></div>
  </div>
</div>