// Vendor Imports
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      const user = JSON.parse(currentUser);
      const isAuthorized = user && user.roleId;

      if (!isAuthorized) {
        this.router.navigate(['/login']); // Redirect to default page if user is not authorized
        return false;
      }


      const allowedRoles = route.data.allowedRoles as Array<number>;
      if (allowedRoles && allowedRoles.indexOf(user.roleId) === -1) {
        if(user.roleId==1){
        this.router.navigate(['/members']); // Redirect to default page if user's role is not allowed
        return false;
        }
        else{
        this.router.navigate(['/organization']); // Redirect to default page if user's role is not allowed
        return false;
        }
      }

      // User is logged in and authorized
      return true;
    }

    // Not logged in so redirect to login page
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
