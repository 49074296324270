import { Component, OnInit, ViewChild } from '@angular/core';
import { MemberService } from '../shared/services/member.service';
import { VideoListResponse } from '../shared/models/user';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';

declare var $: any;

export interface VideoData {
  id: Number;
  title: string;
  description: string;
  link: Links;
  memberId: Number;
  createdDate: string;
}

export interface Links {
  linkId: Number;
  embeddedLink: string;
}

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.css']
})

export class VideoUploadComponent implements OnInit {

  users: VideoData[];
  dataSource: MatTableDataSource<VideoData>;
  totalItems: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  videouploadForm: FormGroup[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    "title",
    "roles",
    "create_for",
    "create_date",
    "actions",
  ];
  pageSize = 10;
  currentPage = 0;
  searchKey: any = '';
  token: string;

  redBorderIndices: number[] = [1, 3];
  redBorderValues: string[] = []; // 
  isShowtitle: boolean;
  videoId = 0;
  isshowEmbeded: boolean;
  videoLength: any;
  constructor(private memberService: MemberService, private formBuilder: FormBuilder, private spinnerService: NgxSpinnerService) {
    this.addForm();
  }



  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.getVideoList()
  }

  getVideoList() {

    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    let params = {
      "searchTitle": this.searchKey,
      "pageNo": this.currentPage + 1,
      "pageSize": this.pageSize
    }

    this.memberService.getVideoLists(params, token).subscribe(
      (data: any) => {
        if (data.statusCode === 200) {
          let userData = this.getVideoListArray(data.impactVideos);
          this.users = userData;
          this.dataSource = new MatTableDataSource(userData);
          // this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.totalItems = data.totalCount
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  getVideoListArray(memberList: VideoListResponse[]) {
    return memberList.map(member => ({
      id: member.impactId,
      title: member.title,
      description: member.description,
      link: member.link,
      memberId: member.memberId,
      memberName: member.memberName,
      createdDate: this.GetFormattedDate(member.createdDate),
    }));
  }

  filterEvent(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0)
      this.getVideoList()
  }

  applyFilter() {
    if (this.searchKey != '' && this.searchKey != undefined)
      this.getVideoList()
  }

  GetFormattedDate(date: string) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    return month + "-" + day + "-" + year;
  }

  getAppendValue = value => {
    if (value <= 9) {
      return `0${value}`;
    }
    return value;
  };


  addForm() {
    this.isShowtitle = false
    this.isshowEmbeded = false

    this.videouploadForm.push(this.createForm());
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      title: ['', Validators.required],
      description: [''],
      embeddedLink: this.formBuilder.array([this.createLinkGroup()], Validators.required)
    });
  }


  createLinkGroup() {
    return this.formBuilder.group({
      embeddedLink: ['', Validators.required]
    });
  }

  addEmbeddedLink(formIndex: number) {
    this.isshowEmbeded = false;
    const embeddedLink = (this.videouploadForm[formIndex].get('embeddedLink') as FormArray);
    embeddedLink.push(this.createLinkGroup());
  }

  removeEmbeddedLink(formIndex: number, linkIndex: number) {
    console.log(formIndex, linkIndex)
    const embeddedLink = this.videouploadForm[formIndex].get('embeddedLink') as FormArray;
    embeddedLink.removeAt(linkIndex);
  }


  // Remove form 
  removeForm(linkIndex) {
    console.log(linkIndex)
    if (linkIndex >= 0 && linkIndex < this.videouploadForm.length) {
      this.videouploadForm.splice(linkIndex, 1);
    }
  }

  editVideo(row) {

    console.log(row)
    this.resetForm()
    $("#newformCreate").modal("show");
    this.videouploadForm[0].get('title').setValue(row.title);
    this.videouploadForm[0].get('description').setValue(row.description);
    const embeddedLink = this.videouploadForm[0].get('embeddedLink') as FormArray;
    this.videoId = row.id
    // Clear existing controls
    embeddedLink.clear();
    this.videoLength = row.link.length;
    // Iterate over each link in row.link and set the value for each control in embeddedLink
    for (const link of row.link) {
      const linkGroup = this.formBuilder.group({
        linkId: [link.linkId],
        embeddedLink: [link.embeddedLink, Validators.required]
      });
      embeddedLink.push(linkGroup);
    }

  }

  hide() {
    $("#newformCreate").modal("hide");
  }


  resetForm() {
    this.isShowtitle = false
    this.isshowEmbeded = false

    this.videouploadForm = []
    this.videouploadForm.push(this.createForm());
    this.videouploadForm.forEach((formGroup) => {
      formGroup.reset(); // Reset each form group
    });
  }

  openAddVideo() {
    this.resetForm()
    this.videoId = 0
    $("#newformCreate").modal("show");
  }

  deleteVideos(id) {
    Swal.fire({
      title: "Delete ImpactShowVideo",
      text: "Are you sure?  Do you want to delete ImpactShowVideo?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        this.spinnerService.show();
        let params = {
          "impactId": id
        }
        this.memberService
          .deleteVideo(params, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                Swal.fire({
                  icon: 'success',
                  title: 'Deleted Successfully',
                  showCloseButton: true,
                  allowOutsideClick: false,
                }).then(() => {
                  this.spinnerService.hide();
                  this.ngOnInit()
                });
                this.spinnerService.hide();
                // this.sharedService.successAlert(() => {
                //   this.ngOnInit()
                // });
                return;
              }
              else {
                // this.spinnerService.hide();
                // this.sharedService.errorAlert(() => {
                //   this.ngOnInit()
                // });
                return;
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    })

  }

  submitAllForms() {

    let allFormsValid = true;


    // Check Validation
    this.videouploadForm.forEach((formGroup, index) => {
      // Check if the current form group is valid
      if (formGroup.valid) {
        console.log(`Form Group ${index + 1} Values:`, formGroup.value);
      } else {
        if (formGroup.get('title').invalid) {
          this.isShowtitle = true
        }
        console.log(formGroup.get('title').invalid)
        console.error(`Form Group ${index + 1} is not valid.`);
        allFormsValid = false;
      }
    });

    // Validation return the value
    if (allFormsValid) {
      const values = this.videouploadForm.map((formGroup, index) => {
        const formValue = { ...formGroup.value, impactId: this.videoId };
        return formValue;
      });

      let user = JSON.parse(localStorage.getItem("currentUser"));
      let token = "Bearer" + " " + user.jwtToken;
      this.spinnerService.show();

      if (this.videoId == 0) {

        const outputData = values.map(item => {
          const extractedLinks = item.embeddedLink.map(embeddedLink => embeddedLink.embeddedLink);
          return {
            title: item.title,
            description: item.description,
            embeddedLink: extractedLinks,

          };
        });
        let params = {
          impactList: outputData
        }
        this.memberService
          .createVideoImpact(params, token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.spinnerService.hide();
                $("#newformCreate").modal("hide");
                Swal.fire({
                  icon: "success",
                  title: "Updated Successfully",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                  this.ngOnInit()
                });
                return;
              }
              else {
                this.isshowEmbeded = true
                Swal.fire({
                  icon: "warning",
                  title: "Invalid link",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                });
                this.redBorderValues = data.statusMessage?.split(", ").map(value => value.trim());
                this.spinnerService.hide();
              }
            },
            err => {

              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
      else {
        console.log(values[0]);
        let params = values[0]
        this.memberService
          .editVideoImpact(params, token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.spinnerService.hide();
                $("#newformCreate").modal("hide");
                Swal.fire({
                  icon: "success",
                  title: "Updated Successfully",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                  this.ngOnInit()
                });
                return;
              }
              else {
                this.redBorderValues = data.statusMessage?.split(", ").map(value => value.trim());
                Swal.fire({
                  icon: "warning",
                  title: "Invalid link",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                });
                this.spinnerService.hide();
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    }
    else {
      this.isshowEmbeded = true
      console.log("invalid")
    }

  }

  saveVideoURL() {
  }

  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize
    this.getVideoList()
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
    });
    return;
  }
}
