// Vendor Imports
import { Component, OnInit } from "@angular/core";
import { UserIdleService } from "angular-user-idle";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "ima-admin";
  constructor(private userIdle: UserIdleService, private router: Router) { }

  ngOnInit() {
    //Start watching for user inactivity.
    // this.userIdle.startWatching();

    // this.userIdle.onTimerStart().subscribe();

    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(() => {
    //   localStorage.clear();
    //   // this.router.navigate(["/login"]);
    //   this.restart();
    // });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
}
