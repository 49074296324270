
<div class="container custom-container">
    <div class="row">
      <div class="col-auto p-0">
        <app-sidenav></app-sidenav>
      </div>
      <div class="col p-0">
            <div class="p-0 mt-0" style="background-color: #fff;width: 100%;height: 100%;">
                <app-header></app-header>
                <div class="main-block">
                    <div class="d-flex justify-content-between align-items-start wrap-block create-col">
                        <div class="mt-2">
                          <div class="split-button" style="display:flex;gap:8px">
                            <!-- <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;"
                            (click)="openAddMemberModal()">
                              <img src="assets/img/plus.png" class="btn-img"/> New Member
                            </button>
                            <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;"
                            (click)="openbulkInsert()">
                              Bulk User Import
                            </button> -->
                          </div>
                        </div>
                        <div class="mt-2 wrap-block forms-search" style="display: flex; align-items: center; margin-right: 20px;">
                            <div style="margin-right: 20px;" class="form-search-sub">
                                <mat-form-field style="width: 100%; margin-top: auto;">
                                  <mat-select [(value)]="selectedType" 
                                              class="user-select" 
                                              placeholder="User Type"
                                              (selectionChange)="onSelectChange($event)" 
                                              panelClass="custom-dropdown-panel" 
                                              disableOptionCentering>
                                    <mat-option [value]="0">None</mat-option>
                                    <mat-option *ngFor="let role of roleTypes" [value]="role.id">
                                      {{ role.role }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <!-- (keyup)="filterEvent($event)" -->
                          <div class="search-form-field searchType-input">
                            <mat-form-field>
                              <input matInput  (keyup)="filterEvent($event)" [(ngModel)]="searchKey" placeholder="Type to Search" />
                            </mat-form-field>
                            <mat-icon class="searchIcon" style="cursor: pointer;"(click)="applyFilter($event)" >search</mat-icon>
                          </div>
                        </div>
                      </div>
                  
                      <div class="mat-elevation-z8 member-table table-container mobile-scroll">
                      <table mat-table [dataSource]="dataSource" class="mat-table">
                        <!-- Ticket ID Column -->
                        <ng-container matColumnDef="ticketId">
                          <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Ticket ID </th>
                          <td mat-cell *matCellDef="let element"[ngStyle]="{'color': getBackgroundColor(element.userTypeId)}"> {{element.ticketId || 'N/A'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="User Type">
                          <th class="mat-table-header" mat-header-cell *matHeaderCellDef> User Type </th>
                          <td mat-cell *matCellDef="let element" style="white-space: nowrap">
                            <span class="circle-span" [ngStyle]="{'background-color': getBackgroundColor(element.userTypeId)}"></span>
                             <span style="margin-left: 7px;">{{getUserType(element.userTypeId) || 'N/A'}}</span> </td>
                        </ng-container>
                        <!-- Subject Column -->
                        <ng-container matColumnDef="subject">
                          <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Subject </th>
                          <td mat-cell *matCellDef="let element"  style="width: 20%;  min-height: 50px; padding: 20px;"> {{element.subject}} </td>
                        </ng-container>
                      
                        <!-- Comments Column -->
                        <ng-container matColumnDef="comments">
                          <th class="mat-table-header" mat-header-cell *matHeaderCellDef  style="width: 30%; text-align: left;"> Comments </th>
                          <td mat-cell *matCellDef="let element"  style="width: 30%;  min-height: 50px; padding: 20px;" > {{element.comments}} </td>
                        </ng-container>
                      
                        <!-- Created Date Column -->
                        <ng-container matColumnDef="createdDate">
                          <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Created Date </th>
                          <td mat-cell *matCellDef="let element"> {{element.createdDate | date:'dd/MM/yyyy'}} </td>
                        </ng-container>
                       
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                      
                      <div *ngIf="this.helpDeskList?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
                        <p>no records found </p>
                      </div>
                      
                          <mat-paginator [pageSizeOptions]="[5,10, 25, 100]" showFirstLastButtons [length]="length" [(pageSize)]="PageSize"
                          [(pageIndex)]="pageNo" (page)="onPaginatorClick($event)" class="metaPaginate">
                        </mat-paginator>
                      </div>
                      
                </div>
            </div>
      </div>
    </div>
</div>