import { AbstractControl, ValidationErrors } from "@angular/forms";

export const PasswordStrengthValidator = function(
  control: AbstractControl
): ValidationErrors | null {
  let value: string = control.value || "";

  if (!value) {
    return null;
  }

  let upperCaseCharacters = /[A-Z]+/g;
  if (upperCaseCharacters.test(value) === false) {
    return {
      passwordStrength: `Should contain one upper case character`
    };
  }

  let lowerCaseCharacters = /[a-z]+/g;
  if (lowerCaseCharacters.test(value) === false) {
    return {
      passwordStrength: `Should contain one lower case character`
    };
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (specialCharacters.test(value) === false) {
    return {
      passwordStrength: `Should contain one special character`
    };
  }

  let numberCharacters = /[0-9]+/g;
  if (numberCharacters.test(value) === false) {
    return {
      passwordStrength: `Should contain one number`
    };
  }

  return null;
};
