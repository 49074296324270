import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private Ref: MatDialogRef<ModalComponent>) { }
  public test: string = "";
  ngOnInit(): void {
  }
  Closepopup() {
    this.Ref.close(this.test);
    this.test = ""
  }

  closemodal() {
    this.Ref.close('Done')
  }
}