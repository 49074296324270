
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { MemberService } from '../shared/services/member.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.css']
})
export class HelpdeskComponent implements OnInit {
  displayedColumns: string[] = ['ticketId','User Type', 'subject', 'comments', 'createdDate'];
  dataSource: MatTableDataSource<any>;
  selectedType: Number;
  token: string;
  roleList: any;
  length:Number;
  pageNo:number=0;
  PageSize:Number=10;
  searchKey: any = '';
  roleTypes = [];
  color:string;
  helpDeskList :any[]=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService, private formBuilder: FormBuilder, private router: Router) {
  }
  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    // this.dataSource.paginator = this.paginator;
    this.dataSource = new MatTableDataSource(this.helpDeskList);
    this.getRoles();
    this.getHelpdesk(this.pageNo+1,this.PageSize,this.searchKey,this.selectedType);
    
  }
  getRoles() {
  // console.log('jnjnn');
   this.memberService
      .getRoleValues(this.token)
      .subscribe(
        (data:any) => {
          console.log('data',data)
          this.roleList = data;
          this.roleTypes = this.roleList.roleType
          console.log('role',this.roleTypes);
          this.getHelpdesk(this.pageNo+1,this.PageSize,this.searchKey,this.selectedType);
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
            this.spinnerService.hide();
          }
        }
      );
  }
  getHelpdesk(pageNo, pageSize, searchKey, roleType) {
    let params = {
      "search": searchKey.trim(),
      "userTypeId": roleType,
      "pageNo": pageNo,
      "pageSize": pageSize
    };
    this.spinnerService.show();
    // Assign the data to the data source for the table to render
    this.memberService.getHelpDesk(params, this.token).subscribe(
      (data: any) => {
        // this.ishubspotLoading = false;
        if (data.statusCode == 200) {
          console.log(data.statusCode)
          console.log(data);
          this.length=data.filteredCount;
          this.helpDeskList=data.helpDeskList;
          
          this.dataSource = new MatTableDataSource(this.helpDeskList);
          this.spinnerService.hide();
        }
        else {
          // this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();

        }
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }
  getBackgroundColor(id):string{
    if(id==161){
      return "#963bf1";
    }
    else if(id==160){
      return "#009EFB";
    }
    else if(id==170){
      return "#04B440";
    }
    else{
      return "red";
    }
  }
 getUserType(usertype:number):string{
  if(usertype==0){
    return"";
  }
  if(usertype==160){
     return "Regular";
  }
  else if(usertype==161){
     return "Most Privileged"
  }
  else if(usertype==170){
    return "Privileged"
  }
  else{
  let s=this.roleTypes.filter((value,key)=>{
    if(value.id==usertype){
    return value.role;
    }
    
    
  });
 
  console.log(s[0]);
//  let element=this.roleList.map((value,key)=>value.roleTypes);
//  element=element.filter((value,key)=>value.roleId==usertype);
//  console.log(element);
// if(s[0].role=='Regular'){
// this.color="green";
// }
// else if(s[0].role=='Most Privileged'){
// this.color="orange";
// }
// else{
// this.color="red";
// }
 return s[0].role;
}
 }
  onSelectChange(event: any): void {
    // Handle the change event here
    console.log('Selected value:', this.selectedType);
    console.log('page',this.pageNo,'pagesize',this.PageSize,'search',this.searchKey,'type',this.selectedType);
    this.getHelpdesk(this.pageNo+1,this.PageSize,this.searchKey,this.selectedType);
    // You can perform additional actions or logic based on the selected value
  }
  filterEvent(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim();
    console.log(filterValue.length);
    if(filterValue.length==0){
      this.getHelpdesk(this.pageNo+1,this.PageSize,filterValue,this.selectedType);
     }
    
  }
  applyFilter(event: Event) {
    console.log(this.searchKey.trim());
    this.getHelpdesk(this.pageNo+1,this.PageSize,this.searchKey.trim(),this.selectedType);
    
  }
  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.getHelpdesk(event.pageIndex + 1, event.pageSize, this.searchKey, this.selectedType)
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);

    });
    return;
  }
}
