// Vendor Imports
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Local Imports
import { MemberService } from "../shared/services/member.service";
import { HubspotConnectionRequestListResponse, MemberListResponse, MembertoHubsportListRequest } from "../shared/models/user";
import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";
import { States } from "../shared//models/mock-states";
import { NgxSpinnerService } from "ngx-spinner";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { PusherService } from "src/services/pusher-service.service";
import { DmCountService } from "src/services/dm-count.service";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { ChatsComponent } from "../chats/chats.component";
import { OrganisationService } from "../shared/services/Organization.service";
import { Observable } from 'rxjs';

declare var $: any;
declare const Pusher: any;




  @Component({
    selector: 'app-organization-digital-idcard',
    templateUrl: './organization-digital-idcard.component.html',
    styleUrls: ['./organization-digital-idcard.component.css'],

  animations: [
    trigger('modalAnimation', [
      state('open', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'scale(0.8)'
      })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class OrganizationDigitalIdcardComponent implements OnInit {
  
  OrganizationForm: FormGroup;
  addIdForm = false;
  OrganizationSubmitted = false;
  isLoading = false;
  token: string;
  selectedFile: File = null;

  logoUrl = 'https://imaapistorage.blob.core.windows.net/imaapistorage/techresolutions_logo-06-25-2024-7:13-AM.jpg';
  name = 'Ramanathan';
  Designation = 'Software Engineer';
  Email = 'ramanathan@datanetiix.com';
  CardId='DNX001';
  
 date = new Date();
 

  
  constructor(
    private Organizationservice: OrganisationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private pusherService: PusherService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    
  }
  onFileSelect(event: any) {
    this.selectedFile = event.target.files[0];
    }

  
 GenrateIdcard() {
    $("#newIdCardCreate").modal("show");
    this.addIdForm = true;
    this.OrganizationForm = this.formBuilder.group({
      firstName: ["", [Validators.required, WhiteSpaceValidator]],
      lastName: ["", [Validators.required, WhiteSpaceValidator]],
      designation: ["", [Validators.required, WhiteSpaceValidator]],
      organizationId: ["", [Validators.required, WhiteSpaceValidator]],
      waterMark: ["", [Validators.required, WhiteSpaceValidator]],
      backgroundImageName: ["", [Validators.required, WhiteSpaceValidator]],
      // backgroundImage:[""],
      bgColor: ["", [Validators.required, WhiteSpaceValidator]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
     
    });
  }

//   // convenience getter for easy access to basic info form fields
  get OrganizationControl() {
    return this.OrganizationForm.controls;
  }

  checkAllFieldsEntered(): boolean {
    return Object.values(this.OrganizationForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }

  IDFormSubmit() {
   
    this.OrganizationSubmitted = true;
    console.log(this.OrganizationForm)
    if (this.OrganizationForm.invalid) {
      console.log("Hey");
      return;
    }

    this.OrganizationSubmitted = false;
    this.isLoading = true;
    const body = new FormData();

    Object.keys(this.OrganizationForm.controls).forEach(key => {
      // if(key !='backgroundImage'){
        body.append(key, this.OrganizationForm.get(key)?.value);
      // }
    });
    
    body.append('backgroundImage',this.selectedFile)
    body.forEach((value, key) => {
     console.log(key+"  "+value );
  });
  this.spinnerService.show();
  this.Organizationservice.generateIdCard(body, this.token).subscribe(
    (response: { blob: Blob, status: number, statusText: string }) => {
      console.log('Status Code:', response.status);
      console.log('statusMessage',response.statusText);
      console.log('response',response.blob);
      if (!response.blob) {
        this.isLoading = false;
        this.spinnerService.hide();
        Swal.fire({
          icon: 'warning',
          title:response.statusText,
          showCloseButton: true,
          allowOutsideClick: false
        });
      } else {
        const url = window.URL.createObjectURL(response.blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Dnx.pdf'; // Replace with your desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.isLoading = false;
        this.addIdForm = false;
        this.spinnerService.hide();
        $("#newIdCardCreate").modal("hide");
        
        Swal.fire({
          title: 'Created!',
          text: 'ID created successfully',
          icon: 'success',
          showCloseButton: true,
          allowOutsideClick: false
        });
      }
    },
    err => {
      this.spinnerService.hide();
      console.log(err);
    }
  );
  }  
 
  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();

    });
    return;
  }


}