<div class="usersChatBox">
    <div class="userChatHead d-flex justify-content-between">
        <div class="chatUserName">{{this.userName}}</div>
        <div style="display: flex;">
            <div style="margin-right: 15px;">
                <!-- <img style="width: 18px;height: 18px;" src="assets/img/expand.png" alt="Close" /> -->
            </div>
            <div (click)="hideChatBox()" class="closeButton" style="cursor: pointer;">
                <img style="fill: green;" src="assets/svg/white-close.svg" alt="Close" />
            </div>
        </div>

    </div>

    <div class="chat-scroll" #chatListElement>
        <div class="description" *ngFor="let messages of msglists;let i = index;">
          <div class="datedisplay" style="display: flex; justify-content: center; align-items: center;">
            <p >{{messages.dateDescription}}</p>
            <!-- style="padding: 5px; border: 2px solid whitesmoke; background-color: whitesmoke;border-radius: 5px; " -->
            <!-- <p *ngIf="i==msglists.length-2" style="padding: 5px; border: 2px solid whitesmoke; background-color: whitesmoke;border-radius: 5px; ">{{messages.day}}</p> -->
          </div>
          
          <div class="chat-area">
            <ng-container>
                
              <div *ngFor="let message of messages.messages" class="message" [ngClass]="{'sender': message.alignment === 'R'}" style="width:100%">
                <div class="description" >   <p  [ngStyle]="{
                    'position': 'relative',
                    'top': '13px',
                    'font-size': 'small',
                    'display': 'flex',
                    'justify-content': message.alignment === 'R' ? 'end' : 'start'
                  }" class="chat-time">{{formatDateTime(message.createdAt,messages.dateDescription) }}</p>
                  <div class="message-content" style="display: flex; flex-direction: column;word-break: break-all;overflow-wrap: break-word;" [ngStyle]="{
                    'background-color': message.alignment === 'R' ? '#bcadd1' : 'rgb(220, 216, 216)',
                    'margin-left':message.alignment === 'R'?'27px':'0px'
                  }">
                    
                  <div class="messagetext" style="display: flex; justify-content: start;" >
                    <div class="view">
                      {{ message.messageText }}
                    </div>
                    
                  </div>
                  <!-- <div class="time" style="display: flex; justify-content: flex-end; font-size: small;">
                    <div class="dib" style="margin-bottom: 5px;">
                      <div class="view" style="padding-left:70px; position: relative; top: 15px; left: 8px;">
                        {{message.createdOnlyTime}}
                      </div>
                    </div>
                  </div> -->
                </div>
            </div>
              </div>
               
            </ng-container>
          </div>
        </div>
        <!-- The last element in the chat container -->
        <div #endOfChat></div>
      </div>
      
      <div class="d-flex flex-row" style="padding: 15px 10px 10px 10px;">
        <input type="text" class="form-control thread-input" placeholder="Type a message here" [(ngModel)]="chatText" />
        <div style="padding: 8px 8px;">
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="newChatLoading">
                <span class="sr-only">Loading...</span>
            </div>
            <img src="assets/svg/send.svg" (click)="sendMessage()" *ngIf="!newChatLoading" style="cursor: pointer" />
        </div>
    </div>
</div>




<!-- <div id="chatContainer">
    <app-sidebar (conversationClicked)="onConversationSelected($event)"></app-sidebar>
    <div id="chat">
        <div id="chatPlaceholder" *ngIf="!conversation">
            <img width="400" src="../assets/images/phoneIcon.png" />
            <h2>Keep your phone connected</h2>
            <p>
                WhatsApp connects your phone to sync messages. To reduce data<br />
                usage, connect to your phone to Wi-Fi.
            </p>
            <div class="seperator"></div>

            <p>WhatsApp is available for Mac. <a href="#">Get it here</a></p>
        </div>

        <app-chat [conversations]="conversations" *ngIf="conversations"></app-chat>
    </div>
</div> -->