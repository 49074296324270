// Vendor Imports
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

// Local Imports
import { MemberService } from "../shared/services/member.service";
import {
  MemberConnectionListResponse,
  MemberConnectionServiceList,
  MemberConnectionResponse,
  HubspotConnectionListResponse,
  HubspotConnectionRequestListResponse,
  HubspotConnectionPaidRequestListResponse
} from "../shared/models/user";
import { NgxSpinner } from "ngx-spinner/lib/ngx-spinner.enum";
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;

export interface HubspotConnectionList {
  // memberId: Number;
  // matchedMemberId: Number;
  // member: string;
  // matchedMember: string;
  // matchedServiceCount: Number;
  // matchedMemberDesignation: string;
  // matchedMemberLocation: string;
  // matchedMemberProfileImageUrl: string;
  // matchedMemberorganizationName: string;
  // actionStatus: string;

  hubspotId: Number;
  memberName: string;
  company: string;
  email: string;
  schedulerDate: string;
  phone: string;
  subscription: string;
  checked: boolean;
  paidchecked: boolean;
}

@Component({
  selector: "app-hubspot-members",
  templateUrl: "./hubspot-members.component.html",
  styleUrls: ["./hubspot-members.component.css"]
})
export class HubspotMembersComponent implements OnInit {
  showModal = false;
  approveLoading: boolean;
  revokeLoading: boolean;
  serviceRequest: boolean;
  services: MemberConnectionServiceList[];
  displayedColumns: string[] = [
    "member",
    "matchedMember",
    "matchedMemberProfile",
    "matchedTitle",
    "matchedOrganisation",
    "matchedLocation",
    "matchedServiceCount",
    //"review",
    //"approved"
  ];
  users: HubspotConnectionList[];
  dataSource: MatTableDataSource<HubspotConnectionList>;
  currentUser: HubspotConnectionList;
  pageSize: number = 10;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  hubsportusersList: HubspotConnectionRequestListResponse[] = [];
  hubsportpaidusersList: HubspotConnectionPaidRequestListResponse[] = [];
  adminselectvalue: boolean;
  paidadminselectvalue: boolean;
  ishubspotLoading: boolean;
  isLoading: boolean;
  isPaidLoading: boolean;
  filter: string;


  constructor(private memberService: MemberService, private router: Router, private spinnerService: NgxSpinnerService) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();

    // Assign the data to the data source for the table to render
    let params = {
    };
    this.memberService.getHubspotMemberList(params, token).subscribe(
      (data: { hubspotMember: HubspotConnectionListResponse[] }) => {
        this.ishubspotLoading = false;
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        let userData = this.getMemberListArray(data.hubspotMember);
        // console.log(userData)
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);
        this.dataSource.paginator = this.paginator;
        // console.log(this.dataSource.paginator)
        this.dataSource.sort = this.sort;
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getMemberListArray(memberList: HubspotConnectionListResponse[]) {
    return memberList.map(member => ({
      hubspotId: member.hubspotId,
      memberName: member.memberName,
      company: member.company,
      email: member.email,
      schedulerDate: member.schedulerDate,
      phone: member.phone,
      subscription: member.subscription,
      checked: false,
      paidchecked: false
    }));
  }

  reviewUser(currentUser: any) {
    this.serviceRequest = true;
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    let params = {
      memberId: currentUser.memberId,
      matchedMemberId: currentUser.matchedMemberId
    };
    $("#memberReviewModel").modal("show");
    this.currentUser = currentUser;
    this.showModal = true;
    // Assign the data to the data source for the table to render
    this.memberService.getMemberConnectionServices(params, token).subscribe(
      (data: { memberConnectServiceList: MemberConnectionServiceList[] }) => {
        // console.log(data.memberConnectServiceList)
        this.services = data.memberConnectServiceList;
        this.serviceRequest = false;
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  hubspot() {
    // console.log("clik")
    this.ishubspotLoading = true;
    this.spinnerService.show()
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    let params = {
    };
    this.memberService.postgetHubspotDetails(params, token).subscribe(
      (data: { statusCode: Number; statusMessage: string }) => {
        if (data.statusCode === 200) {
          this.hubspotRefresh()
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  hubspotRefresh() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    // Assign the data to the data source for the table to render
    let params = {
    };
    this.memberService.getHubspotMemberList(params, token).subscribe(
      (data: { hubspotMember: HubspotConnectionListResponse[] }) => {
        this.ishubspotLoading = false;
        let userData = this.getMemberListArray(data.hubspotMember);
        // console.log(userData)
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);
        this.dataSource.paginator = this.paginator;
        // console.log(this.dataSource.paginator)
        this.dataSource.sort = this.sort;
        setTimeout(() => {
          this.spinnerService.hide();
          Swal.fire({
            icon: "success",
            title: "Hubspot members updated in IMA App successfully",
            showCloseButton: true,
            allowOutsideClick: false
          }).then(() => {
            $("#memberEditModel").modal("hide");
            // this.hubsportpaidusersList = []
          });
          return;
        }, 800)

      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  //Bootstrap Modal Close event
  hide() {
    $("#memberReviewModel").modal("hide");
    this.showModal = false;
  }

  // approve(id: Number, matchId: Number) {
  //   this.approveLoading = true;
  //   let user = JSON.parse(localStorage.getItem("currentUser"));
  //   let token = "Bearer" + " " + user.jwtToken;
  //   let params = {
  //     memberId: id,
  //     matchedMemberId: matchId,
  //     actionStatus: "approved"
  //   };
  //   this.memberService.memberConnection(params, token).subscribe(
  //     (data: MemberConnectionResponse) => {
  //       if (
  //         data.statusCode === 200 &&
  //         data.statusMessage.toLowerCase() === "approved success"
  //       ) {
  //         this.users = this.users.map(user => {
  //           if (user.memberId === id && user.matchedMemberId === matchId) {
  //             return {
  //               memberId: user.memberId,
  //               matchedMemberId: user.matchedMemberId,
  //               member: user.member,
  //               matchedMember: user.matchedMember,
  //               matchedServiceCount: user.matchedServiceCount,
  //               matchedMemberDesignation: user.matchedMemberDesignation,
  //               matchedMemberLocation: user.matchedMemberLocation,
  //               matchedMemberProfileImageUrl: user.matchedMemberProfileImageUrl,
  //               matchedMemberorganizationName:
  //                 user.matchedMemberorganizationName,
  //               actionStatus: "approved",
  //               checked: false,
  //               paidchecked: false
  //             };
  //           }
  //           return user;
  //         });
  //         this.dataSource = new MatTableDataSource(this.users);
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //         $("#memberReviewModel").modal("hide");
  //         this.showModal = true;
  //         this.approveLoading = false;
  //         return;
  //       }
  //       if (
  //         data.statusCode === 200 &&
  //         data.statusMessage.toLowerCase() === "approved limit exceeded"
  //       ) {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "Approve limit exceeded for this member",
  //           allowOutsideClick: false,
  //           showCloseButton: true
  //         }).then(() => {
  //           this.approveLoading = false;
  //         });
  //         return;
  //       }
  //     },
  //     err => {
  //       if (err.status === 401) {
  //         this.unauthorizedException();
  //       }
  //     }
  //   );
  // }


  public getPaginatorData(event?: PageEvent) {
    // console.log(event)
    this.pageSize = event.pageSize
  }
  userFreeRegistration() {
    // console.log(this.hubsportusersList)
    if (this.hubsportusersList.length > 0) {
      let params = {
        "hubspotMemberList": this.hubsportusersList
      }
      let user = JSON.parse(localStorage.getItem("currentUser"));
      let token = "Bearer" + " " + user.jwtToken;

      this.isLoading = true;
      this.memberService
        .PostFreeSubscriptionAddedByAdmin(params, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.isLoading = false;
              this.filter = ''
              Swal.fire({
                icon: "success",
                title: "Updated Successfully",
                showCloseButton: true,
                allowOutsideClick: false
              }).then(() => {
                $("#memberEditModel").modal("hide");
                this.hubsportusersList = []
                this.ngOnInit()
              });
              return;
            }
            else if (data.statusCode === 400) {
              Swal.fire({
                icon: "warning",
                title: "Email ID already Exist",
                allowOutsideClick: false,
                showCloseButton: true
              }).then(() => {
                $("#memberEditModel").modal("hide");
                this.hubsportusersList = [];
                this.isLoading = false;
                this.ngOnInit()
              });
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }

  }

  userpaidRegistration() {
    // console.log(this.hubsportpaidusersList)
    if (this.hubsportpaidusersList.length > 0) {
      let params = {
        "hubspotMemberList": this.hubsportpaidusersList
      }
      let user = JSON.parse(localStorage.getItem("currentUser"));
      let token = "Bearer" + " " + user.jwtToken;

      this.isPaidLoading = true;
      this.memberService
        .PostPaidSubscriptionAddedByAdmin(params, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.isPaidLoading = false;
              this.filter = ''
              Swal.fire({
                icon: "success",
                title: "Updated Successfully",
                showCloseButton: true,
                allowOutsideClick: false
              }).then(() => {
                $("#memberEditModel").modal("hide");
                this.hubsportpaidusersList = []
                this.ngOnInit()
              });
              return;
            }
            else if (data.statusCode === 400) {
              Swal.fire({
                icon: "warning",
                title: "Email ID already Exist",
                allowOutsideClick: false,
                showCloseButton: true
              }).then(() => {
                $("#memberEditModel").modal("hide");
                this.hubsportusersList = [];
                this.isPaidLoading = false;
                this.ngOnInit()
              });
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );

    }
  }

  showOptions(event, row) {
    var isPresent = this.hubsportpaidusersList.some(function (el) { return el.hubspotMemberId === row.hubspotId });
    // console.log(isPresent);
    if (isPresent) {
      row.paidchecked = false;
      this.hubsportpaidusersList = this.hubsportpaidusersList.filter(({ hubspotMemberId }) => hubspotMemberId !== row.hubspotId);
    }
    if (event.checked) {
      this.hubsportusersList.push({
        "hubspotMemberId": row.hubspotId,
        "email": row.email,
        "freeSubscription": event.checked
      })
    }
    else {
      let index = this.hubsportusersList.findIndex(x => x.hubspotMemberId === row.hubspotId);
      // console.log(index);
      this.hubsportusersList.splice(index, 1);
      // if (this.hubsportusersList.length > 0) {
      //   this.adminselectvalue = true
      // }
      // else {
      //   this.adminselectvalue = false
      // }
    }
    // console.log("hubsportusersList", this.hubsportusersList)
    // console.log("hubsportpaidusersList", this.hubsportpaidusersList)

  }

  showPaidOptions(event, row) {
    // console.log(event.checked); //true or false

    var isPresent = this.hubsportusersList.some(function (el) { return el.hubspotMemberId === row.hubspotId });
    // console.log(isPresent);
    if (isPresent) {
      row.checked = false;
      this.hubsportusersList = this.hubsportusersList.filter(({ hubspotMemberId }) => hubspotMemberId !== row.hubspotId);

    }
    if (event.checked) {
      this.hubsportpaidusersList.push({
        "hubspotMemberId": row.hubspotId,
        "email": row.email,
        "paidSubscription": event.checked
      })
    }
    else {
      // this.hubsportpaidusersList = this.hubsportpaidusersList.filter(({ hubspotMemberId }) => hubspotMemberId !== row.hubspotId);
      let index = this.hubsportpaidusersList.findIndex(x => x.hubspotMemberId === row.hubspotId);
      // console.log(index);
      this.hubsportpaidusersList.splice(index, 1);
      // if (this.hubsportpaidusersList.length > 0) {
      //   this.paidadminselectvalue = true
      // }
      // else {
      //   this.paidadminselectvalue = false
      // }
    }

    // console.log("hubsportusersList", this.hubsportusersList)
    // console.log("hubsportpaidusersList", this.hubsportpaidusersList)
  }

  adminselect(event) {
    const checked = event.checked;
    // console.log(checked)
    if (checked) {
      this.users.forEach((val, index) => {
        if (index < this.pageSize) {
          val.checked = true
          this.hubsportusersList.push({
            "hubspotMemberId": val.hubspotId,
            "email": val.email,
            "freeSubscription": val.checked
          })
        }
      });
    }
    else {
      this.users.forEach((val, index) => {
        if (index < this.pageSize) {
          val.checked = false;
          this.hubsportusersList = []
        }
      });
    }

    // console.log(this.hubsportusersList)
  }


  getHubspotValues(hubsportusersList: HubspotConnectionRequestListResponse[]) {
    return hubsportusersList.map(member => ({
      hubspotMemberId: member.hubspotMemberId,
      freeSubscription: member.freeSubscription,
    }));
  }



  paidselect(event) {
    const checked = event.checked;
    // console.log(checked)
    if (checked) {
      this.users.forEach((val, index) => {
        if (index < this.pageSize) {
          val.paidchecked = true
          // console.log(val)
          this.hubsportpaidusersList.push({
            "hubspotMemberId": val.hubspotId,
            "email": val.email,
            "paidSubscription": val.paidchecked
          })
        }
      });
    }
    else {
      this.users.forEach((val, index) => {
        if (index < this.pageSize) {
          val.paidchecked = false;
          this.hubsportpaidusersList = []
        }
      });
    }
  }

  // revoke(id: Number, matchId: Number) {
  //   this.revokeLoading = true;
  //   let user = JSON.parse(localStorage.getItem("currentUser"));
  //   let token = "Bearer" + " " + user.jwtToken;
  //   let params = {
  //     memberId: id,
  //     matchedMemberId: matchId,
  //     actionStatus: "revoked"
  //   };
  //   this.memberService.memberConnection(params, token).subscribe(
  //     (data: MemberConnectionResponse) => {
  //       if (
  //         data.statusCode === 200 &&
  //         data.statusMessage.toLowerCase() === "revoke success"
  //       ) {
  //         this.users = this.users.map(user => {
  //           if (user.memberId === id && user.matchedMemberId === matchId) {
  //             return {
  //               memberId: user.memberId,
  //               matchedMemberId: user.matchedMemberId,
  //               member: user.member,
  //               matchedMember: user.matchedMember,
  //               matchedServiceCount: user.matchedServiceCount,
  //               actionStatus: "revoked"
  //             };
  //           }
  //           return user;
  //         });
  //         this.dataSource = new MatTableDataSource(this.users);
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //         $("#memberReviewModel").modal("hide");
  //         this.showModal = true;
  //         this.revokeLoading = false;
  //         return;
  //       }
  //       if (
  //         data.statusCode === 200 &&
  //         data.statusMessage.toLowerCase() === "approved limit exceeded"
  //       ) {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "Approve or Revoke limit exceeded",
  //           allowOutsideClick: false,
  //           showCloseButton: true
  //         }).then(() => {
  //           this.revokeLoading = false;
  //         });
  //         return;
  //       }
  //     },
  //     err => {
  //       if (err.status === 401) {
  //         this.unauthorizedException();
  //       }
  //     }
  //   );
  // }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }

  getProfileUrl(img: string) {
    if (img === null) {
      return "assets/img/user.png";
    } else {
      return img;
    }
  }
}
