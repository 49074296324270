// Vendor Imports
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class SignupService {
  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  signup(params: any) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postMemberRegisterWithPayment`, params)
      .pipe(catchError(this.handleError));
  }

  getHubsportMemberDetails(params: any) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postHubspotMemberRegisterData`, params)
      .pipe(catchError(this.handleError));
  }

  retrieveCoupon(params: any) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/getCouponRetrieve`, params)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
