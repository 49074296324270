import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
// Local Imports

import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";

import { NgxSpinnerService } from "ngx-spinner";
import { animate, state, style, transition, trigger } from "@angular/animations";

import { OrganisationService } from "../shared/services/Organization.service";
'@angular/core';
declare var $: any;
declare const Pusher: any;

@Component({
  selector: 'app-organisation-smtp',
  templateUrl: './organisation-smtp.component.html',
  styleUrls: ['./organisation-smtp.component.css']
})
export class OrganisationSmtpComponent implements OnInit {

  OrganizationForm: FormGroup;
  smtpform= false;
  OrganizationSubmitted = false;
  isLoading = false;
  token: string;
  mailServers=["smtp.gmail.com","smtp.office365.com","smtp.mail.yahoo.com","smtp.aol.com","smtp.mail.att.net","smtp.comcast.net","smtp.mail.me.com"]
  
  
  constructor(
    private Organizationservice: OrganisationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
   
  ) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    
  }
  
  
  createSmtp() {
    $("#newSmtpinsert").modal("show");
    this.smtpform = true;
    this.OrganizationForm = this.formBuilder.group({
      MailServer: ["", [Validators.required, WhiteSpaceValidator]],
      MailPort: ["", [Validators.required, WhiteSpaceValidator]],
      Password: ["", [Validators.required, WhiteSpaceValidator,
      Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=\S+$).{8,}$/)]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
     
    });
  }

//   // convenience getter for easy access to basic info form fields
  get OrganizationControl() {
    return this.OrganizationForm.controls;
  }

  checkAllFieldsEntered(): boolean {
    return Object.values(this.OrganizationForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }

  smtpSubmit() {
    this.OrganizationSubmitted = true;
    console.log(this.OrganizationForm)
    if (this.OrganizationForm.invalid) {
      console.log("Hey");
      return;
    }

    this.OrganizationSubmitted = false;
    this.isLoading = true;
    this.spinnerService.show();
    this.Organizationservice.SmtpInsert(this.OrganizationForm.value,this.token).subscribe(
      (data: any) => {
        console.log(data.statusCode);
        if (data.statusCode === 200) {
          // this.isLoading = false;
          this.smtpform = false;
          this.spinnerService.hide();
          $("#newSmtpinsert").modal("hide");
          
          Swal.fire({
            title: "Created!",
            text: "Smtp Created Successfully",
            icon: "success",
            showCloseButton: true,
            allowOutsideClick: false
          });
        } else {
          $("#newSmtpinsert").modal("hide");
          this.isLoading = false;
          Swal.fire({
            text: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
        else if(err.status === 403){
          this.unauthorizedException();
        }
      }
    );
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Access Denied",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();

    });
    return;
  }


}


