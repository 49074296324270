<!-- <div id="mySidenav" class="sidenav" [ngStyle]="{ width: navToggle ? '280px' : '0px', padding: navToggle ? '25px 30px' : '0px' }"> -->
  <div id="sidebar" class="collapse collapse-horizontal show border-end">
    <!-- <div class="sidenav-border"
      [ngStyle]="{'height': '200px', 'display': 'flex', 'justify-content': 'center', 'align-items': 'center', 'margin-top': (activeTab === '/terms' || activeTab === '/settings') ? '15%' : '20px'}"> -->
  
      <!-- <span class="closebtn" (click)="toggleNav()">×</span> -->
      <!-- <img [src]="UserDtls == '1' ? profileimage: 'assets/img/applogo.png'" style="height: 80%" /> -->
  
    <!-- </div> -->
  
    <!-- <a routerLink="/dashboard" [ngClass]="{
                  'sidenav-border': true,
                  active: activeTab === '/dashboard'
                }" (click)="toggleNav()">
      Dashboard
    </a> -->
    <div class="tmz-block">
      <img [src]="UserDtls == 1 ? 'assets/img/TMZ_logo.png' : 'assets/img/applogo.png'" style="width:49px ; height:49px" />
    </div>
    <div id="sidebar-nav" class="list-group border-0 rounded-0 text-sm-start min-vh-100">
    <a routerLink="/members" *ngIf="this.UserDtls ==1" [ngClass]="{
      'sidenav-border': true,
      active: activeTab === '/members'
    }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltipPosition="right" matTooltip="Members"><img class="dark-icon member-icon" src="assets/img/member.png" /><img class="light-icon member-icon" src="assets/img/member-white.png" /> Members</a>
  
    <a routerLink="/manageusertype" *ngIf="this.UserDtls ==1" [ngClass]="{
          'sidenav-border': true,
          active: activeTab === '/manageusertype' 
        }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltipPosition="right" matTooltip="User Type"><img class="dark-icon user-icon" src="assets/img/usertype.png" /><img class="light-icon user-icon" src="assets/img/user-light.png" /> User Type</a>
  
  
    <ng-container *ngFor="let item of modules">
      <a *ngIf="item.isActive && isExcludedModule(item.uniqueCode)" [routerLink]="getRouteAndIcon(item.uniqueCode,true).routing" [ngClass]="{
                'sidenav-border': true,
              active:activeTab ===  getRouteAndIcon(item.uniqueCode,true).routing
              }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltipPosition="right" matTooltip = "{{ item.moduleName }}">
              <img class="dark-icon announce-icon" [src]="getRouteAndIcon(item.uniqueCode,true).routingIconUrl"  /> <img class="light-icon announce-icon" [src]="getRouteAndIcon(item.uniqueCode,false).routingIconUrl" />  {{item.moduleName}}
      </a>
    </ng-container>
  
  
    <!-- <ng-container *ngFor="let item of moduleList">
      <a *ngIf="item.isActive && isExcludedModule(item.uniqueCode)" [routerLink]="generateRouterLink(item)" [ngClass]="{
              'sidenav-border': true,
              'active': isActiveLink(item)
            }" (click)="toggleNav()">
        {{item.moduleName}}
      </a>
    </ng-container> -->
  
  
  
    <!-- <ng-container *ngFor="let item of modules">
      <a *ngIf="item.isActive && item.uniqueCode !='#001ChatM2A'  && item.uniqueCode !='#001ChatDuration24' && item.uniqueCode !='#001ChatDuration8AMto6PM'  && item.uniqueCode !='#001ChatM2M'"
        [routerLink]="generateRouterLink(item)" [ngClass]="{
          'sidenav-border': true,
        'active': isActiveLink(item)
        }" (click)="toggleNav()">
        {{item.moduleName}}
      </a>
    </ng-container>
  
    <ngx-spinner size="default" color="#fff" type="square-loader" [fullScreen]="true">
      <p style="color: white"> Loading... </p>
    </ngx-spinner>
  
  
  
  
  
    <a routerLink="/manageSubscription" *ngIf="this.UserDtls ==2" [ngClass]="{
            'sidenav-border': true,
            active: activeTab === '/manageSubscription'
          }" (click)="toggleNav()">Subscription Management</a>
  
    <a routerLink="/modulemanage" *ngIf="this.UserDtls ==2" [ngClass]="{
            'sidenav-border': true,
            active: activeTab === '/modulemanage'
          }" (click)="toggleNav()">Module Management</a>
  
  
  
     <a routerLink="/manageusertype" *ngIf="this.UserDtls ==1" [ngClass]="{
            'sidenav-border': true,
            active: activeTab === '/manageusertype' 
          }" (click)="toggleNav()">User Type</a> -->
  
    <!-- <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title style="font-size: 16px;
          padding-left: 10px;">
          Subscription Managment
        </mat-panel-title>
      </mat-expansion-panel-header>
      <a routerLink="/manageSubscription" style="font-size: 16px;" [ngClass]="{
            'sidenav-border': true,
            active: activeTab === '/manageSubscription'
          }" (click)="toggleNav()">Assign Subscription</a>
  
      <a routerLink="/addmodule" style="font-size: 16px;" [ngClass]="{
            'sidenav-border': true,
            active: activeTab === '/addmodule'
          }" (click)="toggleNav()">Module Managment</a>
  
    </mat-expansion-panel> -->
  
    <!-- 
    <a routerLink="/manageSubscription" [ngClass]="{
            'sidenav-border': true,
            active: activeTab === '/manageSubscription'
          }" (click)="toggleNav()">Subscription Managment</a> -->
  
    <a routerLink="/organization" *ngIf="this.UserDtls ==2" [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/organization'
      }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltipPosition="right" matTooltip="Organizations"><img class="dark-icon organise-icon" src="assets/img/user-add.png" /><img class="light-icon organise-icon" src="assets/img/organise-light.png" /> Organizations</a>
  
  
    <a routerLink="/manageSubscription" *ngIf="this.UserDtls ==2" [ngClass]="{
                    'sidenav-border': true,
                    active: activeTab === '/manageSubscription'
                  }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltipPosition="right" matTooltip="Subscription Management"><img class="dark-icon mgmt-icon" src="assets/img/office-chair.png" /><img class="light-icon mgmt-icon" src="assets/img/subscribe-light.png" /> Subscription Management</a>
  
    <a routerLink="/modulemanage" *ngIf="this.UserDtls ==2" [ngClass]="{
                    'sidenav-border': true,
                    active: activeTab === '/modulemanage'
                  }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltipPosition="right" matTooltip="Module Management"><img class="dark-icon module-icon" src="assets/img/cash.png" /><img class="light-icon module-icon" src="assets/img/cash-light.png" /> Module Management</a>
  
    <a routerLink="/digital-id-card" *ngIf="this.UserDtls !=2 && this.UserDtls !=1 " [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/digital-id-card'
      }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltipPosition="right" matTooltip="Digital-id-card"> <img class="dark-icon announce-icon" src="assets/img/office-chair.png" /><img class="light-icon announce-icon" src="assets/img/cash-light.png" /> Digital-id-card</a>
    <!-- <a routerLink="/organizationSmtp" *ngIf="this.UserDtls ==1" [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/organizationSmtp' 
      }" (click)="toggleNav()">Smtp-Organization</a> -->
    <!-- <a routerLink="/event" *ngIf="this.UserDtls ==1" [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/event' 
      }" (click)="toggleNav()">Events</a>
    <a routerLink="/blogs" *ngIf="this.UserDtls ==1" [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/blogs' 
      }" (click)="toggleNav()">Blogs</a> -->
    <!-- 
    <a routerLink="/connect-members" *ngIf="this.UserDtls ==1" [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/s'
      }" (click)="toggleNav()">Add Members</a> -->
  
    <!-- <a routerLink="/rolemanage" *ngIf="this.UserDtls !=2" [ngClass]="{
                  'sidenav-border': true,
                  active: activeTab === '/rolemanage'
                }" (click)="toggleNav()">User Role Managment</a> -->
  
    <!-- <a routerLink="/annouce" *ngIf="this.UserDtls !=2" [ngClass]="{
              'sidenav-border': true,
              active: activeTab === '/annouce'
              }" (click)="toggleNav()">Announcement</a> -->
    <!-- 
    <a routerLink="/sharepoint" *ngIf="this.UserDtls !=2" [ngClass]="{
      'sidenav-border': true,
      active: activeTab === '/sharepoint'
      }" (click)="toggleNav()">Forms and Application</a> -->
  
    <!-- <a routerLink="/video-upload" *ngIf="this.UserDtls ==1" [ngClass]="{
              'sidenav-border': true,
              active: activeTab === '/video-upload'
            }" (click)="toggleNav()">Video Upload</a> -->
  
    <!-- <a routerLink="/settings" *ngIf="this.UserDtls !=2" [ngClass]="{
      'sidenav-border': true,
      active: activeTab === '/settings'
      }" (click)="toggleNav()">Settings</a> -->
      <a routerLink="/helpdesk" *ngIf="this.UserDtls ==1" [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/helpdesk'
      }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltipPosition="right" matTooltip="Help Desk">
      <!-- <img class="dark-icon rules-icon" src="assets/img/book.png" />
      <img class="light-icon rules-icon" src="assets/img/rules-light.png" />  -->
      <mat-icon class="dark-icon module-icon" style="font-size: 18px; position: relative;top: 5px; color:#707b7c;">support_agent</mat-icon> 
      <mat-icon class="light-icon mgmt-icon" style="font-size: 18px; position: relative;top: 5px; color:white;">support_agent</mat-icon> 
      Help Desk</a>
  
    <a routerLink="/terms" *ngIf="this.UserDtls ==2" [ngClass]="{
            'sidenav-border': true,
            active: activeTab === '/terms'
          }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" matTooltipPosition="right" data-bs-parent="#sidebar" matTooltip="Rules and Guidelines"><img class="dark-icon rules-icon" src="assets/img/book.png" /><img class="light-icon rules-icon" src="assets/img/rules-light.png" /> Rules and Guidelines</a>
  
    <!-- <a routerLink="/coupon-discounts" [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/coupon-discounts'
      }" (click)="toggleNav()">Discount Coupons</a>
  
    <a routerLink="/request-list" [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/request-list'
      }" (click)="toggleNav()">Member Connection Request</a>
  
    <a routerLink="/help" [ngClass]="{
              'sidenav-border': true,
              active: activeTab === '/help'
      }" (click)="toggleNav()">Help</a> -->
    <!-- <a
      routerLink="/updates"
      [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/updates'
      }"
      (click)="toggleNav()"
      >Updates</a
    >
    <a
      routerLink="/update-comments"
      [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/update-comments'
      }"
      (click)="toggleNav()"
      >Update Comments</a
    > -->
    <!-- <a
      routerLink="/member-subscription"
      [ngClass]="{
        'sidenav-border': true,
        active: activeTab === '/member-subscription'
      }"
      (click)="toggleNav()"
      >Member Subscriptions</a
    > -->
    <a routerLink="/vendorgroup" class="icon" *ngIf="this.UserDtls ==1"  [ngClass]="{
      'sidenav-border': true,
      active: activeTab === '/vendorgroup'
    }" (click)="toggleNav()" matTooltipPosition="right" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltip="Vendor Group">
    <mat-icon  class="dark-icon module-icon" style="font-size: 18px; position: relative;top: 5px; color:#707b7c;">group_work</mat-icon>
    <mat-icon  class="light-icon mgmt-icon" style="font-size: 18px; position: relative;top: 5px; color:rgb(255, 255, 255);">group_work</mat-icon> Vendor Group</a> 
      <a routerLink="/vendor" *ngIf="this.UserDtls ==1"  [ngClass]="{
      'sidenav-border': true,
      active: activeTab === '/vendor'
      
    }" (click)="toggleNav()" matTooltipPosition="right" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltip="Vendor">
    <mat-icon  class="dark-icon module-icon" style="font-size: 18px; position: relative;top: 5px; color:#707b7c;">business</mat-icon>
    <mat-icon  class="light-icon mgmt-icon" style="font-size: 18px; position: relative;top: 5px; color:rgb(255, 255, 255);">business</mat-icon> Vendor </a> 
 


   
    



    <a routerLink="/category" class="icon" *ngIf="this.UserDtls ==1"  [ngClass]="{
      'sidenav-border': true,
      active: activeTab === '/category'
    }" (click)="toggleNav()" matTooltipPosition="right" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltip="category">
    <mat-icon  class="dark-icon module-icon" style="font-size: 18px; position: relative;top: 5px; color:#707b7c;">view_list</mat-icon>
    <mat-icon  class="light-icon mgmt-icon" style="font-size: 18px; position: relative;top: 5px; color:rgb(255, 255, 255);">view_list</mat-icon> Category</a> 
    <!-- <a routerLink="/buysubscriptions" *ngIf="this.UserDtls ==1" [ngClass]="{
      'sidenav-border': true,
      active: activeTab === '/buysubscriptions'
    }" (click)="toggleNav()" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar" matTooltip="Buy Subscription"><img class="dark-icon mgmt-icon" src="assets/img/office-chair.png" /><img class="light-icon mgmt-icon" src="assets/img/subscribe-light.png" /> Buy Subsription</a> -->
</div>
    <div style="height: 100px;">
  
    </div>
  </div>
