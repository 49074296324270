
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NgxMaskModule } from 'ngx-mask';
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
// Local Imports

import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";

import { NgxSpinnerService } from "ngx-spinner";
import { animate, state, style, transition, trigger } from "@angular/animations";

import { OrganisationService } from "../shared/services/Organization.service";

'@angular/core';
declare var $: any;
declare const Pusher: any;

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css'],
  animations: [
    trigger('modalAnimation', [
      state('open', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'scale(0.8)'
      })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]

})

export class OrganisationComponent implements OnInit {
  filtercount:any;
  pageShift:boolean=false;
  OrganizationForm: FormGroup;
  addOrgForm = false;
  OrganizationSubmitted = false;
  isLoading = false;
  formHeading: string;
  formButton: string;
  token: string;
  visible: boolean = false;
  statuscode: string;
  Search: string = "";
  subscriptionSelection: any = 0;
  organizationlist: any;
  selectedFile: File = null;
  totalcount: any;
  valueMapping :any;
  customvalidator:boolean=false;
  pageSize = 10;
  currentPage = 0;
  subscriptionlist = []
  organisationId: any;
  subscription: any = 1;
  blobData: Blob;
  logoUrl: string;
  logoDisplay: string;
  Email:string;
  subscriptionupdate:number;
  logoHide: boolean = true;
  validTypes=  ['image/jpeg', 'image/png', 'image/gif'];
  constructor(
    private organizationService: OrganisationService,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private router: Router) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.getSusbscriptionList()
    this.getOrganaisation();
    this.spinnerService.show();
    


  }
  getSusbscriptionList(){
  const response =this.organizationService.GetsubscriptionList(this.token).subscribe((data: any) => {
    if(data.statusCode==200){
      console.log(data);
       this.valueMapping = data.subscriptions.reduce((map, subscription) => {
        map[subscription.subscriptionType] = subscription.subscriptionId;
        return map;
      }, {});
      console.log('value Mapping',this.valueMapping);
      this.subscriptionlist=data.subscriptions.map((value)=>value.subscriptionType);
      console.log('subsscriptionList',this.subscriptionlist);
      console.log(this.valueMapping[this.subscriptionlist[0]])
    }

  }, (err) => {
    if (err.status === 401) {
      this.unauthorizedException();
      this.spinnerService.hide();
    }
      
  });

}



  getOrganaisation() {
    
    this.totalcount=this.filtercount
    let params = {
      "searchOrganaisation": this.Search.trim(),
      "pageNo": this.currentPage + 1,
      "subscriptionId": this.subscriptionSelection,
      "pageSize": this.pageSize
    }
    console.log(this.subscriptionSelection);
    const response = this.organizationService.organisationList(params,this.token).subscribe((data: any) => {
      console.log(data.organisationList);
      this.spinnerService.show();
      if (data.statusCode == 200) {
        this.organizationlist = data.organisationList;
        // this.totalcount = data.totalCount;
        this.totalcount = data.filteredCount
        this.filtercount=data.filteredCount;
        console.log(this.totalcount);
        this.spinnerService.hide();
      }
      else {
        this.ExceptionMessage(data.statusMessage, "warning");
        this.spinnerService.hide();
      }
    }, (err) => {
      // this.unauthorizedException() ;
      if (err.status === 401) {
        this.unauthorizedException();
        this.spinnerService.hide();
      }
    });
  }
  // Convert The Upload file Into Object 
  onFileSelect(event: any) {
    this.logoHide = false;
    if(this.OrganizationSubmitted==true||this.validTypes.includes(event.target.files[0].type)){
    this.customvalidator= ! this.validTypes.includes(event.target.files[0].type);
    }
    this.selectedFile = event.target.files[0];
  }

  // Access The Drop Down List Key Value 
  onListSelect(event: any) {
    const selectedOption = (event.target as HTMLSelectElement).value;//gold  silver 
    console.log(this.valueMapping);
    this.subscription = this.valueMapping[selectedOption]; // 3
    console.log(this.subscription);// 3
  }
  // for Insert Api
  onClickInsert() {
    let body = new FormData();
    this.OrganizationSubmitted = true;
    console.log(this.OrganizationForm)
    if (this.OrganizationForm.invalid ) {
      return;
    }
    this.OrganizationSubmitted = false;
    this.isLoading = true;
    this.customvalidator= ! this.validTypes.includes(this.selectedFile.type);
    if(! this.validTypes.includes(this.selectedFile.type)){
     
     return;

    }
    
    // Convert The Keys And Values into Formdata Object 
     
    body.append('name',this.OrganizationForm.get('name')?.value.trim())
    body.append('shortName',this.OrganizationForm.get('shortName')?.value.trim())
    body.append('contactEmail',this.OrganizationForm.get('contactEmail')?.value.trim())
    body.append('contactNumber',this.OrganizationForm.get('contactNumber')?.value.trim())
    body.append('subscriptionId', this.subscription);
    body.append('logoImg', this.selectedFile);
    body.append('logoImgName', this.OrganizationForm.get('name')?.value.trim());
    body.append('organisationId', this.organisationId);
    // this.customvalidator=this.validTypes.includes(this.selectedFile.type);
    body.forEach((value, key) => {
    console.log('key' + key + 'value' + value);

    });
    this.spinnerService.show();
    // Api Call For Update OrganizationUpdate 
    const response = this.organizationService.OrganizationInsert(body, this.token).subscribe((data: any) => {
    console.log(data.statusMessage);


      if (data.statusCode == 200) {
        this.visible = false;
        this.spinnerService.hide();
        this.getOrganaisation();
        $("#newOrgCreate").modal("hide");
        this.SucessMessage('Created', 'Organization  Inserted Successfully', 'success');
      }
      else  if (data.statusCode == 500) {
        
        this.ExceptionMessage('Something Went Wrong in the Server', 'warning');
        this.spinnerService.hide();
      }
      else {
        // $("#newOrgCreate").modal("hide");
        this.ExceptionMessage(data.statusMessage, 'warning');
        this.spinnerService.hide();
      }
    }, (err) => {
      // this.unauthorizedException() ;
      if (err.status === 401) {
        $("#newOrgCreate").modal("hide");
        this.unauthorizedException();
        this.spinnerService.hide();
      }
    });


  }

  onClickUpdate() {
    //this.downloadImage('https://imaapistorage.blob.core.windows.net/imaapistorage/Indusland-03-25-2024-6:05-PM.jpg');
    console.log(this.subscription);
    let body = new FormData();
    this.OrganizationSubmitted = true;
    console.log(this.OrganizationForm)
   
    if (this.OrganizationForm.invalid ) {
      return;
    }
    this.OrganizationSubmitted = false;
    this.isLoading = true;
   
    console.log('subscripton-->', this.subscription);
    let subscriptionvalue = this.subscription;
   
    if(this.selectedFile!=null){
    if(! this.validTypes.includes(this.selectedFile.type)){
      this.customvalidator= ! this.validTypes.includes(this.selectedFile.type);
      return;
    }
  }
    body.append('name',this.OrganizationForm.get('name')?.value.trim())
    body.append('shortName',this.OrganizationForm.get('shortName')?.value.trim())
    body.append('contactEmail',this.Email);
    body.append('contactNumber',this.OrganizationForm.get('contactNumber')?.value.trim())
    body.append('subscriptionId', subscriptionvalue);
    
    body.append('logoImg', this.selectedFile);
    body.append('logoImgName', this.OrganizationForm.get('name')?.value.trim());
    body.append('organisationId', this.organisationId);
    body.forEach((value, key) => {
      console.log('key   ' + key + 'value  ' + value);
    });
    this.spinnerService.show();
    const response = this.organizationService.OrganizationUpdate(body, this.token).subscribe((data: any) => {
      console.log(data.statusMessage);
      if (data.statusCode == 200) {
        // this.visibleupdate=false;
        this.SucessMessage('Updated', 'Organization Updated Successfully', 'success');
        $("#newOrgCreate").modal("hide");
        this.getOrganaisation();
         
      }
      
      else  {
        // $("#newOrgCreate").modal("hide");
        this.ExceptionMessage(data.statusMessage, 'warning');
        this.spinnerService.hide();
      }
    }, (err) => {

      if (err.status === 401) {
        // $("#newOrgCreate").modal("hide");
        this.unauthorizedException();
        this.spinnerService.hide();
      }
    });

  }
  hide(){
      this.selectedFile=null;
      $("#newOrgCreate").modal("hide");
      this.addOrgForm = false;
      this.isLoading = false;
      this.OrganizationSubmitted = false;
    
  }
// Insert Organization Model trigger
  openAddOrganizationModal() {
    this.customvalidator=false;  
    this.logoHide = false;
    this.selectedFile = null;
    this.formButton = 'Add';
    this.visible = true;
    this.formHeading = 'New Organization';
    $("#newOrgCreate").modal("show");
    this.addOrgForm = true;
    this.OrganizationForm = this.formBuilder.group({
      // organaisationId: ["", [Validators.required, WhiteSpaceValidator]],
      shortName: ['', [Validators.required,Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9,._ -]{2,}$/)]],
      subscriptionId: ["Free", [Validators.required, WhiteSpaceValidator]],
      logoimg: ["", [Validators.required, WhiteSpaceValidator]],
      name: ['', [Validators.required,Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9,._ -]{3,}$/) ]],
      contactNumber: ["", [Validators.required, Validators.pattern(/^[1-9][0-9]{9}$/)]],
      contactEmail: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],

    });
  }
// Event Trigger for Update 
  openUpdateOrganizationModal(data: any) {
    this.customvalidator=false; 
    this.selectedFile = null;
    this.logoUrl = data.logoImg;
    var s = this.logoUrl.split('/');
    this.logoDisplay = s[4];
    this.logoHide = true;
    this.formButton = 'Update';
    this.organisationId = data.organaisationId;
    this.visible = false;
    this.Email=data.contactEmail;
    this.subscriptionupdate=data.susbscriptionId

    // console.log('sample', data.organaisationId)
    this.formHeading = 'Update Organization';
    $("#newOrgCreate").modal("show");
    this.addOrgForm = true;
    this.OrganizationForm = this.formBuilder.group({
     
      // organaisationId: [data.organaisationId, [Validators.required]],
      shortName: [data.shortName, [Validators.required,Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9,._ -]{2,}$/)]],
      subscriptionId: [this.subscriptionlist[data.susbscriptionId - 1], [Validators.required]],
      logoimg: [],
      name: [data.organisationName, [Validators.required,Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9,._ -]{3,}$/) ]],
      contactNumber: [data.contactNumber.replace(/\D/g, data.contactNumber), [Validators.required, Validators.pattern(/^[1-9][0-9]{9}$/)]],
      contactEmail: [
        data.contactEmail,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],

    });
    
    this.subscription = data.susbscriptionId;
    console.log('Subscription', this.subscription);
  }
  // Search  Button 
  submitfilter() {
    this.currentPage=0;
    this.pageShift=true;
    this.spinnerService.show();
    this.getOrganaisation();
  }
  // Filter Drop down Click Event
  filterEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0){
      this.spinnerService.show();
      this.getOrganaisation();
    }
  }
  // convenience getter for easy access to basic info form fields
  get OrganizationControl() {
    return this.OrganizationForm.controls;
  }
  // Disable And Enable The button Only when the all The Fields Are Entered 
  checkAllFieldsEntered(): boolean {
    return Object.keys(this.OrganizationForm.controls).every(key => {
      // Skip Only For Update Module 
      if (key === "logoimg"&& ! this.visible ) {
        return true; // Skip validation for 'logoimg'
      }
      const control = this.OrganizationForm.get(key);
      return control && control.value !== '' && control.value !== null;
    });
  }
   
  onSelectChange(event: any): void {
    // Handle the change event here
   this.spinnerService.show()
    console.log('Selected value:', this.subscriptionSelection);
    this.currentPage = this.currentPage;
    this.pageSize = this.pageSize;
    this.getOrganaisation();
    // You can perform additional actions or logic based on the selected value

  }
  // Handle the Last Character for Delete in Contact number 
  handleKeyDown(event: KeyboardEvent): void {
    console.log('Key pressed:', event.key);
    if(event.key=='Backspace' && this.OrganizationForm.get('contactNumber').value.length==1){
    
      this.OrganizationForm.controls['contactNumber'].setValue(''); 
      
    }
    // Add your logic here based on the key pressed
  }

  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.spinnerService.show();
    // Update currentPage and pageSize based on event
    this.currentPage = event.pageIndex; // If your API expects 1-based page index, otherwise use event.pageIndex
    console.log('currentpage', this.currentPage);
    this.pageSize = event.pageSize;
    // Logging currentPage for debugging
    console.log(this.currentPage);

    // Fetch updated data
    this.getOrganaisation();

  }
  // Store The Given Number In Form Of Us format
  stripMask(phoneNumber) {
  
    const areaCode = phoneNumber.slice(0, 3);
    const centralOfficeCode = phoneNumber.slice(3, 6);
    const lineNumber = phoneNumber.slice(6, 10);
  
    return `(${areaCode})-${centralOfficeCode}-${lineNumber}`;
  }
  // Exception

  ExceptionMessage(title, icon) {
    Swal.fire({
      title: title,
      icon: icon,
      showCloseButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'OK'
    });
    return;
  }


  SucessMessage(title, text, icon) {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCloseButton: true,
      allowOutsideClick: false
    });
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/superadmin-login"]);
  });
    return;
  }

}


