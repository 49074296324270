<app-header></app-header>

<div class="p-5 mt-5" style="background-color: #f0f4f8;width: 100%;height: 100%;">
  <div class="d-flex justify-content-between">
    <div class="mt-2">
      <button type="button" class="btn btn-secondary btn-sm" style="background-color: #4C3A67;" (click)="createSmtp()">
        Create Smtp
      </button>
    </div>


    <ngx-spinner
      bdColor="rgba(0, 0, 0, .6)"
      class="spin-img"
      template="<img src='assets/img/logo-D.gif' />"
    >
    </ngx-spinner>


    <div class="modal fade" id="newSmtpinsert" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Create Smtp </h4>
              <!-- (click)="hide() -->
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <!-- Modal body -->
            <div class="modal-body mb-3">
              <!-- Coupon Discount Form -->
              <div class="container" *ngIf="smtpform">
                <form [formGroup]="OrganizationForm" (ngSubmit)="smtpSubmit()">
                  <div class="form-group mb-3">
                    <label for="lastName">Mail Server<span style="color: red;"> *</span></label>
                    <select id="mailServer" class="form-control signup-input" formControlName="MailServer">
                      <option *ngFor="let server of mailServers" [value]="server">{{ server }}</option>
                    </select>
                    <div *ngIf="OrganizationSubmitted && OrganizationControl.MailServer.errors" class="ml-2">
                      <div *ngIf="OrganizationControl.MailServer.errors.required" class="text-danger">
                        Mail Server required
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <label for="MailPort">Mail Port <span style="color: red;"> *</span></label>
                    <select class="form-control signup-input" id="mailPort" formControlName="MailPort">
                      <option value="587">587</option>
                      <option value="25">25</option>
                    </select>
                    <div *ngIf="OrganizationSubmitted && OrganizationControl.MailPort.errors" class="ml-2">
                      <div *ngIf="OrganizationControl.MailPort.errors.required" class="text-danger">
                        Mail Port is required
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="Email">Email<span style="color: red;"> *</span></label>
                    <input type="text" class="form-control signup-input" id="lastName" placeholder="Email"
                      formControlName="email" />
                    <div *ngIf="OrganizationSubmitted && OrganizationControl.email.errors" class="ml-2">
                      <div *ngIf="OrganizationControl.email.errors.required" class="text-danger">
                        Email required
                      </div>
                      <div *ngIf="OrganizationControl.email.errors.pattern" class="text-danger">
                        Enter valid email address
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="Password">Password<span style="color: red;"> *</span></label>
                    <input type="password" class="form-control signup-input" id="title" placeholder="Password"
                      formControlName="Password" />
                    <div *ngIf="OrganizationSubmitted && OrganizationControl.Password.errors" class="ml-2">
                      <div *ngIf="OrganizationControl.Password.errors.required" class="text-danger">
                        Password Name required
                      </div>
                      <div *ngIf="OrganizationControl.Password.errors.pattern" class="text-danger">
                        Invalid Password Format
                      </div>
                    </div>
                  </div>
                  <button class="btn button-color btn-md btn-block" type="submit" [disabled]="!checkAllFieldsEntered()">
                    <!-- <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                  </span> -->
                    <span>Add</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>