<div class="container custom-container">
    <div class="row">
        <div class="col-auto p-0">
            <app-sidenav></app-sidenav>
        </div>
        <div class="col p-0">
            <div class="p-0 mt-0" style="background-color: #ffff ;width: 100%;height: 100%;">
                <app-header></app-header>
                <div class="main-block">
                    <div class="d-flex justify-content-between">
                        <div class="mt-2">
                            <ng-container>
                                <button type="button" class="btn btn-secondary btn-sm new-btn"
                                    style="background-color:  #5D3C6C;" (click)="OpencreateUsertype()">
                                    Create User Type
                                </button>
                            </ng-container>
                        </div>
                        <!-- <div style="margin-right: 95px;height: 30px;"> -->
                        <!-- <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filter" placeholder="Type to Search" />
            </mat-form-field> -->
                        <!-- </div> -->
                    </div>
                    <div class="space" style="height: 20px;"></div>
                    <div class="user-table table-container mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort class="">
                            <!-- <ng-container matColumnDef="Usertype Id">
            <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                Usertype Id 
            </th>
            <td class="mat-table-cell" mat-cell *matCellDef="let row">{{ row.UsertypeId }}</td>
        </ng-container> -->
                            <ng-container matColumnDef="User Type">
                                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                                    User Type
                                </th>
                                <td mat-cell *matCellDef="let row"
                                    style="padding-left: 20px; padding-right: 20px;white-space: nowrap">
                                    <span class="circle-span"
                                        [ngStyle]="{'background-color': getBackgroundColor(row.usertypeId)}"></span>
                                    <span style="margin-left: 8px;">{{ row.usertype }}</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Modules">
                                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                                    Modules
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <div>
                                        <span *ngFor="let role of row.moduleList;let i=index "
                                            style="text-transform: capitalize;">
                                            <span>
                                                {{ role.module_name}}<span
                                                    *ngIf="row.moduleList.length - 1 > i  ">,&nbsp; </span>

                                            </span>
                                        </span>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Actions">
                                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                                    Actions
                                </th>
                                <td class="mat-table-cell" mat-cell *matCellDef="let row" style="width:11%;">
                                    <!-- <mat-icon class="mr-2" matTooltip="Edit" class="edit-icon" style="cursor: pointer;"
                        (click)="OpenUpdateUserType(row)">edit</mat-icon> -->
                                    <div class="view-icon" style="cursor: pointer;" (click)="OpenUpdateUserType(row)">
                                        <img src="assets/img/view.png" matTooltip="Edit" style="cursor: pointer;" />
                                    </div>
                                    <!-- <mat-icon class="mr-2" matTooltip="Delete" class="delete-icon" style="cursor: pointer;" (click)="DeleteUsertype(row)"
                        *ngIf="!row.isMemberMapped">delete</mat-icon> -->
                                    <div class="delete-icon" (click)="DeleteUsertype(row)" *ngIf="!row.isMemberMapped"
                                        style="cursor: pointer;">
                                        <img src="assets/img/delete.png" matTooltip="Delete" style="cursor: pointer;" />
                                    </div>
                                    <span class="notification" *ngIf="row.memberCount!=0"
                                        (click)="UserTypememberDetails(row)" style="cursor: pointer;">
                                        <mat-icon matBadge="{{row.memberCount}}" matBadgeColor="accent"
                                            matTooltip="Click to view member details"
                                            style="margin:5px;position: relative;bottom: -6px;">people</mat-icon>
                                    </span>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                        <div *ngIf="userData?.length == 0 "
                            style="text-align: center;padding: 50px;background-color: white;">
                            <p>no records found</p>
                        </div>
                        <mat-paginator [pageSizeOptions]="[3,5,10, 25, 100]" showFirstLastButtons [length]="totalSize">
                        </mat-paginator>
                    </div>
                </div>
                <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons length="300"></mat-paginator> -->

                <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator> -->
                <div class="modal fade" id="newUsertypeCreate" data-backdrop="static" tabindex="-1" role="dialog"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title"> {{formHeading}} </h4>
                                <button type="button" class="close" data-dismiss="modal" (click)="createUserhide()">
                                    <!-- <img src="assets/img/modal-close.png" /> -->
                                    <mat-icon class="inactive-icon"
                                        style="color: red;font-size: 27px;">cancel</mat-icon>
                                </button>
                            </div>
                            <!-- User Type Add Module -->

                            <div>
                                <div class="container" *ngIf="eventFormOpen">
                                    <form [formGroup]="usertypeForm" *ngIf="visible"
                                        (ngSubmit)="this.visible ? onSubmitUsertype() : onSubmitUsertypeUpdate()">
                                        <div class="userType-input">
                                            <label for="firstName">User Type<span style="color: red;"> *</span> </label>
                                            <mat-form-field style="width: 100%;">
                                                <input formControlName="usertype" matInput />
                                            </mat-form-field>
                                            <div *ngIf="usertypeSubmit && userFormControl.usertype.errors" class="ml-2">
                                                <div *ngIf="userFormControl.usertype.errors.required"
                                                    class="text-danger">
                                                    User type required
                                                </div>
                                                <div *ngIf="userFormControl.usertype.errors.pattern"
                                                    class="text-danger">
                                                    User type required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="module">
                                            <div class="flex-content">
                                                <div>
                                                    <label for="interval">Modules <span style="color: red;" *ngIf="!Validation()"> * (Atleast
                                                            4 Modules )</span></label>
                                                </div>
                                                <!-- <div >
                                        <label for="interval">Sort By Order</label>
                                    </div> -->
                                            </div>
                                            <div *ngFor="let item of ModuleSubscriptionList; let i = index"
                                                style="justify-content: space-between;display: flex;align-items: center;">
                                                <div>
                                                    <mat-checkbox color="primary"
                                                        (change)="toggleCheckboxInsert(i,item.isActive)"
                                                        class="captalize" style=" text-align: start;padding: 5px;"
                                                        *ngIf="(i!=ChatSelect)">{{item.moduleName}}
                                                    </mat-checkbox>
                                                </div>
                                                <!-- <div style="margin: 10px;">
                                            <input type="text"  style="width: 50px;height:50px; border-radius: 10px;text-align: center;"  mask="0000" 
                                             (change)="toggleCheckbox(item.orderById,i,item.isActive,'text')" />
                                      </div> -->
                                            </div>
                                        </div>
                                        <div *ngIf="visible" class="userTpe-footer"
                                            style="display: flex; justify-content: flex-end;">
                                            <button class="sync-btn" type="submit"
                                                [ngStyle]="{ 'opacity': isSaveEnabledInsert() ? 1 : 0.5 }"
                                                [disabled]="!isSaveEnabledInsert()"
                                                style="display: flex;justify-content: flex-end;"><span>Save</span></button>
                                                <!-- <button class="sync-btn" type="submit"
                                            [ngStyle]="{ 'opacity': isRequired ? 1 : 0.5 }"
                                            [disabled]="!isRequired"
                                            style="display: flex;justify-content: flex-end;"><span>Save</span></button> -->
                                        </div>
                                    </form>
                                </div>

                                <!-- user Type Add Module End here -->


                                <!-- User Type Update Module  -->
                                <div class="updatemodule" *ngIf="!visible">
                                    <div class="userType-input">
                                        <label for="firstName">User Type<span style="color: red;"> *</span> </label>
                                        <mat-form-field style="width: 100%;">
                                            <input [(ngModel)]="usertype" matInput />
                                        </mat-form-field>
                                        <div *ngIf="isEmpty() && usertypeSubmit" class="text-danger">User Type Required
                                        </div>
                                    </div>

                                    <div class="module p-0">
                                        <div class="module-inner">
                                            <div class="flex-content">
                                                <div>
                                                    <label for="interval">Modules <span style="color: red;" *ngIf="!ValidationUpdate()"> * (Atleast
                                                            4 Modules )</span></label>
                                                </div>
                                                <div>
                                                    <label for="interval">Sort By Order<span style="color: red;" *ngIf="isRepeaded" >  (
                                                        Avoid Duplicates)</span>  </label>
                                                </div>
                                            </div>

                                            <div *ngFor="let item of ModuleList; let i = index" class="module-row">

                                                <div class="module-checkbox">
                                                    <mat-checkbox color="primary"
                                                        (change)="toggleCheckbox('',i,item.isActive,'check')"
                                                        [checked]="item.isActive" class="captalize"
                                                        *ngIf="(i!=ChatSelect)">
                                                        {{item.moduleName}}
                                                    </mat-checkbox>
                                                </div>

                                                <div class="sortby-order">
                                                    <label for="order"></label>
                                                    <input type="text" class="order-input" mask="0000"
                                                        [(ngModel)]="item.orderById"
                                                       
                                                        (change)="toggleCheckbox(item.orderById,i,item.isActive,'text')"
                                                        *ngIf="(i!=ChatSelect)" />
                                                </div>
                                                <!-- [ngStyle]="{ 'border-color': trueArray[i] ? 'red' : 'black' }" -->
                                            </div>


                                            <div class="userTpe-footer">

                                                <div>
                                                    <button class="sync-btn" type="submit"
                                                       (click)="onSubmitUsertypeUpdate()"
                                                        [ngStyle]="{ 'opacity': isSaveEnabled() ? 1 : 0.5 }"
                                                        [disabled]="!isSaveEnabled()"
                                                        style="display: flex;justify-content: flex-end;"><span>Save</span></button>
                                                </div>
                                            </div>
                                            <!-- User Type Update Module  -->


                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="memberDetaiils" data-backdrop="static" tabindex="-1" role="dialog"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content" style="padding: 20px; width: 100%;">
                            <div class="modal-header">
                                <h4 class="modal-title">{{detailsHeading}} </h4>
                                <button type="button" class="close" data-dismiss="modal" (click)="createUserhide()">
                                    <!-- <img src="assets/img/modal-close.png" /> -->
                                    <mat-icon class="inactive-icon"
                                        style="color: red;font-size: 27px;">cancel</mat-icon>
                                </button>
                            </div>
                            <div class="user-table table-container mat-elevation-z8">
                                <table mat-table [dataSource]="dataSources" class="module-table">

                                    <!-- Name Column -->
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef> Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                    </ng-container>

                                    <!-- Email Column -->
                                    <ng-container matColumnDef="email">
                                        <th mat-header-cell *matHeaderCellDef> Email </th>
                                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                                    </ng-container>

                                    <!-- Phone Column -->
                                    <ng-container matColumnDef="phone">
                                        <th mat-header-cell *matHeaderCellDef> Phone </th>
                                        <td mat-cell *matCellDef="let element"> {{element.phone || 'N/A'}} </td>
                                    </ng-container>

                                    <!-- Designation Column -->
                                    <ng-container matColumnDef="designation">
                                        <th mat-header-cell *matHeaderCellDef> Designation </th>
                                        <td mat-cell *matCellDef="let element"> {{element.designation || 'N/A'}} </td>
                                    </ng-container>

                                    <!-- Created Date Column -->
                                    <ng-container matColumnDef="createdDate">
                                        <th mat-header-cell *matHeaderCellDef> Created Date </th>
                                        <td mat-cell *matCellDef="let element"> {{ element.createdDate |
                                            date:'dd/MM/yyyy' }}
                                        </td>
                                    </ng-container>

                                    <!-- Header and Row Declarations -->
                                    <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>



                                </table>
                                <!-- <div *ngIf="this.helpDeskList?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
                                    <p>no records found </p>
                                  </div> -->
                                <mat-paginator showFirstLastButtons [pageSize]="5" [length]="totalLength"
                                    (page)="onPageChange($event)">
                                </mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, .6)" class="spin-img" template="<img src='assets/img/logo-D.gif' />">
</ngx-spinner>
















<!-- <div style="margin: 15px;">
                                <label for="interval" >Sort By Order</label>
                            </div>
                        </div>
                            <div class="flex-content" >
                            <div class="vertical-checkbox-group">
                                
                                <mat-checkbox *ngFor="let item of ModuleList;let i=index" color="primary"
                                    (change)="toggleCheckbox(item.moduleId,i,item.isActive)" [checked]="item.isActive" class="captalize"
                                    style=" text-align: start;">{{item.moduleName}}</mat-checkbox>
                                    
                            </div>
                            
                            <div class="vertical-checkbox-group"  >
                              
                                <div *ngFor="let item of ModuleList; let i = index" >
                                    <input 
                                  
                                     type="number" 
                                      style="width: 50px;padding-left:5px ;" 
                                      [(ngModel)]="item.orderById" 
                                      (change)="toggleBox(item.orderById,i)"
                                      
                                   />
                                  
                                  </div>                                 -->