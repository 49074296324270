import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { sharePointListsResponse } from '../shared/models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
import { SharedalertService } from '../shared/services/sharedalert.service';

declare var $: any;

export interface UserData {
  id: Number,
  roleId: Number,
  title: String,
  content: String,
  created_date: String,
}

@Component({
  selector: 'app-sharepoint',
  templateUrl: './sharepoint.component.html',
  styleUrls: ['./sharepoint.component.css']
})
export class SharepointComponent implements OnInit {
  token: string;
  roleList: any;
  dataSource: MatTableDataSource<UserData>;
  title: any;
  selectedRoles: string[] = [];
  sharePointLInkId: any;
  rolevalue: any;
  displayedColumns: string[] = [
    "title",
    "roles",
    "create_date",
    "actions"
  ];
  filter: string;
  shareLink: any;
  // @ViewChild(MatSort) sort: MatSort;
 notes:string;
 titles:string;
 sharePointLink:string;

  // disableSorting() {
  //   this.sort.disabled = true;
  // }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  sharePointForm: FormGroup;
  users: UserData[];
  addAnnouncementForm: boolean;
  sharePointSubmit: boolean;
  isSelectedRoles: boolean;
  typeofSharePoint = "Create"
  apiselectedRoles: string[] = [];
  pageSize = 10;
  currentPage = 0;
  searchKey: any = '';
  selectedType: Number
  totalItems: any;

  constructor(private spinnerService: NgxSpinnerService, private sharedService: SharedalertService, private memberService: MemberService, private formBuilder: FormBuilder) {
    this.sharePointForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator]],
      // rolevalue: ['', Validators.required],
      html: ['', [Validators.required, WhiteSpaceValidator]],
      notes: ['', [WhiteSpaceValidator, Validators.maxLength(150)]],
      interests: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.getRoles()
    this.getsharePointLists(this.currentPage + 1, this.pageSize, this.searchKey, 0);
  }

  getsharePointLists(pageNo, pageSize, searchKey, roleType) {
    this.spinnerService.show();
    let params = {
      "searchTitle": searchKey,
      "roleId": roleType,
      "pageNo": pageNo,
      "pageSize": pageSize
    }
    this.memberService.getsharePointLists(params, this.token).subscribe(
      (data: { totalCount: Number;filteredCount:Number;sharePointLists: sharePointListsResponse[] }) => {
        console.log(data);
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        let userData = this.getMemberListArray(data.sharePointLists);
        console.log(userData)
        this.users = userData;
        console.log('Userdata',this.users);
        this.dataSource = new MatTableDataSource(userData);
        // this.dataSource.paginator = this.paginator;
        this.totalItems = data.filteredCount

        this.dataSource.sort = this.sort;
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
        else {
          this.spinnerService.hide();
        }
      }
    );
  }

  GetFormattedDate(date: string) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    return month + "-" + day + "-" + year;
  }

  getAppendValue = value => {
    if (value <= 9) {
      return `0${value}`;
    }
    return value;
  };


  getMemberListArray(sharePointLists: sharePointListsResponse[]) {
    return sharePointLists.map(member => ({
      id: member.sharePointId,
      roleId: member.roleId,
      title: member.sharePointTitle,
      content: member.sharePointLink,
      created_date: this.GetFormattedDate(member.createdDate),
      notes:member.notes
    }));
  }

  getRoles() {
    if (this.roleList) {
      return this.roleList;
    }

    this.memberService
      .getRoleValues(this.token)
      .subscribe(
        data => {
          this.roleList = data;
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }



  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
    });
    return;
  }


  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.getsharePointLists(event.pageIndex + 1, event.pageSize, this.searchKey, this.selectedType)
  }



  toggleRoleSelection(role: string) {
    const index = this.selectedRoles.indexOf(role);
    if (index === -1) {
      // Role is not in the array, add it
      this.selectedRoles.push(role);
    } else {
      // Role is already in the array, remove it
      this.selectedRoles.splice(index, 1);
    }
    if (this.selectedRoles.length > 0) {
      this.isSelectedRoles = false
    }
    else {
      this.isSelectedRoles = true
    }
  }

  hide() {
    this.sharePointSubmit = false;
    this.isSelectedRoles = false;
    this.sharePointForm.reset()
    this.selectedRoles = []
    $("#newformCreate").modal("hide");
  }

  openAddMemberModal() {
    $("#newformCreate").modal("show");
    this.typeofSharePoint = "Create"
    this.addAnnouncementForm = true;
    this.sharePointForm.reset();
    this.sharePointForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator]],
      html: ['', [Validators.required, Validators.pattern(/^https:\/\/.+$/), WhiteSpaceValidator]],
      notes: ['', [WhiteSpaceValidator, Validators.maxLength(150)]],
      interests: this.formBuilder.array([])
    });
    this.selectedRoles = []
    this.apiselectedRoles = []
    this.sharePointLInkId = 0
  }


  editAnnouncement(row) {
    console.log('row',row);
    this.sharePointForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator]],
      html: ['', [Validators.required, Validators.pattern(/^https:\/\/.+$/), WhiteSpaceValidator]],
      notes: [row.notes, [WhiteSpaceValidator, Validators.maxLength(150)]],
      interests: this.formBuilder.array([])
    });
    this.addAnnouncementForm = true
    this.typeofSharePoint = "Edit"
    console.log(row)
    this.selectedRoles = []
    this.apiselectedRoles = []
    $("#newformCreate").modal("show");
    this.sharePointForm.controls['title'].setValue(row.title);
    this.sharePointForm.controls['html'].setValue(row.content);
    this.title = row.title
    this.shareLink = row.content
    this.sharePointLInkId = row.id
    this.notes=row.notes;
    this.titles=row.title,
    this.sharePointLink=row.sharePointLink
    row.roleId.forEach(element => {
      this.selectedRoles.push(element.roleId)
      this.apiselectedRoles.push(element.roleId)

    });
  }

  deleteSharePoint(id) {
    Swal.fire({
      title: "Delete Forms and Application",
      text: "Are you sure?  Do you want to delete Forms and Application?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        let params = {
          "sharepointLinkId": id
        }
        this.spinnerService.show();
        this.memberService
          .deleteSharePoint(params, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.spinnerService.hide();
                this.sharedService.successAlert(() => {
                  this.ngOnInit()
                });
                return;
              }
              else {
                this.spinnerService.hide();
                this.sharedService.errorAlert(() => {
                  this.ngOnInit()
                });
                return;
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    })

  }

  onSelectChange(event: any): void {
    // Handle the change event here
    console.log('Selected value:', this.selectedType);
    this.paginator.pageIndex = 0;
    // You can perform additional actions or logic based on the selected value
    this.getsharePointLists(1, this.pageSize, this.searchKey, this.selectedType);

  }

  filterEvent(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0)
      this.getsharePointLists(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)
  }
  applyFilter() {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.searchKey = filterValue
    this.getsharePointLists(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)

    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log("filt", this.dataSource.filter)
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }


  get memberControl() {
    return this.sharePointForm.controls;
  }

  checkAllFieldsEntered(): boolean {
    return Object.keys(this.sharePointForm.controls).every(controlName => {
      if (controlName === 'notes') {
        // Skip validation for the "notes" field
        return true;
      }

      const control = this.sharePointForm.get(controlName);
      return control.value !== '' && control.value !== null;
    });
  }


  saveSharePoint() {
    this.sharePointSubmit = true;
    console.log(this.sharePointForm)
    if (this.selectedRoles.length === 0 || this.sharePointForm.invalid) {
      this.isSelectedRoles = true;

      if (this.selectedRoles.length > 0) {
        this.isSelectedRoles = false;
        return;
      }

      if (this.sharePointForm.invalid) {
        return;
      }

      return; // Return if either condition is true
    }
    this.sharePointSubmit = false;
    let params = {
      "title": this.sharePointForm.value.title,
      "roleId": this.selectedRoles,
      "link": this.sharePointForm.value.html,
      "sharePointLInkId": this.sharePointLInkId,
      "notes": this.sharePointForm.value.notes,

    }
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();

    if (this.sharePointLInkId == 0) {
      this.memberService
        .PostSendSharePoints(params, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.spinnerService.hide();
              // this.addAnnouncementForm = false;
              Swal.fire({
                icon: "success",
                title: "Created Successfully",
                showCloseButton: true,
                allowOutsideClick: false
              }).then(() => {
                $("#newformCreate").modal("hide");
                this.sharePointSubmit = false;
                this.ngOnInit()
              });
              return;
            }
            else {
              this.errorMessage()
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }
    else {
      let param = {
        "title": this.titles,
        "roleId": this.selectedRoles,
        "link": this.shareLink,
        "sharePointLInkId": this.sharePointLInkId,
        "notes": this.notes,
  
      }
      console.log(param);
      // return;
      this.memberService
        .updateSharePoints(param, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.spinnerService.hide();
              // this.addAnnouncementForm = false;
              Swal.fire({
                icon: "success",
                title: "Forms and application sent to the selected group successfully",
                showCloseButton: true,
                allowOutsideClick: false
              }).then(() => {
                $("#newformCreate").modal("hide");
                this.ngOnInit()
              });
              return;
            }
            else{
              this.spinnerService.hide();
              // this.addAnnouncementForm = false;
              Swal.fire({
                icon: "warning",
                title: data.statusMessage,
                showCloseButton: true,
                allowOutsideClick: false
               })
              //  .then(() => {
              //   // $("#newformCreate").modal("hide");
              //   this.ngOnInit()
              // });
              return;
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }
  }

  errorMessage() {
    this.spinnerService.hide();
    Swal.fire({
      icon: "warning",
      title: "Something went wrong",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "OK"
    }).then(() => {
    });
    return;
  }

}
