<div class="p-4 mt-5">
  <div>
    <div class="text-right">
      <mat-form-field>
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Type to Search"
        />
      </mat-form-field>
    </div>

    <div class="mat-elevation-z8" style="margin-bottom: 60px">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="userName">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Member
          </th>
          <td mat-cell *matCellDef="let row">{{ row.userName }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Email
          </th>
          <td mat-cell *matCellDef="let row">{{ row.email }}</td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="designation">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Designation
          </th>
          <td mat-cell *matCellDef="let row">{{ row.designation }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="companyName">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Company Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.companyName }}</td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="status">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Payment
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon class="mr-2" *ngIf="row.status === 1"
              >check_circle</mat-icon
            >
            <mat-icon class="mr-2" *ngIf="row.status === 0">cancel</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Payment Date
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.status === 1">{{ row.date }}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </div>
</div>
