import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MemberService } from '../shared/services/member.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
declare var $: any;
@Component({
  selector: 'app-vendor-group',
  templateUrl: './vendor-group.component.html',
  styleUrls: ['./vendor-group.component.css']
})
export class VendorGroupComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginators: MatPaginator;
  VendorGroupArray: any[]=[];
  dataSource: MatTableDataSource<any>;
  dataSourcefilter: MatTableDataSource<any>;
  token: string="";
  vendorList:any[]=[];
  groupdetails:string;
  displayedColumns: string[] = ['Name', 'CreatedDate', 'Status', 'Actions'];
  displayedColumn:string[]=['Name','email','CreatedDate']
  pageNo:number=0;
  PageSize:Number=10;
  submitControl:boolean=false;
  pagenationLength:number;
  heading:string='';
  footer:string='';
  Array:any;
  DummyData = {
    _ventorGroupList: [
      {
        groupId: 1,
        name: 'Healthcare',
        createdDate: '2024-12-02T10:55:47',
        isActive: 1,
        isDeleted: 0,
        memberCount: 10,
        deleteEnable: false
      },
      {
        groupId: 2,
        name: 'Financial',
        createdDate: '2024-12-02T10:55:47',
        isActive: 1,
        isDeleted: 0,
        memberCount: 0,
        deleteEnable: true
      },
      {
        groupId: 3,
        name: 'Engineering',
        createdDate: '2024-12-02T10:55:47',
        isActive: 1,
        isDeleted: 0,
        memberCount: 5,
        deleteEnable: false
      }
    ],
    statusCode: 200,
    statusMessage: 'Success'
  };
  vendorGroupForm: FormGroup;
  vendorGroupSubmitted: boolean;
  length: any;
  groupId: any;
  selectedType: string;


  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService, private formBuilder: FormBuilder, private router: Router) {
    this.vendorGroupForm = this.formBuilder.group({
      groupName: ['', [Validators.required,WhiteSpaceValidator]]
    });
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.GetVendorGroupList();
  }
 GetVendorGroupFilter(row,pageNo,pageSize){

  this.Array=row;
  let params = {
    "searchVendor": "",
    "pageNo": pageNo,
    "pageSize": pageSize,
    "groupId":row.groupId
  };
  console.log(params);
  this.spinnerService.show();
  this.memberService.getVendorList(params, this.token).subscribe(
    (data: any) => {
      // this.ishubspotLoading = false;
      if (data.statusCode == 200) {
        console.log(data.statusCode)
        console.log(data);
        this.length=data.filteredCount;
        this.vendorList=data.vendorList;
        this.dataSourcefilter = new MatTableDataSource(this.vendorList);
        this.pagenationLength=data.filteredCount;
        this.dataSourcefilter.paginator = this.paginators;
        this.groupdetails=row.name;
        $("#VendorDetails").modal("show");
        this.spinnerService.hide();

      }
      else {
        // this.isLoading = false;
        Swal.fire({
          title: data.statusMessage,
          icon: "warning",
          showCloseButton: true,
          allowOutsideClick: false
        });
        this.spinnerService.hide();

      }
    },
    err => {
      if (err.status === 401) {
        this.spinnerService.hide();
        this.unauthorizedException();
      }
    }
  );
 }
 onPageChange(event: any){
  
  this.GetVendorGroupFilter(this.Array,event.pageIndex + 1, event.pageSize);
 
 }
 applyFilterIcon(){
  this.dataSource.filter = this.selectedType.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
 }
 Listhide(){
  $("#VendorDetails").modal("hide");
  this.ngOnInit();
 }
  GetVendorGroupList(){
    this.spinnerService.show();
    this.memberService.getVendorGroup(this.token).subscribe(
      (data: any) => {
        // this.ishubspotLoading = false;
        if (data.statusCode == 200) {
         this.VendorGroupArray=data.vendorGroupList;
         this.VendorGroupArray.sort((a, b) => {
          return b.groupId - a.groupId;
      });
         this.dataSource = new MatTableDataSource(this.VendorGroupArray);
         console.log('Vendor ',this.VendorGroupArray);
         this.dataSource.paginator = this.paginator;
         this.spinnerService.hide();
        }
        else {
          // this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();

        }
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }
  onClickInsert(){
    // this.vendorGroupSubmitted = false;
    this.heading='Add Vendor Group';
    this.footer='Add';
    this.submitControl=true;
    $("#newVendorCreate").modal("show");
    this.vendorGroupForm = this.formBuilder.group({
      groupName: ['', [Validators.required,WhiteSpaceValidator]]
    });

  }
  onDeleteSubmit(row){
  console.log('row',row.groupId);
  Swal.fire({
    title: "Delete Vendor Group ",
    text: "Are you sure you want to delete this vendor Group ? Doing so will also delete all history and data associated with this vendor Group.",
    icon: "warning",
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",
    allowOutsideClick: false,
  }).then((result) => {
    if (result.value) {
      this.spinnerService.show();
   let params={
    "vendorGroupId":row.groupId
   };
   console.log(params);
      this.memberService
        .postDeleteGroupVendor(params, this.token) // Assuming `row.id` is the correct memberId
        .subscribe(
          () => {
            this.spinnerService.hide();
            Swal.fire({
              title: "Deleted!",
              text: "Vendor Group is deleted now",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false,
            }).then(() => {
              this.ngOnInit(); // Refresh data after deletion
            });
          },
          (err) => {
            this.spinnerService.hide();
            if (err.status === 401) {
              this.unauthorizedException();
            } else {
              Swal.fire({
                title: "Error!",
                text: "An error occurred while deleting the Vendor.",
                icon: "error",
                showCloseButton: true,
                allowOutsideClick: false,
              });
            }
          }
        );
    }
  });
  }
  onClickUpdate(row){
    this.groupId=row.groupId;
    this.heading='Update Vendor Group';
    this.footer='Update';
    this.submitControl=false;
    $("#newVendorCreate").modal("show");
    this.vendorGroupForm = this.formBuilder.group({
      groupName: [row.name, [Validators.required,WhiteSpaceValidator]]
    });

  }
  submitVendorGroupNameUpdate():void{
    this.heading='Update Vendor Group';
    this.footer='Update';
    this.vendorGroupSubmitted = true;
    this.submitControl=false;
    
    if (this.vendorGroupForm.valid) {
      let params={
        "vendorGroupId": this.groupId,
        "vendorGroupName":this.vendorGroupForm.get('groupName')?.value.trim(),

      }
      this.vendorGroupSubmitted = false;
      this.spinnerService.show();
      console.log('Form Submitted:', params);
      // let params=this.vendorGroupForm.value;
      this.memberService.PostUpdateVendorGroup(params,this.token).subscribe(
        (data: any) => {
          // this.ishubspotLoading = false;
          if (data.statusCode == 200) {
            $("#newVendorCreate").modal("hide");
            Swal.fire({
              title: "Updated!",
              text: "Vendor group updated successfully",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false
            });
            this.ngOnInit();
            this.spinnerService.hide();
          
          }
          else {
            // this.isLoading = false;
            Swal.fire({
              title: data.statusMessage,
              icon: "warning",
              showCloseButton: true,
              allowOutsideClick: false
            });
            this.spinnerService.hide();
  
          }
        },
        err => {
          if (err.status === 401) {
            this.spinnerService.hide();
            this.unauthorizedException();
          }
        }
      );

    }
  
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  submitVendorGroupName(): void {
    console.log(this.vendorGroupForm.invalid);
    this.vendorGroupSubmitted = true;
    if (this.vendorGroupForm.invalid) {
     console.log("HeY",this.vendorGroupSubmitted);
     return ;
    }
      console.log('Form Submitted:', this.vendorGroupForm.value);
      let params={
        // "vendorGroupId": this.groupId,
        "vendorGroupName":this.vendorGroupForm.get('groupName')?.value.trim(),

      }
      this.vendorGroupSubmitted = false;
      this.spinnerService.show();
      this.memberService.postInsertVendorGroup(params,this.token).subscribe(
        (data: any) => {
          // this.ishubspotLoading = false;
          if (data.statusCode == 200) {
            $("#newVendorCreate").modal("hide");
            Swal.fire({
              title: "Inserted!",
              text: "Vendor  group inserted successfully",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false
            });
            this.ngOnInit();
            this.spinnerService.hide();
          
          }
          else {
            // this.isLoading = false;
            Swal.fire({
              title: data.statusMessage,
              icon: "warning",
              showCloseButton: true,
              allowOutsideClick: false
            });
            this.spinnerService.hide();
  
          }
        },
        err => {
          if (err.status === 401) {
            this.spinnerService.hide();
            this.unauthorizedException();
          }
        }
      );
    
  }
  get vendorGroupControl() {
    return this.vendorGroupForm.controls;
  }
  checkAllFieldsEntered():boolean{
    // return this.vendorGroupForm.valid;
    return Object.values(this.vendorGroupForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }
  hide(): void {
    // Logic to hide the modal
    this.vendorGroupForm.reset();
    this.vendorGroupSubmitted=false;
    $("#newVendorCreate").modal("hide");
  
  }
  activateUser(row){
    Swal.fire({
      title: "Activate Vendor Group",
      text: "Are you sure? Do you want to Vendor Group?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        // $("#progressModel").modal("show");
        this.spinnerService.show();
        let params={
          "groupId": row.groupId,
          "isActive":row.isActive
        }
        this.memberService
          .postVendorStatus(params, this.token)
          .subscribe(
            (data: any) => {
              this.spinnerService.hide();
              if (data.statusCode === 200) {
               Swal.fire({
                  title: "Activated!",
                  text: "Vendor Group is active now",
                  icon: "success",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
                this.ngOnInit();
              }
              else {
                this.spinnerService.hide();
                Swal.fire({
                  title: data.statusMessage,
                  icon: "warning",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
                this.spinnerService.hide();
              }
            }
          );
      }
    });
  }
  
  suspendUser(row){
    Swal.fire({
      title: "Inactivate Vendor group",
      text: "Are you sure? Do you want to Inactivate Vendor group?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        // $("#progressModel").modal("show");
        this.spinnerService.show();
        let params={
          "groupId": row.groupId,
          "isActive":row.isActive
        }
        this.memberService
          .postVendorStatus(params, this.token)
          .subscribe(
            (data: any) => {
              if (data.statusCode === 200) {
                this.spinnerService.hide();
               Swal.fire({
                  title: "Inactivated!",
                  text: "Vendor Group is Inactive now",
                  icon: "success",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
                this.ngOnInit();
              }
              else {
                this.spinnerService.hide();
                Swal.fire({
                  title: data.statusMessage,
                  icon: "warning",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
                this.spinnerService.hide();
              }
            }
          );
      }
    });
  }
  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);

    });
    return;
  }
}
