
<div class="container custom-container">
    <div class="row">
        <div class="col-auto p-0">
            <app-sidenav></app-sidenav>
          </div>
   <div class="col p-0">
    <div class="p-0 mt-0" style="background-color: #ffff ;width: 100%;height: 100%;">
    <app-header></app-header>
      <div class="main-block">
        <!-- <div class="d-flex justify-content-between align-items-start wrap-block create-col">
            <div class="mt-2">
              <div class="split-button" style="display:flex;gap:8px">
                <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;" (click)="onClickInsert()"
               >
                  <img src="assets/img/plus.png" class="btn-img"/> New Vendor Group
                </button>
                <div class="search-field-col" style="margin-right: 95px">
                  <mat-form-field class="search-block">
                      <mat-label>Search</mat-label>
                      <input class="search-block-input" matInput (keyup)="applyFilter($event)"  placeholder="Type to Search" />
                  </mat-form-field>
              </div>
              </div>
            </div>
        </div> -->
        <div class="space" style="height: 20px;"></div>
        <div class="d-flex justify-content-between align-items-start wrap-block create-col">
          <div class="mt-2">
            <div class="split-button" style="display:flex;gap:8px">
              <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;" (click)="onClickInsert()"
             >
                <img src="assets/img/plus.png" class="btn-img"/> New Vendor Group
              </button>
            </div>
          </div>
          <!-- (selectionChange)="onSelectChange($event)" -->
          <div class="mt-2 wrap-block forms-search" style="display: flex; align-items: center; margin-right: 20px;">
            <div style="margin-right: 20px;" class="form-search-sub" >
              <!-- <mat-form-field style="width: 100%; margin-top: auto;">
                <mat-select [(value)]="selectedType" class="user-select" style="margin-top: auto;" placeholder="Vendor Group"
                   panelClass="custom-dropdown-panel"  disableOptionCentering>
                  <mat-option [value]="0">None</mat-option>
                 
                </mat-select>
              </mat-form-field> -->
            </div>
            <div class="search-form-field searchType-input">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Type to Search"
                  [(ngModel)]="selectedType"
                  (keyup)="applyFilter($event)"
                />
              </mat-form-field>
              <mat-icon
                class="searchIcon"
                style="cursor: pointer;"
                (click)="applyFilterIcon()"
              >
                search
              </mat-icon>
            </div>
            
          </div>
        </div>
        <div class="mat-elevation-z8 member-table table-container mobile-scroll">
            <table mat-table [dataSource]="dataSource" class="mat-table">

                <!-- Name Column -->
                <ng-container matColumnDef="Name">
                  <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"> 
                    {{element.name  || 'N/A'}} 
                  </td>
                </ng-container>
            
                <!-- Email Column -->
                
            
                <!-- Group Name Column -->
               
                <!-- Created Date Column -->
                <ng-container matColumnDef="CreatedDate">
                  <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Created Date </th>
                  <td mat-cell *matCellDef="let element"> 
                    {{element.createdDate ? (element.createdDate | date:'dd/MM/yyyy') : 'N/A'}} 
                  </td>
                </ng-container>
                <ng-container matColumnDef="Status">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> 
                      <span *ngIf="element.isActive==1" class="badge bg-success-subtle text-success">Active</span>
                      <span *ngIf="element.isActive==0" class="badge bg-danger-subtle text-danger"> Inactive</span>
                    </td>
                  </ng-container>
            
                <!-- Actions Column -->
                <ng-container matColumnDef="Actions">
                  <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Actions </th>
                  <td mat-cell *matCellDef="let element"> 
                    <!-- Add any action buttons here -->
                    <div class="edit-icon" matTooltip="Edit" style="cursor: pointer;" (click)="onClickUpdate(element)" >
                      <img src="assets/img/view.png" />
                  </div>
                  <div class="activate-icon" *ngIf="element.isActive==1" style="cursor: pointer;background-color:#F8D7DA;" matTooltip="Inactivate" 
                  (click)="suspendUser(element) ">
                  <img src="assets/img/suspend.png" />
                  </div>
                  
                <!-- <mat-icon class="mr-2" *ngIf="row.status === 1" matTooltip="Suspend" style="cursor: pointer;"
                  (click)="suspendUser(row)" class="suspend-icon">cancel</mat-icon> -->
                  <div class="suspend-icon" *ngIf="element.isActive==0" matTooltip="Activate" style="cursor: pointer; background-color:#D1E7DD;"
                  (click)="activateUser(element)">
                  <img src="assets/img/activate.png" />
                  
                    </div>
              <!-- <mat-icon matTooltip="Delete" style="cursor: pointer;"
                  (click)="deleteSharePoint(row.id)" class="delete-icon">delete</mat-icon> -->
                  <div class="delete-icon" matTooltip="Delete" style="cursor: pointer;"
                    (click)="onDeleteSubmit(element)" *ngIf="element.deleteEnable">
                      <img src="assets/img/delete.png" >
                    </div>
                    <span class="notification" *ngIf="element.memberCount!=0"
                                         style="cursor: pointer;" (click)="GetVendorGroupFilter(element,1,10)">
                                        <mat-icon matBadge="{{element.memberCount}}" matBadgeColor="accent"
                                            matTooltip="vendor Details"
                                            style="margin:5px;position: relative;bottom: -6px;">people</mat-icon></span>
                  </td>
                </ng-container>
            
                <!-- Header and Row Definitions -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
              </table>
          
            
            <div *ngIf="VendorGroupArray?.length == 0" style="text-align: center; padding: 50px; background-color: white;">
              <p>No records found</p>
            </div>
          
            
            <mat-paginator [pageSizeOptions]="[ 10, 25, 100]" showFirstLastButtons [length]="VendorGroupArray.length"
                           [(pageSize)]="PageSize" [(pageIndex)]="pageNo"  class="metaPaginate">
            </mat-paginator>  
        </div>
        <div class="modal fade" id="newVendorCreate" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">{{heading}}</h4>
                  <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                    <mat-icon class="inactive-icon" style="color: red; font-size: 27px;">cancel</mat-icon>
                  </button>
                </div>
          
                <!-- Modal Body -->
                <div class="modal-body">
                  <div class="container">
                    <form [formGroup]="vendorGroupForm" (ngSubmit)="this.submitControl? submitVendorGroupName() : submitVendorGroupNameUpdate()">
                      <!-- Group Name -->
                      <div class="form-group mb-3">
                        <label for="groupName"> Vendor Group Name <span style="color: red;">*</span></label>
                        <input type="text" class="form-control signup-input" id="groupName" placeholder="Enter Vendor  Group Name" formControlName="groupName" />
                        <div *ngIf="vendorGroupSubmitted && vendorGroupControl.groupName.errors" class="text-danger ml-2">
                          <div *ngIf="vendorGroupControl.groupName.errors.required"> Vendor group name is required</div>
                          <div *ngIf="vendorGroupControl.groupName.errors.noWhiteSpace">Vendor group name is required</div>
                        
                        </div>
                      </div>
          
                      <!-- Submit and Cancel Buttons -->
                      <div class="footer-modal" style="display: flex; justify-content: flex-end;">
                        <button class="btn button-color btn-md btn-block memberAdd-btn" type="submit" [disabled]="!checkAllFieldsEntered()" [ngStyle]="{ 'opacity': checkAllFieldsEntered() ? 1 : 0.5 }">
                          <span>{{footer}}</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="VendorDetails" data-backdrop="static" tabindex="-1" role="dialog"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog custom-modal-width"  style="width: 80%; max-width: 1000px;">
              <div class="modal-content" style="padding: 20px; width: 100%;">
                  <div class="modal-header">
                      <h4 class="modal-title">{{groupdetails}} </h4>
                      <button type="button" class="close" data-dismiss="modal" (click)="Listhide()">
                          <!-- <img src="assets/img/modal-close.png" /> -->
                          <mat-icon class="inactive-icon"
                              style="color: red;font-size: 27px;">cancel</mat-icon>
                      </button>
                  </div>
                  <div class="mat-elevation-z8 member-table table-container mobile-scroll" >
                    <table mat-table [dataSource]="dataSourcefilter" class="module-table">

                        <!-- Name Column -->
                        <ng-container matColumnDef="Name">
                          <th mat-header-cell *matHeaderCellDef> Name </th>
                          <td mat-cell *matCellDef="let element"> {{element.firstName+" "+element.lastName|| 'N/A'}} </td>
                        </ng-container>
                      
                        <!-- Email Column -->
                        <ng-container matColumnDef="email">
                          <th mat-header-cell *matHeaderCellDef> Email </th>
                          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                        </ng-container>
                      
                        <!-- Created Date Column -->
                        <ng-container matColumnDef="CreatedDate">
                          <th mat-header-cell *matHeaderCellDef> Created Date </th>
                          <td mat-cell *matCellDef="let element"> {{ element.createdDate | date:'dd/MM/yyyy' }} </td>
                        </ng-container>

                        <!-- Header and Row Declarations -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>



                    </table>
                    <div *ngIf="this.vendorList?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
                        <p>no records found </p>
                      </div>
                      <mat-paginator showFirstLastButtons  pageSize="5" 
                      [pageSizeOptions]="[5]" 
                      [length]="pagenationLength" (page)="onPageChange($event)"></mat-paginator>
                      
                  
                </div>
              </div>
          </div>
      </div>

      </div>
      </div>
    </div>  
</div>
</div>
<ngx-spinner
bdColor="rgba(0, 0, 0, .6)"
class="spin-img"
template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>