// Vendor Imports
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import {
  MemberConnectionServiceListRequest,
  MemberConnectionRequest,
  ViewMemberConnectionServiceListRequest,
  HubspotMemberSubscriptionAddRequest,
  MemberConnectionListRequest,
  ViewMemberHubspotServiceListRequest,
  MemberNotConnectionListRequest,
  ChatHistoryRequest,
  DeleteAnnouncementRequest,
  EditAnnouncementRequest,
  EditSharePointRequest,
  DeleteSharePointRequest,
  agreementRequest,
  getagreementRequest,
  ArchiveAnnouncementRequest,
  MemberListRequest,
  AnnouncementListRequest,
  HelpDeskRequest,
  getModuleListRequest,
  createModuleRequest
} from "../models/user";

// Local Imports
import { environment } from "../../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root"
})
export class MemberService {
  baseUrl = environment.baseUrl;
  localUrl = environment.localUrl;
  user = JSON.parse(localStorage.getItem("currentUser"));
  token = "Bearer" + " " + this.user.jwtToken;

  private modulesSubject = new BehaviorSubject<{ moduleList: any[], statusCode: number }>({
    moduleList: [],
    statusCode: 0
  });
  modules$ = this.modulesSubject.asObservable();
  constructor(private httpClient: HttpClient, private spinnerService: NgxSpinnerService) { }

  // Get Members List
  // getMembers(
  //   params: MemberListRequest,
  //   token: string) {
  //   // let user = JSON.parse(localStorage.getItem("currentUser"));
  //   return this.httpClient
  //     .post(`${this.baseUrl}/v1/Member/getMemberList`, params, {
  //       headers: new HttpHeaders().set("Authorization", token)
  //     })
  //     .pipe(catchError(this.handleError));
  // }

  getMembers(
    params: MemberListRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/getMemberList`, params,
        // .post(`${this.localUrl}/v1/Member/getMemberList`, params,  
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }

  //Get Memer Details
  getMemberDetails(token: string, id: Number) {
    return this.httpClient
      .post(
        `${this.baseUrl}/v1/Member/postMemberProfile`,
        // `${this.localUrl}/v1/Member/postMemberProfile`,
        {
          memberId: id,

        },
        {
          headers: new HttpHeaders().set("Authorization", token)
        }
      )
      .pipe(catchError(this.handleError));
  }

  // Get Member Connections List
  getMemberConnections(token: string) {
    return this.httpClient
      .get(`${this.baseUrl}/v1/Member/getMemberConnectList`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // Get Hubspot member list
  // Get  Connected Member List 
  getHubspotMemberList(
    params: any,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postHubspotMemberList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }



  // Get  Connected Member List 
  getRequestMemberList(
    params: any,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postRequestMemberList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  getRoleValues(token: string) {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    return this.httpClient
      // .get(`${this.localUrl}/v1/Master/getMemberRole`, 
      // .get(`${this.localUrl}/v1/Master/getMemberRole`, 
      .get(`${this.baseUrl}/v1/Master/getMemberRole`,
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }

  getSubscriptionTypes(token: string) {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getSubscriptionTypes`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  getUserTypes(token: string) {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getSubscriptionTypes`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  postCreateModule(
    // params: createModuleRequest,
    params: any,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postModuleCreation`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  getModuleList(
    params: getModuleListRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postSubscriptionModuleMappingList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  // getModuleLists(params: any, token: string): Observable<any> {
  //   return this.httpClient.post<any>(`${this.baseUrl}/v1/Master/postSubscriptionModuleMappingList`, params, {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   }).pipe(
  //     tap(data => this.modulesSubject.next(data.moduleList))
  //   );
  // }

  getModuleLists(params: any, token: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}/v1/Master/postSubscriptionModuleMappingList`, params, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).pipe(
      tap(data => {
        if (data && data.moduleList) {
          this.modulesSubject.next({ moduleList: data.moduleList, statusCode: data.statusCode });
        }
      })
    );
  }
  fetchModule(subsciptionId:Number): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));

    if (user.roleId == 1) {
      let params = {
        "subscriptionId":subsciptionId,
      };

      this.spinnerService.show()
      this.getModuleLists(params, user.jwtToken).subscribe(
        (data) => {
          // Handle the response data
          if (data && data.moduleList) {
            this.modulesSubject.next({ moduleList: data.moduleList, statusCode: data.statusCode });
            console.log('Modules:', data.moduleList);
          } else {
            console.error('Unexpected response:', data);
          }

          // Hide the spinner after processing the response
          this.spinnerService.hide();
        },
        (error) => {
          console.error('Error fetching modules from API', error);

          // Hide the spinner if an error occurs
          this.spinnerService.hide();
        }
      );
    }
  }
  fetchModules(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));

    if (user.roleId == 1) {
      let params = {
        "subscriptionId": user.subscriptionId,
      };

      this.spinnerService.show()
      this.getModuleLists(params, user.jwtToken).subscribe(
        (data) => {
          // Handle the response data
          if (data && data.moduleList) {
            this.modulesSubject.next({ moduleList: data.moduleList, statusCode: data.statusCode });
            console.log('Modules:', data.moduleList);
          } else {
            console.error('Unexpected response:', data);
          }

          // Hide the spinner after processing the response
          this.spinnerService.hide();
        },
        (error) => {
          console.error('Error fetching modules from API', error);

          // Hide the spinner if an error occurs
          this.spinnerService.hide();
        }
      );
    }
  }


  postEditModule(
    // params: editModuleRequest,
    params: any,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postModuleUpdate`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  postDeleteModule(
    // params: deleteModuleRequest,
    params: any,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postModuleDelete`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  postSetModule(
    params: getModuleListRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postSubscriptionModuleMapping`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  getHelpDeskEmailList(token: string) {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getHelpDeskEmailList`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  getViewMemberList(
    params: ViewMemberConnectionServiceListRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postMemberConnectedList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  getChatList(
    params,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postMemberChatList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // getChatHistoryList(token: string) {
  //   // let user = JSON.parse(localStorage.getItem("currentUser"));
  //   return this.httpClient
  //     .post(`${this.baseUrl}/api/Chat/postChatHistoryList`, {
  //       headers: new HttpHeaders().set("Authorization", token)
  //     })
  //     .pipe(catchError(this.handleError));
  // }

  getChatHistoryList(
    params: ChatHistoryRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Chat/postChatHistoryList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  updateChat(
    params: ChatHistoryRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/api/Chat/postChatViewedUpdate`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  postConnectRequest(
    params: MemberConnectionListRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postMemberConnectionRequestByAdmin`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  postNotconnectList(
    params: MemberNotConnectionListRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postYetToConnectMemberList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  postgetHubspotDetails(
    params,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postHubspotGatheringDataCron`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  postAdminMemberRegister(
    params,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postAdminMemberRegister`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  PostFreeSubscriptionAddedByAdmin(
    params,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postFreeSubscriptionAddedByAdmin`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  PostPaidSubscriptionAddedByAdmin(
    params,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postHubspotPaymentSubscription`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  PostSendMsg(
    params,
    token: string
  ) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.httpClient
      .post(`${this.baseUrl}/api/Chat/postChatSendMessage`, params, {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }




  getAnnouncementList(
    params: AnnouncementListRequest,
    token: string
  ) {
    return this.httpClient
      // .post(`${'https://localhost:7280'}/v1/Master/getAnnouncementList`, params, 
      .post(`${this.baseUrl}/v1/Master/getAnnouncementList`, params,
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }

  getArchiveannouncementList(token: string) {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getArchiveList`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // getsharePointLists(token: string) {
  //   // let user = JSON.parse(localStorage.getItem("currentUser"));
  //   return this.httpClient
  //     .get(`${this.baseUrl}/v1/Master/getSharePointLinkList`, {
  //       headers: new HttpHeaders().set("Authorization", token)
  //     })
  //     .pipe(catchError(this.handleError));
  // }


  getsharePointLists(
    params: AnnouncementListRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/getSharePointLinkList`, params,
        //  .post(`${this.localUrl}/v1/Master/getSharePointLinkList`, params, 
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }



  PostSendSharePoints(
    params,
    token: string
  ) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postCreateSharePointLink`, params,
        // .post(`${this.localUrl}/v1/Master/postCreateSharePointLink`, params,
        {
          headers: headers
        })
      .pipe(catchError(this.handleError));
  }

  updateSharePoints(
    params,
    token: string
  ) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.httpClient
      // .post(`${this.localUrl}/v1/Master/postUpdateSharePointLink`, params, {
      .post(`${this.baseUrl}/v1/Master/postUpdateSharePointLink`, params, {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }

  updatRoles(
    params,
    token: string
  ) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postUpdateMemberRoleChat`, params, {
        headers: headers
      })
      .pipe(catchError(this.handleError));
  }

  PostSendAnnouncement(
    params,
    token: string
  ) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
     
    return this.httpClient
      // .post(`${'https://localhost:7280'}/v1/Master/postAdminAnnouncement`, params,
      .post(`${this.baseUrl}/v1/Master/postAdminAnnouncement`, params,
        {
          headers: headers
        })
      .pipe(catchError(this.handleError));
  }

  updateAnnouncement(
    params: EditAnnouncementRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postupdateAnnouncementById`, params,
        // .post(`${'https://localhost:7280'}/v1/Master/postupdateAnnouncementById`, params,
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }

  viewAnnouncement(
    params: DeleteAnnouncementRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postViewedMemberList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  deleteAnnouncement(
    params: DeleteAnnouncementRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postDeleteAnnouncementById`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  archiveAnnouncement(
    params,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postAnnouncementArchive`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  updateHelpDesk(
    params: HelpDeskRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postEmailSetting`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  deleteSharePoint(
    params: DeleteSharePointRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postDeleteSharepointLinkById`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  getAgreement(
    params: getagreementRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/getUserAgreement`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  updateAgreement(
    params: agreementRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postCreateUserAgreement`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  // Get Member Connection Matching Services List
  getMemberConnectionServices(
    params: MemberConnectionServiceListRequest,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postMemberConnectServiceList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  // getHubsportMemberDetails(
  //   params: ViewMemberHubspotServiceListRequest,
  //   token: string
  // ) {
  //   return this.httpClient
  //     .post(`${this.baseUrl}/v1/Member/postHubspotMemberRegisterData`, params, {
  //       headers: new HttpHeaders().set("Authorization", token)
  //     })
  //     .pipe(catchError(this.handleError));
  // }
  // getHubsportMemberDetails(params: ViewMemberHubspotServiceListRequest) {
  //   return this.httpClient.post(
  //     `${this.baseUrl}/v1/Auth/postHubspotMemberRegisterData`,
  //     params
  //   );
  // }

  // Member Connection Approve Process
  memberConnection(params: MemberConnectionRequest, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postMemberConnectUpdate`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // Member Basic Information Edit
  memberBasicInfoEdit(params: any, token: string) {
    return this.httpClient
      .post(
        `${this.baseUrl}/v1/Member/postAdminEditMemberBesicdetail`,
        params,
        {
          headers: new HttpHeaders().set("Authorization", token)
        }
      )
      .pipe(catchError(this.handleError));
  }

  // Member Education Edit
  memberEducationEdit(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postAdminEditMemberEducation`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // Member Experience Edit
  memberExperienceEdit(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postAdminEditMemberExperience`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // Get Interest & Skill Dropdown List
  getServicesList(token: string) {
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getServiceList`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // Delete Member
  deleteMember(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postAdminDeleteMember`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // Delete Member Interest
  deleteMemberInterest(params: any, token: string) {
    return this.httpClient
      .post(
        `${this.baseUrl}/v1/Member/postAdminDeleteMemberInterestedService`,
        params,
        {
          headers: new HttpHeaders().set("Authorization", token)
        }
      )
      .pipe(catchError(this.handleError));
  }


  // Delete Member Skill
  deleteMemberSkill(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postAdminDeleteMemberService`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // Change Member Status
  memberStatusChange(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postAdminUpdateMemberStatus`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // update list
  updateBlogList(token: any) {
    return this.httpClient
      .post(
        `${this.baseUrl}/v1/Master/postBlogReportSpamList`,
        {},
        {
          headers: new HttpHeaders().set("Authorization", token)
        }
      )
      .pipe(catchError(this.handleError));
  }

  // update Comment list
  updateCommentList(token: any) {
    return this.httpClient
      .post(
        `${this.baseUrl}/v1/Master/postBlogCommentReportSpamList`,
        {},
        {
          headers: new HttpHeaders().set("Authorization", token)
        }
      )
      .pipe(catchError(this.handleError));
  }

  // Discounts list
  getCouponDiscountList() {
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getCouponList`, {
        headers: new HttpHeaders().set("Authorization", this.token)
      })
      .pipe(catchError(this.handleError));
  }

  // Create discount coupon
  discountCouponCreate(params: any) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postCouponCreate`, params, {
        headers: new HttpHeaders().set("Authorization", this.token)
      })
      .pipe(catchError(this.handleError));
  }

  // Create member
  createMember(params: any,token:string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postAdminRegisterMember`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // Delete discount coupon
  deleteCoupon(id: any) {
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getCouponDelete?couponId=${id}`, {
        headers: new HttpHeaders().set("Authorization", this.token)
      })
      .pipe(catchError(this.handleError));
  }

  // Member Connection Request List
  getMemberRequestList(token:string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Member/postMemberConnectionRequestList`, {}, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  // update member profile
  updateMemberProfile(params: any,token:string) {
    return this.httpClient
      // .post(`${this.localUrl}/v1/Member/postMemberProfileImageUploadAdmin`, params, 
      .post(`${this.baseUrl}/v1/Member/postMemberProfileImageUploadAdmin`, params,
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }


  getVideoLists(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postEmbeddedVideoList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  deleteVideo(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postDeleteEmbeddedVideo`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  //create Video 
  createVideoImpact(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postCreateEmbeddedVideo`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  //edit Video 
  editVideoImpact(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postEditEmbeddedVideo`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }


  // new Changes 
  GetUserTypeList(token: string) {
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getUsertypeList`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  postUsertypeInsert(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postUsertype`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  postUsertypeUpdate(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postUsertypeUpdate`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  postUserTypeModuleMapping(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postUserTypeModuleMapping`, params,
        // .post(`${this.localUrl}/v1/Master/postUserTypeModuleMapping`, params,
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }
  postUserTypeMemberdetails(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postUsertypeMemberDetails`, params,
        // .post(`${this.localUrl}/v1/Master/postUserTypeModuleMapping`, params,
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }


  postUsertypeDelete(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postUsertypeDelete`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  postUsertypeModuleMapping(params: any, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postUserTypeModuleMappingList`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  getCreatedModulesList(
    token: string
  ) {
    return this.httpClient
      .get(`${this.baseUrl}/v1/Master/getModuleList`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  updateModule(
    params: any,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postModuleUpdate`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  postAdminProfile(params: any,token: string){
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postAdminProfile`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  GetAdminProfiles(token: string){
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/getAdminProfile`,null, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  postAdminSubscription(params:any,token: string) {
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/postAdminSubscriptionUpdate`, params,{
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }
  getSubscription(
    token: string
  ) {
    return this.httpClient
      .get(`${this.localUrl}/v1/Master/getSubscriptionDetails`, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  getHelpDesk(
    params: any,
    token: string
  ) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Master/getHelpDesk`, params,
        // .post(`${this.localUrl}/v1/Member/getMemberList`, params,  
        {
          headers: new HttpHeaders().set("Authorization", token)
        })
      .pipe(catchError(this.handleError));
  }

}
