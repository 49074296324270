<app-header></app-header>

<div class="p-5 mt-5" style="background-color: #f0f4f8;width: 100%;height: 100%;">
  <div class="d-flex justify-content-between">
    <div class="mt-2">
      <button type="button" class="btn btn-secondary btn-sm" style="background-color: #4C3A67;" (click)="createBlog() ">
        Create News
      </button>
    </div>
    <!-- <div class="mt-2" style="display: flex; align-items: center; margin-right: 20px;"> -->
    <!-- <div style="margin-right: 20px;">
          
          
        </div> -->

    <div class="search-form-field">
      <mat-form-field>
        <input matInput placeholder="Type to Search" [(ngModel)]="Search" (keyup)="filterEvent($event)" />

      </mat-form-field>

      <mat-icon class="searchIcon" (click)="submitfilter()">search</mat-icon>

    </div>


  </div>
  <div class="layout-table" style="width: 100%; display: block; ">
    <!-- <div class="one" style="width: 5%;"></div> -->
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <!-- Image Column -->
      <ng-container matColumnDef="Blog Images">
        <th mat-header-cell *matHeaderCellDef> News Images </th>
        <td mat-cell *matCellDef="let data" style="width: 10%;"> <img [src]="data. imageUrl" alt="Logo Image"
            style="width:30px;height:30px"> </td>
      </ng-container>

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Title </th>
        <td mat-cell *matCellDef="let data" style="text-align: justify;width: 25%;"> {{data.title}} </td>
      </ng-container>

      <!-- Link Column -->
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef> Link </th>
        <!-- <td mat-cell *matCellDef="let data" style="width: 25%;"> <a [href]="data.link">{{data.link}}</a> </td> -->
        <td mat-cell *matCellDef="let data" style="width: 10%;"> <a [href]="data.link" target="_blank"> Click Link </a>
        </td>
      </ng-container>

      <!-- Content Column -->
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let data" style="width: 25%;text-align: justify;"> {{Htmlparser(data.description)}}
        </td>

      </ng-container>

      <!-- Creator Column -->
      <ng-container matColumnDef="creator">
        <th mat-header-cell *matHeaderCellDef> Creator </th>
        <td mat-cell *matCellDef="let data" style="width: 10%;"> {{data.creator}} </td>
      </ng-container>

      <!-- Created Date Column -->
      <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef> Created Date </th>
        <td mat-cell *matCellDef="let data" style="width: 10%;"> {{data.created_date.substring(0,10)}} </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let data" style="width: 10%;">
          <mat-icon class="mr-2" matTooltip="Update" style="cursor: pointer;"
            (click)="createBlogUpdate(data)">mode_edit</mat-icon>
        </td>
      </ng-container>

      <!-- Header and Row Declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <!-- Pageinator -->

  </div>
  <div *ngIf="blogList?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
    <p>No data to display</p>
  </div>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons [length]="totalcount"
    [(pageIndex)]="currentPage" [(pageSize)]="pageSize" (page)="onPaginatorClick($event)">
  </mat-paginator>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, .6)"
  class="spin-img"
  template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>


<div class="modal fade" id="newblogInsert" role="dialog" aria-labelledby="staticBackdropLabel" style="margin-top: 50px;">
  <div class="modal-dialog">
    <div class="modal-dialog">
      <div class="modal-content" style="width: 700px;">
        
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">{{formHeading}}</h4>
          <!-- (click)="hide() -->
          <button type="button" class="close" data-dismiss="modal" (click)="hide()">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body mb-3">
          <!-- Coupon Discount Form -->
          <div class="container" *ngIf="blogFormOpen">
            <!-- Dynamic form for Update and Insert If Visible is true it will Insert Else it will Update -->
            <form [formGroup]="blogForm" (ngSubmit)=" visible ? BlogSubmit() : BlogUpdateSubmit()">
              <div class="form-group mb-3">
                <label for="">Title <span style="color: red;">*</span></label>
                <input type="text" class="form-control signup-input" id="title" placeholder="Title"
                  formControlName="title">
                <div *ngIf="blogSubmitted && blogControl.title.errors" class="ml-2">
                  <div *ngIf="blogControl.title.errors.required" class="text-danger">
                    Title is required
                  </div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label for="">Link <span style="color: red;">*</span></label>
                <input type="url" class="form-control signup-input" id="link" placeholder="Link" formControlName="link">
                <div *ngIf="blogSubmitted && blogControl.link.errors" class="ml-2">
                  <div *ngIf="blogControl.link.errors.required" class="text-danger">
                    Link is required
                  </div>
                  <div *ngIf="blogControl.link.errors.pattern" class="text-danger">

                    Please enter the valid link
                   </div>
                </div>

              </div>

              <!-- <div class="form-group mb-3">
                  <label for="">Description  <span style="color: red;">*</span></label>
                  <textarea class="form-control signup-input" id="description" placeholder="Description"
                  formControlName="description"></textarea>
                  <div *ngIf="blogSubmitted && blogControl.description.errors" class="ml-2">
                    <div *ngIf="blogControl.description.errors.required" class="text-danger">
                      Description is  required
                    </div>
                  </div>
                  
                </div> -->

              <div class="form-group mb-3">
                <label for="">Description <span style="color: red;">*</span></label>
                <!-- TINYMCE editor  primaryly used for Text Editing inside the Input Fields -->
                <editor apiKey="xdkenger2ocg52ups58llhfazuqsfl4o58n4u5umy0k0zsqr" formControlName="description" [init]="{
                  height: 400,
                  plugins: ['image', 'lists link image table code help wordcount', 'lists', 'table', 'code', 'help', 'wordcount', 'save'],
                  toolbar: 'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | save | link image|link',
                            images_upload_handler: this.uploadImages.bind(this)
                           
                
                
                }"></editor>

                <div *ngIf="blogSubmitted && blogControl.description.errors" class="ml-2">
                  <div *ngIf="blogControl.description.errors.required" class="text-danger">
                    Description is required
                  </div>
                </div>

              </div>
              

              <!-- <div class="form-group mb-3">
                  <label for="">Content  <span style="color: red;">*</span></label>
                  <textarea  type="text" class="form-control signup-input" id="content" placeholder="Content"
                  formControlName="content"></textarea>
                  <div *ngIf="blogSubmitted && blogControl.content.errors" class="ml-2">
                    <div *ngIf="blogControl.content.errors.required" class="text-danger">
                      Content is  required
                    </div>
                  </div>
                  
                </div> -->

              <div class="form-group mb-3">
                <label for="">Creator <span style="color: red;">*</span></label>
                <input type="url" class="form-control signup-input" id="" placeholder="Creator"
                  formControlName="creator">
                <div *ngIf="blogSubmitted && blogControl.creator.errors" class="ml-2">
                  <div *ngIf="blogControl.creator.errors.required" class="text-danger">
                    Creator is required
                  </div>
                </div>
              </div>
              <!-- <div class="updateVisible"   >
                  <div class="form-group mb-3" >
                    <label for="dropdown">Spam  <span style="color: red;">*</span></label>
                    <select class="form-control" formControlName="isSpam" (change)="onChange($event)">
                      <option *ngFor="let option of isspam" [value]="option">{{ option }}</option>
                    </select>
                  </div> -->



              <div class="form-group mb-3">
                <label for="">Published Date <span style="color: red;">*</span></label>
                <input type="datetime-local" class="form-control signup-input" id="" placeholder="Published Date "
                  formControlName="publishedDate">
              </div>

              <div class="form-group mb-3">
                <label for="">News Image <span [ngStyle]="{'display': display}" style="color: red;">*</span></label>
                <br>
                <span *ngIf="updateVisible"><a [href]="logo_url">{{logo_url_name}}</a></span>
                <input type="file" class="form-control signup-input" id="" placeholder="Image Url"
                  formControlName="blogImage" accept="image/*" (change)="onFileSelect($event)">
                  <div class="text-danger" *ngIf="customvalidator" > Please upload an image in GIF, JPEG, or PNG format. </div>

              </div>

              <button class="btn button-color btn-md btn-block" type="submit" [disabled]="!checkAllFieldsEntered()">
                <!-- <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                </span> -->
                <span>{{formButton}}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>