
<div class="container custom-container">
    <div class="row">
        <div class="col-auto p-0">
            <app-sidenav></app-sidenav>
          </div>
   <div class="col p-0">
    <div class="p-0 mt-0" style="background-color: #ffff ;width: 100%;height: 100%;">
    <app-header></app-header>
      <div class="main-block">
        <div class="d-flex justify-content-between align-items-start wrap-block create-col">
          <div class="mt-2">
            <div class="split-button" style="display:flex;gap:8px">
              <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;" (click)="onOpenVendorInsert()"
             >
                <img src="assets/img/plus.png" class="btn-img"/> New Vendor
              </button>
            </div>
          </div>
          <!-- (selectionChange)="onSelectChange($event)" -->
          <div class="mt-2 wrap-block forms-search" style="display: flex; align-items: center; margin-right: 20px;">
            <div style="margin-right: 20px;" class="form-search-sub" >
              <mat-form-field style="width: 100%; margin-top: auto;">
                <mat-select [(value)]="selectedType" class="user-select" style="margin-top: auto;" placeholder="Vendor Group"
                   panelClass="custom-dropdown-panel" (selectionChange)="onSelectChange($event)" disableOptionCentering>
                  <mat-option [value]="0">None</mat-option>
                  <mat-option *ngFor="let group of groupList" [value]="group.id">{{ group.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
    
            <div class="search-form-field searchType-input">
              <mat-form-field>
                <input matInput (keyup)="filterEvent($event)" [(ngModel)]="searchKey" placeholder="Type to Search" />
              </mat-form-field>
              <mat-icon class="searchIcon" style="cursor: pointer;" (click)="applyFilter()">search</mat-icon>
            </div>
          </div>
        </div>
        <div class="mat-elevation-z8 member-table table-container mobile-scroll">
  <table mat-table [dataSource]="dataSource" class="mat-table">

    <!-- Name Column -->
    <ng-container matColumnDef="Name">
      <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.firstName + ' ' + element.lastName || 'N/A'}} 
      </td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="Email">
      <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element" style="white-space: nowrap; width: 20%; min-height: 50px; padding: 20px;"> 
        {{element.email || 'N/A'}} 
      </td>
    </ng-container>

    <!-- Group Name Column -->
    <ng-container matColumnDef="GroupName">
      <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Vendor group</th>
      <td mat-cell *matCellDef="let element" style="width: 20%; min-height: 50px; padding: 20px;"> 
        {{element.groupName || 'N/A'}} 
      </td>
    </ng-container>

    <!-- Created Date Column -->
    <ng-container matColumnDef="CreatedDate">
      <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Created Date </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.createdDate ? (element.createdDate | date:'dd/MM/yyyy') : 'N/A'}} 
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
      <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element"> 
        <!-- Add any action buttons here -->
        <div class="edit-icon" matTooltip="Edit" style="cursor: pointer;" (click)="onOpenVendorUpdate(element)" >
          <img src="assets/img/view.png" />
      </div>
  <!-- <mat-icon matTooltip="Delete" style="cursor: pointer;"
      (click)="deleteSharePoint(row.id)" class="delete-icon">delete</mat-icon> -->
      <div class="delete-icon" matTooltip="Delete" style="cursor: pointer;"
        (click)="onDeleteSubmit(element)">
          <img src="assets/img/delete.png" >
        </div>
      </td>
    </ng-container>

    <!-- Header and Row Definitions -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <!-- No Records Message -->
  <div *ngIf="vendorList.length === 0" style="text-align: center; padding: 50px; background-color: white;">
    <p>No records found</p>
  </div>

  <!-- Paginator -->
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons [length]="length"
                 [(pageSize)]="PageSize" [(pageIndex)]="pageNo" (page)="onPaginatorClick($event)" class="metaPaginate">
  </mat-paginator>
</div>
<div class="modal fade" id="newVendorCreate" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{heading}}</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">
          <mat-icon class="inactive-icon" style="color: red; font-size: 27px;">cancel</mat-icon>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="modal-body">
        <div class="container">
          <form [formGroup]="vendorForm" (ngSubmit)="this.submitControl? vendorFormSubmit() : vendorFormUpdateSubmit()">
            <!-- First Name -->
            <div class="form-group mb-3">
              <label for="firstName">First Name <span style="color: red;">*</span></label>
              <input type="text" class="form-control signup-input" id="firstName" placeholder="First Name" formControlName="firstName" />
              <div *ngIf="vendorSubmitted && vendorControl.firstName.errors" class="text-danger ml-2">
                <div *ngIf="vendorControl.firstName.errors.required">First Name is required</div>
                <div *ngIf="vendorControl.firstName.errors.pattern">Avoid special characters. Minimum 3 characters required.</div>
              </div>
            </div>

            <!-- Last Name -->
            <div class="form-group mb-3">
              <label for="lastName">Last Name <span style="color: red;">*</span></label>
              <input type="text" class="form-control signup-input" id="lastName" placeholder="Last Name" formControlName="lastName" />
              <div *ngIf="vendorSubmitted && vendorControl.lastName.errors" class="text-danger ml-2">
                <div *ngIf="vendorControl.lastName.errors.required">Last Name is required</div>
                <div *ngIf="vendorControl.lastName.errors.pattern">Avoid special characters. Minimum 1 character required.</div>
              </div>
            </div>

            <!-- Email -->
            <div class="form-group mb-3">
              <label for="email">Email <span style="color: red;">*</span></label>
              <input type="email" class="form-control signup-input" id="email" placeholder="Email" formControlName="email" />
              <div *ngIf="vendorSubmitted && vendorControl.email.errors" class="text-danger ml-2">
                <div *ngIf="vendorControl.email.errors.required">Email is required</div>
                <div *ngIf="vendorControl.email.errors.pattern">Enter a valid email address</div>
              </div>
            </div>

            <!-- Group ID -->
            <div class="form-group mb-3">
              <label for="groupId"> Vendor Group <span style="color: red;">*</span></label>
              <select class="form-control" id="groupId" formControlName="groupId">
                <option value="" disabled selected>Select Group</option>
                <option *ngFor="let group of groupList" [value]="group.id">{{ group.name }}</option>
              </select>
              <div *ngIf="vendorSubmitted && vendorControl.groupId.errors" class="text-danger ml-2">
                <div *ngIf="vendorControl.groupId.errors.required">Group ID is required</div>
              </div>
            </div>

            <!-- Submit and Cancel Buttons -->
            <div class="footer-modal" style="display: flex; justify-content: flex-end;">
              <button class="btn button-color btn-md btn-block memberAdd-btn" type="submit" [disabled]="!checkAllFieldsEntered()" [ngStyle]="{ 'opacity': checkAllFieldsEntered() ? 1 : 0.5 }">
                <span >{{footer}} </span>
              </button>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

       </div>  
      </div>
    </div>
 </div>
</div>