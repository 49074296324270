<div class="container custom-container">
  <div class="row">
    <div class="col-auto p-0">
      <app-sidenav></app-sidenav>
    </div>
    <div class="col p-0">

<div class="p-0 mt-0" style="background-color: #fff;width: 100%;height: 100%;">
  <app-header></app-header>
  <div class="main-block">
  <div class="p-0 mt-0" style="width: 100%;height: 100%;">
    <div class="d-flex justify-content-between wrap-block create-col">
      <div class="mt-2 create-btn">
        <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;"
          (click)="openAddOrganizationModal()">
          New Organization
        </button>
      </div>
      <div class="mt-2 wrap-block forms-search" style="display: flex; align-items: center; margin-right: 20px;">
        <div style="margin-right: 20px;" class="form-search-sub">
          <mat-form-field style="width: 100%; margin-top: auto;">
            <mat-select [(value)]="subscriptionSelection" class="user-select" style="margin-top: auto;" placeholder="Subscription Type"
              (selectionChange)="onSelectChange($event)" panelClass="custom-dropdown-panel" disableOptionCentering>
              <mat-option [value]="0">None</mat-option>
              <mat-option *ngFor="let subscription of subscriptionlist"
                [value]="valueMapping[subscription]">{{subscription}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="search-form-field searchType-input">
          <mat-form-field>
            <input matInput placeholder="Type to Search" [(ngModel)]="Search" (keyup)="filterEvent($event)"/>

          </mat-form-field>

          <mat-icon class="searchIcon"  (click)="submitfilter()" style="cursor: pointer;">search</mat-icon>
          <!-- <label>{{subscriptionSelection}}</label> -->
        </div>
      </div>
    </div>

    <!-- Loader -->
    <ngx-spinner
      bdColor="rgba(0, 0, 0, .6)"
      class="spin-img"
      template="<img src='assets/img/logo-D.gif' />"
    >
    </ngx-spinner>

    <!-- Table -->
    <div class="layout-table mat-elevation-z8 member-table table-container" style="width: 100%; display: block;">
      <!-- <div class="one" style="width: 5%;"></div> -->
      <table>
        <thead>
          <tr>
            <td class="right" style="padding-left: 24px;">  Logo</td>
            <td> Org Name</td>
            <!-- <td> Secret Key</td> -->
            <td>Short Name</td>
            <td>Email</td>
            <td>Phone </td>
            <td >Subscription</td>
            <td>Date Created</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let data of organizationlist">
            <td class="left" style="padding-left: 24px;"> <img [src]="data.logoImg" alt="Logo Image"
                style="width:50px;height:50px;padding:5px"></td>
            <td style="text-transform: capitalize;">{{data.organisationName}}</td>
            <!-- <td>{{data.secretKey}}</td> -->
            <td style="text-transform: capitalize;">{{data.shortName}}</td>
            <td>{{data.contactEmail}}</td>
            <td>{{stripMask(data.contactNumber)}}</td>
            <td>{{subscriptionlist[data.susbscriptionId-1]}}</td>
            <td>{{data.createdDate.substring(0,10)}}</td>
            <td>
              <!-- <mat-icon class="mr-2" matTooltip="Update" style="cursor: pointer;"
                (click)="openUpdateOrganizationModal(data)">mode_edit</mat-icon> -->
              <!-- <mat-icon class="mr-2" matTooltip="Delete" style="cursor: pointer;">delete</mat-icon> -->
              <div matTooltip="Update" class="edit-icon" style="cursor: pointer;"
              (click)="openUpdateOrganizationModal(data)">
              <img src="assets/img/view.png" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="organizationlist?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
        <p>no records found </p>
      </div>
      <!-- Pageinator -->
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons [length]="totalcount"
        [(pageIndex)]="currentPage" [(pageSize)]="pageSize" (page)="onPaginatorClick($event)">
      </mat-paginator>
    </div>



    <!-- Model form -->

    <div class="modal fade" id="newOrgCreate" data-backdrop="static" tabindex="-1" role="dialog"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">{{formHeading}}</h4>
              <!-- (click)="hide() -->
              <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                <!-- <img src="assets/img/modal-close.png" /> -->
                <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
              </button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <!-- Coupon Discount Form -->
              <div class="container" *ngIf="addOrgForm">
                <form [formGroup]="OrganizationForm" (ngSubmit)=" this.visible ? onClickInsert() : onClickUpdate()">
                  <div class="form-group mb-3">
                    <label for="lastName">Organization Name<span style="color: red;"> *</span></label>
                    <input type="text" class="form-control signup-input" id="lastName" placeholder="Organization Name"
                      formControlName="name" />
                    <div *ngIf="OrganizationSubmitted && OrganizationControl.name.errors" class="ml-2">
                      <div *ngIf="OrganizationControl.name.errors.required" class="text-danger">
                        Organization Name Required
                      </div>
                      <div *ngIf="OrganizationControl.name.errors.pattern" class="text-danger">
                        Avoid using special characters and ensure that your  name more than 3 character
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="title"> Organization Short Name<span style="color: red;"> *</span></label>
                    <input type="text" class="form-control signup-input" id="title" placeholder=" Organization Short Name"
                      formControlName="shortName" />
                    <div *ngIf="OrganizationSubmitted && OrganizationControl.shortName.errors" class="ml-2">
                      <div *ngIf="OrganizationControl.shortName.errors.required" class="text-danger">
                        Organization Short Name required
                      </div>
                      <div *ngIf="OrganizationControl.shortName.errors.pattern" class="text-danger">
                        Avoid using special characters and ensure that your short name more than 2 character
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-3" *ngIf="this.visible">
                    <label for="email">Email <span style="color: red;"> *</span></label>
                    <input type="email" class="form-control signup-input" id="email" placeholder="Email"
                      formControlName="contactEmail"  [readonly]=" this.visible? false : true" />
                    <div *ngIf="OrganizationSubmitted && OrganizationControl.contactEmail.errors" class="ml-2">
                      <div *ngIf="OrganizationControl.contactEmail.errors.required" class="text-danger">
                        Email required
                      </div>
                      <div *ngIf="OrganizationControl.contactEmail.errors.pattern" class="text-danger">
                        Enter valid email address
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <label for="phoneNo">Phone Number <span style="color: red;"> *</span></label>
                    <input type="text" class="form-control signup-input" id="phoneNo" placeholder="Phone"
                      mask="(000)-000-0000" formControlName="contactNumber" (keydown)="handleKeyDown($event)" />
                    <div *ngIf="OrganizationSubmitted && OrganizationControl.contactNumber.errors" class="ml-2">
                      <div *ngIf="OrganizationControl.contactNumber.errors.required" class="text-danger">
                        Phone required
                      </div>
                      <div *ngIf="OrganizationControl.contactNumber.errors.pattern" class="text-danger">
                        Enter valid phone number
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-3" *ngIf="visible">
                    <label for="dropdown">Subscription Type <span style="color: red;"> *</span></label>
                    <div class="readonly">
                    <select class="form-control" formControlName="subscriptionId" (change)="onListSelect($event)" >
                      <option *ngFor="let option of subscriptionlist" [value]="option">{{ option }}</option>
                    </select>
                  </div>
                 
                  </div>
                  <div class="form-group mb-3">
                    <label for="organaisationName"> Logo Image<span style="color: red;" *ngIf="visible"> *</span></label>
                    <br>
                    <a [href]="logoUrl" target="_blank" *ngIf="logoHide"><span *ngIf="logoHide">View Image</span>
                    </a>
                    <br>
                    <img [src]="logoUrl" *ngIf="logoHide" alt="Profile Image" width="50" height="50">
                    <input type="file" class="form-control signup-input" accept="image/*" name="logoImg"
                      (change)="onFileSelect($event)" formControlName="logoimg" />
                      <div class="text-danger" *ngIf="customvalidator" > Please upload an image in GIF, JPEG, or PNG format. </div>

                  </div>
                  <div class="footer-modal" style="display: flex; justify-content: flex-end;">
                  <button class="btn button-color btn-md btn-block memberAdd-btn" type="submit" [disabled]="!checkAllFieldsEntered()">
                    <span>{{formButton}}</span>
                  </button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
