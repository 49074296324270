<app-header></app-header>

<div class="p-4 mt-4" style="background-color: #ffff;height: 100%;">

    <div class="dashboard-container" *ngIf="this.UserDtls == 2">
        <h1>Super Admin</h1>
        <div style="height: 50px;"></div>
        <div class="cards-container">
            <div class="row">
                <div class="card card-1" (click)="goto(1)">
                    <!-- <h6 class="title" style="font-weight: bolder;">Organizations & Members </h6> -->
                    <div>
                        <img src="../../assets/img/org.png">
                        <div class="card-number">100K</div>
                    </div>
                    <h6 class="title" style="font-weight: bolder;">Organizations & Members </h6>
                    <!-- <div class="card-title">Number of Organisations and members of the Organizations</div> -->
                </div>
                <div class="card card-2" (click)="goto(2)">
                    <!-- <h6 class="title" style="font-weight: bolder;">Subscription Tiers  </h6> -->
                    <div>
                        <img src="../../assets/img/subscription.png">
                        <div class="card-number">100K</div>
                    </div>
                    <h6 class="title" style="font-weight: bolder;">Subscription Tiers  </h6>
                    <!-- <div class="card-title">Subscription levels - Number of Subscriptions</div> -->
                </div>
                <div class="card card-3" (click)="goto(3)">
                    <!-- <h6 class="title" style="font-weight: bolder;">Active vs Inactive Users </h6> -->
                    <div>
                        <img src="../../assets/img/active.png">
                        <div class="card-number">100K</div>
                    </div>
                    <h6 class="title" style="font-weight: bolder;">Active vs Inactive Users </h6>
                    <!-- <div class="card-title">Active Users and Inactive Users of the Organizations</div> -->
                </div>
            </div>
            <div class="row">
                <div class="card card-4" (click)="goto(4)">
                    <!-- <h6 class="title" style="font-weight: bolder;">Organizations Approaching Expiration   </h6> -->
                    <div>
                        <img src="../../assets/img/calender.png">
                        <div class="card-number">100K</div>
                    </div>
                    <h6 class="title" style="font-weight: bolder;">Organizations Approaching Expiration   </h6>
                    <!-- <div class="card-title">Organizations whose Expiry date is nearing with dates</div> -->
                </div>
                <div class="card card-5" (click)="goto(5)">
                    <!-- <h6 class="title" style="font-weight: bolder;">New Organizations This Month  </h6> -->
                    <div>
                        <img src="../../assets/img/neworg.png">
                        <div class="card-number">100K</div>
                    </div>
                    <h6 class="title" style="font-weight: bolder;">New Organizations This Month  </h6>
                    <!-- <div class="card-title">New Organizations in that Month</div> -->
                </div>
                <div class="card card-6" (click)="goto(6)">
                    <!-- <h6 class="title" style="font-weight: bolder;">Revenue: Total & Monthly   </h6> -->
                    <div>
                        <img src="../../assets/img/expdate.png">
                        <div class="card-number">310K</div>
                    </div>
                    <h6 class="title" style="font-weight: bolder;">Revenue: Total & Monthly   </h6>
                    <!-- <div class="card-title">Total Subscription revenue to the date and for that month</div> -->
                </div>
            </div>
        </div>
    </div>


    <div class="dashboard-container" *ngIf="this.UserDtls == 1">
        <h1> Admin</h1>
        <div style="height: 50px;"></div>
        <div class="cards-container">
            <div class="row">
                
                <div class="card card-1">
                    
                    <div>
                        <img src="../../assets/img/active.png">
                        <div class="card-number">100K</div>
                    </div>
                    <div class="card-title">Active Users and Inactive Users of the Organizations</div>
                </div>
                <div class="card card-2">
                    <div>
                        <img src="../../assets/img/announ.png">
                        <div class="card-number">100K</div>
                    </div>
                    <div class="card-title">Number of Announcements
                        in that Month</div>
                </div>
                <div class="card card-3">
                    <div>
                        <img src="../../assets/img/news.png">
                        <div class="card-number">100K</div>
                    </div>
                    <div class="card-title">Number of News in that Month</div>
                </div>
            </div>
            <div class="row">
                <div class="card card-4">
                    <div>
                        <img src="../../assets/img/calender.png">
                        <div class="card-number">100K</div>
                    </div>
                    <div class="card-title">Number of Events in that Month</div>
                </div>
                <div class="card card-5">
                    <div>
                        <img src="../../assets/img/id.png">
                        <div class="card-number">100K</div>
                    </div>
                    <div class="card-title">Number of ID cards downloaded Color Theme</div>
                </div>

            </div>
        </div>
    </div>
</div>