// Vendor Imports
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable, Subject } from 'rxjs';

// Local Imports
import { environment } from 'src/environments/environment';


declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  private subject = new Subject<any>();
  private groupSubject = new Subject<any>();
  baseUrl = environment.baseUrl;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  receiverId = JSON.parse(localStorage.getItem('receiverId'));

  token = 'Bearer' + ' ' + this.currentUser.token;
  pusher: any;
  channel: any;
  userId: any;
  connectionid: any;

  constructor(private httpClient: HttpClient) {
    this.userId = this.currentUser.adminMemberId
    this.pusher = new Pusher(environment.pusherKey, {
      cluster: environment.cluster
    });

  }

  subscribeToChannel(channelName: string) {
    return this.pusher.subscribe(channelName);
    // channel.bind(eventName, callback);
  }

  unsubscribeFromChannel(channelName: string) {
    this.pusher.unsubscribe(channelName);
  }


  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  sendGroupEvent() {
    this.groupSubject.next();
  }

  getGroupEvent(): Observable<any> {
    return this.groupSubject.asObservable();
  }

  sendMessage(params: any) {
    return this.httpClient
      .post(`${this.baseUrl}/api/message`, params, {
        headers: new HttpHeaders().set('Authorization', this.token)
      })
      .pipe(catchError(this.handleError));
  }

  getMessage(params: any) {
    return this.httpClient
      .post(`${this.baseUrl}/api/getmessage`, params, {
        headers: new HttpHeaders().set('Authorization', this.token)
      })
      .pipe(catchError(this.handleError));
  }

  getGroupMessage(params: any) {
    return this.httpClient
      .post(`${this.baseUrl}/api/getgroupmessage`, params, {
        headers: new HttpHeaders().set('Authorization', this.token)
      })
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
