// Vendor Imports
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal from "sweetalert2";

// Local Imports
import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";
import { States } from "../shared//models/mock-states";
import { SignupService } from "../shared/services/signup-service";
import { ActivatedRoute, Router } from "@angular/router";
import { MemberService } from "../shared/services/member.service";
import { MemberConnectionList } from "../ima-members/ima-members.component";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-registeration",
  templateUrl: "./registeration.component.html",
  styleUrls: ["./registeration.component.css"]
})
export class RegisterationComponent implements OnInit {


  signupForm: FormGroup;
  signupSubmitted = false;
  paymentForm: FormGroup;
  paymentSubmitted = false;
  states = States;
  toggle = false;
  screen = 1;
  isLoading = false;
  payValue = 0;

  // Coupon
  coupon = "";
  couponApplied = "";
  couponError = "";
  couponLoading = false;
  amountOff = null;
  percentOff = null;
  husportId: any;
  services: any;
  hubspotmemberId: Number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.husportId = params["id"];
      // console.log(this.husportId)
      if (this.husportId != undefined)
        this.openAddMemberModal(this.husportId)
    });
    this.RegisterationForm("");
  }

  formatPhoneNumber(event: any) {
    // Remove non-digit characters from the input
    let value = event.target.value.replace(/\D/g, '');

    if (value.length <= 3) {
      value = value;
    } else if (value.length <= 6) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else {
      value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }

    // Update the form control value with the formatted value
    this.signupForm.get('phoneNo').setValue(value);
  }
  openAddMemberModal(id) {
    console.log(id)
    let params = {
      hubspotMemberId: id,
    };

    this.signupService
      .getHubsportMemberDetails(params)
      .subscribe((data) => {
        // console.log(data.connectedMemberList)
        this.services = data
        this.hubspotmemberId = this.services.hubspotmemberId;
        // console.log(this.services)
        this.RegisterationForm(this.services);
      },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
          //   }
        });
    // this.memberService.getHubsportMemberDetails(params) {
    //   return this.httpClient.post(
    //     `${this.baseUrl}/v1/Auth/postMasterLogin`,
    //     params
    //   );
    // }
    // this.memberService.getHubsportMemberDetails(params, token).subscribe(
    //   (data) => {
    //     // console.log(data.connectedMemberList)
    //     this.services = data
    //     console.log(this.services) 
    //     this.RegisterationForm(this.services);
    //   },
    //   err => {
    //     if (err.status === 401) {
    //       this.unauthorizedException();
    //     }
    //   }
    // );
  }
  RegisterationForm(servicename) {
    // console.log(servicename)
    this.screen = 1;
    this.coupon = "";
    this.couponApplied = "";
    this.percentOff = null;
    this.amountOff = null;
    this.payValue = this.getCouponValue(this.toggle ? 95 : 995);
    this.signupForm = this.formBuilder.group({
      firstName: [servicename.firstName, [Validators.required, WhiteSpaceValidator]],
      lastName: [servicename.lastName, [Validators.required, WhiteSpaceValidator]],
      title: [servicename.title, [Validators.required, WhiteSpaceValidator]],
      organaisationName: [servicename.organizationName, [Validators.required, WhiteSpaceValidator]],
      email: [
        servicename.email,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      phoneNo: [servicename.phone, [Validators.required, Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)]]
    });
    // this.paymentForm = this.formBuilder.group({
    //   accountNumber: [
    //     "",
    //     [Validators.required, Validators.pattern(/^[0-9]{13,16}$/)]
    //   ],
    //   exp: [
    //     "",
    //     [
    //       Validators.required,
    //       Validators.pattern(/^(0[1-9]|1[0-2])\/?([0-9]{4})$/)
    //     ]
    //   ],
    //   cvc: ["", [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]],
    //   name: ["", [Validators.required, WhiteSpaceValidator]],
    //   billingEmail: [
    //     "",
    //     [
    //       Validators.required,
    //       Validators.pattern(
    //         /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //       )
    //     ]
    //   ],
    //   address: ["", [Validators.required, WhiteSpaceValidator]],
    //   city: ["", [Validators.required, WhiteSpaceValidator]],
    //   state: ["", [Validators.required, WhiteSpaceValidator]],
    //   zipCode: ["", [Validators.required, Validators.pattern(/^[0-9]{5}$/)]]
    // });
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      // this.router.navigate(["/login"]);
    });
    return;
  }

  // convenience getter for easy access to form fields
  get signupControl() {
    return this.signupForm.controls;
  }

  signupSubmit() {
    this.signupSubmitted = true;

    if (this.signupForm.invalid) {
      return;
    }

    this.signupSubmitted = false;
    this.spinnerService.show()

    let params = {
      firstName: this.signupForm.value.firstName,
      lastName: this.signupForm.value.lastName,
      title: this.signupForm.value.title,
      organaisationName: this.signupForm.value.organaisationName,
      email: this.signupForm.value.email,
      phoneNo: this.signupForm.value.phoneNo,
      // accountNumber: this.paymentForm.value.accountNumber,
      // expiryYear: parseInt(expiryYear, 10),
      // expiryMonth: parseInt(expiryMonth, 10),
      // cvc: this.paymentForm.value.cvc,
      // name: this.paymentForm.value.name,
      // billingEmail: this.paymentForm.value.billingEmail,
      // address: this.paymentForm.value.address,
      // city: this.paymentForm.value.city,
      // state: this.paymentForm.value.state,
      // zipCode: this.paymentForm.value.zipCode,
      // interval: this.toggle ? "month" : "year",
      // couponId: this.couponApplied,
      // hubspotMemberId: this.hubspotmemberId
    };
    this.isLoading = true;
    this.signupService.signup(params).subscribe((data: any) => {
      if (data.statusCode === 201) {
        this.isLoading = false;
        this.spinnerService.hide()
        Swal.fire({
          title: "Thank You For Registering",
          text:
            "We have sent email address with password to the admin, please contact the admin to get the credentials.",
          icon: "success",
          showCloseButton: true,
          allowOutsideClick: false
        }).then(() => {
          this.RegisterationForm("");
          // window.location.assign("https://imanetwork.org/");
        });
        return;
      }
      if (data.statusCode === 403 && data.statusMessage.toLowerCase() === "email already exist please try another email") {
        this.isLoading = false;
        this.spinnerService.hide()
        Swal.fire({
          text: "Email already exists. Please try another email",
          icon: "warning",
          showCloseButton: true,
          allowOutsideClick: false
        }).then(() => {
          this.screen = 1;
        });
        return;
      }
      Swal.fire({
        text: data.statusMessage,
        icon: "warning",
        showCloseButton: true,
        allowOutsideClick: false
      });
      this.isLoading = false;
    });
    // this.paymentForm.controls["billingEmail"].setValue(
    //   this.signupForm.value.email
    // );
    // this.screen = 2;
  }

  // convenience getter for easy access to form fields
  get paymentControl() {
    return this.paymentForm.controls;
  }

  paymentFormSubmit() {
    // this.paymentSubmitted = true;

    if (this.signupForm.invalid) {
      return;
    }

    // this.paymentSubmitted = false;
    // let expiryMonth = this.paymentForm.value.exp.slice(0, 2);
    // let expiryYear = this.paymentForm.value.exp.slice(2, 6);


    let params = {
      firstName: this.signupForm.value.firstName,
      lastName: this.signupForm.value.lastName,
      title: this.signupForm.value.title,
      organaisationName: this.signupForm.value.organaisationName,
      email: this.signupForm.value.email,
      phoneNo: this.signupForm.value.phoneNo,
      // accountNumber: this.paymentForm.value.accountNumber,
      // expiryYear: parseInt(expiryYear, 10),
      // expiryMonth: parseInt(expiryMonth, 10),
      // cvc: this.paymentForm.value.cvc,
      // name: this.paymentForm.value.name,
      // billingEmail: this.paymentForm.value.billingEmail,
      // address: this.paymentForm.value.address,
      // city: this.paymentForm.value.city,
      // state: this.paymentForm.value.state,
      // zipCode: this.paymentForm.value.zipCode,
      // interval: this.toggle ? "month" : "year",
      // couponId: this.couponApplied,
      // hubspotMemberId: this.hubspotmemberId
    };
    this.isLoading = true;
    this.signupService.signup(params).subscribe((data: any) => {
      if (data.statusCode === 201) {
        this.isLoading = false;
        Swal.fire({
          title: "Thank You For Registering",
          text:
            "We have sent an email with password to your registered email address",
          icon: "success",
          showCloseButton: true,
          allowOutsideClick: false
        }).then(() => {
          this.RegisterationForm("");
          // window.location.assign("https://imanetwork.org/");
        });
        return;
      }
      if (
        data.statusCode === 403 &&
        data.statusMessage.toLowerCase() ===
        "email already exist please try another email"
      ) {
        this.isLoading = false;
        Swal.fire({
          text: "Email already exists. Please try another email",
          icon: "warning",
          showCloseButton: true,
          allowOutsideClick: false
        }).then(() => {
          this.screen = 1;
        });
        return;
      }
      Swal.fire({
        text: data.statusMessage,
        icon: "warning",
        showCloseButton: true,
        allowOutsideClick: false
      });
      this.isLoading = false;
    });
  }

  toggleSwitch() {
    this.toggle = !this.toggle;
    this.payValue = this.getCouponValue(this.toggle ? 95 : 995);
    this.applyCoupon(false);
  }

  screenChange(screen: any) {
    this.screen = screen;
  }

  couponKeyUp(value: any) {
    this.couponError = "";
  }

  getCouponValue(payVal: any) {
    if (this.amountOff === null && this.percentOff === null) {
      return payVal;
    }
    if (this.amountOff !== null) {
      return payVal - this.amountOff;
    }
    if (this.percentOff !== null) {
      return payVal - this.getPercentVal(this.percentOff, payVal);
    }
    if (this.amountOff === null) {
      return payVal;
    }
    if (this.percentOff === null) {
      return payVal;
    }
  }

  getPercentVal(percentOff: any, payVal: any) {
    return (percentOff / 100) * payVal;
  }

  applyCoupon(val: any) {
    let value = this.coupon.trim();
    if (this.coupon.length === 0) {
      this.couponError = val ? "required" : "";
      return;
    }
    this.couponLoading = true;
    let params = {
      couponId: this.coupon,
      amountType: this.toggle ? "monthly" : "yearly"
    };
    this.signupService.retrieveCoupon(params).subscribe((data: any) => {
      if (data.statusCode === 200 && data.coupon !== null) {
        this.couponLoading = false;
        if (data.coupon.valid) {
          this.couponApplied = this.coupon;
          this.amountOff = data.coupon.amount_off;
          this.percentOff = data.coupon.percent_off;
          this.payValue = this.getCouponValue(this.toggle ? 95 : 995);
        }
        if (!data.coupon.valid) {
          this.coupon = "";
          this.couponApplied = "";
          this.percentOff = null;
          this.amountOff = null;
          this.payValue = this.getCouponValue(this.toggle ? 95 : 995);
          Swal.fire({
            title: "Invalid Coupon",
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
        }
      }
      if (data.statusCode === 404 && data.coupon === null) {
        this.couponLoading = false;
        this.coupon = "";
        this.couponApplied = "";
        this.percentOff = null;
        this.amountOff = null;
        this.payValue = this.getCouponValue(this.toggle ? 95 : 995);
        Swal.fire({
          title: "Invalid Coupon",
          icon: "warning",
          showCloseButton: true,
          allowOutsideClick: false
        });
      }
      if (data.statusCode === 400 && data.coupon === null) {
        this.couponLoading = false;
        this.coupon = "";
        this.couponApplied = "";
        this.percentOff = null;
        this.amountOff = null;
        this.payValue = this.getCouponValue(this.toggle ? 95 : 995);
        Swal.fire({
          title: data.statusMessage,
          icon: "warning",
          showCloseButton: true,
          allowOutsideClick: false
        });
      }
    });
  }
}
