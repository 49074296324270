import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  UserDtls: any;

  constructor(private router: Router) {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.UserDtls = user.roleId
  }

  ngOnInit(): void {
  }

  goto(id) {
    
    // if (id == 1)
    //   this.router.navigate(['/organization'])
    // else if (id == 2)
    //   this.router.navigate(['/manageSubscription'])
    // else if (id == 3)
    //   this.router.navigate(['/manageSubscription'])
    // else if (id == 4)
    //   this.router.navigate(['/organization'])
  }

}
