<app-header></app-header>

<div class="p-5 mt-5">
    <h4 style="text-align: center;">Admin Panel Operation</h4>
    <hr style="border-top: 3px solid;">
    <h5 class="title"> Login Page</h5>
    <p> First, go to <a href="https://admin.imamemberconnect.com/">https://admin.imamemberconnect.com/</a> and log into
        the
        admin panel using username and password</p>
    <br>
    <img src="assets/img/login.png" style="width: 30%;" />
    <br>
    <br>
    <div class="col">
        <div class="col1">
            <h5 class="title">IMA Members Page</h5>
            <p style="width: 60%;">You will begin at the IMA Members
                page. This lists all of the members
                currently in the IMA app, allowing you
                to view each profile, suspend/delete
                members, add members, or sync all
                member data to hubspot</p>
        </div>
        <div class="col2" style="text-align: end;">
            <img src="assets/img/memberpage.png" class="img" />

        </div>
    </div>
    <br>
    <br>
    <!-- <h5>IMA Members Page</h5>
    <p>You will begin at the IMA Members
        page. This lists all of the members
        currently in the IMA app, allowing you
        to view each profile, suspend/delete
        members, add members, or sync all
        member data to hubspot</p> -->
    <!-- <img src="assets/img/memberpage.png" style="width: 30%;" /> -->


    <div class="col">
        <div class="col1">
            <h5 class="title">Navigation Panel</h5>
            <p style="width: 60%;">To move to other screens, click on the Menu icon on the upper left
                to open the Navigation panel. This will allow you to swap between
                pages as needed.</p>
        </div>
        <div class="col2" style="text-align: end;">
            <img src="assets/img/navigation.png" style="width: 50%;" />

        </div>
    </div>

    <br>
    <br>


    <!-- <h5>Navigation Panel</h5>
    <p>To move to other screens, click on the Menu icon on the upper left
        to open the Navigation panel. This will allow you to swap between
        pages as needed.</p> -->

    <div class="col">
        <div class="col1">
            <h5 class="title">Hubspot Members Page</h5>
            <p style="width: 60%;">The Hubspot members page is
                primarily used to add members to
                the IMA app with only their hubspot
                information. Simply find the user
                you'd like to add, and select either:
                <br>
                <br>
                Free Subscription: Admin will be sent
                the user's information and will then
                create a free account with the user
                <br>
                <br>

                Paid Subscription: The user will be
                sent a registration link with their
                information pre-filled. They will then
                be asked to pay for a subscription.
                <br>
                <br>
                Then, click on the registration buttons on the bottom, and the accounts will be added.
            </p>
        </div>
        <div class="col2" style="text-align: end;">
            <img src="assets/img/hubspot.png" class="img" />

        </div>
    </div>

    <br>
    <br>
    <!-- <h5>Hubspot Members Page</h5>
    <p>The Hubspot members page is
        primarily used to add members to
        the IMA app with only their hubspot
        information. Simply find the user
        you'd like to add, and select either:
        <br>
        <br>
        Free Subscription: Admin will be sent
        the user's information and will then
        create a free account with the user
        <br>
        <br>

        Paid Subscription: The user will be
        sent a registration link with their
        information pre-filled. They will then
        be asked to pay for a subscription. Then, click on the registration buttons on the bottom, and the
        <br>
        <br>
        Then, click on the registration buttons on the bottom, and the accounts will be added.
    </p> -->

    <div class="col">
        <div class="col1">
            <h5 class="title">Connect Members Page</h5>
            <p style="width: 60%;"> This page is used to manually connect
                members, the admin may select any
                account, view the account's
                connections, and then select any
                account to connect with. Once the
                connect button is clicked, both users
                will receive an email inviting them to
                connect on the IMA app.</p>
        </div>
        <div class="col2" style="text-align: end;">
            <img src="assets/img/connect.png" class="img" />

        </div>
    </div>
    <br>
    <br>
    <br>

    <!-- <h5>Connect Members Page</h5>
    <p> This page is used to manually connect
        members, the admin may select any
        account, view the account's
        connections, and then select any
        account to connect with. Once the
        connect button is clicked, both users
        will receive an email inviting them to
        connect on the IMA app.</p> -->


    <div class="col">
        <div class="col1">
            <h5 class="title"> Discount Coupons Page</h5>
            <p style="width: 60%;"> This page is used to manage any coupons to
                be used for registration costs. Admins can
                create and delete coupons here for the
                monthly or yearly subscriptions, on a
                percentage or dollar basis.</p>
        </div>
        <div class="col2" style="text-align: end;">
            <img src="assets/img/discount.png" class="img" />

        </div>
    </div>
    <!-- <h5> Discount Coupons Page</h5>
    <p> This page is used to manage any coupons to
        be used for registration costs. Admins can
        create and delete coupons here for the
        monthly or yearly subscriptions, on a
        percentage or dollar basis.</p> -->

    <br>
    <br>

    <div class="col">
        <div class="col1">
            <h5 class="title"> Member Connection Requests Page</h5>
            <p style="width: 60%;"> Finally, the panel includes a page used only
                for monitoring member connection requests. The page displays a feed of the most recent
                requests.</p>
        </div>
        <div class="col2" style="text-align: end;">
            <img src="assets/img/membercon.png" class="img" />

        </div>
    </div>

    <br>
    <br>
    <!-- <h5> Member Connection Requests Page</h5>

    <p> Finally, the panel includes a page used only
        for monitoring member connection requests. The page displays a feed of the most recent
        requests.</p> -->

</div>

<nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
    <div>Member Connect</div>
</nav>