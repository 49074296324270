
<div class="p-4 mt-0">
  <div>
    <div class="text-right">
      <!-- <mat-icon class="mr-2" *ngIf="row.status === 1" matTooltip="Suspend" style="cursor: pointer;"
          (click)="suspendUser(row)">cancel</mat-icon> -->
      <img src="assets/img/download.png" (click)="downloadExcels()" alt="Hand Symbol"
        style="width: 30px; height: 30px" />
      <!-- <mat-form-field style="width: 35%;height: 20%;">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filter" placeholder="Type to Search" />
      </mat-form-field> -->
    </div>

    <!-- <ngx-spinner size="medium" type="square-loader"></ngx-spinner> -->

    <ngx-spinner
      bdColor="rgba(0, 0, 0, .6)"
      class="spin-img"
      template="<img src='assets/img/logo-D.gif' />"
    >
    </ngx-spinner>

    <div style="display: flex;">
      <label class="custom-file-input-label">
        <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls" />
        <span>Import File</span>
      </label>
    </div>


    <!-- <label for="fileInput" class="file-input-button">
      Select Excel File
    </label>
    <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls" /> -->
    <!-- <input id="fileInput" type="file" (change)="onFileChange($event)" accept=".xlsx, .xls" style="display: none;" /> -->

    <div class="mat-elevation-z8 bulk-table" *ngIf="this.dataSource" style="margin-bottom: 60px">


      <mat-table [dataSource]="dataSource" matSort>

        <ng-container *ngFor="let column of displayedColumns">
          <ng-container matColumnDef="{{column}}">
            <!-- Use a single *ngIf directive on the ng-container -->
            <ng-container>
              <mat-header-cell class="mat-table-header" *matHeaderCellDef style="min-width: 200px;background-color: #EBECEF;" >

                <ng-container>
                  <div *ngIf="column=='Phone No *'">
                    {{'Phone#'}}
                    </div>
                    <div *ngIf="column!='Phone No *'">
                      {{ column.replace('*', '') }}
                      </div>
                 
                </ng-container>
              </mat-header-cell>

              <mat-cell *matCellDef="let row" style="min-width: 200px;" >
                <ng-container *ngIf="column == 'Message' && row[column]== false;else elseBlock1">
                  <span class="text-danger">"Either Phone#/Email is invalid."</span>
                </ng-container>
                <ng-template #elseBlock1>
                  <ng-container *ngIf="column == 'Message' && row[column]== true; else elseBlock2">
                  </ng-container>
                  <ng-template #elseBlock2>
                    <div *ngIf="column=='Phone No *'">
                    {{stripMask(row[column].toString())}}
                    </div>
                    <div *ngIf="column!='Phone No *'">
                      {{ row[column] }}
                    </div>
                  </ng-template>
                </ng-template>
                <!-- <ng-container *ngIf="column == 'isValid' && row[column]== false;else elseBlock">
                  invalid
                </ng-container>
                <ng-template #elseBlock>
                  {{ row[column] }}
                </ng-template> -->
              </mat-cell>
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- <ng-container *ngFor="let column of displayedColumns">
          <ng-container matColumnDef="{{column}}">
            <mat-header-cell class="mat-table-header" *matHeaderCellDef mat-sort-header>{{column.replace('*',
              '')}}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row[column]}}</mat-cell>
          </ng-container>
        </ng-container> -->

        <!-- <ng-container matColumnDef="checkbox">
          <mat-header-cell class="mat-table-header" *matHeaderCellDef>
            <div class="free">Select <br />
              <mat-checkbox color="accent" (change)="toggleSelectAll($event)">
              </mat-checkbox>
            </div>
          </mat-header-cell>
          <mat-checkbox color="accent">
          </mat-checkbox>
          <mat-cell *matCellDef="let row">
            <mat-checkbox [(ngModel)]="row.selected" (change)="onCheckboxChange(row)"></mat-checkbox>
          </mat-cell>
        </ng-container> -->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

     
        <!-- <mat-header-row *matHeaderRowDef="displayedColumns.concat('checkbox')"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns.concat('checkbox')"></mat-row> -->
      </mat-table>
      <table>
        <tr>
          <td>
            <div>
              <button class="sync-btn cancel-btn" style="  background: #dc3545;" (click)="clearExcel()">
                <!-- <span  class="spinner-border spinner-border-sm text-light" role="status">
              </span> -->
                <span>Cancel</span>
              </button>

              <button class="sync-btn submit-btn" style="  background: #4C3A67;" (click)="sendEmail()">
                <span>Submit</span>
              </button>
              <!-- <label (click)="clearExcel()">
                <span>Clear</span>
              </label> -->
            </div>
          </td>
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" [pageSize]="pageSize" [length]="totalItems"></mat-paginator>

 
    </div>

    <div style="text-align: center;" *ngIf="!this.dataSource">
      <label style="margin-top: 15px;" class="bulk-content">

        <span>To import the user data, kindly download the Excel template provided above by clicking the designated
          icon<img src="assets/img/download.png" style="width: 30px; height: 30px" />.<br />
          Subsequently, please ensure to fill all mandatory fields before importing. </span>

        <!-- <span>to download this excel sheet.</span> -->
      </label>
    </div>
  </div>

  <!-- <nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
    <div>Member Connect</div>
  </nav> -->

  <!--Member Review Modal -->
  <div class="modal fade" id="memberReviewModel" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Member Match Details</h4>
          <button type="button" class="close" data-dismiss="modal" (click)="hide()">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <!-- <div class="modal-body mb-3" *ngIf="showModal">
        <div class="font-weight-bold" style="font-size: 17px">
          {{ currentUser.member }}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{{
            currentUser.matchedMember
          }}
          -
          <span>{{ currentUser.matchedServiceCount }} value match(es)</span>
        </div>
        <div class="mt-2 text-center" *ngIf="serviceRequest">
          <span class="spinner-border text-dark" role="status"> </span>
        </div>
        <div class="mt-2" *ngIf="!serviceRequest">
          <ul *ngFor="let value of services">
            <li>{{ value.serviceName }}</li>
          </ul>
        </div>
        <hr />
        <div class="text-center mt-2">
          <button
            *ngIf="currentUser.actionStatus === null"
            type="button"
            class="btn btn-success btn-sm mr-2"
            style="padding: 5px 70px;"
            (click)="approve(currentUser.memberId, currentUser.matchedMemberId)"
            [disabled]="approveLoading"
          >
            <span
              *ngIf="approveLoading"
              class="spinner-border text-light spinner-border-sm"
              role="status"
            >
            </span>
            <span *ngIf="!approveLoading">Approve</span>
          </button> 
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            style="padding: 5px 70px;"
            (click)="hide()"
          >
            Cancel
          </button>
        </div>
      </div> -->
      </div>
    </div>
  </div>