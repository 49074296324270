import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
// Local Imports
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";

import { NgxSpinnerService } from "ngx-spinner";
import { animate, state, style, transition, trigger } from "@angular/animations";


import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { blogService } from "../shared/services/blog.service";
declare var $: any;
declare const Pusher: any;
interface BlogData {
  imageUrl: string;
  title: string;
  link: string;
  content: string;
  creator: string;
  created_date: string;
}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})

export class BlogComponent implements OnInit {
  html = '<p>Hi, TinyMCE!</p>';
  validTypes=  ['image/jpeg', 'image/png', 'image/gif'];
  customvalidator:boolean=false;
  displayedColumns: string[] = ['Blog Images', 'title', 'link', 'Description', 'creator', 'created_date', 'actions'];
  dataSource: MatTableDataSource<BlogData>;
  blogForm: FormGroup;
  sanitizedHtml: SafeHtml;
  blogFormOpen:boolean= false;
  blogSubmitted = false;
  token:string;
  formHeading:string;
  visible:boolean;
  display: string = 'none'; 
  uploadImage:any=null;
  selectedFile:any=null;
  isLoading = false;
  currentPage:number=0;
  pageSize:number=10;
  Search:string="";
  blogList:[];
  totalcount:number;
  spam:any;
  imageUrl:any;
  filtercount:any;
  updateVisible:boolean;
  logo_url_name:string;
  logo_url:string;
  blogId:any;
  pageShift:boolean=false;
  Default:false;
  formButton:string;
  logoVisible:boolean=false;
  selectedValue:any;
  isspam=['True','False'];
  options = [
    { value: 1, label: 'True' },
    { value: 0, label: 'False' }
  ];

  constructor(
    private blogservice: blogService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer
  ) {  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();
    this.isLoading=true;
    this.getBlogList();
    this.isLoading=false;
    
  }

  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.spinnerService.show();
    // Update currentPage and pageSize based on event
    this.currentPage = event.pageIndex; // If your API expects 1-based page index, otherwise use event.pageIndex
    console.log('currentpage', this.currentPage);
    this.pageSize = event.pageSize;
    // Logging currentPage for debugging
    console.log(this.currentPage);

    // Fetch updated data
    this.getBlogList();
  }
  // Drop Down Vlaue 
  onChange(value: any) {
    this.selectedValue =value
    console.log('Selected value:', this.selectedValue);
    
    
  }
  hide(){
    this.selectedFile=null;
  }
  
  // Get Api
  getBlogList(){
    let params=
      {
        "searchBlog": this.Search.trim(),
        "pageNo": this.currentPage+1,
        "pageSize": this.pageSize
      }
      this.blogservice.blogList(params,this.token).subscribe((data: any) => {
      console.log(data.organisationList);
      this.spinnerService.show();
      if (data.statusCode == 200) {
      this.dataSource=data.blogList;
      console.log(this.dataSource);
      this.blogList = data.blogList;
      this.totalcount =(this.pageShift) ? data.filteredCount:data.totalCount ;
      this.filtercount=data.filteredCount;
      console.log(this.totalcount);
      this.spinnerService.hide();
    }
    else {
      this.ExceptionMessage(data.statusMessage, "warning");
      this.spinnerService.hide();
    }
  }, (err) => {
    this.unauthorizedException() ;
    if (err.status === 401) {
      this.unauthorizedException();
      
      this.spinnerService.hide();
    }
  });
  }

// OnClick Search Icon
  submitfilter(){
    this.spinnerService.show();
    this.currentPage=0;
    this.pageShift=true;
    this.getBlogList();
  }
  // Pagenation For Filter Event When the Size =0 it will show all the Records 
  filterEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0)
      this.getBlogList();
  }
  // Image Upload Onchnage
  onFileSelect(event: any) {
    this.selectedFile = event.target.files[0];
    if(this.blogSubmitted==true||this.validTypes.includes(event.target.files[0].type)){
      this.customvalidator= ! this.validTypes.includes(event.target.files[0].type);
      }
    }
  somefunction() {
  // Do something here.
  console.log(this.html);
}

// Blog Insert
    BlogSubmit(){
    
      this.blogSubmitted = true;
      console.log(this.blogForm)
      if (this.blogForm.invalid) {
        // console.log("Hey");
        return;
      }
      this.blogSubmitted = false;
      this.isLoading = true;
      if(this.selectedFile!=null){
      if(! this.validTypes.includes(this.selectedFile.type)){
        this.customvalidator=true;
         return;
    
        }
      }
        this.spinnerService.show();
     
      // this.eventForm.get('isSpam')?.setValue(change);
      let params= new FormData();
      params.append('title',this.blogForm.get('title')?.value.trim())
      params.append('link',this.blogForm.get('link')?.value.trim())
      params.append('description',this.blogForm.get('description')?.value.trim())
      params.append('content',this.blogForm.get('description')?.value.trim())
      params.append('creator',this.blogForm.get('creator')?.value.trim())
     
      // params.append('endDate',this.blogForm.get('endDate').value)
      params.append('publishedDate',this.blogForm.get('publishedDate').value)
      params.append('blogImage',this.selectedFile)
      // this.eventForm.value;
  
      this.blogservice.postInsertBlog(params,this.token).subscribe(
        (data: any) => {
          console.log(data.statusCode);
          if (data.statusCode === 200) {
            this.blogFormOpen = false;
            $("#newblogInsert").modal("hide");
            this.getBlogList();
            this.isLoading=false;
            this.spinnerService.hide();
            
            Swal.fire({
              title: "Created!",
              text: "News Created Successfully",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false
            });
            
          } else {
            $("#newblogInsert").model("hide");
            this.isLoading = false;
            Swal.fire({
              text: data.statusMessage,
              icon: "warning",
              showCloseButton: true,
              allowOutsideClick: false
            });
          }
        },
        err => {
          if (err.status === 401) {
            this.isLoading=false;
            this.unauthorizedException();
          }
          else if(err.status === 403){
            this.isLoading=false;
            this.unauthorizedException();
          }
        }
      );
  
    }

// Blog Update 
    BlogUpdateSubmit(){
      this.logoVisible=false;
      this.visible=false;
      this.blogSubmitted = true;
      console.log(this.blogForm)
      if (this.blogForm.invalid) {
        console.log("Hey");
        
        return;
      }
      this.blogSubmitted = false;
      this.isLoading = true;
      if(this.selectedFile!=null){
      if(! this.validTypes.includes(this.selectedFile.type)){
        this.customvalidator=true;
         return;
        }
      }
      this.spinnerService.show();
      let params= new FormData();
      params.append('title',this.blogForm.get('title')?.value.trim())
      params.append('link',this.blogForm.get('link')?.value.trim())
      params.append('description',this.blogForm.get('description')?.value.trim())
      params.append('content',this.blogForm.get('description')?.value.trim())
      params.append('creator',this.blogForm.get('creator')?.value.trim())

      // params.append('endDate',this.blogForm.get('endDate').value)
      params.append('publishedDate',this.blogForm.get('publishedDate').value)
      params.append('blogImage',this.selectedFile)
      // this.eventForm.value;
      params.append('blogId', this.blogId);
      // console.log(params)
      // params['eventId'] = this.eventId;
      
     
      this.blogservice.postUpdateBlog(params,this.token).subscribe(
        (data: any) => {
          console.log(data.statusCode);
          if (data.statusCode === 200) {
            // this.isLoading = false;
            this.blogFormOpen = false;
           
            $("#newblogInsert").modal("hide");
            this.getBlogList();
            this.spinnerService.hide();
            Swal.fire({
              title: "Updated!",
              text: "News Updated Successfully",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false
            });
            
          } else {
            $("#newblogInsert").modal("hide");
            this.isLoading = false;
            Swal.fire({
              text: data.statusMessage,
              icon: "warning",
              showCloseButton: true,
              allowOutsideClick: false
            });
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
          else if(err.status === 403){
            this.unauthorizedException();
          }
        }
      );
     
  
    }
    //Blog Open Update Form 
    createBlogUpdate(data:any){
      this.customvalidator=false;
      this.display="none";
      this.blogId=data.blogId;
      this.formButton="Update"
      this.visible=false;
      this.logoVisible=true;
      this.updateVisible=true;
      console.log("State",this.updateVisible);
      this.formHeading="Update News"
      $("#newblogInsert").modal("show");
      this.blogFormOpen=true;
  
        this.blogForm = this.formBuilder.group({
        
        title: [data.title, [Validators.required, WhiteSpaceValidator]],
        link: [data.link,
          [Validators.required, Validators.pattern(/^(https?:\/\/)?([\w.-]+)(:[0-9]{1,5})?(\/[\w\/.-]*)*(\?[;&a-zA-Z0-9%_.+-]*)?(#[^\s]*)?$/), WhiteSpaceValidator]]
        ,
        description: [data.description, [Validators.required, WhiteSpaceValidator]],
       
        blogImage: [],
        creator: [data.creator, [Validators.required, WhiteSpaceValidator]],
       
        publishedDate:[this.getCurrentDateTimeFormatted(), [Validators.required, WhiteSpaceValidator]],
       
  
    });
    this.logo_url=data.imageUrl
    let s=data.imageUrl.split("/");
    this.logo_url_name=s[s.length-1];
  
    }
    // Blog -open Create Blog
    createBlog() {
      this.customvalidator=false;
      this.display="inline";
      this.formButton="Add";
      this.visible=true;
      this.updateVisible=false;
      $("#newblogInsert").modal("show");
      this.formHeading="Create News";
      this.blogFormOpen=true;
      this.blogForm = this.formBuilder.group({
        title: ["", [Validators.required, WhiteSpaceValidator]],
        link: ["",
          [Validators.required, Validators.pattern(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/), WhiteSpaceValidator]]
        ,
        description: ["", [Validators.required, WhiteSpaceValidator]],
       
        blogImage: ["", [Validators.required, WhiteSpaceValidator]],
        creator: ["", [Validators.required, WhiteSpaceValidator]],
       
        publishedDate:[this.getCurrentDateTimeFormatted(), [Validators.required, WhiteSpaceValidator]],
        
  
    });
  
    }
    
  // Control Over The Form Values
  get blogControl() {
    return this.blogForm.controls;
  }
  // Defaultly Set the date And Time As Utc
  getCurrentDateTimeFormatted(): string {
    // Get current date and time formatted for datetime-local input
    const now = new Date();
    const year = now.getFullYear();
    const month = this.padZero(now.getMonth() + 1); // Month is zero-indexed
    const day = this.padZero(now.getDate());
    const hours = this.padZero(now.getHours());
    const minutes = this.padZero(now.getMinutes());
  
    // Format: YYYY-MM-DDTHH:MM (e.g., "2023-07-04T12:30")
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  
  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  // Upload Image Throgh Tiny mc Editor 
  uploadImages(blobInfo, success, failure): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64Data = e.target.result;
      success(base64Data);
    };

    reader.onerror = (error) => {
      console.error('Error reading image file:', error);
      failure('Error reading image file');
    };

    reader.readAsDataURL(blobInfo.blob());
  }
  // Disable The FSubmit Button when All field Are not Filled 
  checkAllFieldsEntered(): boolean {
    return Object.keys(this.blogForm.controls).every(key => {
      // Skip Only For Update Module 
      if (key === "blogImage"&& this.updateVisible) {
        return true; // Skip validation for 'logoimg'
      }

      const control = this.blogForm.get(key);
      return control && control.value !== '' && control.value !== null;
    });
  }
  // Pop  Messages 
  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Access Denied",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);

    });
    return;
  }
  SucessMessage(title, text, icon) {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCloseButton: true,
      allowOutsideClick: false
    });
  }
  ExceptionMessage(title, icon) {
    Swal.fire({
      title: title,
      icon: icon,
      showCloseButton: true,
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: 'ok'
    });
    return;
  }
  // For Removing the tags in Html
  Htmlparser(data:string):string{
    var res=data.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
     
    return res;
    
  }



}
