import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { AnnouncementListResponse, ViewAnnouncementListResponse } from '../shared/models/user';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { element } from 'protractor';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SharedalertService } from '../shared/services/sharedalert.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

export interface UserData {
  id: Number;
  title: string;
  userRoles: userRoles;
  created_date: string;
}
export interface userRoles {
  roleId: Number;
  roleName: string;
}

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {

  html = '<p></p>';
  customvalidator:boolean=false;
  validTypes=  ['image/jpeg', 'image/png', 'image/gif'];
  logoHide: boolean = true;
  isShow:boolean=true;
  logoDisplay: string;
  token: string;
  roleList: any;
  selectedFile:File=null;
  title: any;
  rolevalue: any;
  dataSource: MatTableDataSource<UserData>;
  users: UserData[];
  filter: string;
  typeofAnnounce: string = "Create"
  displayedColumns: string[] = [
  
    "title",
    "roles",
    "create_date",
    "actions"
  ];

  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  addAnnouncementForm: boolean;
  announcementId = 0;
  selectedRoles: string[] = [];
  istitleError: boolean;
  ishtmlError: boolean;
  isRoleError: boolean;
  announcementForm: FormGroup;
  announcementSubmit: boolean;
  resizedBlob: Blob | null = null;
  isSelectedRoles: boolean;
  apiselectedRoles: string[] = [];
  isArchiveList: boolean;
  viewUserdata: any[];
  isEmpty:number;
  searchKey: any = '';
  selectedType: Number
  pageSize = 10;
  currentPage = 0;
  totalItems: any;
  uniqueCode: string;
  titles:string;
  content:string;
  shortTitle:string;
  shortDescription:string;

  
  // selectedRoles: number[] = []; // Assuming you have this property
  // roleList: any; // Assuming you have this property
  constructor(private spinnerService: NgxSpinnerService, private route: ActivatedRoute, private sharedService: SharedalertService, private memberService: MemberService, private formBuilder: FormBuilder) {
    this.announcementForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator]],
      // rolevalue: ['', Validators.required],
      html: ['', [Validators.required, WhiteSpaceValidator]],
      shortTitle:['',[Validators.required, WhiteSpaceValidator]],
      shortDescription:['',[Validators.required, WhiteSpaceValidator]],
      logoimg:['',[Validators.required, WhiteSpaceValidator]],
      interests: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show()
   
    this.route.paramMap.subscribe(params => {
      this.uniqueCode = params.get('uniqueCode');
      // Use the uniqueCode as needed
      console.log(this.uniqueCode); // Example: log the uniqueCode
    });

    this.getRoles()
    // this.getarchiveAnnouncement()
    this.getAnnouncementList(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)
  }

  uploadImage(blobInfo, success, failure): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64Data = e.target.result;
      success(base64Data);
    };

    reader.onerror = (error) => {
      console.error('Error reading image file:', error);
      failure('Error reading image file');
    };

    reader.readAsDataURL(blobInfo.blob());
  }
  onFileSelect(event: any) {
    this.logoHide = false;
    if(this.announcementSubmit==true||this.validTypes.includes(event.target.files[0].type)){
    this.customvalidator= ! this.validTypes.includes(event.target.files[0].type);
    }
    this.selectedFile = event.target.files[0];
      // Resize the image and get it as a Blob
      this.resizeImage(this.selectedFile ,622, 700).then((blob) => {
        this.resizedBlob = blob; // Now we have the resized Blob
      });
    
  }
  viewAnnouncementHistoryHide(){
    $("#viewAnnouncementHistory").modal("hide");
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    const reader = new FileReader();
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    return new Promise<Blob>((resolve, reject) => {
      reader.onload = (e: any) => {
        img.src = e.target.result;
      };

      img.onload = () => {
  // Original dimensions
  // let width = img.width;
  // let height = img.height;

  // // Calculate the desired aspect ratio
  // const aspectRatioWidth = 80; // 80%
  // const aspectRatioHeight = 70; // 70%

  // // Determine the scaling factor based on the desired aspect ratio
  // const scaleFactorWidth = maxWidth / aspectRatioWidth;
  // const scaleFactorHeight = maxHeight / aspectRatioHeight;
  
  // // Choose the smaller scale factor to maintain the aspect ratio within the bounds
  // const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

  // // Calculate new dimensions based on the scale factor
  // width = Math.floor((aspectRatioWidth * scaleFactor));
  // height = Math.floor((aspectRatioHeight * scaleFactor));

        // Resize the image using the canvas
        canvas.width = 314;
        canvas.height = 280;
        ctx.drawImage(img, 0, 0, 314, 280);

        // Convert the canvas to a Blob
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob); // Return the Blob
          } else {
            reject(new Error('Canvas is empty or failed to resize image'));
          }
        }, 'image/jpeg', 0.9); // Adjust quality if needed
      };

      reader.readAsDataURL(file); // Read the image file as a Data URL
    });
  }

  



  onSelectChange(event: any): void {
    // Handle the change event here
    console.log('Selected value:', this.selectedType);
    this.paginator.pageIndex = 0;
    // You can perform additional actions or logic based on the selected value
    this.getAnnouncementList(1, this.pageSize, this.searchKey, this.selectedType);

  }

  getAnnouncementList(pageNo, pageSize, searchKey, roleType) {
    let params = {
      "searchTitle": searchKey,
      "roleId": roleType,
      "pageNo": pageNo,
      "pageSize": pageSize
    }
    this.isArchiveList = false;
    this.spinnerService.show()
    this.memberService.getAnnouncementList(params, this.token).subscribe(
      (data: { filteredCount: Number; announcementList: AnnouncementListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        let userData = this.getMemberListArray(data.announcementList);
        console.log(userData)
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);
        this.totalItems = data.filteredCount
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
        else {
          this.spinnerService.hide();
        }
      }
    );
  }

  getarchiveAnnouncement() {
    this.isArchiveList = true
    this.spinnerService.show()
    this.memberService.getArchiveannouncementList(this.token).subscribe(
      (data: { archiveList: AnnouncementListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        let userData = this.getMemberListArray(data.archiveList);
        console.log(userData)
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  GetFormattedDate(date: string) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    return month + "-" + day + "-" + year;
  }

  getAppendValue = value => {
    if (value <= 9) {
      return `0${value}`;
    }
    return value;
  };

  getMemberListArray(announcementList: AnnouncementListResponse[]) {
    return announcementList.map(member => ({
      id: member.id,
      title: member.title,
      userRoles: member.userRoles,
      content: member.content,
      created_date: this.GetFormattedDate(member.created_date),
      isArchived: member.isArchived,
      shortDescription:member.shortDescription,
      shortTitle:member.shortTitle,
      announcementUrl:member.announcementUrl
    }));
  }

  getViewMemberListArray(announcementList: ViewAnnouncementListResponse[]) {
    return announcementList.map(member => ({
      id: member.announcementId,
      title: member.title,
      memberName: member.memberName,
      content: member.content,
      created_date: this.GetFormattedDate(member.viewedDate),
    }));
  }

  getRoles() {
    if (this.roleList) {
      return this.roleList;
    }
    this.memberService
      .getRoleValues(this.token)
      .subscribe(
        data => {
          this.roleList = data;
          console.log('roles',data);
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }



  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.getAnnouncementList(event.pageIndex + 1, event.pageSize, this.searchKey, this.selectedType)
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
    });
    return;
  }

  openAnnouncement(row) {
    $("#viewAnnouncementHistory").modal("show");
    this.viewUserdata = []
    let params = {
      "announcementId": row
    }
    this.memberService
      .viewAnnouncement(params, this.token)
      .subscribe(
        (data: { members: any[], statusCode: number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            // Map and format the date for each item in the members array
            const formattedData = data.members.map(item => ({
              ...item,
              chatCreatedAt: this.GetFormattedDate(item.viewedDate)
            }));
            // Assign the formatted data to this.viewUsers
            this.viewUserdata = formattedData;
            
           this.isEmpty=this.viewUserdata.length;
            console.log('Viewd data ddataatata',this.viewUserdata.length);
           

          }
        }
      );

  }



  editAnnouncement(row) {
    console.log(row)
    this.isShow=false;
    this.logoHide=true;
    this.selectedRoles = []
    this.apiselectedRoles = []
    this.addAnnouncementForm = true;
    
    this.announcementForm = this.formBuilder.group({
      title: [row.title, [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      // rolevalue: ['', Validators.required],
      html: [row.content, [Validators.required, WhiteSpaceValidator]],
      shortTitle:[row.shortTitle, [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      shortDescription:[row.shortDescription, [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      logoimg:[]
    });
    this.typeofAnnounce = "Edit"
    $("#newAnnouncementCreate").modal("show");
    this.announcementForm.controls['title'].setValue(row.title);
    this.announcementForm.controls['html'].setValue(row.content);
    this.announcementForm.controls['shortDescription'].setValue(row.shortDescription);
    this.announcementForm.controls['shortTitle'].setValue(row.shortTitle);
    // this.announcementForm.controls['title'].disable();
    this.logoDisplay=row.announcementUrl;
    this.announcementId = row.id;
    this.titles=row.title,
    this.content=row.content,
    this.shortDescription=row.shortDescription;
    this.shortTitle=row.shortTitle;
    console.log(this.titles ,this.content,this.shortDescription,this.shortTitle);
    row.userRoles.forEach(element => {
      this.selectedRoles.push(element.roleId)
      this.apiselectedRoles.push(element.roleId)
    });
  }

  archiveAnnouncement(row) {
    let params = {
      "announcementId": [row.id],
      "archieve": row.isArchived == 0 ? true : false
    }
    this.memberService
      .archiveAnnouncement(params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            this.users = this.users.filter(obj => obj.id !== row.id);
            let userData = this.users
            this.dataSource = new MatTableDataSource(userData);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            const customTitle = row.isArchived === 0 ? "Announcement archived." : "Announcement unarchived.";
            this.sharedService.toast(customTitle, () => {
              // this.ngOnInit()
            });
            return;
          }
          else {
            this.spinnerService.hide();
            this.sharedService.errorAlert(() => {
              this.ngOnInit()
            });
            return;
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  deleteAnnoucment(id) {
    Swal.fire({
      title: "Delete Announcement",
      text: "Are you sure?  Do you want to delete announcement?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false,
      
    }).then(result => {
      if (result.value) {
        let params = {
          "announcementId": id
        }
        this.spinnerService.show();
        this.memberService
          .deleteAnnouncement(params, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.spinnerService.hide();
                this.sharedService.successAlert(() => {
                  this.ngOnInit()
                });
                return;
              }
              else {
                this.spinnerService.hide();
                this.sharedService.errorAlert(() => {
                  this.ngOnInit()
                });
                return;
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    })

  }
  // Add Member
  openAddMemberModal() {
    this.customvalidator=false;
    this.logoHide=true;
    this.isShow=true;
    this.addAnnouncementForm = true;
    this.typeofAnnounce = "Create"
    this.announcementId = 0
    $("#newAnnouncementCreate").modal("show");
    this.selectedRoles = []
    this.apiselectedRoles = []
    this.announcementSubmit = false;
    this.isSelectedRoles = false;
    this.announcementForm = this.formBuilder.group({
      title: ['', [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      // rolevalue: ['', Validators.required],
      html: ['', [Validators.required, WhiteSpaceValidator]],
      shortTitle:['', [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      shortDescription:['', [Validators.required, WhiteSpaceValidator, Validators.pattern(/^.{2,}$/)]],
      logoimg:['',[Validators.required, WhiteSpaceValidator]]
    });

  }

  get memberControl() {
    return this.announcementForm.controls;
  }
  hide() {
    this.addAnnouncementForm = false;
    this.announcementSubmit = false;
    this.isSelectedRoles = false;
    $("#newAnnouncementCreate").modal("hide");
  }

  toggleRoleSelection(role: string) {
    const index = this.selectedRoles.indexOf(role);
    if (index === -1) {
      // Role is not in the array, add it
      this.selectedRoles.push(role);
    } else {
      // Role is already in the array, remove it
      this.selectedRoles.splice(index, 1);
    }
    if (this.selectedRoles.length > 0) {
      this.isSelectedRoles = false
    }
    else {
      this.isSelectedRoles = true
    }
  }

  filterEvent(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0)
      this.getAnnouncementList(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)
  }
  applyFilter() {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.searchKey = filterValue
    if (this.searchKey != '' && this.searchKey != undefined)
      this.getAnnouncementList(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)

    // this.dataSource.filter = filterValue.trim().toLowerCase();


    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  checkAllFieldsEntered(): boolean {
    return Object.values(this.announcementForm.controls).every(control => {
     
      return control.value !== '' && control.value !== null;
    });
    
      // return Object.keys(this.announcementForm.controls).every(key => {
      //   // Skip Only For Update Module 
      //   if (key === "logoimg"&&  !this.isShow ) {
      //     return true; // Skip validation for 'logoimg'
      //   }
      //   const control = this.announcementForm.get(key);
      //   return control && control.value !== '' && control.value !== null;
      // });
    
  }

  saveAnnouncement() {
    this.announcementSubmit = true;

    if (this.selectedRoles.length === 0 || this.announcementForm.invalid) {
      this.isSelectedRoles = true;

      if (this.selectedRoles.length > 0) {
        this.isSelectedRoles = false;
        return;
      }

      if (this.announcementForm.invalid) {
        return;
      }

      return; // Return if either condition is true
    }
    if(!this.logoHide){
    this.customvalidator= ! this.validTypes.includes(this.selectedFile.type);
    if(! this.validTypes.includes(this.selectedFile.type)){
     
     return;

    }
  }
    let params = {
      "title": this.announcementForm.value.title,
      "content": this.announcementForm.value.html,
      "roleId": this.selectedRoles,
      "adminId": 1,
      "announcementId": this.announcementId,
      "shortDescription":this.announcementForm.value.shortDescription,
      "shortTitle":this.announcementForm.value.shortTitle,
      "announcementImage":this.selectedFile
    }
    
    let param =new FormData();
    param.append("title", this.announcementForm.value.title);
    param.append("content", this.announcementForm.value.html);
    console.log(this.selectedRoles);
    this.selectedRoles.forEach((role) => {
      param.append("roleId", role);  // Sends multiple roleId values
    });
    param.append("adminId", "1");
    param.append("shortDescription",this.announcementForm.value.shortDescription);
    
    param.append("shortTitle",this.announcementForm.value.shortTitle);
    param.append("announcementImage",this.selectedFile);
    
    console.log(param);
    param.forEach((value, key) => {
      console.log('key   ' + key + 'value  ' + value);
    });
 
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();

    if (this.announcementId == 0) {
      this.memberService
        .PostSendAnnouncement(param, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.spinnerService.hide();
              $("#newAnnouncementCreate").modal("hide");
              this.addAnnouncementForm = false;
              Swal.fire({
                icon: "success",
                title: " Announcement Created Successfully",
                
                showCloseButton: true,
                allowOutsideClick: false
              }).then(() => {
                this.ngOnInit()
              });
              return;
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }
    else {
      let param = {
        "title": this.titles,
        "content": this.content,
        "roleId": this.selectedRoles,
        "adminId": 1,
        "announcementId": this.announcementId,
        "shortDescription":this.shortDescription,
        "shortTitle":this.shortTitle,
        "announcementImage":this.selectedFile
      }
      this.memberService
        .updateAnnouncement(param, token)
        .subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              this.spinnerService.hide();
              $("#newAnnouncementCreate").modal("hide");
              this.addAnnouncementForm = false;
              Swal.fire({
                icon: "success",
                title: "Announcement sent to the selected group successfully",
                showCloseButton: true,
                allowOutsideClick: false,
                customClass: {
                  closeButton: 'my-close-button' // Assign custom class to close button
                }
              }).then(() => {
                this.ngOnInit()
              });
              return;
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
    }
  }


} 