<!-- <app-header></app-header> -->

<div class="container-fluid main">
    <div class="row login-row" >
        <div class="col-md-6 loginLeftCol">
            <img src="assets/img/login-pic.png" />
        </div>
        <div class="col-md-6 login-col">
            <div class="card login-card">
                <div class="sidenav-border" class="mb-4" style="height: 100px;display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 30px;
                    margin-left: 15px;
          ">
                    <!-- <span class="closebtn" (click)="toggleNav()">×</span> -->
                    <img src="assets/img/applogo.png" style="height: 100%"  />
                </div>

                <div class="card-body ">
                    <div class="pb-3 text-center Welcome-text">
                        Welcome Again
                    </div>
                    <div class="pb-5 text-center login-content">
                        Request to Login to your account and access it
                    </div>
                    <div class="pb-4 text-center login-text">
                        SUPER ADMIN LOGIN
                      </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group pb-2">
                            <label for="email" class="form-label" >Email <span style="color: red;"> *</span></label>
                            <input type="email" class="form-control" formControlName="email" id="email"
                                placeholder="Email" required />
                            <div *ngIf="submitted && f.email.errors" class="ml-2">
                                <div *ngIf="f.email.errors.required" class="text-danger">
                                    Email required
                                </div>
                                <div *ngIf="f.email.errors.pattern" class="text-danger">
                                    Enter valid email address
                                </div>
                            </div>
                        </div>
                        <div class="form-group pb-2 mb-0">
                            <label for="pwd" class="form-label pass-label">Password <span style="color: red;"> *</span></label>
                            <input type="password" class="form-control" formControlName="password" id="password"
                                placeholder="Password" required />
                            <div *ngIf="submitted && f.password.errors" class="ml-2">
                                <div *ngIf="f.password.errors.required" class="text-danger">
                                    Password required
                                </div>
                            </div>
                        </div>
                        <div class="pb-4 text-right">
                            <a routerLink="/SA-forgot-password" class="forgot-text">Forgot Password?</a>
                        </div>
                        <button class="btn button-color btn-md btn-block login-btn" type="submit" [disabled]="!checkAllFieldsEntered()">
                            <span *ngIf="isLoading" class="spinner-border text-light" role="status">
                            </span>
                            <span *ngIf="!isLoading">Login</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>TMZ</div>
</nav> -->