<!-- <app-header></app-header> -->

<div class="container-fluid main">
  <div class="row login-row" >
    <div class="col-md-6 loginLeftCol">
      <img src="assets/img/forgot.png" />
    </div>
    <div class="col-md-6 login-col">
      <div class="card login-card">
        <div class="sidenav-border" style="display: flex;
                      justify-content: center;
                      align-items: center;
                  ">
          <!-- <span class="closebtn" (click)="toggleNav()">×</span> -->
          <img src="assets/img/D-logo.png" style="height: 100%" />
        </div>
        <div class="card-body">
          <!-- <div class="pb-3 text-center font-weight-bold" style="font-size: 28px;">
            DNX Connect
          </div> -->
          <div class="pb-4 text-center login-text">
            Forgot Password
          </div>
          <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group pb-2 mb-0">
              <label for="email" class="form-label">Email <span style="color: red;"> *</span></label>
              <input type="email" class="form-control" formControlName="email" id="email" placeholder="Email"
                required />
              <div *ngIf="submitted && f.email.errors" class="ml-2">
                <div *ngIf="f.email.errors.required" class="text-danger">
                  Email required
                </div>
                <div *ngIf="f.email.errors.pattern" class="text-danger">
                  Enter valid email address
                </div>
              </div>
            </div>
            <div class="pb-4 text-right">
              <a routerLink="/login" class="forgot-text">Back to Login</a>
            </div>
            <button class="btn button-color btn-md btn-block login-btn" type="submit" [disabled]="!checkAllFieldsEntered()">
              <span *ngIf="isLoading" class="spinner-border text-light" role="status">
              </span>
              <span *ngIf="!isLoading">Send</span>
            </button>
          </form>
          
        </div>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>

<!-- <nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>Member Connect</div>
</nav> -->