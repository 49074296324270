<app-header></app-header>

<div class="p-5 mt-5" style="background-color: #f0f4f8;width: 100%;height: 100%;">
    <div class="d-flex justify-content-between">
        <div class="mt-2">
            <ng-container>
                <button type="button" class="btn btn-secondary btn-sm" (click)="openAddVideo()">
                    Create
                </button>
            </ng-container>
        </div>

        <div class="mt-2" style="display: flex; align-items: center; margin-right: 20px;">


            <div class="search-form-field">
                <mat-form-field>
                    <input matInput (keyup)="filterEvent($event)" [(ngModel)]="searchKey"
                        placeholder="Type to Search" />
                </mat-form-field>
                <mat-icon class="searchIcon" (click)="applyFilter()">search</mat-icon>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8" style="margin-bottom: 60px">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="title">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Title
                </th>
                <td mat-cell *matCellDef="let row" style="width: 20%;">{{ row.title }}</td>
            </ng-container>

            <ng-container matColumnDef="roles">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Video
                </th>

                <td mat-cell *matCellDef="let row" style="width: 30%;">
                    <ng-container *ngFor="let role of row.link; let last = last">
                        <a [href]="role.embeddedLink" target="_blank"> {{ role.embeddedLink }}</a>
                        <span *ngIf="!last">, </span>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="create_date">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Create Date
                </th>
                <td mat-cell *matCellDef="let row" style="width: 10%;">
                    {{row.createdDate}}
                </td>
            </ng-container>

            <ng-container matColumnDef="create_for">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Create for
                </th>
                <td mat-cell *matCellDef="let row" style="width: 20%;">
                    {{row.memberName}}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Actions
                </th>
                <td mat-cell *matCellDef="let row" style="width:11%;">

                    <mat-icon class="mr-2" matTooltip="Edit" style="cursor: pointer;"
                        (click)="editVideo(row)">edit</mat-icon>
                    <mat-icon matTooltip="Delete" style="cursor: pointer;"
                        (click)="deleteVideos(row.id)">delete</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="this.users?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
            <p>No data to display</p>
        </div>

        <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons [length]="totalItems"
            [(pageSize)]="pageSize" [(pageIndex)]="currentPage" (page)="onPaginatorClick($event)">
        </mat-paginator>
    </div>

    <!-- Modal for Video upload -->
    <div class="modal fade" id="newformCreate" data-backdrop="static" tabindex="-1" role="dialog"
        aria-labelledby="staticBackdropLabel" aria-hidden="true" style="margin-top: 50px;">

        <div class="modal-dialog common-styling">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title"> Video Upload</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                        &times;
                    </button>
                </div>

                <div style="margin: 20px;">
                    <div *ngFor="let formGroup of videouploadForm; let i = index">
                        <form [formGroup]="formGroup" (ngSubmit)="saveVideoURL()">
                            <mat-icon (click)="removeForm(i)" *ngIf="i > 0" class="icon-container"
                                style="float: right;">close</mat-icon>
                            <div style="margin: 15px;">
                                <!-- Your existing form controls -->
                                <!-- Heading -->
                                <label style="font-weight: 100;margin:0;" for="firstName">Heading <span
                                        style="color: red;">*</span></label>
                                <mat-form-field style="width: 100%;margin:0;">
                                    <input formControlName="title" matInput />
                                </mat-form-field>

                                <div *ngIf="formGroup.get('title').invalid && this.isShowtitle">
                                    <span style="color: red;">Title is required.</span>
                                </div>


                                <!-- Descriptions -->
                                <label style="font-weight: 100;margin-top: 10px;"
                                    for="firstName">Descriptions</label><br>
                                <textarea formControlName="description" class="content-container" maxlength="150"
                                    placeholder="Descriptions (100 characters)"></textarea><br>

                                <!-- Embedded Video Links -->
                                <label style="font-weight: 100; margin-top: 10px;" for="firstName">Embeded Video
                                    Links(Youtube)
                                    <span style="color: red;">*</span>
                                </label>

                                <div formArrayName="embeddedLink">
                                    <div
                                        *ngFor="let linkGroup of formGroup.get('embeddedLink').controls; let j = index">
                                        <div class="link-group" [formGroupName]="j">
                                            <input formControlName="embeddedLink" matInput
                                                class="content-container input" placeholder="Enter Embeded Link"
                                                [style.border-color]="redBorderValues.includes(linkGroup.get('embeddedLink').value) ? 'red' : 'initial'" />
                                            <mat-icon *ngIf="j >= this.videoLength && this.videoId != 0"
                                                (click)="removeEmbeddedLink(i, j)"
                                                class="icon-container">close</mat-icon>

                                            <mat-icon *ngIf="j >= 1 && this.videoId == 0"
                                                (click)="removeEmbeddedLink(i, j)"
                                                class="icon-container">close</mat-icon>
                                        </div>
                                    </div>

                                    <div *ngIf="formGroup.get('embeddedLink').invalid && this.isshowEmbeded">
                                        <span style="color: red;">Embeded Link is required.</span>
                                    </div>
                                </div>
                                <button type="button" (click)="addEmbeddedLink(i)" *ngIf="this.videoId == 0"
                                    class="sync-btn" style="margin-top: 10px;">Add
                                    Link</button>
                            </div>
                        </form>
                    </div>

                    <button type="button" (click)="addForm()" *ngIf="this.videoId == 0" class="sync-btn"
                        style="margin-top: 10px;">Add
                        Form</button>

                    <button type="submit" class="sync-btn" style="float: right;" (click)="submitAllForms()">
                        <span>Submit</span>
                    </button>

                </div>
            </div>
        </div>

    </div>







</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, .6)"
  class="spin-img"
  template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>