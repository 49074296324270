<app-header></app-header>


<div class="parent"style="display: flex;justify-content: center;align-items: center;height: 100vh;width: 100%;margin:2%">
<div class="id-card" >

  <div class="header">
   
  </div>
  <div class="photo">
    <img src="../../assets/img/ProfileAvator.jpg" alt="Profile Photo" class="profile-photo">
  </div>
  <h3>DNX Connect</h3>
  <div class="space" style="margin: 10px;"></div>
  <div class="info">
    
    <p><b>Member Id : </b>601</p>
    <p><span>Name :</span>{{ name }}</p>
    <p> <span>Id:</span>{{ CardId }}</p>
    <p><span>Designation:</span>{{ Designation }}</p>
    
    <!-- <p>Email: {{ contactNumber }}</p> -->
    <p><span>Email:</span> {{Email }}</p>
  </div>
  
  <div class="qr-code">
    <!-- Replace 'qr-code-content' with your actual QR code component or image -->
    <img src="../../assets/img/QRscanner.png" alt="QR Code" style="width: 100px;height: 100px;">
  </div>
  
  <div class="timestamp">
  {{date.toISOString().substring(0,10)}}
  </div>
 

<div class="footer" ></div>
    
<!-- <div id="bg-text">Datanetiix</div> -->
</div>
<!-- <div class="clicke-event" style="display: flex;justify-content: space-around; margin: 5px; width:380px">
    <button style="background:red ;color: white; border: red ; margin: 0px; border-radius: 10px; width:140px ;height: 40px;" > <mat-icon class="mr-2"  matTooltip="Delete" style="cursor: pointer; ">visibility</mat-icon>Preview </button>
<button style="background:red ;color: white; border: red ; margin: 0px; border-radius: 10px;width: 160px;height: 40px;"> <mat-icon class="mr-2"  matTooltip="Delete" style="cursor: pointer; " >file_download</mat-icon>Download Pdf</button>
</div> -->
</div>
<!-- <input type="color"> -->