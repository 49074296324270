<app-header></app-header>

<div class="p-5 mt-5">
  <div class="d-flex justify-content-between">
    <div class="mt-2">
      <button
        type="button"
        class="btn btn-secondary btn-sm"
        (click)="openDiscountCoupon()"
      >
        New Coupon
      </button>
    </div>
    <div style="margin-right: 95px">
      <mat-form-field>
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Type to Search"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="mat-elevation-z8" style="margin-bottom: 60px">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="subscription">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Subscription
        </th>
        <td mat-cell *matCellDef="let row">{{ row.subscription }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Name
        </th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          ID
        </th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>

      <ng-container matColumnDef="discount">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Discount
        </th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.amountOff !== null">${{ row.amountOff }}</span>
          <span *ngIf="row.percentOff !== null">{{ row.percentOff }}%</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Duration
        </th>
        <td mat-cell *matCellDef="let row">{{ row.duration }}</td>
      </ng-container>

      <ng-container matColumnDef="durationInMonths">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Duration Months
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.durationInMonths }}
        </td>
      </ng-container>

      <ng-container matColumnDef="redemDate">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Redeem By
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.redemDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="maxRedemptions">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Max Redeemptions
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.maxRedemptions }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Status
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon
            matTooltip="Delete"
            style="cursor: pointer;"
            (click)="deleteCoupon(row)"
            >delete</mat-icon
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[10, 25, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<div
  class="modal fade"
  id="discountCouponCreate"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Discount Coupon</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="hide()"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="modal-body mb-3">
          <!-- Coupon Discount Form -->
          <div class="container" *ngIf="couponForm">
            <form
              [formGroup]="discountCouponForm"
              (ngSubmit)="discountCouponFormSubmit()"
            >
              <div class="form-group mb-3">
                <label for="subscrb">Subscription</label>
                <select
                  class="form-control"
                  formControlName="subscrb"
                  id="subscrb"
                >
                  <option>yearly</option>
                  <option>monthly</option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label for="name ml-2">Name</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  id="name"
                />
                <div
                  *ngIf="submitted && discountCouponFormControls.name.errors"
                  class="ml-2"
                >
                  <div
                    *ngIf="discountCouponFormControls.name.errors.required"
                    class="text-danger"
                  >
                    required
                  </div>
                  <div
                    *ngIf="discountCouponFormControls.name.errors.noWhiteSpace"
                    class="text-danger"
                  >
                    required
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="id ml-2"
                  >ID
                  <span
                    style="margin-left: 5px;
                  color: #8a9c8f;"
                    >(example: test_10_discount)</span
                  ></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="id"
                  id="id"
                />
                <div
                  *ngIf="submitted && discountCouponFormControls.id.errors"
                  class="ml-2"
                >
                  <div
                    *ngIf="discountCouponFormControls.id.errors.required"
                    class="text-danger"
                  >
                    required
                  </div>
                  <div
                    *ngIf="discountCouponFormControls.id.errors.noWhiteSpace"
                    class="text-danger"
                  >
                    required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="type">Type</label>
                <label class="radio-container"
                  >Percentage discount
                  <input
                    type="radio"
                    formControlName="type"
                    id="type"
                    value="percent"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group mb-3">
                <label class="radio-container"
                  >Fixed amount discount
                  <input
                    type="radio"
                    formControlName="type"
                    id="type"
                    value="amount"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group mb-3">
                <label for="discount ml-2">Discount Amount/Percentage</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="discount"
                  id="discount"
                />
                <div
                  *ngIf="
                    submitted && discountCouponFormControls.discount.errors
                  "
                  class="ml-2"
                >
                  <div
                    *ngIf="discountCouponFormControls.discount.errors.required"
                    class="text-danger"
                  >
                    required
                  </div>
                  <div
                    *ngIf="
                      discountCouponFormControls.discount.errors
                        .percentValidator
                    "
                    class="text-danger"
                  >
                    Discount percentage exceeded
                  </div>
                  <div
                    *ngIf="
                      discountCouponFormControls.discount.errors.amountValidator
                    "
                    class="text-danger"
                  >
                    Discount amount exceeded
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="duration">Duration</label>
                <select
                  class="form-control"
                  formControlName="duration"
                  id="duration"
                  (change)="durationChange()"
                >
                  <option>once</option>
                  <option>repeating</option>
                  <option>forever</option>
                </select>
              </div>
              <div
                class="form-group mb-3"
                *ngIf="this.discountCouponForm.value.duration === 'repeating'"
              >
                <label for="durationInMonths ml-2">Duration In Months</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="durationInMonths"
                  id="durationInMonths"
                />
                <div
                  *ngIf="
                    submitted &&
                    discountCouponFormControls.durationInMonths.errors
                  "
                  class="ml-2"
                >
                  <div
                    *ngIf="
                      discountCouponFormControls.durationInMonths.errors
                        .required
                    "
                    class="text-danger"
                  >
                    required
                  </div>
                  <div
                    *ngIf="
                      discountCouponFormControls.durationInMonths.errors
                        .noWhiteSpace
                    "
                    class="text-danger"
                  >
                    required
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="redeemBy">Max Redeemption Date</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  id="redeemBy"
                  formControlName="redeemBy"
                />
                <div
                  *ngIf="
                    submitted && discountCouponFormControls.redeemBy.errors
                  "
                  class="ml-2"
                >
                  <div
                    *ngIf="discountCouponFormControls.redeemBy.errors.required"
                    class="text-danger"
                  >
                    required
                  </div>
                  <div
                    *ngIf="
                      discountCouponFormControls.redeemBy.errors.dateValidator
                    "
                    class="text-danger"
                  >
                    Redeem date can't be earlier than your current date
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="maxRedemptions ml-2">Max Redeemptions</label>
                <input
                  type="number"
                  class="form-control"
                  formControlName="maxRedemptions"
                  id="maxRedemptions"
                />
                <div
                  *ngIf="
                    submitted &&
                    discountCouponFormControls.maxRedemptions.errors
                  "
                  class="ml-2"
                >
                  <div
                    *ngIf="
                      discountCouponFormControls.maxRedemptions.errors.required
                    "
                    class="text-danger"
                  >
                    required
                  </div>
                  <div
                    *ngIf="
                      discountCouponFormControls.maxRedemptions.errors
                        .noWhiteSpace
                    "
                    class="text-danger"
                  >
                    required
                  </div>
                </div>
              </div>
              <button
                class="btn button-color btn-md btn-block"
                type="submit"
                [disabled]="isLoading"
              >
                <span
                  *ngIf="isLoading"
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                </span>
                <span *ngIf="!isLoading">Add</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Progress Modal -->
<div
  class="modal fade"
  id="discountProgressModel"
  data-backdrop="static"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header" style="padding: 10px 15px;">
        <h4 class="modal-title">Processing . . . .</h4>
      </div>
      <div class="modal-body text-center" style="padding: 25px 10px;">
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated bg-dark"
            role="progressbar"
            style="width: 100%"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>

<nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>Member Connect</div>
</nav>
