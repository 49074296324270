import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

// Local Imports
import { LoginService } from "../shared/services/login.service";
import { ForgotPasswordResponse } from "../shared/models/user";


@Component({
  selector: 'app-super-admin-forgot-password',
  templateUrl: './super-admin-forgot-password.component.html',
  styleUrls: ['./super-admin-forgot-password.component.css']
})
export class SuperAdminForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ]
    });
  }
  get f() {
    return this.forgotPasswordForm.controls;
  }
  checkAllFieldsEntered():boolean{
    return Object.values(this.forgotPasswordForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }
  onSubmit() {
    this.submitted = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.isLoading = true;
    let params = {
      email: this.forgotPasswordForm.value.email,
      organaisationId: 0
    };

    this.loginService
      .forgetPasswordSuperAdmin(params)
      .subscribe((data: ForgotPasswordResponse) => {
        if (data.statusCode === 200) {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Please Check Your Email",
            text:
              "An email has been sent with instructions on how to reset your password",
            showCloseButton: true,
            allowOutsideClick: false
          }).then(() => {
            this.router.navigate(["/login"]);
          });
          return;
        }
        this.isLoading = false;
        Swal.fire({
          icon: "warning",
          title: "This email address does not exist",
          showCloseButton: true,
          allowOutsideClick: false
        });
      });
  }

}
