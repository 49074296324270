<p>ima-members works!</p>
<app-header></app-header>

<div class="p-4 mt-5">
  <div>
    <div class="text-right">
      <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Type to Search" />
      </mat-form-field>
    </div>

    <div class="mat-elevation-z8" style="margin-bottom: 60px">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="member">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Date
          </th>
          <td mat-cell *matCellDef="let row">
            <!-- {{ row.member }} -->
            03/17/2023
          </td>
        </ng-container>

        <ng-container matColumnDef="matchedMember">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Member Name
          </th>
          <td mat-cell *matCellDef="let row">
            <!-- {{ row.matchedMember }} -->
            Dakota Hopi
          </td>
        </ng-container>

        <ng-container matColumnDef="matchedMemberProfile">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Email
          </th>
          <td mat-cell *matCellDef="let row" style="padding: 5px;">
            <!-- <img
              src="{{ getProfileUrl(row.matchedMemberProfileImageUrl) }}"
              style="width: 50px;
                height: 50px;"
            /> -->
            test@gmail.com
          </td>
        </ng-container>

        <ng-container matColumnDef="matchedTitle">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Phone#
          </th>
          <td mat-cell *matCellDef="let row">
            <!-- {{ row.matchedMemberDesignation }} -->
            1234567891
          </td>
        </ng-container>

        <ng-container matColumnDef="matchedOrganisation">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Company
          </th>
          <td mat-cell *matCellDef="let row">
            <!-- {{ row.matchedMemberorganizationName }} -->
            Company
          </td>
        </ng-container>

        <ng-container matColumnDef="matchedLocation">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Subscription Type
          </th>
          <td mat-cell *matCellDef="let row">
            <!-- {{ row.matchedMemberLocation }} -->
            Paid
          </td>
        </ng-container>

        <ng-container matColumnDef="matchedServiceCount">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Source
          </th>
          <td mat-cell *matCellDef="let row">
            <!-- {{ row.matchedServiceCount }} -->
            Free Subscription Hubspot
          </td>
        </ng-container>

        <ng-container matColumnDef="review">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Review
          </th>
          <td mat-cell *matCellDef="let row">
            <button type="button" (click)="reviewUser(row)" class="btn btn-primary btn-sm">
              Review
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="approved">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Approved
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.actionStatus === 'approved'" style="color:#28a745">check_box</mat-icon>
            <!-- <mat-icon
            *ngIf="row.actionStatus === null || row.actionStatus === 'revoked'"
            style="color:#28a745; cursor: pointer"
            (click)="approve(row.memberId, row.matchedMemberId)"
            >check_box_outline_blank</mat-icon
          > -->
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="revoked">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Revoked
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.actionStatus === 'revoked'" style="color:#dc3545"
            >check_box</mat-icon
          >
          <mat-icon
            *ngIf="row.actionStatus === null || row.actionStatus === 'approved'"
            style="color:#dc3545; cursor: pointer"
            (click)="revoke(row.memberId, row.matchedMemberId)"
            >check_box_outline_blank</mat-icon
          >
        </td>
      </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>

<nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>Member Connect</div>
</nav>

<!--Member Review Modal -->
<div class="modal fade" id="memberReviewModel" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Member Match Details</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body mb-3" *ngIf="showModal">
        <div class="font-weight-bold" style="font-size: 17px">
          {{ currentUser.member }}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{{
          currentUser.matchedMember
          }}
          -
          <span>{{ currentUser.matchedServiceCount }} value match(es)</span>
        </div>
        <div class="mt-2 text-center" *ngIf="serviceRequest">
          <span class="spinner-border text-dark" role="status"> </span>
        </div>
        <div class="mt-2" *ngIf="!serviceRequest">
          <ul *ngFor="let value of services">
            <li>{{ value.serviceName }}</li>
          </ul>
        </div>
        <hr />
        <div class="text-center mt-2">
          <button *ngIf="currentUser.actionStatus === null" type="button" class="btn btn-success btn-sm mr-2"
            style="padding: 5px 70px;" (click)="approve(currentUser.memberId, currentUser.matchedMemberId)"
            [disabled]="approveLoading">
            <span *ngIf="approveLoading" class="spinner-border text-light spinner-border-sm" role="status">
            </span>
            <span *ngIf="!approveLoading">Approve</span>
          </button>
          <!-- <button
            *ngIf="currentUser.actionStatus === null"
            type="button"
            class="btn btn-danger btn-sm mr-2 mb-2"
            style="padding: 5px 70px;"
            (click)="revoke(currentUser.memberId, currentUser.matchedMemberId)"
            [disabled]="revokeLoading"
          >
            <span
              *ngIf="revokeLoading"
              class="spinner-border text-light spinner-border-sm"
              role="status"
            >
            </span>
            <span *ngIf="!revokeLoading">Revoke</span>
          </button> -->
          <button type="button" class="btn btn-secondary btn-sm" style="padding: 5px 70px;" (click)="hide()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>