<app-header></app-header>

<div class="p-5 mt-5" style="background-color: #f0f4f8;width: 100%;height: 100%;">
    <div class="d-flex justify-content-between">
        <div class="mt-2">
            <!-- <button type="button" class="btn btn-secondary btn-sm" (click)="openAddMemberModal()">
                Create Announcement
            </button> -->
        </div>
        <div style="margin-right: 95px">
            <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filter" placeholder="Type to Search" />
            </mat-form-field>
        </div>
    </div>

    <div class="center-table ">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

            <ng-container matColumnDef="title">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                    User Type
                </th>
                <td class="mat-table-cell" mat-cell *matCellDef="let row">{{ row.role }}</td>
            </ng-container>

            <ng-container matColumnDef="roles">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                    Chats
                </th>

                <td class="mat-table-cell" mat-cell *matCellDef="let row">{{row.chat_duration
                    ? row.chat_duration : ' --
                    '
                    }}</td>
            </ng-container>


            <ng-container matColumnDef="actions">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Actions
                </th>
                <td class="mat-table-cell" mat-cell *matCellDef="let row" style="width:11%;">
                    <mat-icon class="mr-2" matTooltip="Edit" (click)="editRoles(row)"
                        style="cursor: pointer;">edit</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>



        <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator> -->


    </div>

    <div class="modal-overlay" *ngIf="isModalOpen" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">Role Managment</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                    &times;
                </button>
            </div>

            <div class="mt-4 reset-psd-form">
                <label for="interval">User Type</label>

                <div>
                    <mat-form-field style="width: 100%;">
                        <mat-select [(ngModel)]="selectedrow" (ngModelChange)="onChangeSelectedValue()">
                            <mat-option *ngFor="let role of roleList?.roleType"
                                [value]="role.id">{{role.role}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <mat-radio-group [(ngModel)]="chatFeature" (ngModelChange)="onRadioGroupChange()">
                        <mat-radio-button color="primary" style="margin-right: 15px;"
                            value="true">Yes</mat-radio-button>
                        <mat-radio-button color="primary" value="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="vertical-checkbox-group" *ngIf="ischatFeature">
                    <div>
                        <label for="interval">Chats Type</label>
                    </div>
                    <mat-radio-group [(ngModel)]="chatDuration" (ngModelChange)="onChatDurationChange()">
                        <mat-radio-button color="primary" style="margin-right: 15px;"
                            value="24/7">24/7</mat-radio-button>
                        <mat-radio-button color="primary" value="8 A.M - 6 P.M">8 A.M - 6 P.M</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="ischatDuration" class="text-danger">
                    Chat Duration required
                </div>
                <button class="sync-btn" (click)="saveBtn()">
                    <span>Save</span>
                </button>
            </div>
        </div>
    </div>

    <!-- <div class="divcenterd" id="newRoleUpdate" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel"
        aria-hidden="true" style="margin-top: 50px;">

        <div class="modal-dialog">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title">Role Managment</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                        &times;
                    </button>
                </div>

                <div class="mt-4 reset-psd-form">
                    <label for="interval">User Type</label>

                    <div>

                        <mat-form-field style="width: 100%;">
                            <mat-select [(ngModel)]="roleType">
                                <mat-option *ngFor="let role of users" value="{{role.id}}">{{role.role}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-radio-group [(ngModel)]="chatFeature">
                            <mat-radio-button style="margin-right: 15px;" value="true">Yes</mat-radio-button>
                            <mat-radio-button value="false">No</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="vertical-checkbox-group" *ngIf="chatFeature">
                        <div>
                            <label for="interval">Chats Type</label>
                        </div>
                        <mat-radio-group [(ngModel)]="chatDuration">
                            <mat-radio-button style="margin-right: 15px;" value="24/7">24/7</mat-radio-button>
                            <mat-radio-button value="8 A.M - 6 P.M">8 A.M - 6 P.M</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <button class="sync-btn" (click)="saveBtn()">
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>

    </div> -->

</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, .6)"
  class="spin-img"
  template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>