// Vendor Imports
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

// Local Imports
import { MemberService } from "../shared/services/member.service";

declare var $: any;

export interface UserData {
  id: Number;
  userName: string;
  email: string;
  designation: string;
  companyName: string;
  date: string;
  status: Number;
}

@Component({
  selector: 'app-member-request-list',
  templateUrl: './member-request-list.component.html',
  styleUrls: ['./member-request-list.component.css']
})
export class MemberRequestListComponent implements OnInit {
  isLoading = false;

  displayedColumns: string[] = [
    "profile",
    "name",
    "email",
    "organisation",
    "toProfile",
    "toName",
    "toEmail",
    "toOrganisation",
    "date"
  ];
  token: string;
  users: any[];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private memberService: MemberService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getMembers();
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
  }

  getMembers() {
    // Assign the data to the data source for the table to render
    this.memberService.getMemberRequestList(this.token).subscribe(
      (data: { connectionRequestList: any[] }) => {
        let userData = this.getMemberListArray(data.connectionRequestList);
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  getMemberListArray(memberList: any[]) {
    return memberList.map(member => ({
      fromName: member.requestFromFirstName + " " + member.requestFromLastName,
      fromEmail: member.requestFromEmail,
      fromImage: member.requestFromProfileImageUrl,
      fromDesignation: member.requestFromDesignation,
      fromCompany: member.requestFromOrganization,
      date: member.requestedDisplayDate,
      toName: member.requestToFirstName + " " + member.requestToLastName,
      toEmail: member.requestToEmail,
      toImage: member.requestToProfileImageUrl,
      toDesignation: member.requestToDesignation,
      toCompany: member.requestToOrganization,
    }));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }

  getProfileUrl(img: string) {
    if (img === null) {
      return "assets/img/user.png";
    } else if (img === "") {
      return "assets/img/user.png";
    } else if (img.toLowerCase() === "test") {
      return "assets/img/user.png";
    } else {
      return img;
    }
  }
}
