import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { userModuleListResponse, userSubscriptionListResponse } from '../shared/models/user';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';

declare var $: any;

export interface UserData {
  id: Number;
  role: string;
  moduleList: moduleListResponse;
}

export interface moduleListResponse {
  moduleId: Number;
  moduleName: string;
}


@Component({
  selector: 'app-managesubscription',
  templateUrl: './managesubscription.component.html',
  styleUrls: ['./managesubscription.component.css']
})
export class ManagesubscriptionComponent implements OnInit {

  token: string;
  subscriptionList: any;
  roleType: {}
  chatFeature: any;
  chatDuration: any;
  displayedColumns: string[] = [
    "title",
    "roles",
    "actions"
  ];
  displayedColumnsOne: string[] = [
    "id",
    "name",
    "actions"
  ];
  filter: string;
  dataSource: MatTableDataSource<UserData>;
  users: UserData[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  rolesvalues: any;
  selectedsubscription: any;
  isModalOpen: boolean = false;
  ischatFeature: boolean;
  ischatDuration: any;
  ModuleList: any;
  selectedsubscriptionType: any;
  checkedModuleIds: number[] = [];
  addModuleForm: boolean;
  moduleForm: FormGroup;
  moduleSubmit: boolean;
  moduleCreatedList: MatTableDataSource<moduleListResponse>;
  moduleList: moduleListResponse[];


  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService, private formBuilder: FormBuilder) {
    this.moduleForm = this.formBuilder.group({
      moduleName: ['', [Validators.required, WhiteSpaceValidator]]
    });
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.getRoles();
    // this.getCreatedModules();
   

    // this.getModules(1)
  }


  getRoles() {
    console.log("called")
    this.spinnerService.show();
    this.memberService.getSubscriptionTypes(this.token).subscribe(
      (data: { subscriptionTypeList: userSubscriptionListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        this.subscriptionList = data;
        let userData = this.getMemberListArray(data.subscriptionTypeList);
        this.users = userData;
        console.log("users", this.users)

        this.dataSource = new MatTableDataSource(userData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  getModules(subscriptionId) {
    this.spinnerService.show();
    let params = {
      "subscriptionId": subscriptionId,
    }
    this.memberService.getModuleList(params, this.token).subscribe(
      (data: { subscriptionTypeList: userModuleListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        this.ModuleList = data;
        this.checkedModuleIds = this.ModuleList.moduleList
          .filter(module => module.isActive)
          .map(module => module.moduleId);
        console.log(this.subscriptionList)

      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  get memberControl() {
    return this.moduleForm.controls;
  }

  getMemberListArray(roleType: userSubscriptionListResponse[]) {
    return roleType.map(member => ({
      id: member.subscriptionId,
      role: member.subscriptionType,
      moduleList: member.moduleList,
    }));
  }

  editRoles(row) {
    console.log(row)
    this.roleType = row
    this.selectedsubscription = row.id
    this.selectedsubscriptionType = row.role

    this.getModules(row.id)

    this.isModalOpen = true
  }

  onRadioGroupChange() {
    console.log(this.chatFeature)
    this.chatDuration = ''
    if (this.chatFeature == "true")
      this.ischatFeature = true
    else {
      this.ischatFeature = false
      this.ischatDuration = false
    }

  }
  onChatDurationChange() {
    console.log(this.chatFeature)
    this.ischatDuration = false

  }
  onChangeSelectedValue(event: any) {

    console.log('Selected value changed:', event.value, event.source.triggerValue);
    this.selectedsubscription = event.value,
      this.selectedsubscriptionType = event.source.triggerValue
    this.getModules(event.value)
  }


  hide() {
    this.moduleSubmit = false;
    this.addModuleForm = false;
    this.isModalOpen = false;
    // this.moduleForm.reset();
    $("#newModuleCreate").modal("hide");


  }

  errorMessage(dataMessage) {
    this.spinnerService.hide();
    Swal.fire({
      icon: "warning",
      title: dataMessage,
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "OK"
    }).then(() => {
    });
    return;
  }
  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
    });
    return;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  openAddModule() {

    this.addModuleForm = true
    $("#newModuleCreate").modal("show");
  }

  toggleCheckbox(moduleId: number) {
    const index = this.checkedModuleIds.indexOf(moduleId);
    if (index === -1) {
      this.checkedModuleIds.push(moduleId);
    } else {
      this.checkedModuleIds.splice(index, 1);
    }
  }
  createModule() {
    this.moduleSubmit = true;
    if (this.moduleForm.invalid) {
      return;
    }
    this.moduleSubmit = false;
    let params = {
      "moduleName": this.moduleForm.get('moduleName').value.trim()
    }
    console.log(params);
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();
    this.memberService
      .postCreateModule(params, token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {


          console.log(this.moduleForm.get('moduleName').value);

          if (data.statusCode === 200) {
            this.moduleForm.reset();
            this.spinnerService.hide();
            $("#newModuleCreate").modal("hide");
            this.addModuleForm = false;
            Swal.fire({
              icon: "success",
              title: "Updated Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;
          }
          else if (data.statusCode == 401) {

            this.spinnerService.hide();
            // this.addModuleForm = false;
            Swal.fire({
              icon: "warning",
              title: "Module Already Exist",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;

          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );


  }
  saveBtn() {

    console.log(this.checkedModuleIds)
    this.spinnerService.show();

    let params = {
      "subscriptionType": this.selectedsubscriptionType,
      "subscriptionId": this.selectedsubscription,
      "moduleId": this.checkedModuleIds
    }
    if(this.checkedModuleIds.length<4){
     
      Swal.fire({
        icon: "warning",
        title: "Please Select Atleast 4 Modules",
        showCloseButton: true,
        allowOutsideClick: false
      }).then(() => {
       return;
      });
     
      this.spinnerService.hide();
      return;

    }
  // if(this.checkedModuleIds.length<4){
  //   this.spinnerService.hide();
  //   return;
  // }

   
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;

    this.memberService
      .postSetModule(params, token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            this.spinnerService.hide();
            this.ischatFeature = false

            Swal.fire({
              icon: "success",
              title: "Updated Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.isModalOpen = false
              this.ngOnInit()
            });
            return;
          }
          else {
            this.spinnerService.hide();
            this.errorMessage(data.statusMessage)
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );

  }

  getModuleListArray(moduleList: moduleListResponse[]) {
    return moduleList.map(module => ({
      moduleId: module.moduleId,
      moduleName: module.moduleName
    }));
  }

  //List Modules
  getCreatedModules() {
    this.spinnerService.show();
    this.memberService.getCreatedModulesList(this.token).subscribe(
      (data: { moduleList: moduleListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        // this.ModuleList = data;
        // this.checkedModuleIds = this.ModuleList.moduleList
        //   .filter(module => module.isActive)
        //   .map(module => module.moduleId);
        // console.log(this.subscriptionList)


        this.subscriptionList = data;
        let userData = this.getModuleListArray(data.moduleList);
        this.moduleList = userData;
        console.log(this.users)

        this.moduleCreatedList = new MatTableDataSource(userData);
        this.moduleCreatedList.paginator = this.paginator;
        this.moduleCreatedList.sort = this.sort;

      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  //Edit modules
  // editModules(row) {
  //   console.log(row)
  //   this.roleType = row
  //   this.selectedsubscription = row.id
  //   this.selectedsubscriptionType = row.role

  //   this.getModules(row.id)

  //   this.isModalOpen = true
  // }

  // getModules(subscriptionId) {
  //   this.spinnerService.show();
  //   let params = {
  //     "subscriptionId": subscriptionId,
  //   }
  //   this.memberService.getModuleList(params, this.token).subscribe(
  //     (data: { subscriptionTypeList: userModuleListResponse[] }) => {
  //       setTimeout(() => {
  //         this.spinnerService.hide();
  //       }, 800)
  //       this.ModuleList = data;
  //       this.checkedModuleIds = this.ModuleList.moduleList
  //         .filter(module => module.isActive)
  //         .map(module => module.moduleId);
  //       console.log(this.subscriptionList)

  //     },
  //     err => {
  //       this.spinnerService.hide();
  //       if (err.status === 401) {
  //         this.spinnerService.hide();
  //         this.unauthorizedException();
  //       }
  //     }
  //   );
  // }

}
