// Vendor Imports
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from 'moment';
// Local Imports
import { MemberService } from "../shared/services/member.service";
import { HubspotConnectionRequestListResponse, MemberListResponse, MembertoHubsportListRequest } from "../shared/models/user";
import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";
import { States } from "../shared//models/mock-states";
import { NgxSpinnerService } from "ngx-spinner";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { PusherService } from "src/services/pusher-service.service";
import { DmCountService } from "src/services/dm-count.service";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { ChatsComponent } from "../chats/chats.component";
import { Renderer2 } from '@angular/core';
declare var $: any;
declare const Pusher: any;

export interface UserData {
  id: Number;
  userName: string;
  email: string;
  designation: string;
  phone: string;
  companyName: string;
  roleType: string;
  date: string;
  status: Number;
  userTypeId?: number;
  userTypeName?: string;
  isChatActive?: boolean;

}

@Component({
  selector: "app-members",
  templateUrl: "./members.component.html",
  styleUrls: ["./members.component.css"],
  animations: [
    trigger('modalAnimation', [
      state('open', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'scale(0.8)'
      })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class MembersComponent implements OnInit {
  // Member Form
  formheading: string = '';
  memberForm: FormGroup;
  addMemberForm = false;
  memberSubmitted = false;
  isLoading = false;
  isusersubmitted: boolean = false;
  states = States;
  member: any;
  @Input() modalState: 'open' | 'closed' = 'closed';
  chatHistory: any;
  msglists: any;
  @ViewChild('endOfChat') endOfChat: ElementRef;
  @ViewChild('chatListElement') chatListElement: ElementRef;
  memberSuspendForm: FormGroup;
  receiverId: any;
  isShow: boolean = false;
  roleList: any;
  roleTypes = [];
  currentUser: any;
  receiverUser: any;
  receiverid: any;
  connectionid: string;
  channel: any;
  pusher: any;
  userId: string;
  channelName: string;
  totalItems: Number;
  searchKey: any = '';
  selectedType: Number

  onselect: boolean = false;;
  usertype: string;
  closeModal(): void {
    this.modalState = 'closed';
  }
  displayedColumns: string[] = [
    "userName",
    "phone",
    "email",
    "designation",
    "companyName",
    "date",
    "usertype",
    "message",
    "status",
    "actions"

  ];
  users: UserData[];
  dataSource: MatTableDataSource<UserData>;
  token: string;
  isPaidLoading: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  filter: string;
  hubsportusersList: MembertoHubsportListRequest[] = [];
  ishubspotLoading: boolean;
  newChatLoading: true;
  organizationName: string;
  chatText: ""
  userName: ""
  senderId: Number;
  visible: boolean;
  textvalue: string = "";
  pageSize = 10;
  currentPage = 0;
  // @ViewChild('modalElement') modalElement!: ElementRef;
  constructor(
    private memberService: MemberService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private pusherService: PusherService,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {


  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.organizationName = user.organaisationName;
    this.senderId = user.adminMemberId;
    // this.spinnerService.show()
    console.log(this.senderId);
    this.getMembers(this.currentPage + 1, this.pageSize, this.searchKey, 0);
    this.onselect = false;
    this.getRoles()
    // const modal = this.modalElement.nativeElement;
    // modal.addEventListener('hidden.bs.modal', () => {
    //   this.renderer.setStyle(modal, 'padding-left', '40px');
    //   this.renderer.setStyle(modal, 'padding-right', '40px');
    // });
  }

  getRoles() {
    if (this.roleList) {
      return this.roleList;
    }

    this.memberService
      .getRoleValues(this.token)
      .subscribe(
        data => {
          this.roleList = data;
          this.roleTypes = this.roleList.roleType

        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
            this.spinnerService.hide();
          }
        }
      );
  }

  onSelectChange(event: any): void {
    // Handle the change event here
    console.log('Selected value:', this.selectedType);
    this.paginator.pageIndex = 0;
    // You can perform additional actions or logic based on the selected value
    this.getMembers(1, this.pageSize, this.searchKey, this.selectedType);

  }
  getBackgroundColor(id):string{
    if(id==161){
      return "#963bf1";
    }
    else if(id==160){
      return "#009EFB";
    }
    else if(id==170){
      return "#04B440";
    }
    else{
      return "red";
    }
  }
  openFullChat(row): void {
    this.modalState = 'closed';
    const dialogRef = this.dialog.open(ChatsComponent, {
      width: '200px',
      height: '100px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openModal(row): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.chatHistory = []
    this.msglists = []
    this.modalState = 'open';
    this.userName = row.userName
    this.userId = this.currentUser.adminMemberId
    if (this.userId > row.id) {
      this.connectionid = 'ima-channel-' + row.id + '-' + this.userId;
    } else {
      this.connectionid = 'ima-channel-' + this.userId + '-' + row.id;
    }
    console.log(row.id)
    console.log(this.connectionid)

    this.changeChannel(this.connectionid);
    this.getChatHistroy(row.id)

  }

  ngOnDestroy() {
    this.unsubscribeFromChannel();
  }

  private subscribeToChannel() {
    const channel = this.pusherService.subscribeToChannel(this.connectionid);

    channel.bind('ima-event', (data: any) => {
      console.log("res", JSON.stringify(data))
      if (this.modalState == 'open') {
        if (data.sender_id == this.receiverId) {
          const chatEntry = this.msglists.find(entry => entry.dateDescription === 'Today');

          if (chatEntry) {
            // Append the new message to the messages array
            chatEntry.messages = [...chatEntry.messages, { messageText: data.message, alignment: "L" }];
          }
          this.scrollToBottom();
          this.getChatHistroy(this.receiverId);
        }
        else {
          // this.msglists = [...this.msglists, { "messageText": data.message, "alignment": "R" }];
          const chatEntry = this.msglists.find(entry => entry.dateDescription === 'Today');

          if (chatEntry) {
            // Append the new message to the messages array
            chatEntry.messages = [...chatEntry.messages, { messageText: data.message, alignment: "R" }];
          }
          this.scrollToBottom();
          this.getChatHistroy(this.receiverId);
        }
      }
    })
  }

  private unsubscribeFromChannel() {
    this.pusherService.unsubscribeFromChannel(this.channelName);
  }

  // You can call these methods when the channel name changes
  changeChannel(newChannelName: string) {
    this.unsubscribeFromChannel();
    this.channelName = newChannelName;
    this.subscribeToChannel();
  }
  onListSelect(event: any) {
    this.memberForm.patchValue({
      'roleTypes': (event.target as HTMLSelectElement).value
    });
    console.log(this.memberForm.value);

  }


  scrollToBottom() {
    setTimeout(() => {
      if (this.endOfChat) {
        this.endOfChat.nativeElement.scrollIntoView({ behavior: "smooth" })
      }
    }, 100);
  }

  onScroll() {
    const container = this.chatListElement.nativeElement;
    console.log('Scrolled to the bottom!');
    // Check if the user has scrolled to the bottom
    const isScrolledToBottom = container.scrollHeight - container.scrollTop === container.clientHeight;

    if (isScrolledToBottom) {
      console.log('Scrolled to the bottom!');
      // Perform actions when scrolled to the bottom
    }
  }

  hideChatBox(): void {
    console.log("clic")
    this.modalState = 'closed';
  }
  openbulkInsert() {
    $("#BulkInsert").modal("show");


  }

  getChatHistroy(memberId) {
    this.receiverId = memberId
    this.userId = this.currentUser.adminMemberId
    let params = {
      "senderId": parseInt(this.userId),
      "receiverId": memberId
    }
    this.memberService
      .getChatHistoryList(params, this.token)
      .subscribe(
        (data: any) => {
          this.chatHistory = data;
          this.msglists = data.chatHistoryList;
          console.log('data', data);
          this.scrollToBottom();
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
            this.spinnerService.hide();
          }
        }
      );
  }


  getMembers(pageNo, pageSize, searchKey, roleType) {
    let params = {
      "searchMember": searchKey.trim(),
      "filterRoleType": roleType,
      "pageNo": pageNo,
      "pageSize": pageSize
    }
    this.spinnerService.show();
    // Assign the data to the data source for the table to render
    this.memberService.getMembers(params, this.token).subscribe(
      (data: { statusCode: Number; filteredCount: Number; statusMessage: string; memberList: MemberListResponse[] }) => {
        // this.ishubspotLoading = false;
        if (data.statusCode == 200) {
          console.log(data.statusCode)
          setTimeout(() => {
            this.spinnerService.hide();
          }, 800)
          let userData = this.getMemberListArray(data.memberList);
          this.users = userData;
          console.log('Users', this.users);
          this.dataSource = new MatTableDataSource(userData);
          // this.dataSource.paginator = this.paginator;
          this.totalItems = data.filteredCount
          // this.dataSource.sort = this.sort;
        }
        else {
          this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();

        }
      },
      err => {
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.getMembers(event.pageIndex + 1, event.pageSize, this.searchKey, this.selectedType)
  }


  getMemberListArray(memberList: MemberListResponse[]) {
    return memberList.map(member => ({
      id: member.memberId,
      userName: member.firstName + " " + member.lastName,
      email: member.email,
      designation: member.designation,
      phone: this.stripMask(member.phone),
      companyName: member.organizationName,
      date: this.GetFormattedDate(member.registerdDate),
      subscriptionType: member.subscriptionType,
      source: member.source,
      status: member.isActive,
      hubspotId: member.hubspotId,
      roleType: member.role,
      roleId: member.roleId,
      userTypeId: member.userTypeId,
      userTypeName: member.userTypeName,
      isChatActive: member.isChatActive,
      chatDuration: member.chatDuration,
      syncedToHubspot: member.syncedToHubspot != "" ? this.GetFormattedDate(member.syncedToHubspot) : "",
    }));
  }

  // Add Member
  openAddMemberModal() {
    $("#newMemberCreate").modal("show");
    this.addMemberForm = true;
    this.memberForm = this.formBuilder.group({
      firstName: ["", [Validators.required, WhiteSpaceValidator, Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z\s]{3,}$/)]],
      lastName: ["", [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/)]],
      title: ["", [Validators.required, WhiteSpaceValidator, Validators.pattern(/^[a-zA-Z0-9,._ -]*$/), Validators.minLength(2)]],
      organaisationName: [this.organizationName],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      phoneNo: ["", [Validators.required, Validators.pattern(/^[1-9][0-9]{9}$/)]],
      // address: ["", [Validators.required, WhiteSpaceValidator]],
      // city: ["", [Validators.required, WhiteSpaceValidator]],
      roleType: ['', [Validators.required]],
      // zipCode: ["", [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
      // interval: ["year"]
    });
    console.log('Checking role ', this.roleTypes[0].role);
  }

  handleKeyDown(event: KeyboardEvent): void {
    console.log('Key pressed:', event.key);
    if (event.key == 'Backspace' && this.memberForm.get('phoneNo').value.length == 1) {

      this.memberForm.controls['phoneNo'].setValue('');

    }
    // Add your logic here based on the key pressed
  }
  // convenience getter for easy access to basic info form fields
  get memberControl() {
    return this.memberForm.controls;
  }

  checkAllFieldsEntered(): boolean {
    return Object.values(this.memberForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }

  memberFormSubmit() {
    this.memberSubmitted = true;
    console.log(this.memberForm)
    if (this.memberForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.spinnerService.show();
    this.memberSubmitted = false;
    this.isLoading = true;
    console.log(this.memberForm.value);
    this.memberService.createMember(this.memberForm.value, this.token).subscribe(
      (data: any) => {
        if (data.statusCode === 201) {
          // this.isLoading = false;
          this.addMemberForm = false;
          this.isLoading = false;
          $("#newMemberCreate").modal("hide");
          this.getMembers(this.currentPage + 1, this.pageSize, this.searchKey, 0);
          Swal.fire({
            title: "Created!",
            text: "Member created successfully",
            icon: "success",
            showCloseButton: true,
            allowOutsideClick: false
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
          this.spinnerService.hide();
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  hide() {
    $("#newMemberCreate").modal("hide");
    this.addMemberForm = false;
    this.isLoading = false;
    this.memberSubmitted = false;
  }
  SuspendHide(){
    $("#suspendModel").modal("hide"); 
  }

  bulkHide() {
    $("#BulkInsert").modal("hide");
  }

  filterEvent(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if (filterValue.length == 0)
      this.getMembers(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)
  }


  applyFilter() {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.searchKey = filterValue
    if (this.searchKey != '' && this.searchKey != undefined)
      this.getMembers(this.currentPage + 1, this.pageSize, this.searchKey, this.selectedType)
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log("filt", this.dataSource.filter)
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  deleteUser(userValue: any) {
    Swal.fire({
      title: "Delete Member",
      text: "Are you sure you want to delete this member? Doing so will also delete all history and data associated with this member.",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false,
      
    }).then(result => {
      if (result.value) {
        // $("#progressModel").modal("show");
        this.spinnerService.show()
        this.memberService
          .deleteMember({ memberId: userValue.id }, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.users = this.users.filter(
                  user => user.id !== userValue.id
                );
                this.dataSource = new MatTableDataSource(this.users);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                // $("#progressModel").modal("hide");
                this.spinnerService.hide()
                this.filter = ''
                Swal.fire({
                  title: "Deleted!",
                  text: "Member is deleted now",
                  icon: "success",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                  this.ngOnInit()
                });
                return;;
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
                this.spinnerService.hide();
              }
            }
          );
      }
    });
  }

  suspendUser(member: any) {
    this.textvalue = '';
    this.visible = true;
    this.member = null;
    this.isusersubmitted = false;
    this.formheading = "Suspend User"
    $("#suspendModel").modal("show");
    this.member = member;
  }
  SuspendMemberSubmit() {
    this.isusersubmitted = true;
    if (this.isEmpty()) {
      return;

    }
    this.isusersubmitted = false;
    $("#suspendModel").modal("hide");
    Swal.fire({
      title: "Suspend Member",
      text: "Are you sure? Do you want to suspend the member?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        console.log('reason', this.textvalue);
        // $("#progressModel").modal("show");
        this.spinnerService.show();
        this.memberService
          .memberStatusChange({ memberId: this.member.id, status: 0, reason: this.textvalue }, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                $("#suspendModel").modal("hide");
                this.users = this.users.map(user => {
                  if (user.id === this.member.id) {
                    return {
                      id: user.id,
                      userName: user.userName,
                      email: user.email,
                      designation: user.designation,
                      companyName: user.companyName,
                      date: user.date,
                      roleType: user.roleType,
                      phone: user.phone,
                      status: 0,
                      userTypeId: user.userTypeId,
                      userTypeName: user.userTypeName,
                      isChatActive: user.isChatActive
                    };
                  }
                  return user;
                });
                console.log('UserActive Or not', this.users);
                this.dataSource = new MatTableDataSource(this.users);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.filter = ''
                this.spinnerService.hide();
                Swal.fire({
                  title: "Suspended!",
                  text: "Member is suspended now",
                  icon: "success",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                  this.ngOnInit()
                });
                return;
              }
              else {
                $("#suspendModel").modal("hide");
                Swal.fire({
                  title: data.statusMessage,
                  icon: "warning",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
                this.spinnerService.hide();
              }
            }
          );
      }
    });



  }
  activeMemberSubmit() {
    this.isusersubmitted = true;
    if (this.isEmpty()) {
      return;
    }
    this.isusersubmitted = false;
    console.log(this.member);
    $("#suspendModel").modal("hide");
    Swal.fire({
      title: "Activate Member",
      text: "Are you sure? Do you want to activate member?",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        // $("#progressModel").modal("show");
        this.spinnerService.show();
        this.memberService
          .memberStatusChange({ memberId: this.member.id, status: 1, reason: this.textvalue }, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.users = this.users.map(user => {
                  if (user.id === this.member.id) {
                    return {
                      id: user.id,
                      userName: user.userName,
                      email: user.email,
                      phone: user.phone,
                      designation: user.designation,
                      companyName: user.companyName,
                      roleType: user.roleType,
                      date: user.date,
                      status: 1,
                      userTypeId: user.userTypeId,
                      userTypeName: user.userTypeName,
                      isChatActive: user.isChatActive
                    };
                  }
                  return user;
                });
                console.log(this.users);
                this.dataSource = new MatTableDataSource(this.users);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.spinnerService.hide();
                this.filter = ''
                Swal.fire({
                  title: "Activated!",
                  text: "Member is active now",
                  icon: "success",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
              }
              else {
                this.spinnerService.hide();
                Swal.fire({
                  title: data.statusMessage,
                  icon: "warning",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
                this.spinnerService.hide();
              }
            }
          );
      }
    });
  }

  activateUser(member: any) {
    this.textvalue = '';
    this.isusersubmitted = false;
    this.visible = false;
    this.member = null;
    this.formheading = "Activate User"
    $("#suspendModel").modal("show");
    this.member = member;

  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }

  GetFormattedDate(date: string) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    return month + "-" + day + "-" + year;
  }
  isEmpty(): boolean {
    if (this.textvalue) {
      let a: string = this.textvalue.trim();
      return a.length === 0;
    }
    return true;
  }

  formatPhoneNumber(event: any) {
    // Remove non-digit characters from the input
    let value = event.replace(/\D/g, '');

    if (value.length <= 3) {
      value = value;
    } else if (value.length <= 6) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else {
      value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }

    // Update the form control value with the formatted value
    return value
  }

  getAppendValue = value => {
    if (value <= 9) {
      return `0${value}`;
    }
    return value;
  };

  stripMask(phoneNumber) {

    const areaCode = phoneNumber.slice(0, 3);
    const centralOfficeCode = phoneNumber.slice(3, 6);
    const lineNumber = phoneNumber.slice(6, 10);

    return `(${areaCode})-${centralOfficeCode}-${lineNumber}`;
  }



  sendMessage() {
    // this.pusherService.pusherTrigger()
    console.log(this.chatText)
    console.log('Sender Id ', this.senderId);
    if (this.chatText == '' || this.chatText == undefined) {
      console.log("Not Get The list");
      return
    }
    console.log('Sender Id ', this.senderId);
    let params = {
      "senderId": this.senderId,
      "receiverId": this.receiverId,
      "messageText": this.chatText,
      "socketId": "",
      "parentMessageId": 0,
      "emoji": ""
    }
    console.log('params passing', params);
    this.chatText = ''

    // let user = JSON.parse(localStorage.getItem("currentUser"));
    // let token = "Bearer" + " " + user.jwtToken;

    this.isPaidLoading = true;
    this.memberService
      .PostSendMsg(params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            // this.msglists.push({ "messageText": this.chatText, "alignment": "R" })
            this.chatText = ''
            this.getChatHistroy(this.receiverId);
            // this.scrollToBottom();
            return;
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  formatDateTime(date: string, datedesc: string): string {
    // Convert to UTC
    const utcDate = moment.utc(date);
    // Convert to local timezone and format as 'DD-MM HH:mm'
    const localDate = utcDate.local();
    const formattedDate = localDate.format('DD-MM HH:mm');
    if (datedesc == 'Yesterday') {
      return 'Yesterday' + " " + localDate.format('HH:mm');
    }
    else if (datedesc == 'Today') {
      return localDate.format('HH:mm');
    }
    return formattedDate;
  }

}
