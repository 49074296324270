<!-- <div class="page-header" style="display: flex; justify-content: center; align-items: center;height:70px; background-color:  #4C3A67;padding: 15px;">
   <div class="title" style="background-color: #ffff">
    <img src="../../assets/img/DNX_logo.png" style="height:50px;width:100% ;padding: 2px;">  -->
      
      
  <!-- </div>

</div> -->
<h4 > DNX Connect</h4>
<app-header></app-header>
  
<div class="container-md" style="margin-top: 48px">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6" style="margin-top: 35px">
      <div class="error-template card">

        <img src="../../assets/img/page_not_found.png" style="height: 300px;">
        <h1>
          Oops!
        </h1>
        <h2>
          404 Page  Not Found
        </h2>
        <div class="error-details">
          Sorry, an error has occured, Requested page not found!
        </div>
        <div class="error-actions">
          <button class="btn btn-primary btn-md btn-block" (click)="goBack()" style="background-color: #4C3A67;border: none;">
            Back
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>
<!-- 
<nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>Member Connect</div>
</nav> -->