<div class="container custom-container">
  <div class="row">
    <div class="col-auto p-0">
      <app-sidenav></app-sidenav>
    </div>
    <div class="col p-0">
<div class="p-0 mt-0" style="width: 100%;height: 100%;">
  <!-- <div class="p-0 mt-0" style="background-color: #f0f4f8;width: 100%;height: 100%;"> -->
<app-header></app-header>
<div class="main-block">
<a routerLink="/members" style="margin-bottom: 10px; display: block;"><mat-icon class="back-icon">arrow_back</mat-icon></a>
<div class="container profile-container" style="width: 100%;height:100vh">
<div class="row">
  <div class="col-sm-6 admin-bg">
    <img src="assets/img/admin-profile.png" />
  </div>
  <div class="col-sm-6 profile-form">
    <div class="form-model"
        style="display:flex ;flex-direction:column;width:538px">
        <div class="" style="display:flex;justify-content: center;align-items: center;">
            <h4 style="margin:20px">Admin Profile</h4>
        </div>
        <div class="form" style="width:100%">
            <form style="padding: 20px;" [formGroup]="adminForm">
                <div class="form-group pb-2">
                  <label>First Name <span style="color: red;"> *</span></label>
                  <input type="text" class="form-control" formControlName="firstname" id="firstname"
                    placeholder="First Name" required   />
                    <div *ngIf="adminFormSubmitted && adminControl.firstname.errors" class="ml-2">
                      <div *ngIf="adminControl.firstname.errors.required" class="text-danger">
                        First Name is required
                      </div>
                      <div *ngIf="adminControl.firstname.errors.pattern" class="text-danger">
                        Avoid using special characters/Numbers and ensure that your first name more than 3 character
                      </div>
                    </div>
                
                </div>
                <div class="form-group pb-2">
                  <label>Last Name <span style="color: red;"> *</span></label>
                  <input type="text" class="form-control" formControlName="lastname" id="LastName"
                    placeholder="Last Name" required   />
                    <div *ngIf="adminFormSubmitted && adminControl.lastname.errors" class="ml-2">
                      <div *ngIf="adminControl.lastname.errors.required" class="text-danger">
                        Last Name is required
                      </div>
                      <div *ngIf="adminControl.lastname.errors.pattern" class="text-danger">
                        Avoid using special characters/Numbers and ensure that your last name more than one character
                      </div>
                    </div>
                  
                </div>
                <div class="form-group pb-2">
                  <label>Email <span style="color: red;"> *</span></label>
                  <input type="text" class="form-control" formControlName="email" id="confirmNewPassword"
                    placeholder="Email" required   readonly/>
                    <div *ngIf="adminFormSubmitted && adminControl.email.errors" class="ml-2">
                      <div *ngIf="adminControl.email.errors.required" class="text-danger">
                       Email is Required
                      </div>
                      <div *ngIf="adminControl.email.errors.pattern" class="text-danger">
                        Please Enter the Valid Email
                      </div>
                    </div>
                </div>
                <div class="form-group pb-2">
                  <label>Type <span style="color: red;"> *</span></label>
                    <input type="text" class="form-control" formControlName="type" id="confirmNewPassword"
                      placeholder="Type" value="Admin" required  readonly/>
                      
                  </div>
                <button class="btn button-color btn-md btn-block" type="submit" (click)="SubmitAdminProfile()" [disabled]="!checkAllFieldsEntered()">
                 
                  <span  >Submit</span>
                </button>
              </form>
        </div>

</div>
</div>
    </div>
  </div>
</div>
    
</div>
    </div>
  </div>
    

</div>
<ngx-spinner
  bdColor="rgba(0, 0, 0, .6)"
  class="spin-img"
  template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>