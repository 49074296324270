import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

// Local Imports
import { LoginService } from "../shared/services/login.service";
import { MemberService } from "../shared/services/member.service";
import Swal from "sweetalert2";
import { PusherService } from "src/services/pusher-service.service";
import { ChatsComponent } from "../chats/chats.component";
import { MatDialog } from "@angular/material/dialog";
import { Subscription, interval } from "rxjs";
import { SharedService } from "src/services/shared.service";
import { NgxSpinnerService } from "ngx-spinner";
import { RoutingResponse } from '../shared/models/user';
import { Interface } from 'readline';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  title: 'DNX Connect';
  activeTab: string;
  navToggle = false;
  isUserAvailable: boolean;
  isShowmodal: boolean;
  token: string;
  chatList: any;
  result: any;
  connectionid: string;
  channelName: string;
  private subscription: Subscription;
  module: Subscription;
  UserDtls: any;
  moduleList: any;
  moduleresult: any;
  pagenotfound: boolean;
  modules: any[];
  profileimage: any;
  constructor(public loginService: LoginService,
    private sharedService: SharedService,
    private pusherService: PusherService,
    private memberService: MemberService, private dialog: MatDialog, private router: Router, private spinnerService: NgxSpinnerService) {
    this.subscription = this.sharedService.apiResponseTriggered$.subscribe(() => {
     
      let user = JSON.parse(localStorage.getItem("currentUser"));
      this.token = "Bearer" + " " + user.jwtToken;
      // this.getChatList()
      
    });
  }

  // ngOnDestroy() {
  //   // Unsubscribe to avoid memory leaks
  //   this.subscription.unsubscribe();
  // }

  ngDoCheck() {
    this.activeTab = this.router.url;
    if (this.loginService.isLoggedIn()) {
      this.isUserAvailable = true;
    } else {
      this.isUserAvailable = false;
    }
    if (this.module) {
      // this.module.unsubscribe();
    }
  }


  ngOnInit() {
    console.log('Curent Router Url ', this.router.url);
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.profileimage = user.organaisationLogo
    this.UserDtls = user.roleId
    this.moduleList = user.moduleList
    this.pagenotfound = false;
    this.connectionid = 'ima-channel-g-count-' + user.adminMemberId;
    // this.changeChannel(this.connectionid);
    // this.getChatList()
    //  if(this.router.url=='/PageNotFound'){
    //   this.pagenotfound=true;
    //  }

    if (user.roleId == 1) {
      this.subscription = this.memberService.modules$.subscribe(
        (data) => {
          this.modules = data.moduleList;
          console.log(this.modules)
          if (data.statusCode == 200) {
            setTimeout(() => {
              this.spinnerService.hide();
            }, 800);
          } else if (data.statusCode === 400 || data.statusCode === 500) {
            this.spinnerService.hide();
          }

          if (!this.modules || this.modules.length === 0) {
            this.memberService.fetchModules();
          }
        },
        (error) => {
          console.error('Error fetching modules from API', error);
          this.spinnerService.hide();
        }
      );
    }
  }

  // changeChannel(newChannelName: string) {
  //   console.log("channel", this.channelName, newChannelName)
  //   if (this.channelName !== newChannelName) {
  //     console.log("channel")
  //     this.unsubscribeFromChannel();
  //     this.channelName = newChannelName;
  //     this.subscribeToChannel();
  //   }
  // }
  // private unsubscribeFromChannel() {
  //   this.pusherService.unsubscribeFromChannel(this.channelName);
  // }

  isExcludedModule(uniqueCode: string): boolean {
    const excludedCodes = [
      '#003Announcement',
      '#010Forms&Applications'
    ];
    return excludedCodes.includes(uniqueCode);
  }

  routing(uniqueCode: string): string {
   
    var routing = ""
    if (uniqueCode == '#003Announcement') {
      routing = '/Announcement'
    }
    else if (uniqueCode == '#004Event') {
      routing = '/Event'
    }
    else if (uniqueCode == '#002News') {
      routing = '/News'
    }
    else if (uniqueCode == '#010Forms&Applications') {
      routing = '/Forms&Applications'
    }
    
    return routing
  }
  //   routingAndUrl(uniqueCode: string, isdarkIcon):RoutingResponse{
  //   var routing = "";
  //   let routingobject: RoutingResponse = {
  //     routing: '',
  //     routingIconUrl: ''
  //   };
  //   if (uniqueCode == '#003Announcement') {
  //     routing = '/Announcement'
  //     routingobject.routing= routing;
  //     routingobject.routingIconUrl=isdarkIcon?"assets/img/announcement.png":"assets/img/announce-light.png";
  //     return routingobject;

  //   }
  //   else if (uniqueCode == '#004Event') {
  //     routing = '/Event'
  //   }
  //   else if (uniqueCode == '#002News') {
  //     routing = '/News'
  //   }
  //   else if (uniqueCode == '#010Forms&Applications') {
  //     routing = '/Forms&Applications'
  //     routingobject.routing= routing;
  //     routingobject.routingIconUrl=isdarkIcon?"assets/img/forms.png":"assets/img/forms-light.png";
  //     return routingobject;
  //   }
  //   else{
  //      routing = "";
  //      return routingobject;
  //   }
    
  // }
  getRouteAndIcon(uniqueCode: string, isdarkIcon: boolean): RoutingResponse {
    let routingObject: RoutingResponse = {
      routing: '',
      routingIconUrl: ''
    };
  
    switch (uniqueCode) {
      case '#003Announcement':
        routingObject.routing = '/Announcement';
        routingObject.routingIconUrl = isdarkIcon 
          ? 'assets/img/announcement.png' 
          : 'assets/img/announce-light.png';
        break;
  
      case '#004Event':
        routingObject.routing = '/Event';
        routingObject.routingIconUrl = isdarkIcon 
          ? 'assets/img/event.png' 
          : 'assets/img/event-light.png'; // Add icons if needed
        break;
  
      case '#002News':
        routingObject.routing = '/News';
        routingObject.routingIconUrl = isdarkIcon 
          ? 'assets/img/news.png' 
          : 'assets/img/news-light.png'; // Add icons if needed
        break;
  
      case '#010Forms&Applications':
        routingObject.routing = '/Forms&Applications';
        routingObject.routingIconUrl = isdarkIcon 
          ? 'assets/img/forms.png' 
          : 'assets/img/forms-light.png';
        break;
  
      default:
        routingObject.routing = '';
        routingObject.routingIconUrl = ''; // Optionally add a default icon
        break;
    }
  
    return routingObject; // Return the routing object
  }
  // private subscribeToChannel() {
  //   this.unsubscribeFromChannel();
  //   const channel = this.pusherService.subscribeToChannel(this.connectionid);
  //   console.log(channel)
  //   channel.bind('ima-global-count', (data: any) => {
  //     console.log("res", JSON.stringify(data))
  //     this.result.unviewedMessageCount = data.unviewedMessageCount
  //     this.getChatList()
  //   })
  // }
  getChatList() {
    console.log('token',this.token);
    let params = {
    };
    if(this.UserDtls!=1){
      return;
    }
    this.memberService
      .getChatList(params, this.token)
      .subscribe(
        data => {
          console.log(data)
          this.result = data;
          console.log(this.result.unviewedMessageCount)

          this.chatList = this.result.memberChatList;
          if (this.chatList.length > 0) {
            this.chatList = this.chatList.sort((a, b) => new Date(b.chatCreatedAt).getTime() - new Date(a.chatCreatedAt).getTime());
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  getMoudleList() {



    // let user = JSON.parse(localStorage.getItem("currentUser"));

    // let params = {
    //   "subscriptionId": user.subscriptionId,
    // }
    // this.memberService
    //   .getModuleList(params, this.token)
    //   .subscribe(
    //     data => {
    //       console.log(data)
    //       this.moduleresult = data;
    //       this.moduleList = this.moduleresult.moduleList

    //     },
    //     err => {
    //       if (err.status === 401) {
    //         this.unauthorizedException();
    //       }
    //     }
    //   );
  }


  openFullChat(row): void {
    console.log(row)
    const dialogRef = this.dialog.open(ChatsComponent, {
      data: { userName: row.memberName, id: row.memberId },
      position: {
        bottom: '10px', // adjust the top position as needed
        right: '10px', // adjust the right position as needed
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  isActiveLink(item: any): boolean {
    const link = this.generateRouterLink(item);
    // if (link === '/module/#003Announcement') { // Example condition
    //   console.log("Generated Link:", link);
    //   console.log("Current Active Tab:", decodeURIComponent(this.activeTab));
    // }
    return true
  }


  generateRouterLink(item: any): string {

    return `${item.moduleName}`;
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      if (this.UserDtls != 2){
        this.router.navigate(["/login"]);
        localStorage.clear();
      }
      else
        this.router.navigate(["/superadmin-login"]);
        localStorage.clear();
    });
    return;
  }

  toggleNav() {
    this.navToggle = !this.navToggle;
  }

  logout() {
    Swal.fire({
      icon: "warning",
      title: "Do you want to sign out?",
      allowOutsideClick: false,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.navToggle = false;
        if (this.UserDtls != 2){
          this.router.navigate(["/login"]);
          localStorage.clear();
          // console.log('Token ',this.token);
        }
        else
          this.router.navigate(["/superadmin-login"]);
          localStorage.clear();
      }
      if (!result.value) {
        // localStorage.clear();
        // this.router.navigate(["/login"]);
      }
    });


  }
  showmodal() {
    this.isShowmodal = true
  }


}
