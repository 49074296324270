// Vendor Imports
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

// Local Imports
import { MemberService } from "../shared/services/member.service";
import { MemberListResponse } from "../shared/models/user";

declare var $: any;

export interface UserData {
  id: Number;
  userName: string;
  email: string;
  designation: string;
  companyName: string;
  date: string;
  status: Number;
}

@Component({
  selector: "app-member-subscription-details",
  templateUrl: "./member-subscription-details.component.html",
  styleUrls: ["./member-subscription-details.component.css"]
})
export class MemberSubscriptionDetailsComponent implements OnInit {
  displayedColumns: string[] = [
    "userName",
    "email",
    "designation",
    "companyName",
    "status",
    "date"
  ];
  users: UserData[];
  dataSource: MatTableDataSource<UserData>;
  token: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private memberService: MemberService, private router: Router) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.token = token;
    // Assign the data to the data source for the table to render
    // this.memberService.getMembers(token).subscribe(
    //   (data: { memberList: MemberListResponse[] }) => {
    //     let userData = this.getMemberListArray(data.memberList);
    //     this.users = userData;
    //     this.dataSource = new MatTableDataSource(userData);
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    //   },
    //   err => {
    //     if (err.status === 401) {
    //       this.unauthorizedException();
    //     }
    //   }
    // );
  }

  getMemberListArray(memberList: MemberListResponse[]) {
    return memberList.map(member => ({
      id: member.memberId,
      userName: member.firstName + " " + member.lastName,
      email: member.email,
      designation: member.designation,
      companyName: member.organizationName,
      date: member.paidDisplayDate,
      status: member.isPaid
    }));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }
}
