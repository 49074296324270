<div class="container custom-container">
    <div class="row">
      <div class="col-auto p-0">
        <app-sidenav></app-sidenav>
      </div>
      <div class="col p-0">
            <div class="p-0 mt-0" style="background-color: #fff;width: 100%;height: 100%;">
                <app-header></app-header>
                <div class="main-block">
                    <div class="p-0 mt-0" style="width: 100%;height: 100%;">
                        <div class="d-flex justify-content-between wrap-block create-col">
                            <div class="mt-2">
                            <ng-container>
                                <!-- <button type="button" class="btn btn-secondary btn-sm" style="background-color: #4C3A67;"
                                    (click)="openAddModule()">
                                    Module Management
                                </button> -->
                            </ng-container>
                        </div>
                        <div class="search-field-col" style="margin-right: 95px">
                            <mat-form-field class="search-block">
                                <mat-label>Search</mat-label>
                                <input class="search-block-input" matInput (keyup)="applyFilter($event)" [(ngModel)]="filter" placeholder="Type to Search" />
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="center-table mat-elevation-z8 subscript-table table-container">
                        <table mat-table [dataSource]="dataSource" matSort class="">

                            <ng-container matColumnDef="title">
                                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                                    Subscription Type
                                </th>
                                <td class="mat-table-cell" mat-cell *matCellDef="let row">{{ row.role }}</td>
                            </ng-container>

                            <ng-container matColumnDef="roles">
                                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                                    Modules
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <div style="display: flex; flex-wrap: wrap; width: 100%;">
                                        <span *ngFor="let role of row.moduleList; let i = index" style="text-transform: capitalize;">
                                            {{ role.module_name}}<span *ngIf="row.moduleList.length - 1 > i">{{", "}}&nbsp;</span><br>
                                            <!-- <span *ngIf="row.moduleList.length - 1 > i">&nbsp;</span><br> -->
                                        </span>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                                    Actions
                                </th>
                                <td class="mat-table-cell" mat-cell *matCellDef="let row" style="width:11%;">
                                    <!-- <mat-icon class="mr-2" matTooltip="Edit" (click)="editRoles(row)"
                                        style="cursor: pointer;">edit</mat-icon> -->
                                        <div matTooltip="Edit" (click)="editRoles(row)"
                                        style="cursor: pointer;" class="edit-icon">
                                            <img src="assets/img/view.png" />
                                        </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                        <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator> -->

                    </div>

                    <div class="modal-overlay" id="subscription-modal" *ngIf="isModalOpen" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel"
                        aria-hidden="true">
                        <div class="modal-content">

                            <div class="modal-header">
                                <h4 class="modal-title">Subscription Management</h4>
                                <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                                    <!-- <img src="assets/img/modal-close.png" /> -->
                                    <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                                </button>
                            </div>

                            <div class="mt-4 reset-psd-form">
                                
                                <div class="form-pad">
                                    <label for="interval">Subscription Type  <span style="color: red;"> *</span></label>

                                    <mat-form-field style="width: 100%;">
                                        <mat-select [(ngModel)]="selectedsubscription"
                                            (selectionChange)="onChangeSelectedValue($event)">
                                            <mat-option *ngFor="let subscription of subscriptionList?.subscriptionTypeList"
                                                [value]="subscription.subscriptionId">{{subscription.subscriptionType}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>



                                <div class="form-pad"> 
                                    <div>
                                        <label for="interval">Modules <span style="color: red;"> *</span></label>
                                    </div>
                                    <div class="vertical-checkbox-group">
                                        <mat-checkbox *ngFor="let item of ModuleList?.moduleList"
                                            (change)="toggleCheckbox(item.moduleId)" [checked]="item.isActive" [disabled]="item.isActive" color="primary"
                                            class="captalize">{{item.moduleName}}</mat-checkbox>
                                    </div>
                                </div>
                                <div class="footer-modal" style="display: flex; justify-content: flex-end;">
                                    <button class="sync-btn" (click)="saveBtn()">
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="newModuleCreate" role="dialog" aria-labelledby="staticBackdropLabel"
                        style="margin-top: 50px;">

                        <div class="modal-dialog">
                            <div class="modal-content">

                                <div class="modal-header">
                                    <h4 class="modal-title">Module</h4>
                                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                                        <!-- <img src="assets/img/modal-close.png" /> -->
                                        <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                                    </button>
                                </div>

                                <div style="margin: 20px;" *ngIf="addModuleForm">

                                    <form [formGroup]="moduleForm" (ngSubmit)="createModule()">
                                        <div style="margin: 15px;">
                                            <label for="firstName">Module<span style="color: red;"> *</span> </label>
                                            <mat-form-field style="width: 100%;">
                                                <input formControlName="moduleName" matInput />
                                            </mat-form-field>

                                            <div *ngIf="moduleSubmit && memberControl.moduleName.errors" class="ml-2">
                                                <div *ngIf="memberControl.moduleName.errors.required" class="text-danger">
                                                    ModuleName required
                                                </div>
                                                <div *ngIf="memberControl.moduleName.errors.noWhiteSpace" class="text-danger">
                                                    {{ memberControl.moduleName.errors.noWhiteSpace }}
                                                </div>
                                            </div>
                                        </div>





                                        <div style="margin: 15px;">

                                            <div>
                                                <button class="sync-btn">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="center-table mat-elevation-z8 subscript-table table-container">
                                    <table mat-table [dataSource]="moduleCreatedList" matSort class="">

                                        <ng-container matColumnDef="id">
                                            <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Module Id
                                            </th>
                                            <td class="mat-table-cell" mat-cell *matCellDef="let row">
                                                {{ row.moduleId }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="name">
                                            <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Modules
                                            </th>
                                            <td lass="mat-table-cell" mat-cell mat-cell *matCellDef="let row">
                                                {{ row.moduleName }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="actions">
                                            <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                                                Actions
                                            </th>
                                            <td class="mat-table-cell" mat-cell *matCellDef="let row" style="width:11%;">
                                                <mat-icon class="mr-2" matTooltip="Edit" (click)="editRoles(row)"
                                                    style="cursor: pointer;">edit</mat-icon>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>
                                    </table>



                                    <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator> -->


                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


    <!-- <div class="modal fade" id="newModuleCreate" role="dialog" aria-labelledby="staticBackdropLabel"
        style="margin-top: 50px;">

        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Module</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                        &times;
                    </button>
                </div>

                <div class="modal-header">
                    <div style="margin: 20px;" *ngIf="addModuleForm">

                        <form>
                            <div style="margin: 15px;">
                                <label for="firstName">Module<span style="color: red;"> *</span> </label>
                                <mat-form-field style="width: 100%;">
                                    <input formControlName="title" matInput />
                                </mat-form-field>
                            </div>
                            <div>
                                <button class="sync-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div> -->

    <ngx-spinner
    bdColor="rgba(0, 0, 0, .6)"
    class="spin-img"
    template="<img src='assets/img/logo-D.gif' />"
    >
    </ngx-spinner>