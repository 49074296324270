import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse, HttpResponse
} from "@angular/common/http";
import { Observable } from 'rxjs';

import { throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators';
// import {

//     OraganizationListResponse,
//     OrganizationRequest
//   } from "../models/user";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class OrganisationService {
  baseUrl = environment.baseUrl;
  constructor(private httpClient: HttpClient) { }
  organisationList(params: any,token:string) {
    const headers = new HttpHeaders({
      'Authorization': token
    });
    return this.httpClient.
      post(environment.baseUrl + `/v1/Master/PostOrganisationList`
        // post(`https://api.dnxconnect.com/v1/Master/postOrganisationList`
        , params,{headers})
      .pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  OrganizationInsert(params: FormData, token: string) {

    const headers = new HttpHeaders({
      'Authorization': token
    });
    return this.httpClient
      .post(environment.baseUrl + `/v1/Master/postOrganisationInsert`
        // .post('https://api.dnxconnect.com'+`/v1/Master/postOrganisationInsert`
        , params, { headers })
      .pipe(catchError(this.handleError));
  }

  OrganizationUpdate(params: FormData, token: string) {

    const headers = new HttpHeaders({
      'Authorization': token
    });
    return this.httpClient
      .post(environment.baseUrl + `/v1/Master/postOrganisationUpdate`
        // .post(`https://api.dnxconnect.com`+`/v1/Master/postOrganisationUpdate`

        , params, { headers })
      .pipe(catchError(this.handleError));
  }
  GetsubscriptionList(token: string){
    const headers = new HttpHeaders({
      'Authorization': token
    });
    return this.httpClient
    .get(environment.baseUrl + `/v1/Master/GetSubscriptionList`,
      // .post(`https://api.dnxconnect.com`+`/v1/Master/postOrganisationUpdate`

       { headers })
    .pipe(catchError(this.handleError));
  }

  // not Yet Implemented 
  generateIdCard(body: any, token: any): Observable<{ blob?: Blob, status: number, statusText: string }> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.httpClient.post(`https://api.dnxconnect.com/v1/Master/postCreateIdCard`, body, { headers: headers, observe: 'response', responseType: 'blob' as 'json' })
      .pipe(catchError(this.handleError));

  }

  SmtpInsert(params: any, token: any) {
    return this.httpClient
      .post(environment.baseUrl + `/v1/Master/postSmtpInsert`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }





}