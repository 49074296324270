import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PusherService } from 'src/services/pusher-service.service';
import { MemberService } from '../shared/services/member.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from 'src/services/shared.service';
import * as moment from 'moment';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.css'],

})
export class ChatsComponent implements OnInit {
  // @Input() conversation;
  // @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  // emojiPickerVisible;
  // message = '';
  // conversation
  @ViewChild('endOfChat') endOfChat: ElementRef;
  msglists: any;
  currentUser: any;
  receiverUser: any;
  receiverid: any;
  connectionid: string;
  channel: any;
  pusher: any;
  userId: string;
  channelName: string;
  @Input() modalState: 'open' | 'closed' = 'closed';
  chatHistory: any;
  chatText: ""
  userName: ""
  token: string;
  receiverId: any;
  newChatLoading: true
  memberId: any;
  senderId:number;

  // onConversationSelected(conversation) {
  //   this.conversations = conversation;
  // }
  constructor(private pusherService: PusherService,
    private dialogRef: MatDialogRef<ChatsComponent>,
    private router: Router, private sharedService: SharedService,
    private memberService: MemberService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.userName = data.userName
    this.memberId = data.id
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = "Bearer" + " " + this.currentUser.jwtToken;
    this.userId = this.currentUser.adminMemberId;

    if (this.userId > this.memberId) {
      this.connectionid = 'ima-channel-' + this.memberId + '-' + this.userId;
    } else {
      this.connectionid = 'ima-channel-' + this.userId + '-' + this.memberId;
    }

    this.changeChannel(this.connectionid);
    this.getChatHistroy(this.memberId)
  }

  formatDateTime(date: string,datedesc:string): string {
  // Convert to UTC
  const utcDate = moment.utc(date);
  // Convert to local timezone and format as 'DD-MM HH:mm'
  const localDate = utcDate.local();
  const formattedDate = localDate.format('DD-MM HH:mm');
  if(datedesc=='Yesterday'){
    return 'Yesterday'+" "+ localDate.format('HH:mm');
  }
  else if(datedesc=='Today'){
    return localDate.format('HH:mm');
  }
  return formattedDate;
  }

  getChatHistroy(memberId) {
    this.modalState = 'open'
    this.receiverId = memberId
    let params = {
      "senderId": parseInt(this.userId),
      "receiverId": memberId
    }
    this.memberService
      .getChatHistoryList(params, this.token)
      .subscribe(
        (data:any) => {
          this.chatHistory = data.chatHistoryList;
          console.log(data);
          this.msglists = this.chatHistory;

          this.scrollToBottom();
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }


  chatUpdate(memberId) {
    this.receiverId = memberId
    let params = {
      "senderId": memberId,
      "receiverId": parseInt(this.userId)
    }
    this.memberService
      .updateChat(params, this.token)
      .subscribe(
        (data) => {
          console.log(data)
          this.handleApiResponse();
          
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  handleApiResponse() {
    // ... process API response

    // Trigger the shared service method
    this.sharedService.triggerApiResponse();
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }

  private unsubscribeFromChannel() {
    this.pusherService.unsubscribeFromChannel(this.channelName);
  }

  // You can call these methods when the channel name changes
  changeChannel(newChannelName: string) {
    this.unsubscribeFromChannel();
    this.channelName = newChannelName;
    this.subscribeToChannel();
  }

  private subscribeToChannel() {
    const channel = this.pusherService.subscribeToChannel(this.connectionid);
    channel.bind('ima-event', (data: any) => {
      console.log("res", JSON.stringify(data))
      if (this.modalState == 'open') {
        if (data.sender_id == this.receiverId) {
          const chatEntry = this.msglists.find(entry => entry.dateDescription === 'Today');
          if (chatEntry) {
            // Append the new message to the messages array
            chatEntry.messages = [...chatEntry.messages, { messageText: data.message, alignment: "L" }];
          } 
          this.scrollToBottoms();
          // this.scrollToBottom();
          // this.getChatHistroy(this.receiverId);
        }
        else {
          // this.msglists = [...this.msglists, { "messageText": data.message, "alignment": "R" }];
          const chatEntry = this.msglists.find(entry => entry.dateDescription === 'Today');
          if (chatEntry) {
            // Append the new message to the messages array
            chatEntry.messages = [...chatEntry.messages, { messageText: data.message, alignment: "R" }];
          } 
          this.scrollToBottoms();
          // this.scrollToBottom();
          // this.getChatHistroy(this.receiverId);
        }
      }
    })
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.endOfChat) {
        this.endOfChat.nativeElement.scrollIntoView({ behavior: "smooth" })
        console.log(this.modalState)
        if (this.modalState == 'open')
          this.chatUpdate(this.memberId)
          
      }
    }, 100);
  }
  scrollToBottoms() {
    setTimeout(() => {
      if (this.endOfChat) {
        this.endOfChat.nativeElement.scrollIntoView({ behavior: "smooth" })
        console.log(this.modalState);
        
       
          
      }
    }, 100);
  }

  hideChatBox(): void {
    console.log("clic")
    this.modalState = 'closed';
    this.dialogRef.close();
  }

  sendMessage() {
    // this.pusherService.pusherTrigger()
    console.log(this.chatText)
    if (this.chatText == '' || this.chatText == undefined) {
      return
    }
    let params = {
      "senderId": this.userId,
      "receiverId": this.receiverId,
      "messageText": this.chatText,
      "socketId": "",
      "parentMessageId": 0,
      "emoji": ""
    }
    this.chatText = ''

    // let user = JSON.parse(localStorage.getItem("currentUser"));
    // let token = "Bearer" + " " + user.jwtToken;

    this.memberService
      .PostSendMsg(params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            // this.msglists.push({ "messageText": this.chatText, "alignment": "R" })
            this.chatText = ''
            this.getChatHistroy(this.receiverId);
            // this.scrollToBottom();
            return;
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }
  // submitMessage(event) {
  //   let value = event.target.value.trim();
  //   this.message = '';
  //   if (value.length < 1) return false;
  //   this.conversation.latestMessage = value;
  //   this.conversation.messages.unshift({
  //     id: 1,
  //     body: value,
  //     time: '10:21',
  //     me: true,
  //   });
  // }

  // emojiClicked(event) {
  //   this.message += event.emoji.native;
  // }
}
