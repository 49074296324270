<div class="container custom-container">
    <div class="row">
      <div class="col-auto p-0">
        <app-sidenav></app-sidenav>
      </div>
      <div class="col p-0">

<div class="p-0 mt-0" style="background-color: #fff;width: 100%;height: 100%;">
    <app-header></app-header>
<div class="container main custom-container rules-col">
    <div style="margin: 10px;">
        <mat-radio-group class="terms-group" style="width: 100%; display: flex; justify-content: start;" [(ngModel)]="agreementType"
            (ngModelChange)="onagreementTypeChange()">
            <mat-radio-button class="terms-radio" color="primary" style="text-align: center; margin-right: 20px;" value="terms">Terms and
                Conditions</mat-radio-button>
            <mat-radio-button color="primary" value="privacy">Privacy and Policy</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="row">
        <div class="col">
            <div class="card reset-psd-card">
                <div class="card-body reset-psd-card-body">
                    <div class="reset-psd-header">
                        <div class="reset-psd-text">
                            {{agreementTitle}}
                        </div>
                    </div>
                    <div class="mt-4 reset-psd-form">

                        <div class="section-container editor-section">

                            <!-- <div class="subheading-container">
                                <mat-form-field>
                                    <input matInput placeholder="Enter Subheading" />
                                </mat-form-field>
                            </div> -->

                            <editor apiKey="xdkenger2ocg52ups58llhfazuqsfl4o58n4u5umy0k0zsqr" [(ngModel)]="html" [init]="{
                                height: 500,
                                  plugins: ['image', 'lists link image table code help wordcount', 'save'],
                                toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help | save'
                            }"></editor>
                            <div *ngIf="isShowContent && this.html == ''" class="text-danger">
                                Content is required
                            </div>
                            <!-- <div>
                                <textarea class="content-container" [(ngModel)]="section.content" id="content"
                                    placeholder="Enter content"></textarea>
                            </div> -->

                        </div>
                        <div class="footer-modal" style="display: flex; justify-content: end; align-items: center;">
                            <button [disabled]="this.html == ''" class="sync-btn" (click)="saveBtn()">Submit</button>
                        </div>
                        <!-- <div>
                            <button class="add-section-button" (click)="addSection()">Add Section</button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, .6)"
  class="spin-img"
  template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>