<app-header></app-header>

<div class="p-4 mt-5">
  <div>
    <div class="text-right">
      <mat-form-field>
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Type to Search"
        />
      </mat-form-field>
    </div>

    <div class="mat-elevation-z8" style="margin-bottom: 60px">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="title">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Title
          </th>
          <td mat-cell *matCellDef="let row">{{ row.title }}</td>
        </ng-container>

        <ng-container matColumnDef="reportCount">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Reports
          </th>
          <td mat-cell *matCellDef="let row">{{ row.reportCount }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Status
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="row.status === 0">Active</span>
            <span *ngIf="row.status === 1">Blocked</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            class="mat-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Action
          </th>
          <td mat-cell *matCellDef="let row">
            <button
              type="button"
              class="btn btn-danger btn-sm"
              *ngIf="row.status === 0"
            >
              block
            </button>
            <button
              type="button"
              class="btn btn-success btn-sm"
              *ngIf="row.status === 1"
            >
              un-block
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>

<nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>Member Connect</div>
</nav>
