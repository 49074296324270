<div class="container custom-container">
  <div class="row">
    <div class="col-auto p-0">
      <app-sidenav></app-sidenav>
    </div>
    <div class="col p-0">
<div class="p-0 mt-0" style="width: 100%;height: 100%;">
<app-header></app-header>
<div class="main-block">
<a routerLink="/members" style="margin-bottom: 10px; display: block;"><mat-icon class="back-icon">arrow_back</mat-icon></a>
<div class="container profile-container">
  <div class="row">
    <div class="col-sm-6 admin-bg">
      <img src="assets/img/reset.png" />
    </div>
    <div class="col-md-6 main profile-form">
      <div class="card">
        <div class="card-header text-center">
          Reset Password
        </div>
        <div class="card-body">
          <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group pb-2">
              <label>Old Password</label>
              <input type="password" class="form-control" formControlName="oldPassword" id="oldPassword"
                placeholder="Old Password" required />
              <div *ngIf="submitted && f.oldPassword.errors" class="ml-2">
                <div *ngIf="f.oldPassword.errors.required" class="text-danger">
                  Old Password required
                </div>
              </div>
            </div>
            <div class="form-group pb-2">
              <label>New Password</label>
              <input type="password" class="form-control" formControlName="newPassword" id="newPassword"
                placeholder="New Password" required />
              <div *ngIf="submitted && f.newPassword.errors" class="ml-2">
                <div *ngIf="f.newPassword.errors.required" class="text-danger">
                  New Password required
                </div>
                <div *ngIf="f.newPassword.errors.minlength" class="text-danger">
                  Minimum 8 characters required
                </div>
              </div>
              <div *ngIf="f.newPassword.errors" class="ml-2">
                <div *ngIf="f.newPassword.errors.passwordStrength" class="text-danger">
                  {{ f.newPassword.errors.passwordStrength }}
                </div>
              </div>
            </div>
            <div class="form-group pb-2">
              <label>Confirm New Password</label>
              <input type="password" class="form-control" formControlName="confirmNewPassword" id="confirmNewPassword"
                placeholder="Confirm New Password" required />
              <div *ngIf="submitted && f.confirmNewPassword.errors" class="ml-2">
                <div *ngIf="f.confirmNewPassword.errors.required" class="text-danger">
                  Confirm Password required
                </div>
                <div *ngIf="f.confirmNewPassword.errors.confirmedValidator" class="text-danger">
                  Password and Confirm Password should match
                </div>
                <div *ngIf="f.confirmNewPassword.errors.minlength" class="text-danger">
                  Minimum 8 characters required
                </div>
              </div>
              <div *ngIf="f.confirmNewPassword.errors" class="ml-2">
                <div *ngIf="f.confirmNewPassword.errors.passwordStrength" class="text-danger">
                  {{ f.confirmNewPassword.errors.passwordStrength }}
                </div>
              </div>
            </div>
            <button class="btn button-color btn-md btn-block" type="submit" [disabled]="isLoading">
              <span *ngIf="isLoading" class="spinner-border text-light" role="status">
              </span>
              <span *ngIf="!isLoading">Reset</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>

<!-- <nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>Member Connect</div>
</nav> -->