import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { RoleListResponse, userRolesListResponse } from '../shared/models/user';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

declare var $: any;

export interface UserData {
  id: Number;
  role: string;
  chat_duration: string;
}

@Component({
  selector: 'app-rolemanage',
  templateUrl: './rolemanage.component.html',
  styleUrls: ['./rolemanage.component.css']
})
export class RolemanageComponent implements OnInit {

  token: string;
  roleList: any;
  roleType: {}
  chatFeature: any;
  chatDuration: any;
  displayedColumns: string[] = [
    "title",
    "roles",
    "actions"
  ];
  filter: string;
  dataSource: MatTableDataSource<UserData>;
  users: UserData[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  rolesvalues: any;
  selectedrow: any;
  isModalOpen: boolean = false;
  ischatFeature: boolean;
  ischatDuration: any;


  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.getRoles()
  }

  onSubmit() {

  }
  getRoles() {
    this.spinnerService.show();
    this.memberService.getRoleValues(this.token).subscribe(
      (data: { roleType: userRolesListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        this.roleList = data;
        let userData = this.getMemberListArray(data.roleType);
        console.log(userData)
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  getMemberListArray(roleType: userRolesListResponse[]) {
    return roleType.map(member => ({
      id: member.id,
      role: member.role,
      chat_duration: member.chat_duration,
    }));
  }

  editRoles(row) {
    console.log(row)
    this.roleType = row
    this.selectedrow = row.id
    if (row.chat_duration != "" && row.chat_duration != null) {
      this.chatFeature = "true"
      this.ischatFeature = true
      this.chatDuration = row.chat_duration
    }
    else {
      this.chatFeature = "false"
      this.ischatFeature = false
    }
    this.isModalOpen = true
  }

  onRadioGroupChange() {
    console.log(this.chatFeature)
    this.chatDuration = ''
    if (this.chatFeature == "true")
      this.ischatFeature = true
    else {
      this.ischatFeature = false
      this.ischatDuration = false
    }

  }
  onChatDurationChange() {
    console.log(this.chatFeature)
    this.ischatDuration = false

  }
  onChangeSelectedValue() {
    console.log('Selected value changed:', this.selectedrow);
    // Add your logic here
  }
  hide() {
    this.isModalOpen = false
  }
  errorMessage() {
    this.spinnerService.hide();
    Swal.fire({
      icon: "warning",
      title: "Something went wrong",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "OK"
    }).then(() => {
    });
    return;
  }
  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
    });
    return;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  openAddMemberModal() {

  }
  saveBtn() {
    console.log("this.chatDuration", this.chatDuration, this.chatFeature)
    if (this.chatFeature == "true") {
      if (this.chatDuration == undefined || this.chatDuration == '') {
        this.ischatDuration = true;
        return
      }
    }
    // if (this.chatDuration == undefined || this.chatDuration == "") {
    //   this.ischatDuration = true
    //   return
    // }
    this.ischatDuration = false
    this.spinnerService.show();

    let params = {
      "roleId": this.selectedrow,
      "chatFeature": this.ischatFeature,
      "chatDuration": this.ischatFeature ? this.chatDuration : ""
    }
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;

    this.memberService
      .updatRoles(params, token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            this.spinnerService.hide();
            this.ischatFeature = false

            Swal.fire({
              icon: "success",
              title: "Updated Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.isModalOpen = false
              this.ngOnInit()
            });
            return;
          }
          else {
            this.spinnerService.hide();
            this.errorMessage()
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

}
