<div class="container custom-container">
    <div class="row">
        <div class="col-auto p-0">
            <app-sidenav></app-sidenav>
          </div>
   <div class="col p-0">
    <div class="p-0 mt-0" style="background-color: #ffff ;width: 100%;height: 100%;">
    <app-header></app-header>
      <div class="main-block">
        <div class="d-flex justify-content-between align-items-start wrap-block create-col">
            <div class="mt-2">
              <div class="split-button" style="display:flex;gap:8px">
                <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;" (click)="onClickInsert()"
               >
                  <img src="assets/img/plus.png" class="btn-img"/> New Category
                </button>
              </div>
            </div>
            <div class="mt-2 wrap-block forms-search" style="display: flex; align-items: center; margin-right: 20px;">
              <div style="margin-right: 20px;" class="form-search-sub" >
                <!-- <mat-form-field style="width: 100%; margin-top: auto;">
                  <mat-select [(value)]="selectedType" class="user-select" style="margin-top: auto;" placeholder="Vendor Group"
                     panelClass="custom-dropdown-panel"  disableOptionCentering>
                    <mat-option [value]="0">None</mat-option>
                   
                  </mat-select>
                </mat-form-field> -->
              </div>
              <div class="search-form-field searchType-input">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Type to Search Category"
                    [(ngModel)]="selectedType"
                    (keyup)="applyFilter($event)"
                  />
                </mat-form-field>
                <mat-icon
                  class="searchIcon"
                  style="cursor: pointer;"
                  (click)="applyFilterIcon()"
                >
                  search
                </mat-icon>
              </div>
              
            </div>
        </div>
        <div class="space" style="height: 20px;"></div>
        <div class="mat-elevation-z8 member-table table-container mobile-scroll">
          
          <table mat-table [dataSource]="dataSource" class="mat-table">

            <!-- Name Column -->
            <!-- <ng-container matColumnDef="categoryId">
              <th class="mat-table-header" mat-header-cell *matHeaderCellDef> category </th>
              <td mat-cell *matCellDef="let element"> 
                {{element.categoryId  || 'N/A'}} 
              </td>
            </ng-container> -->
        
            <!-- Email Column -->
            
        
            <!-- Group Name Column -->
           
            <!-- Created Date Column -->
            <ng-container matColumnDef="categoryName">
              <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Category </th>
              <td mat-cell *matCellDef="let element"> 
                {{element.categoryName||'N/A'}} 
              </td>
            </ng-container>
            <ng-container matColumnDef="subCategoryList">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Sub Category </th>
                <td mat-cell *matCellDef="let element"> 
                  <ng-container *ngFor="let role of element.subCategoryList; let last = last" >
                    {{role.categoryName}}
                    <span *ngIf="!last">,</span>
                </ng-container>
                </td>
              </ng-container>
        
            <!-- Actions Column -->
            <ng-container matColumnDef="Category Actions">
              <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Category Actions </th>
              <td mat-cell *matCellDef="let element"> 
                <!-- Add any action buttons here -->
                <div class="edit-icon" matTooltip="Edit" style="cursor: pointer;" (click)="onClickUpdate(element)" >
                  <img src="assets/img/view.png" />
              </div>
          <!-- <mat-icon matTooltip="Delete" style="cursor: pointer;"
              (click)="deleteSharePoint(row.id)" class="delete-icon">delete</mat-icon> -->
              <div class="delete-icon" matTooltip="Delete" style="cursor: pointer;"  *ngIf="element.subCategoryList.length==0"
                (click)="onDeleteSubmit(element)" >
                  <img src="assets/img/delete.png" >
                </div>
               
              </td>
            </ng-container>
            <ng-container matColumnDef="Sub-Category Action">
              <th class="mat-table-header" mat-header-cell *matHeaderCellDef> Sub-Category Action </th>
              <td mat-cell *matCellDef="let element"> 
                <!-- Add any action buttons here -->
               
                <div class="Add-icon" matTooltip="Add Sub category" style="cursor: pointer;"
                (click)="onClickSubcatogory(element)" >
                <img src="assets/img/plus.png" class="btn-img"/>
                </div>
                <!-- <div class="delete-icon" matTooltip="Delete" style="cursor: pointer;"
                (click)="onDeleteSubmit(element)"  *ngIf="element.subCategoryList.length==0">
                  <img src="assets/img/delete.png" >
                </div> -->
                <!-- <div class="edit-icon" matTooltip="Edit" style="cursor: pointer;" (click)="onClickUpdate(element)" >
                  <img src="assets/img/view.png" /></div>
              
                  <div class="delete-icon" matTooltip="Delete" style="cursor: pointer;"
                  (click)="onDeleteSubmit(element)" >
                    <img src="assets/img/delete.png" >
                  </div> -->
                  <span class="notification" *ngIf="element.subCategoryList.length!=0"
                                         style="cursor: pointer;" (click)="GetsubcatogoryFilter(element.subCategoryList,element.categoryName)">
                                        <mat-icon matBadge="{{element.subCategoryList.length}}" matBadgeColor="accent"
                                            matTooltip="category Details"
                                            style="margin:5px;position: relative;bottom: -6px;">category </mat-icon></span>

              </td>
            </ng-container>
            <!-- Header and Row Definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
          </table>
            
            <div *ngIf="catogoryList?.length == 0" style="text-align: center; padding: 50px; background-color: white;">
              <p>No records found</p>
            </div>
          
            
            <mat-paginator [pageSizeOptions]="[ 1,10, 25, 100]" showFirstLastButtons [length]="10"
                           [(pageSize)]="PageSize" [(pageIndex)]="pageNo"  class="metaPaginate">
            </mat-paginator>  

        </div>

       <div class="modal fade" id="newcatogoryCreate" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">{{heading}}</h4>
                  <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                    <mat-icon class="inactive-icon" style="color: red; font-size: 27px;">cancel</mat-icon>
                  </button>
                </div>
          
                <!-- Modal Body -->
                <div class="modal-body">
                  <div class="container">
                    <form [formGroup]="catagoryForm" (ngSubmit)="this.submitControl? submitcatogory() : submitcatogoryUpdate()">
                      <!-- Group Name -->
                      <div class="form-group mb-3">
                        <label for="groupName">  Category  Name <span style="color: red;">*</span></label>
                        <input type="text" class="form-control signup-input" id="groupName" placeholder="Enter Category Name" formControlName="CategoryNames" />
                        <div *ngIf="catogorySubmitted && catagoryFormrControl.CategoryNames.errors" class="text-danger ml-2">
                          <div *ngIf="catagoryFormrControl.CategoryNames.errors.required"> Category name is required</div>
                          <div *ngIf="catagoryFormrControl.CategoryNames.errors.noWhiteSpace">Category name is required</div>
                        </div>
                      </div>
          
                      <!-- Submit and Cancel Buttons -->
                      <div class="footer-modal" style="display: flex; justify-content: flex-end;">
                        <button class="btn button-color btn-md btn-block memberAdd-btn" type="submit" [disabled]="!checkAllFieldsEntered()" [ngStyle]="{ 'opacity': checkAllFieldsEntered() ? 1 : 0.5 }">
                          <span>{{footer}}</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="newSubcatogoryCreate" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="z-index: 1800;">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">{{heading}}</h4>
                  <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                    <mat-icon class="inactive-icon" style="color: red; font-size: 27px;">cancel</mat-icon>
                  </button>
                </div>
          
                <!-- Modal Body -->
                <div class="modal-body">
                  <div class="container">
                    <form [formGroup]="subcatagoryForm" (ngSubmit)="this.submitControl? submitSubcatogory() : submitSubcatogoryUpdate()">
                      <!-- Group Name -->
                      <div class="form-group mb-3">
                        <label for="groupName">Sub Category  Name <span style="color: red;">*</span></label>
                        <input type="text" class="form-control signup-input" id="groupName" placeholder="Enter Sub  Category Name" formControlName="CategoryName" />
                        <div *ngIf="subcatogorySubmit && subcatagoryFormrControl.CategoryName.errors" class="text-danger ml-2">
                          <div *ngIf="subcatagoryFormrControl.CategoryName.errors.required"> Sub Category name is required</div>
                          <div *ngIf="subcatagoryFormrControl.CategoryName.errors.noWhiteSpace">Sub Category name is required</div>
                        </div>
                      </div>
          
                      <!-- Submit and Cancel Buttons -->
                      <div class="footer-modal" style="display: flex; justify-content: flex-end;">
                        <button class="btn button-color btn-md btn-block memberAdd-btn" type="submit" [disabled]="!checkAllFieldsEnteredSub()" [ngStyle]="{ 'opacity': checkAllFieldsEnteredSub() ? 1 : 0.5 }">
                          <span>{{footer}}</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="categoryDetails" data-backdrop="false" tabindex="-1" role="dialog"
          aria-labelledby="staticBackdropLabel" aria-hidden="true" >
    <div class="modal-dialog custom-modal-width" style="width: 80%; max-width: 1000px;">
        <div class="modal-content" style="padding: 20px; width: 100%;">
            <div class="modal-header">
                <h4 class="modal-title">{{subcatogorydetails}}</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="hideCatogory()">
                    <mat-icon class="inactive-icon"
                              style="color: red;font-size: 27px;">cancel</mat-icon>
                </button>
            </div>
            <div class="mat-elevation-z8 member-table table-container mobile-scroll">
                <table mat-table [dataSource]="dataSourcefilter" class="module-table">

                    <!-- Sub Category Name Column -->
                    <ng-container matColumnDef="Sub Category Name">
                        <th mat-header-cell *matHeaderCellDef> Sub Category  </th>
                        <td mat-cell *matCellDef="let element"> {{element.categoryName || 'N/A'}} </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element">
                          <div class="edit-icon" matTooltip="Edit" style="cursor: pointer;" (click)="onUpdateSubcatogory(element)" >
                  <img src="assets/img/view.png" /></div>
              
                  <div class="delete-icon" matTooltip="Delete" style="cursor: pointer;"
                  (click)="onDeleteSubmitSubCatogory(element,element.categoryName)" >
                    <img src="assets/img/delete.png" >
                  </div>
                        </td>
                    </ng-container>

                    <!-- Header and Row Declarations -->
                    <tr mat-header-row *matHeaderRowDef="subdisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: subdisplayedColumns;"></tr>
                </table>

                <div *ngIf="this.object.length == 0" style="text-align: center;padding: 50px;background-color: white;">
                    <p>No records found</p>
                </div>

                <mat-paginator   showFirstLastButtons pageSize="10"  
                               [length]="this.object.length"
                              ></mat-paginator>
            </div>
        </div>
    </div>
</div>

        </div>
    </div>
   </div>
