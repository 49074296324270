import { AbstractControl, ValidationErrors } from '@angular/forms';

export const WhiteSpaceValidator = function(
  control: AbstractControl
): ValidationErrors | null {
  let value: string = control.value || '';

  if (!value) {
    return null;
  }

  let trimValue = value.trim();
  if (trimValue.length === 0) {
    return {
      noWhiteSpace: `Should contain characters`
    };
  }
  return null;
};
