import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
import { MemberService } from '../shared/services/member.service';
// Local Imports
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";

import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;
@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})

export class AdminProfileComponent implements OnInit {
  adminForm:FormGroup;
  adminFormOpen:boolean= false;
  adminFormSubmitted = false;
  token:string;
  firstName:string;
  lastName:string;
  email:string;
  replaceFirstname:string;
  replaceLastname:string;
  isfirst:boolean=false;
  isLoading = false;
  user:any;
  details:any;
  constructor(
    // private blogservice: blogService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private memberService: MemberService,
    
    
  ) {  }
  
  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();
    this.isLoading=true;
    this.getAdminDetails();
    this.adminForm=this.formBuilder.group({
      firstname: [this.firstName, [Validators.required, WhiteSpaceValidator,Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z\s]{3,}$/)]],
      lastname: [this.lastName, [Validators.required, WhiteSpaceValidator,Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/)]],
      email: [
       this.email,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      type: ["Admin", [Validators.required, WhiteSpaceValidator]]

    });

    
  }
  getAdminDetails(){
    console.log(this.token);
    this.memberService.GetAdminProfiles(this.token).subscribe(
      (data: any) => {
       
         if(data.statusCode==200){
          this.spinnerService.hide();
          this.details=data;
          this.firstName=this.details.firstName;
          this.lastName=this.details.lastName;
          this.email=this.details.email;
          this.adminForm=this.formBuilder.group({
            firstname: [this.firstName, [Validators.required, WhiteSpaceValidator,Validators.pattern(/^(?=.*[a-zA-Z].*[a-zA-Z])[a-zA-Z\s]{3,}$/)]],
            lastname: [this.lastName, [Validators.required, WhiteSpaceValidator,Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/)]],
            email: [
             this.email,
              [
                Validators.required,
                Validators.pattern(
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
              ]
            ],
            type: ["Admin", [Validators.required, WhiteSpaceValidator]]
      
          });
          console.log( this.email);
         }
         else{
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });

         }
        
      },
      err => {
        
      }
    );


  }
  SubmitAdminProfile(){
    this.adminFormSubmitted = true;
    console.log(this.adminForm)
    if (this.adminForm.invalid) {
      // console.log("Hey");
      return;
    }
    
    this.spinnerService.show();
    this.adminFormSubmitted = false;
    this.isLoading = true;
    const formValues = this.adminForm.value;
    // const entries = Object.entries(formValues);
    // let params=entries;
    // console.log(params.values());
    // entries.forEach(([key, value]) => console.log(`${key}: ${value}`));
    const params = {
      "firstname": formValues.firstname,
      "lastname": formValues.lastname,
    
    };
    this.memberService.postAdminProfile(params,this.token).subscribe(
      (data: any) => {
        this.isfirst=true;
         if(data.statusCode==200){
          this.spinnerService.hide();
         
          Swal.fire({
            title: "Updated!",
            text: data.statusMessage,
            icon: "success",
            showCloseButton: true,
            allowOutsideClick: false
          }).then((result) => {
            if(result.isConfirmed){
              this.router.navigate(["members"]);
            }
          });

         }
         else{
          Swal.fire({
            title: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });

         }
        
      },
      err => {
        this.spinnerService.hide();
        this.unauthorizedException();

      }
    );

    
   
  }
  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Access Denied",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);

    });
    return;
  }
  get adminControl() {
    return this.adminForm.controls;
  }
  checkAllFieldsEntered() {
    
    return Object.keys(this.adminForm.controls).every(key => {
      const control = this.adminForm.get(key);
      return control && control.value !== '' && control.value !== null;
    });
  }      

}
