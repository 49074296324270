<div>
    <div style=" display: flex;
  justify-content: space-between">
        <p style="text-align: left;">Connection Notes</p>
        <img style="text-align: right;" src="assets/img/close.png" class="menu-icon" (click)="closemodal()" />
    </div>

    <textarea [(ngModel)]="test" maxlength="120" placeholder="Max Characters 120 ..." style="width: 100%;
    height: 200px;padding: 10px;"></textarea>



</div>
<div mat-dialog-actions>
    <button class="sync-btn" (click)="Closepopup()">Save</button>
</div>