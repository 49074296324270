import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SharedalertService {

  constructor() { }

  successAlert(callback: () => void): () => void {
    Swal.fire({
      icon: 'success',
      title: 'Deleted Successfully',
      showCloseButton: true,
      allowOutsideClick: false,
    }).then(() => {
      callback();
    });

    // Return a function that can be called where successAlert is invoked
    return () => {
      // You can perform additional actions here if needed
      console.log('Returned function called');
      callback();
    };
  }

  customeSuccessAlert(callback: () => void): () => void {
    Swal.fire({
      icon: 'success',
      title: 'Updated Successfully',
      showCloseButton: true,
      allowOutsideClick: false,
    }).then(() => {
      callback();
    });

    // Return a function that can be called where successAlert is invoked
    return () => {
      // You can perform additional actions here if needed
      console.log('Returned function called');
      callback();
    };
  }

  errorAlert(callback: () => void): () => void {
    Swal.fire({
      icon: "warning",
      title: "Something went wrong",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "OK"
    }).then(() => {
      callback();
    });

    // Return a function that can be called where successAlert is invoked
    return () => {
      // You can perform additional actions here if needed
      console.log('Returned function called');
      callback();
    };
  }

  toast(customTitle: string, callback: () => void): () => void {
    Swal.fire({
      icon: 'success',
      title: customTitle, // Use custom title or fallback to default
      toast: true,
      position: 'bottom-left',
      showConfirmButton: false,
      timer: 3000
    }).then(() => {
      callback();
    });

    // Return a function that can be called where successAlert is invoked
    return () => {
      // You can perform additional actions here if needed
      console.log('Returned function called');
      callback();
    };
  }
}
