<div class="container custom-container">
    <div class="row">
      <div class="col-auto p-0">
        <app-sidenav></app-sidenav>
      </div>
      <div class="col p-0">
        <div class="p-0 mt-0" style="background-color: #ffff ;width: 100%;height: 100%;">

            <app-header></app-header>
            <div class="main-block">
                <div class="subscription-banner">
                    <ng-container *ngFor="let usertype of subscriptionList">
                      <div *ngIf="usertype.subscriptionId >= currentsub" class="subscription-item">
                        <div class="show">
                          <!-- Header: Subscription Type -->
                          <div class="subscription-type">
                            <h3>{{ usertype.subscriptionType }}</h3>
                          </div>
                  
                          <!-- Middle: Module List -->
                          <div class="subscription-modules" style="line-height: 2.5rem;">
                            <ul>
                              <li *ngFor="let module of usertype.moduleList">
                                {{ module.module_name }}
                              </li>
                            </ul>
                          </div>
                  
                          <!-- Footer: Buy Plan Button -->
                          <div class="subscription-footer">
                            <button 
                              [ngClass]="{'current-plan': currentsub === usertype.subscriptionId, 'buy-plan': currentsub !== usertype.subscriptionId}"
                              [ngStyle]="{
                                'background-color': currentsub === usertype.subscriptionId ? '#5D3C6C' : 'red',
                                'color': 'white',
                                'border': currentsub === usertype.subscriptionId ? '2px solid #5D3C6C' : 'none',
                                'padding': '10px',
                                'border-radius': '5px',
                                'cursor': currentsub === usertype.subscriptionId ? 'default' : 'pointer'
                              }"(click)="postAdminSubscriptionUpdate(usertype.subscriptionId,currentsub === usertype.subscriptionId)">
                              {{ currentsub === usertype.subscriptionId ? 'Current Plan' : 'Buy Plan at $130' }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  
                  
            </div>
        </div>
    </div>
</div>
</div>

