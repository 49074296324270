import { Component, OnInit } from '@angular/core';
import { MemberService } from '../shared/services/member.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/services/shared.service';
import { SharedalertService } from '../shared/services/sharedalert.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  emailTo: string = '';
  emailCc: string = '';
  token: string;
  inputText: string = '';
  maxHeight: number = 100; // Set your desired max height in pixels
  emailToValid: boolean = true;
  emailData: any;
  emailCcValid: boolean = true;

  adjustTextareaHeight() {
    this.emailCcValid = true
    this.emailToValid = true

    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = Math.min(textarea.scrollHeight, this.maxHeight) + 'px';
    }
  }
  validateEmailFormat(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  validateEmailArrayFormat(emailArray: string[], type): boolean {
    const invalidEmails: string[] = [];

    for (const email of emailArray) {
      if (!this.validateEmailFormat(email)) {
        invalidEmails.push(email); // Add invalid email to the list
      }
    }

    if (invalidEmails.length > 0) {
      this.Erroralert(invalidEmails.join(', '), type)
      // console.error('Invalid email(s) detected:', invalidEmails.join(', '));
      return false; // At least one email is not in a valid format
    }

    return true; // All emails are in a valid format
  }


  constructor(private memberService: MemberService, private sharedService: SharedalertService, private spinnerService: NgxSpinnerService) { }
  submitForm() {
    const emailto = this.emailTo.split(',').map(emailTo => emailTo.trim());
    const emailcc = this.emailCc.split(',').map(emailCc => emailCc.trim());

    if (this.emailTo.toString() == '' || this.emailTo.toString() == undefined) {
      this.emailToValid = false
      return
    }

    if (this.emailCc.toString() == '' || this.emailCc.toString() == undefined) {
      this.emailCcValid = false
      return
    }

    if (!this.validateEmailArrayFormat(emailto, "To")) {
      // All emails are in a valid format, proceed with form submission or other actions
      console.log('All emails are valid');
      return
    }

    if (!this.validateEmailArrayFormat(emailcc, "CC")) {
      // All emails are in a valid format, proceed with form submission or other actions
      console.log('All emails are valid');
      return
    }

    console.log('Emails submitted:', this.emailTo.toString(), this.emailCc.toString());


    this.spinnerService.show();
    let params = {
      "emailTo": this.emailTo.toString(),
      "emailCc": this.emailCc.toString(),
      "moduleName": "HelpDesk"
    }

    this.memberService
      .updateHelpDesk(params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string, invalidToEmailList: string }) => {
          if (data.statusCode === 200) {
            this.spinnerService.hide();
            this.sharedService.customeSuccessAlert(() => {
              this.ngOnInit()
            });
            return;
          }
          else if (data.statusCode === 203) {
            this.spinnerService.hide();
            this.Erroralert(data.statusMessage, "")
            return;
          }
          else {
            this.spinnerService.hide();
            this.ServerErorr()
            return;
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  ServerErorr() {
    Swal.fire({
      icon: "warning",
      title: "Something went wrong",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Ok"
    }).then(() => {
    });
    return;
  }
  Erroralert(email, type) {
    Swal.fire({
      icon: "warning",
      title: "Invalid " + type + " Email!",
      text: email,
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Ok"
    }).then(() => {
    });
    return;
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
    });
    return;
  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.getEmailList()
  }


  getEmailList() {
    this.spinnerService.show();
    console.log("called")
    this.memberService
      .getHelpDeskEmailList(this.token)
      .subscribe(
        data => {
          this.spinnerService.hide();
          this.emailData = data;
          this.emailTo = this.emailData.data[0].emailToList
          this.emailCc = this.emailData.data[0].emailCcList

          console.log(this.emailData)
        },
        err => {
          this.spinnerService.hide();
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }
}
