import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MemberService } from '../shared/services/member.service';
import { Router } from "@angular/router";
declare var $: any;

export interface moduleListResponse {
  moduleId: Number;
  moduleName: string;
  moduleType: string;
  uniqueCode: string;
}

export interface UserData {
  id: Number;
  role: string;
  moduleList: moduleListResponse;
}

@Component({
  selector: 'app-modulemanage',
  templateUrl: './modulemanage.component.html',
  styleUrls: ['./modulemanage.component.css']
})
export class ModulemanageComponent implements OnInit {

  token: string;
  moduleFormupdate:FormGroup;
  subscriptionList: any;
  users: UserData[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  moduleForm: FormGroup;
  filter: string;
  selectionType=["Web","Mobile","Both"];
  addModuleForm: boolean;
  moduleSubmit: boolean;
  isModalOpen: boolean = false;
  totalCount: Number;
  editModuleName: String;
  editModuleId:Number;

  //Create module options
  selectedOption: string;
  options = [
    { value: 'Web', viewValue: 'Web' },
    { value: 'Mobile', viewValue: 'Mobile' },
    { value: 'Both', viewValue: 'Both' }
  ];

  displayedColumnsOne: string[] = [
  
    "name",
    "uniqueCode",
    "modelType",
    "actions"

  ];
  moduletypeSelection:any;
  moduleCreatedList: MatTableDataSource<moduleListResponse>;
  moduleList: moduleListResponse[];

  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService, private formBuilder: FormBuilder, private router: Router) { 
    this.moduleForm = this.formBuilder.group({
      moduleName: ['', [Validators.required, WhiteSpaceValidator]],
      uniqueCode: ['', [Validators.required, WhiteSpaceValidator]],
      moduleType: ['Web', [Validators.required, WhiteSpaceValidator]]
    });
    this.moduleFormupdate = this.formBuilder.group({
      moduleName: ['', [Validators.required, WhiteSpaceValidator]],
      moduleType:['']
    });
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.getCreatedModules();
    this.paginator.pageIndex = 0; // Initialize the paginator's page index
    this.paginator.pageSize = 10;
    
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/superadmin-login"]);
    });
    return;
  }
  onListSelect(event: any) {
    const selectedOption = (event.target as HTMLSelectElement).value;//gold  silver 
    console.log(selectedOption);
    this.moduletypeSelection=selectedOption;

    
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.moduleCreatedList.filter = filterValue.trim().toLowerCase();
    if (this.moduleCreatedList.paginator) {
      this.moduleCreatedList.paginator.firstPage();
    }
  }

  hide() {
    this.moduleSubmit = false;
    this.addModuleForm = false;
    this.isModalOpen = false;
    // this.moduleForm.reset();
    $("#newModuleCreate").modal("hide");
    $("#newModuleEdit").modal("hide")
  }

  openAddModule() {
    this.moduleForm = this.formBuilder.group({
      moduleName: ['', [Validators.required, WhiteSpaceValidator]],
      uniqueCode: ['', [Validators.required, WhiteSpaceValidator]],
      moduleType: ['Web', [Validators.required, WhiteSpaceValidator]]
    });
    this.addModuleForm = true
    $("#newModuleCreate").modal("show");
  }
// Get the Data From Module Form
  get memberControl() {
    return this.moduleForm.controls;
  }
  // Get The Data From Module Update Form
  get updateControl(){
    return this.moduleFormupdate.controls;
  }


  getModuleListArray(moduleList: moduleListResponse[]) {
    return moduleList.map(module => ({
      moduleId: module.moduleId,
      moduleName: module.moduleName,
      moduleType: module.moduleType,
      uniqueCode: module.uniqueCode

    }));
  }

  //Create modules
  createModule() {
    this.moduleSubmit = true;
    if (this.moduleForm.invalid) {
      return;
    }
    this.moduleSubmit = false;    
    let params = {
      "moduleName": this.moduleForm.get('moduleName').value.trim(),
      "uniqueCode": this.moduleForm.get('uniqueCode').value.trim(),
      "moduleType": this.moduleForm.get('moduleType').value.trim()
    }
    console.log(params);
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    
    this.spinnerService.show();
    this.memberService
      .postCreateModule(params, token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {

          console.log(data);
          console.log(this.moduleForm.get('moduleName').value.trim());
          console.log(this.moduleForm.get('uniqueCode').value);
          console.log(this.moduleForm.get('moduleType').value);


          if (data.statusCode === 200) {
            this.moduleForm.reset();
            this.spinnerService.hide();
            $("#newModuleCreate").modal("hide");
            this.addModuleForm = false;
            Swal.fire({
              icon: "success",
              title: "Inserted Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;
          }
          else  {

            this.spinnerService.hide();
            // this.addModuleForm = false;
            Swal.fire({
              icon: "warning",
              title: data.statusMessage,
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;

          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }


  //List modules
  getCreatedModules() {
    this.spinnerService.show();
    this.memberService.getCreatedModulesList(this.token).subscribe(
      (data: { moduleList: moduleListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        // this.ModuleList = data;
        // this.checkedModuleIds = this.ModuleList.moduleList
        //   .filter(module => module.isActive)
        //   .map(module => module.moduleId);
        // console.log(this.subscriptionList)


        this.subscriptionList = data;
        this.totalCount = this.subscriptionList.length;
        let userData = this.getModuleListArray(data.moduleList);
        this.moduleList = userData;
        console.log(this.users);
        console.log(this.totalCount);

        this.moduleCreatedList = new MatTableDataSource(userData);
        this.moduleCreatedList.paginator = this.paginator;
        this.moduleCreatedList.sort = this.sort;
      
        

      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  //Edit modules
  openEditModule(row: any) {
    this.moduleFormupdate = this.formBuilder.group({
      moduleName: [row.moduleName, [Validators.required, WhiteSpaceValidator]],
      moduleType:[row.moduleType]
    });

    this.editModuleName = row.moduleName.trim();
    this.editModuleId = row.moduleId;
    console.log(this.editModuleName);
    console.log(this.editModuleId);
    this.addModuleForm = true
    $("#newModuleEdit").modal("show");
  }

  editModules() {
   
    this.moduleSubmit = true;
    if (this.moduleFormupdate.invalid) {
      console.log(this.editModuleId);
      return;
    }
    this.moduleSubmit = false;
    let params = {
      // "moduleId": this.moduleForm.get('moduleId').value,
      "moduleId": this.editModuleId,
      "moduleName": this.moduleFormupdate.get('moduleName').value,
      "moduleType":this.moduleFormupdate.get('moduleType').value
      // .moduleForm.get('moduleName').value
    }
    console.log(params);
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();
    this.memberService
      .postEditModule(params, token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {

          console.log(this.editModuleId);
          console.log(this.moduleForm.get('moduleName').value);

          if (data.statusCode === 200) {
            this.moduleForm.reset();
            this.spinnerService.hide();
            $("#newModuleEdit").modal("hide");
            this.addModuleForm = false;
            Swal.fire({
              icon: "success",
              title: "Updated Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;
          }
          else  {

            this.spinnerService.hide();
            // this.addModuleForm = false;
            Swal.fire({
              icon: "warning",
              title: "Module Already Exists",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;

          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  //Delete modules
  deleteModules(moduleValue: any) {    
    Swal.fire({
      title: "Delete module",
      text: "Are you sure? Do you want to delete this module?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        // $("#progressModel").modal("show");
        this.spinnerService.show()
        this.memberService
          // .postDeleteModule({ moduleId: moduleValue.id, moduleName: moduleValue.name}, this.token)
          .postDeleteModule({ moduleId: moduleValue.moduleId, moduleName: moduleValue.moduleName}, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {
              if (data.statusCode === 200) {
                this.moduleList = this.moduleList.filter(
                  user => user.moduleId !== moduleValue.moduleId
                );
                this.moduleCreatedList = new MatTableDataSource(this.moduleList);
                this.moduleCreatedList.paginator = this.paginator;
                this.moduleCreatedList.sort = this.sort;
                // $("#progressModel").modal("hide");
                this.spinnerService.hide()
                this.filter = ''
                Swal.fire({
                  title: "Deleted!",
                  text: "Module is deleted now",
                  icon: "success",
                  showCloseButton: true,
                  allowOutsideClick: false
                });
              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
      }
    });
  }  

  // Disable And Blur the Submit button When Inputs Are Not Filled 
  checkAllFieldsEntered(): boolean {
    return Object.keys(this.moduleForm.controls).every(key => {
    const control = this.moduleForm.get(key);
    return control && control.value !== '' && control.value !== null;
});
}
checkAllFieldsEnteredUp(): boolean {
  return Object.keys(this.moduleFormupdate.controls).every(key => {
  const control = this.moduleFormupdate.get(key);
  return control && control.value !== '' && control.value !== null;
});
}

  // getModules(subscriptionId) {
  //   this.spinnerService.show();
  //   let params = {
  //     "subscriptionId": subscriptionId,
  //   }
  //   this.memberService.getModuleList(params, this.token).subscribe(
  //     (data: { subscriptionTypeList: userModuleListResponse[] }) => {
  //       setTimeout(() => {
  //         this.spinnerService.hide();
  //       }, 800)
  //       this.ModuleList = data;
  //       this.checkedModuleIds = this.ModuleList.moduleList
  //         .filter(module => module.isActive)
  //         .map(module => module.moduleId);
  //       console.log(this.subscriptionList)

  //     },
  //     err => {
  //       this.spinnerService.hide();
  //       if (err.status === 401) {
  //         this.spinnerService.hide();
  //         this.unauthorizedException();
  //       }
  //     }
  //   );
  // }


}
