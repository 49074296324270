<nav class="navbar fixed-top navbar-expand-lg navbar-expand-sm justify-content-between header-bar">
  <div>
    <a
                                href="#"
                                data-bs-target="#sidebar"
                                data-bs-toggle="collapse"
                                class="border-0 p-1 text-decoration-none tmz-logo"
                            >
    <!-- <span *ngIf="isUserAvailable" (click)="toggleNav()"> -->
      <img *ngIf="!navToggle" [src]="'assets/img/menu-tab.png'" class="menu-icon" />
      <img *ngIf="navToggle" [src]="'assets/img/menu-tab.png'" class="menu-icon" />
    <!-- </span> -->
    </a>
    
    <span *ngIf="!isUserAvailable" class="ml-2"><img style="width: 80px;" [src]="profileimage" /></span>
    <span class="ml-2 tmz-text">{{organizationname}}</span>
    <!-- {{ isUserAvailable ? "TMZ" : "" }} -->
  </div>
  <div>

    <div class="page-header pt-0">
      <h4>
        DNXconnECT
          <img src="assets/img/line.png" *ngIf="this.UserDtls ==1" >
          <span class="mbl-block" *ngIf="this.UserDtls ==1">
          <span>
              Welcome back,
              <span class="span-name">{{organizationname +" "+"Admin"}} </span>
          </span>
          <span class="lighten">☀️</span>
        </span>
    

    <!-- <span *ngIf="isUserAvailable && activeTab === '/dashboard'">
      Dashboard
    </span> -->

    <!-- <span *ngIf="isUserAvailable && activeTab === '/members'">
       - Members
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/pagenotfoun'">
       - Members
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/organization'">
       - Organizations
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/digital-id-card'">
       - Organization-digital-Id_Card
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/OrganizationSmtp'">
       - Organization-Smtp
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/event'">
      - Events
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/connect-members'">
      - Blogs
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/rolemanage'">
      User Role Managment
    </span>

    <span *ngIf="activeTab === '**'">
      - User Role Managment
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/video-upload'">
      - Video Upload
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/manageSubscription'">
      - Subscription Management
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/manageusertype'">
      - User Type
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/terms'">
      - Rules and Guidelines
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/settings'">
      - Settings
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/connect-members'">
      - Add Members
    </span>


    <span *ngIf="isUserAvailable && activeTab === '/coupon-discounts'">
      - Discount Coupons
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/request-list'">
      - Member Connection Request
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/annouce'">
      - Announcement
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/modulemanage'">
      - Module Management
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/member-subscription'">
      - Member Subscriptionsss
    </span>

    <span *ngIf="isUserAvailable && activeTab === '/help'">
      - Help
    </span> -->

    <!-- <span *ngIf="isUserAvailable && activeTab === '/member-subscription'">
      Member Subscriptions
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/updates'">
      Updates
    </span>
    <span *ngIf="isUserAvailable && activeTab === '/update-comments'">
      Update Comments
    </span> -->
  </h4>
  </div>
  </div>

  <div class="header-last-col" style="display: flex;
      flex-direction: row;justify-content: end; align-items: center;">
      <!-- <div class="searchBlock">
        <form>
            <div class="input-group">
                <img class="input-group-text" src="assets/img/Search.png" alt="" />
                <input type="text" class="form-control" placeholder="Search by" />
            </div>
        </form>
      </div> -->

      <div *ngIf="isUserAvailable" class="user-action ml-0">
        <img id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="user-img"
          src="assets/img/setting.png" />
  
        <div class="dropdown-menu dropdown-menu-right setting-dropdown" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" routerLink="/admin-profile" *ngIf="this.UserDtls ==1">Admin Profile</a>
          <a class="dropdown-item" routerLink="/reset-password">Reset Password</a>
          <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
        </div>
      </div>

      <!-- <div *ngIf="isUserAvailable" class="user-action user-notify">
        <img id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="user-img"
          src="assets/img/mail.png" />
      </div> -->

    <div *ngIf="isUserAvailable &&  this.UserDtls ==1"  class="user-action user-notify"  >

      <!-- <img data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="user-img1"
        [src]="this.result?.unviewedMessageCount === 0 ? 'assets/img/notification.png' : 'assets/img/notification-dot.png'" /> -->

      <div class="notification-container" data-toggle="dropdown">
        <img aria-haspopup="true" aria-expanded="false"  *ngIf="this.UserDtls ==1" class="user-img1" [src]="'assets/img/notification-new.png'"  (click)="chatHistory()" />
        <div class="notification-badge" *ngIf="this.result?.unviewedMessageCount >0">
          <span>{{ this.result?.unviewedMessageCount >0 ?this.result?.unviewedMessageCount:'' }}</span>
        </div>
      </div>

      <div class="dropdown-menu dropdown-menu-right custom-chat" style="margin-right: 10px;" aria-labelledby="chatList">
        <div class="container">
          <div class="centercontent" *ngIf="chatList==0" style="display:flex;justify-content: center; align-items: center;width: inherit;height: inherit;padding-top:10px ;">
            <p>no chat records found</p>
          </div>
          <div>
            
            <div class="item custom-item" *ngFor="let item of chatList" (click)="openFullChat(item)">
              
              <div class="left-content">
                <div class="conversationHeader">
                  <div class="name">{{ item.memberName }}</div>
                  <div class="time">{{ item.chatLastText | slice: 0:20 }}{{ item.chatLastText.length > 50 ? '...' : ''
                    }}</div>
                </div>
              </div>
              <div class="right-content">
                <div class="dot-container" *ngIf="item.isHighlighted == 1">
                  <div class="notify-count"> <span>{{ item.messageCount }}</span></div>
                </div>
                <div>
                  <span class="time">{{ item.chatTimeAgo }}</span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    
    
  </div>

  <!-- <div *ngIf="isUserAvailable" class="user-action">
    <img id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="user-img"
      src="assets/img/user.png" />
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" routerLink="/reset-password">Reset Password</a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
    </div>
  </div> -->
</nav>

<ngx-spinner
  bdColor="rgba(0, 0, 0, .6)"
  class="spin-img"
  template="<img src='assets/img/logo-D.gif' />"
>
</ngx-spinner>