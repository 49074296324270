<div class="container custom-container">
    <div class="row">
      <div class="col-auto p-0">
        <app-sidenav></app-sidenav>
      </div>
      <div class="col p-0">

<div class="p-0 mt-0" style="background-color: #fff;width: 100%;height: 100%;">
    <app-header></app-header>
    <div class="main-block">
<div class="p-0 mt-0" style="width: 100%;height: 100%;">
    <div class="d-flex justify-content-between wrap-block create-col">
        <div class="mt-2 create-btn">

            <ng-container>
                <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;"
                    (click)="openAddModule()">
                    Add Module
                </button>
            </ng-container>
        </div>
        <div class="search-field-col" style="margin-right: 95px">
            <mat-form-field class="search-block">
                <mat-label>Search</mat-label>
                <input class="search-block-input" matInput (keyup)="applyFilter($event)" [(ngModel)]="filter" placeholder="Type to Search" />
            </mat-form-field>
        </div>
    </div>

    <!-- Module list -->
    <div class="mat-elevation-z8 table-container">
        <table mat-table [dataSource]="moduleCreatedList" matSort class="module-table">
            <ng-container matColumnDef="uniqueCode">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Unique Code
                </th>
                <td class="mat-table-cell" mat-cell mat-cell *matCellDef="let row">
                    {{row.uniqueCode}}
                </td>
            </ng-container>
            <!-- <ng-container matColumnDef="id">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                    Module Id
                </th>
                <td class="mat-table-cell" mat-cell *matCellDef="let row">
                    {{ row.moduleId }}
                </td>
            </ng-container> -->

            <ng-container matColumnDef="name">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Modules
                </th>
                <td class="mat-table-cell" mat-cell mat-cell *matCellDef="let row">
                    {{ row.moduleName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="modelType">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef >
                    Model Type
                </th>
                <td lass="mat-table-cell" mat-cell mat-cell *matCellDef="let row">
                    {{ row.moduleType }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                    Actions
                </th>
                <td class="mat-table-cell" mat-cell *matCellDef="let row" style="width:11%;">
                    <!-- <mat-icon class="mr-2" matTooltip="Edit" (click)="openEditModule(row)" -->
                    <!-- <mat-icon class="mr-2" matTooltip="Edit" (click)="openEditModule(row)"
                        style="cursor: pointer;">edit</mat-icon> -->
                        <div class="edit-icon" matTooltip="Edit" (click)="openEditModule(row)"
                        style="cursor: pointer;">
                            <img src="assets/img/view.png"style="cursor: pointer;"/>
                        </div>
                    <!-- <mat-icon class="mr-2" matTooltip="Delete" (click)="deleteModules(row)"
                        style="cursor: pointer;">delete</mat-icon> -->
                </td>
            </ng-container>


            <!-- Pageinator -->
            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons [length]="15"
            [(pageIndex)]="1" [(pageSize)]="5">
            </mat-paginator> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumnsOne"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsOne"></tr>

        </table>



        <div *ngIf="this.users?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
            <p>No data to display</p>
        </div>
        <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons length="300"></mat-paginator> -->
        <mat-paginator [pageSizeOptions]="[5,10, 25, 100]" showFirstLastButtons length="10" pageSize="1" pageIndex="10">
        </mat-paginator>
        <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator> -->


    </div>




    <!-- Create Module -->
    <div class="modal fade" id="newModuleCreate" role="dialog" aria-labelledby="staticBackdropLabel"
        style="margin-top: 50px;">

        <div class="modal-dialog">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title"> Add Module</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                        <!-- <img src="assets/img/modal-close.png" /> -->
                        <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                    </button>
                </div>

                <div *ngIf="addModuleForm">

                    <form [formGroup]="moduleForm" (ngSubmit)="createModule()">
                        <div style="margin: 0px;" class="form form-wrap">
                            <!-- <mat-form-field style="width: 100%;margin: 0;"> -->
                            <div class="form-group mb-3">
                                <label for="firstName">Module<span style="color: red;"> *</span> </label>
                                <input class="form-control signup-input" formControlName="moduleName"
                                    placeholder="Module" matInput />
                                <!-- </mat-form-field>                            -->
                                <div *ngIf="moduleSubmit && memberControl.moduleName.errors" class="ml-2">
                                    <div *ngIf="memberControl.moduleName.errors.required" class="text-danger">
                                        Module Name required
                                    </div>
                                    <div *ngIf="memberControl.moduleName.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.moduleType.errors.noWhiteSpace }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <!-- <mat-form-field style="width: 100%;"> -->
                                <label for="firstName">Unique code<span style="color: red;"> *</span> </label>
                                <input class="form-control signup-input" formControlName="uniqueCode"
                                    placeholder="Unique Code" matInput  />
                                <!-- </mat-form-field> -->
                                <div *ngIf="moduleSubmit && memberControl.uniqueCode.errors" class="ml-2">
                                    <div *ngIf="memberControl.uniqueCode.errors.required" class="text-danger">
                                        Unique Code required
                                    </div>
                                    <div *ngIf="memberControl.uniqueCode.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.moduleType.errors.noWhiteSpace }}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <!-- <mat-form-field style="width: 100%;">   -->
                                <label for="thirdName">Module type<span style="color: red;"> *</span> </label>
                                <select id="mailServer" class="form-control signup-input" formControlName="moduleType"
                                    (click)="onListSelect($event)">
                                    <option *ngFor="let data of selectionType" [value]="data">{{ data }}</option>
                                </select>
                            </div>
                            <!-- </mat-form-field> -->


                            <!-- <div *ngIf="moduleSubmit && (memberControl.moduleName.errors || memberControl.uniqueCode.errors || memberControl.moduleType.errors)" class="ml-2">

                                <div *ngIf="memberControl.moduleName.errors">
                                    <div *ngIf="memberControl.moduleName.errors.required" class="text-danger">
                                        ModuleName required
                                    </div>
                                    <div *ngIf="memberControl.moduleName.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.moduleName.errors.noWhiteSpace }}
                                    </div>
                                </div>

                                <div *ngIf="memberControl.uniqueCode.errors">
                                    <div *ngIf="memberControl.uniqueCode.errors.required" class="text-danger">
                                        Unique code required
                                    </div>
                                    <div *ngIf="memberControl.uniqueCode.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.uniqueCode.errors.noWhiteSpace }}
                                    </div>
                                </div>

                                <div *ngIf="memberControl.moduleType.errors">
                                    <div *ngIf="memberControl.moduleType.errors.required" class="text-danger">
                                        Module type required
                                    </div>
                                    <div *ngIf="memberControl.moduleType.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.uniqueCode.errors.noWhiteSpace }}
                                    </div>
                                </div>                                                          -->


                            <!-- </div> -->

                            <!-- <mat-form-field style="width: 100%;">
                                <mat-label>Select an option</mat-label>
                                <mat-select [(ngModel)]="selectedOption">
                                  <mat-option *ngFor="let option of options" [value]="option.value">
                                    {{ option.viewValue }}
                                  </mat-option>
                                </mat-select>                       
                                <input [(ngModel)]="selectedOption"  formControlName="moduleType" matInput/>  
                            </mat-form-field> -->




                        </div>

                        <div class="footer-modal" style="display: flex ;justify-content: flex-end;">

                            <div>
                                <button class="sync-btn" [ngStyle]="{ 'opacity': checkAllFieldsEntered() ? 1 : 0.5 }"
                                    [disabled]="!checkAllFieldsEntered()"
                                    style="background-color: #4C3A67;">Submit</button>
                            </div>
                        </div>

                    </form>

                </div>

            </div>
        </div>

    </div>

    <!-- Edit Module -->
    <div class="modal fade" id="newModuleEdit" role="dialog" aria-labelledby="staticBackdropLabel"
        style="margin-top: 50px;">

        <div class="modal-dialog">

            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title">Edit Module</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                        <!-- <img src="assets/img/modal-close.png" /> -->
                        <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                    </button>
                </div>

                <div *ngIf="addModuleForm">

                    <form [formGroup]="moduleFormupdate" (ngSubmit)="editModules()">
                        <div style="margin: 0px;" class="form form-wrap">
                            <div class="form-group mb-3">
                                <label for="firstName">Module<span style="color: red;"> *</span> </label>
                                <!-- <mat-form-field style="width: 100%;"> -->
                                <!-- <input formControlName="moduleName" [(ngModel)]="editModuleName" matInput /> -->
                                <input class="form-control signup-input" formControlName="moduleName" />
                                <!-- </mat-form-field> -->
                                <div *ngIf="moduleSubmit && updateControl.moduleName.errors" class="ml-2">
                                    <div *ngIf="updateControl.moduleName.errors.required" class="text-danger">
                                        ModuleName required
                                    </div>
                                    <div *ngIf="updateControl.moduleName.errors.noWhiteSpace" class="text-danger">
                                        {{ memberControl.moduleName.errors.noWhiteSpace }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="">Module Type<span style="color: red;"> *</span> </label>
                                <select id="mailServer" class="form-control signup-input" formControlName="moduleType"
                                    (click)="onListSelect($event)">
                                    <option *ngFor="let data of selectionType" [value]="data">{{ data }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="footer-modal" style="display: flex ;justify-content: flex-end;">
                            <div>
                                <button class="sync-btn" [ngStyle]="{ 'opacity': checkAllFieldsEnteredUp() ? 1 : 0.5 }"
                                [disabled]="!checkAllFieldsEnteredUp()">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
</div>
</div>
    </div>
    


    <ngx-spinner
    bdColor="rgba(0, 0, 0, .6)"
    class="spin-img"
    template="<img src='assets/img/logo-D.gif' />"
    >
    </ngx-spinner>