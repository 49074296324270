import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MemberService } from "../shared/services/member.service";
import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

declare var $: any;

export interface DiscountData {
  id: string;
  name: string;
  amountOff: Number;
  percentOff: Number;
  duration: string;
  durationInMonths: Number;
  redemDate: string;
  maxRedemptions: Number;
  status: string;
  subscription: string;
}

@Component({
  selector: "app-coupon-discount",
  templateUrl: "./coupon-discount.component.html",
  styleUrls: ["./coupon-discount.component.css"]
})
export class CouponDiscountComponent implements OnInit {
  isLoading = false;
  couponForm = false;
  discountCouponForm: FormGroup;
  submitted = false;

  displayedColumns: string[] = [
    "subscription",
    "name",
    "id",
    "discount",
    "duration",
    "durationInMonths",
    "redemDate",
    "maxRedemptions",
    "status",
    "actions"
  ];
  discounts: DiscountData[];
  dataSource: MatTableDataSource<DiscountData>;

  constructor(
    private formBuilder: FormBuilder,
    private memberService: MemberService,
    private router: Router
  ) {}

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.getDiscountCoupons();
  }

  getDiscountCoupons() {
    this.memberService.getCouponDiscountList().subscribe(
      (data: any) => {
        if (data.statusCode === 200) {
          let discountData = this.getDiscountListArray(data.couponList);
          this.discounts = discountData;
          this.dataSource = new MatTableDataSource(discountData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  getDiscountListArray(data: any) {
    return data.map(d => ({
      id: d.id,
      name: d.name,
      amountOff: d.amountOff,
      percentOff: d.percentOff,
      duration: d.duration,
      durationInMonths: d.durationInMonths,
      redemDate: this.GetFormattedDate(d.durationInMonths),
      maxRedemptions: d.maxRedemptions,
      status: d.valid ? "Valid" : "Invalid",
      subscription: d.amountType
    }));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  GetFormattedDate(date: any) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    return month + "-" + day + "-" + year;
  }

  getAppendValue = value => {
    if (value <= 9) {
      return `0${value}`;
    }
    return value;
  };

  openDiscountCoupon() {
    $("#discountCouponCreate").modal("show");
    this.couponForm = true;
    this.discountCouponForm = this.formBuilder.group(
      {
        subscrb: ["yearly", [Validators.required]],
        name: ["", [Validators.required, WhiteSpaceValidator]],
        id: ["", [Validators.required, WhiteSpaceValidator]],
        type: ["percent"],
        discount: ["", [Validators.required]],
        duration: ["once", [Validators.required]],
        durationInMonths: [1, [Validators.required]],
        redeemBy: ["", [Validators.required]],
        maxRedemptions: ["", [Validators.required]]
      },
      {
        validator: [
          this.dateValidator("redeemBy"),
          this.discountAmountValidator("discount", "type", "subscrb")
        ]
      }
    );
  }

  dateValidator(date: any) {
    return (formGroup: FormGroup) => {
      const redeemBy = formGroup.controls[date];
      const newDate = new Date();
      if (redeemBy.errors && !redeemBy.errors.dateValidator) {
        return;
      }
      if (
        newDate > new Date(redeemBy.value) ||
        new Date(redeemBy.value) === newDate
      ) {
        redeemBy.setErrors({ dateValidator: true });
      } else {
        redeemBy.setErrors(null);
      }
    };
  }

  discountAmountValidator(param1: any, param2: any, param3: any) {
    return (formGroup: FormGroup) => {
      const discount = formGroup.controls[param1];
      const type = formGroup.controls[param2];
      const subscrb = formGroup.controls[param3];
      if (type.value === "percent") {
        discount.setErrors(null);
        if (discount.errors && !discount.errors.percentValidator) {
          return;
        }
        if (discount.value > 100) {
          discount.setErrors({ percentValidator: true });
        } else {
          discount.setErrors(null);
        }
      } else if (type.value === "amount") {
        discount.setErrors(null);
        if (discount.errors && !discount.errors.amountValidator) {
          return;
        }
        if (subscrb.value === "yearly" && discount.value > 1000) {
          discount.setErrors({ amountValidator: true });
        } else if (subscrb.value === "monthly" && discount.value > 100) {
          discount.setErrors({ amountValidator: true });
        } else {
          discount.setErrors(null);
        }
      }
    };
  }

  // convenience getter for easy access to experience form fields
  get discountCouponFormControls() {
    return this.discountCouponForm.controls;
  }

  discountCouponFormSubmit() {
    this.submitted = true;

    if (this.discountCouponForm.invalid) {
      return;
    }
    this.submitted = false;
    let params = {
      Id: this.discountCouponForm.value.id,
      amountOff:
        this.discountCouponForm.value.type === "amount"
          ? this.discountCouponForm.value.discount
          : null,
      Currency: "USD",
      percentOff:
        this.discountCouponForm.value.type === "percent"
          ? this.discountCouponForm.value.discount
          : null,
      Duration: this.discountCouponForm.value.duration,
      durationInMonths:
        this.discountCouponForm.value.duration === "repeating"
          ? this.discountCouponForm.value.durationInMonths
          : null,
      maxRedemptions: this.discountCouponForm.value.maxRedemptions,
      Name: this.discountCouponForm.value.name,
      redeemBy: this.getFormattedDateTime(
        this.discountCouponForm.value.redeemBy
      ),
      couponType: this.discountCouponForm.value.type,
      amountType: this.discountCouponForm.value.subscrb
    };
    this.isLoading = true;
    this.memberService.discountCouponCreate(params).subscribe(
      (data: any) => {
        if (data.statusCode === 200) {
          this.isLoading = false;
          let newData = {
            id: data.coupon.id,
            name: data.coupon.name,
            amountOff: data.coupon.amount_off,
            percentOff: data.coupon.percent_off,
            duration: data.coupon.duration,
            durationInMonths: data.coupon.duration_in_months,
            redemDate: this.GetFormattedDate(
              parseInt(data.coupon.redeem_by.toString() + "000", 10)
            ),
            maxRedemptions: data.coupon.max_redemptions,
            status: data.coupon.valid ? "Valid" : "Invalid",
            subscription: params.amountType
          };
          let discountData = [newData].concat(this.discounts);
          this.discounts = discountData;
          this.dataSource = new MatTableDataSource(discountData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          $("#discountCouponCreate").modal("hide");
          this.couponForm = false;
        }
        if (data.statusCode === 400) {
          this.isLoading = false;
          Swal.fire({
            icon: "warning",
            title: data.statusMessage,
            allowOutsideClick: false,
            showCloseButton: true
          });
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  getFormattedDateTime(date: any) {
    var newDate = new Date(date);
    var month = this.getAppendValue(newDate.getMonth() + 1);
    var day = this.getAppendValue(newDate.getDate());
    var year = newDate.getFullYear();
    var hours = this.getAppendValue(newDate.getHours());
    var mins = this.getAppendValue(newDate.getMinutes());
    var amPm = hours >= 12 ? "PM" : "AM";
    return (
      month +
      "/" +
      day +
      "/" +
      year +
      " " +
      hours +
      ":" +
      mins +
      ":" +
      "00" +
      " " +
      amPm
    );
  }

  durationChange() {
    this.discountCouponForm.controls["durationInMonths"].setValue(1);
  }

  hide() {
    $("#discountCouponCreate").modal("hide");
    this.couponForm = false;
  }

  deleteCoupon(row: any) {
    Swal.fire({
      title: "Delete Coupon",
      text: "Are you sure? Do you want to delete coupon?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        $("#discountProgressModel").modal("show");
        this.memberService.deleteCoupon(row.id).subscribe(
          (data: { statusCode: Number; statusMessage: string }) => {
            if (data.statusCode === 200) {
              $("#discountProgressModel").modal("hide");
              this.discounts = this.discounts.filter(d => d.id !== row.id);
              this.dataSource = new MatTableDataSource(this.discounts);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              Swal.fire({
                title: "Deleted!",
                text: "Coupon is deleted now",
                icon: "success",
                showCloseButton: true,
                allowOutsideClick: false
              });
            }
          },
          err => {
            if (err.status === 401) {
              this.unauthorizedException();
            }
          }
        );
      }
    });
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }
}
