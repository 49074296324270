import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { userModuleListResponse, userSubscriptionListResponse } from '../shared/models/user';

@Component({
  selector: 'app-buysubscription',
  templateUrl: './buysubscription.component.html',
  styleUrls: ['./buysubscription.component.css']
})
export class BuysubscriptionComponent implements OnInit {
  token: string;
  Subscription:Number;
  subscriptionList: any;
  currentsub:number=0;
  currentSubscription:boolean=false;
  usertypeList = [
    {
      usertypeId: 1,
      usertype: 'Free',
      roleId: 3,
      orderById: null,
      moduleList: [
        { module_id: 20, module_name: 'Forms And Application', order_by_id: 1 },
        { module_id: 8, module_name: 'Help Desk', order_by_id: 3 },
        { module_id: 4, module_name: 'Announcement', order_by_id: 4 },
        { module_id: 21, module_name: 'Profile', order_by_id: 0 }
      ],
      isMemberMapped: true
    },
    {
      usertypeId: 2,
      usertype: 'Silver',
      roleId: 3,
      orderById: null,
      moduleList: [
        { module_id: 4, module_name: 'Announcement', order_by_id: 1 },
        { module_id: 8, module_name: 'Help Desk', order_by_id: 2 },
        { module_id: 17, module_name: 'Chat 24 Hours', order_by_id: 3 },
        { module_id: 20, module_name: 'Forms And Application', order_by_id: 4 }
      ],
      isMemberMapped: true
    },
    {
      usertypeId: 3,
      usertype: 'Gold',
      roleId: 3,
      orderById: null,
      moduleList: [
        { module_id: 4, module_name: 'Announcement', order_by_id: 1 },
        { module_id: 8, module_name: 'Help Desk', order_by_id: 4 },
        { module_id: 18, module_name: 'Chat 8 A.M To 6 P.M', order_by_id: 3 },
        { module_id: 20, module_name: 'Forms And Application', order_by_id: 2 }
      ],
      isMemberMapped: true
    }
  ];
  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService) {

    

  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.getsubscription();
    // this.getRoles();
  }
  getsubscription(){
    this.memberService.getSubscription(this.token).subscribe(
      (data: any) => {
        console.log('data',data);
        this.currentsub=data.subscriptionId;
        console.log(this.currentsub);
        this.getRoles();
      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          
        }
      }
    );

  }

  
  getRoles() {
    
    console.log('Subscription',this.currentsub);
    console.log("called")
    this.spinnerService.show();
    this.memberService.getSubscriptionTypes(this.token).subscribe(
      (data: { subscriptionTypeList: userSubscriptionListResponse[] }) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
      this.subscriptionList = data.subscriptionTypeList;
      console.log(this.subscriptionList);
      this.subscriptionList=this.subscriptionList.filter((value,key)=>value.subscriptionId>=this.currentsub);
      this.spinnerService.hide();
       
      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          
        }
      }
    );
    
  }
  postAdminSubscriptionUpdate(subscriptionId: number, isTrue: boolean) {
    if (isTrue) {
      return;
    }
  
    // Show confirmation dialog
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the subscription?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel!",
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with the API call
        this.spinnerService.show();
        let params = {
          "subscriptionId": subscriptionId
        };
        console.log('params', params);
  
        this.memberService.postAdminSubscription(params, this.token).subscribe(
          (data: any) => {
            if (data.statusCode == 200) {
              this.currentSubscription = true;
              console.log(data.statusCode);
              this.Subscription = data.subscriptionId;
              this.getsubscription();
              // this.spinnerService.hide();
              Swal.fire({
                title: "Subscription Updated Successfully",
                text: "News Created Successfully",
                icon: "success",
                showCloseButton: true,
                allowOutsideClick: false
              });
            }
          },
          err => {
            this.spinnerService.hide();
            if (err.status === 401) {
              this.spinnerService.hide();
            }
          }
        );
      } else {
        // User clicked "No", do nothing
        console.log('Subscription update cancelled.');
      }
    });
  }
  

}
