// shared.service.ts

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private apiResponseTriggered = new Subject<void>();

  apiResponseTriggered$ = this.apiResponseTriggered.asObservable();

  triggerApiResponse() {
    this.apiResponseTriggered.next();
  }
}
