import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  // transform(value: any, args: any): unknown {
  //   console.log("t", value, args)
  //   //if()
  //   if (args) {
  //     let filter = args.toLowerCase();
  //     return value.filter((option) =>
  //       option.memberName.toLowerCase().startsWith(filter)
  //     );
  //   } else {
  //     return value;
  //   }
  //   ;
  // }

  transform(list: any[], filterText: string): any {
    // console.log("return");
    return list ? list.filter(item => item.memberName.search(new RegExp(filterText, 'i')) > -1) : [];
  }



  // transform(list: any[], filterText: string): any {
  //   return list ? list.filter(item => item.name.search(new RegExp(filterText, 'i')) > -1) : [];
  // }
}
