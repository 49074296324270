import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
// Local Imports

import { WhiteSpaceValidator } from "../shared/validators/white-space-validator";

import { NgxSpinnerService } from "ngx-spinner";
import { animate, state, style, transition, trigger } from "@angular/animations";

import { eventService } from "../shared/services/event.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { isBoolean } from "util";
'@angular/core';
declare var $: any;
declare const Pusher: any;
interface EventData {
  imageUrl: string;
  title: string;
  link: string;
  content: string;
  creator: string;
  created_date: string;
}

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  displayedColumns: string[] = ['imageUrl', 'title', 'link', 'content', 'creator', 'created_date', 'actions'];
  dataSource: MatTableDataSource<EventData>;
  filtercount:any;
  eventForm: FormGroup;
  eventFormOpen:boolean= false;
  eventSubmitted = false;
  token:string;
  formHeading:string;
  visible:boolean
  selectedFile:any=null;
  isLoading = false;
  currentPage:number=0;
  pageSize:number=10;
  Search:string="";
  eventList:[];
  checkFile:File=null;
  isfalse:boolean=true;
  totalcount:any;
  spam:any;
  imageUrl:any;
  display: string = 'none'; 
  updateVisible:boolean;
  logo_url_name:string;
  logo_url:string;
  eventId:any;
  Default:false;
  formButton:string;
  pageShift:boolean=false;
  logoVisible:boolean=false;
  isspam=['True','False'];
  options = [
    { value: 1, label: 'True' },
    { value: 0, label: 'False' }
  ];
  validTypes=  ['image/jpeg', 'image/png', 'image/gif'];
  customvalidator:boolean=false;
  selectedValue: number = this.options[0].value; 
  constructor(
    private eventService: eventService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
  ) {
    
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    console.log('token' , this.token);
    this.isLoading=true;
    this.spinnerService.show();
    this.getEventList();
    this.isLoading=false;
   
  }
  onPaginatorClick(event: any): void {
    console.log('Paginator click event:', event);
    this.spinnerService.show();
    // Update currentPage and pageSize based on event
    this.currentPage = event.pageIndex; // If your API expects 1-based page index, otherwise use event.pageIndex
    console.log('currentpage', this.currentPage);
    this.pageSize = event.pageSize;
    // Logging currentPage for debugging
    console.log(this.currentPage);

    // Fetch updated data
    this.getEventList();
  }
  onChange(value: any) {
    this.selectedValue =value
    console.log('Selected value:', this.selectedValue);
    
    
  }
  getEventList(){
   if(this.pageShift)
    this.totalcount=this.filtercount
    let params=
      {
        "searchEvent": this.Search.trim(),
        "pageNo": this.currentPage+1,
        "pageSize": this.pageSize
      }
    
      this.eventService.EventList(params,this.token).subscribe((data: any) => {
        
      
      this.spinnerService.show();
      if (data.statusCode == 200) {
      this.dataSource=data.eventList;
      console.log(this.dataSource);
      this.eventList = data.eventList;
      
      this.totalcount =(this.pageShift) ? data.filteredCount:data.totalCount ;
     
      this.filtercount=data.filteredCount;
    
      console.log('totalcount',this.totalcount);
      this.spinnerService.hide();
    }
    else {
      this.ExceptionMessage(data.statusMessage, "warning");
      this.spinnerService.hide();
    }
  }, (err) => {
    // this.unauthorizedException() ;
    if (err.status === 401) {
      this.unauthorizedException();
      this.spinnerService.hide();
    }
  });
  }
  submitfilter(){
    this.spinnerService.show();
    this.currentPage=0;
    this.pageShift=true;
   
    
    this.getEventList();
  }
  filterEvent(event: any) {
    this.pageShift=false;
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue.length)
    if(this.pageShift==false){
      this.pageSize=10;
    }
    if (filterValue.length == 0)
      this.getEventList();
  }
 
  
  EventSubmit(){
   
    this.eventSubmitted = true;
    console.log(this.eventForm)
    if (this.eventForm.invalid) {
      // console.log("Hey");
      return;
    }
    this.eventSubmitted = false;
    this.isLoading = true;
    if(this.selectedFile!=null){
    if(! this.validTypes.includes(this.selectedFile.type)){
      this.customvalidator=true;
      console.log(this.customvalidator);
       return;
  
      }
      
    }
    this.spinnerService.show();
   
    // this.eventForm.get('isSpam')?.setValue(change);
    let params= new FormData();
    params.append('title',this.eventForm.get('title')?.value.trim())
    params.append('link',this.eventForm.get('link')?.value.trim())
    params.append('description',this.eventForm.get('description')?.value.trim())
    params.append('content',this.eventForm.get('description')?.value.trim())
    params.append('creator',this.eventForm.get('creator')?.value.trim())

    params.append('endDate',this.eventForm.get('endDate').value)
    params.append('publishedDate',this.eventForm.get('endDate').value)
    params.append('eventImage',this.selectedFile)
    // this.eventForm.value;

    this.eventService.postInsertEvent(params,this.token).subscribe(
      (data: any) => {
        console.log(data.statusCode);
        if (data.statusCode === 200) {
          // this.isLoading = false;
          this.eventFormOpen = false;
          this.spinnerService.hide();
          $("#neweventInsert").modal("hide");
          this.getEventList();
          
          Swal.fire({
            title: "Created!",
            text: "Event Created Successfully",
            icon: "success",
            showCloseButton: true,
            allowOutsideClick: false
          });
          
        } else {
          $("#neweventInsert").modal("hide");
          this.isLoading = false;
          Swal.fire({
            text: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
        else if(err.status === 403){
          this.unauthorizedException();
        }
      }
    );

  }
  onFileSelect(event: any) {

  this.selectedFile = event.target.files[0];
  if(this.eventSubmitted==true||this.validTypes.includes(event.target.files[0].type)){
    
    this.customvalidator= ! this.validTypes.includes(event.target.files[0].type);
    console.log( 'Custom Validator',this.customvalidator)
    
    }
  }
  hide(){
     this.isfalse=true;
     this.selectedFile = null;
    console.log(this.isfalse);
  }



  EventUpdateSubmit(){
    
    this.logoVisible=false;
    this.visible=false;
    this.eventSubmitted = true;
    console.log(this.eventForm)
    console.log(this.customvalidator)
    if (this.eventForm.invalid ) {
      console.log("Hey");
       return;
    }
    this.eventSubmitted = false;
    this.isLoading = true;
    if(this.selectedFile!=null){
    if(! this.validTypes.includes(this.selectedFile.type)){
     this.customvalidator=true;
      return;
     }
    }
    this.spinnerService.show();
    let params= new FormData();
    params.append('title',this.eventForm.get('title')?.value.trim())
    params.append('link',this.eventForm.get('link')?.value.trim())
    params.append('description',this.eventForm.get('description')?.value.trim())
    params.append('content',this.eventForm.get('description')?.value.trim())
    params.append('creator',this.eventForm.get('creator')?.value.trim())
  
    params.append('endDate',this.eventForm.get('endDate').value)
    params.append('publishedDate',this.eventForm.get('endDate').value)
    params.append('eventImage',this.selectedFile)
    // this.eventForm.value;
    params.append('eventId', this.eventId);
    // console.log(params)
    // params['eventId'] = this.eventId;
    
   
    this.eventService.postUpdateEvent(params,this.token).subscribe(
      (data: any) => {
        console.log(data.statusCode);
        if (data.statusCode === 200) {
          // this.isLoading = false;
          this.eventFormOpen = false;
          this.spinnerService.hide();
          $("#neweventInsert").modal("hide");
          this.getEventList();
          
          Swal.fire({
            title: "Updated!",
            text: "Event Updated Successfully",
            icon: "success",
            showCloseButton: true,
            allowOutsideClick: false
          });
          
        } else {
          $("#neweventInsert").modal("hide");
          this.isLoading = false;
          Swal.fire({
            text: data.statusMessage,
            icon: "warning",
            showCloseButton: true,
            allowOutsideClick: false
          });
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
          
        }
        else if(err.status === 403){
          this.unauthorizedException();
        }
      }
    );
   

  }
  createEventUpdate(data:any){
    this.customvalidator=false; 
    
    this.display="none";
    this.eventId=data.event_id;
    this.formButton="Update"
    this.visible=false;
    this.logoVisible=true;
    this.updateVisible=true;
    console.log("State",this.updateVisible);
    this.formHeading="Update Event"
    $("#neweventInsert").modal("show");
    this.eventFormOpen=true;

      this.eventForm = this.formBuilder.group({
      
      title: [data.title, [Validators.required, WhiteSpaceValidator]],
      link: [
        data.link,
        [Validators.required, Validators.pattern(/^(https?:\/\/)?([\w.-]+)(:[0-9]{1,5})?(\/[\w\/.-]*)*(\?[;&a-zA-Z0-9%_.+-]*)?(#[^\s]*)?$/), WhiteSpaceValidator]]
      ,
      description: [data.description, [Validators.required, WhiteSpaceValidator]],
      
      eventImage: [],
      creator: [data.creator, [Validators.required, WhiteSpaceValidator]],
      endDate:[this.getCurrentDateTimeFormatted(), [Validators.required, WhiteSpaceValidator]],
      publishedDate:[this.getCurrentDateTimeFormatted(), [Validators.required, WhiteSpaceValidator]],
   

  });
  this.logo_url=data.imageUrl
  let s=data.imageUrl.split("/");
  this.logo_url_name=s[s.length-1];

  }
  uploadImage(blobInfo, success, failure): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64Data = e.target.result;
      success(base64Data);
    };

    reader.onerror = (error) => {
      console.error('Error reading image file:', error);
      failure('Error reading image file');
    };

    reader.readAsDataURL(blobInfo.blob());
  }

  createEvent() {
    this.customvalidator=false; 
    this.display="inline";
    this.formButton="Add";
    this.visible=true;
    this.updateVisible=false;
    $("#neweventInsert").modal("show");
    this.formHeading="Create Event";
    this.eventFormOpen=true;
    this.eventForm = this.formBuilder.group({
      title: ["", [Validators.required, WhiteSpaceValidator]],
      link: [
        '',
        [Validators.required, Validators.pattern(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/), WhiteSpaceValidator]]
      ,
      description: ["", [Validators.required, WhiteSpaceValidator]],
      
      eventImage: ["", [Validators.required, WhiteSpaceValidator]],
      creator: ["", [Validators.required, WhiteSpaceValidator]],
      endDate:[this.getCurrentDateTimeFormatted(), [Validators.required, WhiteSpaceValidator]],
      publishedDate:[this.getCurrentDateTimeFormatted(), [Validators.required, WhiteSpaceValidator]],
     
  });

  }
  Htmlparser(data:string):string{
    var res=data.replace(/<[^>]*>/g, '');
    return res;
    
  }
  get eventControl() {
    return this.eventForm.controls;
  }

  checkAllFieldsEntered(): boolean {
    return Object.keys(this.eventForm.controls).every(key => {
      // Skip Only For Update Module 
      if (key === "eventImage"&& this.updateVisible) {
        return true; // Skip validation for 'logoimg'
      }

      const control = this.eventForm.get(key);
      return control && control.value !== '' && control.value !== null;
    });
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Access Denied",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);

    });
    return;
  }
  onFileChange(event: Event): void {
    this.eventForm.get('imageUrl')?.setValue('https://calendar.powwows.com/wp-content/uploads/Los-Angeles-Pow-Wow-630x500.jpeg');
     
      
    }

   
    
  
  SucessMessage(title, text, icon) {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCloseButton: true,
      allowOutsideClick: false
    });
  }
  ExceptionMessage(title, icon) {
    Swal.fire({
      title: title,
      icon: icon,
      showCloseButton: true,
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: 'ok'
    });
    return;
  }
stripetags(str:string):string{
  const pattern = new RegExp("\\<.*?\\>");
  str = new String(str).replace(pattern, "");
  return str;

}


getCurrentDateTimeFormatted(): string {
  // Get current date and time formatted for datetime-local input
  const now = new Date();
  const year = now.getFullYear();
  const month = this.padZero(now.getMonth() + 1); // Month is zero-indexed
  const day = this.padZero(now.getDate());
  const hours = this.padZero(now.getHours());
  const minutes = this.padZero(now.getMinutes());

  // Format: YYYY-MM-DDTHH:MM (e.g., "2023-07-04T12:30")
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

padZero(num: number): string {
  return num < 10 ? '0' + num : num.toString();
}
}
