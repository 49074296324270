<div class="container custom-container">
  <div class="row">
    <div class="col-auto p-0">
      <app-sidenav></app-sidenav>
    </div>
    <div class="col p-0">
      <div class="p-0 mt-0" style="background-color: #fff;height: max-content;">
        <app-header></app-header>
        <div class="main-block">
          <div class="container-fluid mt-0 p-0">
            <div class="mt-4 text-center" *ngIf="memberDetail">
              <!-- <span class="spinner-border text-dark" role="status"> </span> -->
            </div>
            <div *ngIf="!memberDetail" style="margin-bottom: 70px ;">
              <!-- <div class="text-left mb-2 mr-2"> -->
              <!-- <a routerLink="/members"><button style="
      color: black;text-decoration: none; border: none;"><mat-icon >arrow_back</mat-icon>Back</button></a> -->
              <!-- </div> -->
              <div class="card p-0 info-card">
                <div class="mt-1">
                  <a routerLink="/members"><mat-icon class="back-icon">arrow_back</mat-icon></a>
                  <h4>Basic Information</h4>
                  <div class="card p-3">
                    <div class="split-content" style="display: flex;justify-content: space-between; width: 100%;">

                      <div class="profile-edit" style="margin-right: 20px;width: 400px;">
                        <!-- <div class="space" style="margin: 5%;"></div> -->
                        <div class="profile" style="display:flex ; justify-content: center;">

                          <img class="user-img" src="{{ profileUrl(currentMember.profileImageUrl) }}" />
                          <mat-icon id="icon" (click)="openProfileEdit(currentMember)" style="cursor: pointer; position: relative; top: 160px;
                  left: -66px;">create</mat-icon>

                        </div>


                        <div class="space" style="margin: 5%; "></div>
                        <div class="profile-details"
                          style="display: flex;flex-direction: column; line-height: 1.5rem; justify-content: center;">


                          <div class="header-profile"
                            style="display: flex;justify-content: space-between; width: 100%;border-bottom: 1px solid rgb(112, 109, 109);">
                            <h4 style="margin-left: 20px;">User Details</h4>
                            <mat-icon (click)="basicInfoModal(currentMember)"
                              style="cursor: pointer;margin-right: 20px;">create</mat-icon>
                          </div>
                          <div class="space" style="margin: 3%; "></div>
                          <div class="flex-content">
                            <p>First Name</p>
                            <p>{{ currentMember.firstName }}</p>
                          </div>
                          <div class="flex-content">
                            <p>Last Name</p>
                            <p>{{ currentMember.lastName }}</p>
                          </div>
                          <div class="flex-content">
                            <p>Title</p>
                            <p>{{ currentMember.designation }}</p>
                          </div>
                          <div class="flex-content">
                            <p>Organization</p>
                            <p>{{this.organizationName}}</p>
                          </div>
                          <div class="flex-content">
                            <p>Category</p>
                            <p>{{categoryName||'N/A'}}</p>
                          </div>
                          <div class="flex-content">
                            <p>Sub Category</p>
                            <p>{{subCategoryName||'N/A'}}</p>
                          </div>
                          <div class="flex-content">
                            <p>Phone</p>
                            <p>{{stripMask(currentMember.phone)}}</p>
                          </div>
                          <!-- <div class="flex-content">
                            <p>Role Type</p>

                            <p>{{currentMember.roleType}}</p>
                          </div> -->
                          <div class="flex-content">
                            <p>User Type</p>

                            <p>{{currentMember.userType}}</p>
                          </div>
                          <!-- <div class="flex-content">
                  <p>State</p>
                  <p>Dallas</p>
                </div> -->
                          <!-- <div class="flex-content">
                  <p>Zip Code</p>
                  <p>{{ currentMember.zipCode }}</p>
                </div> -->
                        </div>

                      </div>


                      <!-- <div class="idcard-preview">


              <div class="header-id"
                style="border-bottom: 1px solid rgb(82, 77, 77);  color: #4C3A67 ;margin-left:20px ; ">

                <h3> <span class="gap" style="width:10px"> </span><mat-icon>assignment_ind</mat-icon> Digital Id Card
                </h3>
              </div>

              <div class="space" style="margin: 5%; "></div>
              <div class="split-preview-form">
                <div class="id-card">

                  <div class="header">

                  </div>
                  <div class="photo">
                    <img src="{{ profileUrl(currentMember.profileImageUrl) }}" alt="Profile Photo"
                      class="profile-photo">

                  </div>
                  <h3>DNX Connect</h3>
                  <div class="space" style="margin: 30px;"></div>
                  <div class="info">
                    <div class="flex-id">
                      <b>Member Id</b>
                      <p>{{currentMemberId}}</p>
                    </div>
                    <div class="flex-id">
                      <span>Name</span>
                      <p>{{ currentMember.firstName+""+currentMember.lastName }}</p>
                    </div>
                    <div class="flex-id">
                      <span>Id</span>
                      <p> {{'DNX00'+currentMember.memberId}} </p>
                    </div>
                    <div class="flex-id">
                      <span>Designation</span>
                      <p>{{ currentMember.designation }}</p>
                    </div>-->

                      <!-- <p>Email: {{ contactNumber }}</p> -->
                      <!--<div class="flex-id">
                      <span>Email</span>
                      <p> {{currentMember.email }}</p>
                    </div>
                  </div>

                  <div class="qr-code"> 
                    Replace 'qr-code-content' with your actual QR code component or image  <img src="../../assets/img/QRscanner.png" alt="QR Code" style="width: 100px;height: 100px;">
                  </div>

                  <div class="timestamp">
                    {{date.toISOString().substring(0,10)}}
                  </div>


                  <div class="footer"></div>

                  <div id="bg-text">Datanetiix</div>
                </div>

              </div>
            </div>  -->
                      <div class="profile-block">
                        <div class="profile-head">
                          <h4>Profile</h4>
                        </div>
                        <div class="profile-col">
                          <img class="profile-img" src="{{ profileUrl(currentMember.profileImageUrl) }}" />
                          <h4>{{ currentMember.firstName+" "+currentMember.lastName }} <img class="divide-line"
                              src="assets/img/line.png" /> <span>United States</span></h4>
                          <div class="email-col">
                            <p>Member Id: {{currentMemberId}}</p>
                          </div>
                          <div class="email-col">
                            <p>Email: {{currentMember.email }}</p>
                          </div>
                          <div class="phone-col">
                            <p>{{stripMask(currentMember.phone)}}</p>
                          </div>
                          <div class="email-col">
                            <p>Id: {{'DNX00'+currentMember.memberId}}</p>
                          </div>
                          <div class="desig-col">
                            <p>Designation: {{ currentMember.designation }}</p>
                          </div>
                          <div class="desig-col">
                            <p>Category Name: {{categoryName||'N/A'}}</p>
                          </div>
                          <div class="desig-col">
                            <p>Sub Category Name: {{subCategoryName||'N/A'}}</p>
                          </div>
                          <div class="last-login">
                            <p>Last Login: {{date.toISOString().substring(0,10)}}</p>
                          </div>

                          <div class="contact-icons">
                            <!-- <a [href]="'mailto:' + currentMember.email" class="cont-mail">
                    <img src="assets/img/whatsap.png" />
                  </a> -->
                            <a [href]="'tel:' + stripMask(currentMember.phone)" class="cont-phone" style="margin:5px">
                              <img src="assets/img/phone.png" />
                            </a>
                            <a [href]="'mailto:' + currentMember.email" class="cont-mail" style="margin:5px">
                              <img src="assets/img/msg.png" />
                            </a>
                          </div>
                          <!-- <div class="qr-code qr-col"> 
                    <p>Replace 'qr-code-content' with your actual QR code component or image</p>  
                    <img src="../../assets/img/QRscanner.png" alt="QR Code" style="width: 100px;height: 100px;">
                  </div> -->
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>






          <!--Member Edit Modal -->
          <div class="modal fade" id="memberEditModel" data-backdrop="static" tabindex="-1" role="dialog"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title">{{ modalHeader }}</h4>
                  <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                    <!-- <img src="assets/img/modal-close.png" /> -->
                    <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                  </button>
                </div>
                <!-- Modal body -->

                <!-- Profile Edit Form -->
                <div class="container" *ngIf="profileEdit">
                  <div>
                    <form [formGroup]="profileEditForm" (ngSubmit)="profileEditSubmit()">
                      <div class="form-group">
                        <input type="hidden" class="form-control" formControlName="memberId" id="memberId" />
                      </div>

                      <div class="form-group">
                        <label for="files">Upload <span style="color: red;"> *</span></label>
                        <br>
                        <a [href]="currentMember.profileImageUrl" target="_blank"
                          *ngIf="currentMember.profileImageUrl.length!=0">View Image</a><br>
                        <img src="{{profileUrl(currentMember.profileImageUrl) }}"
                          style="width: 50px;height:50px;margin:10px ;" />


                        <input formControlName="files" type="file" id="files" name="files" class="form-control"
                          accept="image/*" (change)="onProfileChange($event)">
                        <div class="text-danger" *ngIf="customvalidator"> Please upload an image in GIF, JPEG, or PNG
                          format. </div>

                      </div>
                      <div class="userTpe-footer" style="display: flex; justify-content: flex-end;">

                        <button class="btn button-color btn-md btn-block sync-btn" type="submit"
                          [disabled]="!checkAllFieldsEntered()">
                          <!-- <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                          </span> -->
                          <span>Save</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- Basic Info Edit Form -->
                <div class="container" *ngIf="basicInfoEdit">
                  <div>
                    <form [formGroup]="basicInfoForm" (ngSubmit)="basicInfoSubmit()">
                      <div class="form-group">
                        <input type="hidden" class="form-control" formControlName="memberId" id="memberId" />
                      </div>
                      <div class="form-group">
                        <label for="firstName">First Name <span style="color: red;"> *</span></label>
                        <input type="text" class="form-control" formControlName="firstName" id="firstName"
                          placeholder="First Name" />
                        <div *ngIf="submitted && basicInfoControls.firstName.errors" class="ml-2">
                          <div *ngIf="basicInfoControls.firstName.errors.required" class="text-danger">
                            First Name required
                          </div>
                          <div *ngIf="basicInfoControls.firstName.errors.pattern" class="text-danger">
                            Avoid using special characters/Numbers and ensure that your last name more than 3 character
                          </div>
                          <div *ngIf="
                        basicInfoControls.firstName.errors.noWhiteSpace
                      " class="text-danger">
                            {{ basicInfoControls.firstName.errors.noWhiteSpace }}
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="lastName">Last Name <span style="color: red;"> *</span></label>
                        <input type="text" class="form-control" formControlName="lastName" id="lastName"
                          placeholder="Last Name" />
                        <div *ngIf="submitted && basicInfoControls.lastName.errors" class="ml-2">
                          <div *ngIf="basicInfoControls.lastName.errors.required" class="text-danger">
                            Last Name required
                          </div>
                          <div *ngIf="basicInfoControls.lastName.errors.pattern" class="text-danger">
                            Avoid using special characters/Numbers and ensure that your last name more than one
                            character
                          </div>
                          <div *ngIf="
                        basicInfoControls.lastName.errors.noWhiteSpace
                      " class="text-danger">
                            {{ basicInfoControls.lastName.errors.noWhiteSpace }}
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="title">Title <span style="color: red;"> *</span></label>
                        <input type="text" class="form-control" formControlName="title" id="title"
                          placeholder="Title" />
                        <div *ngIf="submitted && basicInfoControls.title.errors" class="ml-2">
                          <div *ngIf="basicInfoControls.title.errors.required" class="text-danger">
                            Title required
                          </div>
                          <div *ngIf="
                        basicInfoControls.title.errors.noWhiteSpace
                      " class="text-danger">
                            {{ basicInfoControls.title.errors.noWhiteSpace }}
                          </div>

                          <div
                            *ngIf="basicInfoControls.title.hasError('minlength') && (basicInfoControls.title.dirty || basicInfoControls.title.touched)"
                            class="text-danger">
                            Please ensure that your title more than one character
                          </div>
                          <div *ngIf="basicInfoControls.title.errors.pattern" class="text-danger">
                            Please ensure that your title have no special characters
                          </div>
                        </div>
                      </div>
                      <div class="form-group" *ngIf="true">
                        <label for="organaisationName">Organization <span style="color: red;"> *</span></label>
                        <input type="text" class="form-control" formControlName="organaisationName"
                          id="organaisationName" placeholder="Organization" readonly />
                        <div *ngIf="
                      submitted && basicInfoControls.organaisationName.errors
                    " class="ml-2">
                          <div *ngIf="
                        basicInfoControls.organaisationName.errors.required
                      " class="text-danger">
                            Organization Name required
                          </div>
                          <div *ngIf="basicInfoControls.organaisationName.errors.pattern" class="text-danger">
                            Please ensure that your Organization Name have no special characters
                          </div>
                          <div
                            *ngIf="basicInfoControls.organaisationName.hasError('minlength') && (basicInfoControls.organaisationName.dirty || basicInfoControls.organaisationName.touched)"
                            class="text-danger">
                            Please ensure that your title more than one character
                          </div>
                          <div *ngIf="
                        basicInfoControls.organaisationName.errors.noWhiteSpace
                      " class="text-danger">
                            {{ basicInfoControls.organaisationName.errors.noWhiteSpace }}
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="phoneNo">Phone <span style="color: red;"> *</span></label>
                        <input type="text" class="form-control" formControlName="phoneNo" id="phoneNo"
                          placeholder="Phone" mask="(000)-000-0000" (keydown)="handleKeyDown($event)" />
                        <div *ngIf="submitted && basicInfoControls.phoneNo.errors" class="ml-2">
                          <div *ngIf="basicInfoControls.phoneNo.errors.required" class="text-danger">
                            Phone required
                          </div>
                          <div *ngIf="basicInfoControls.phoneNo.errors.pattern" class="text-danger">
                            Phone enter a valid Phone Number
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="state">User Type <span style="color: red;"> *</span></label>
                        <select class="form-control" formControlName="roleType" id="state" placeholder="state"
                          style="padding-left: 15px;" (change)="onListSelect($event)">
                          <option *ngFor="let state of roles" [value]="state">{{
                            state
                            }}</option>
                        </select>
                      </div>
                      <div class="form-group ">
                        <label for="roleType">Catagory </label>
                        <select class="form-control" formControlName="parentCategoryId" id="roleType"
                          placeholder="roleType" style="
                                                    padding-left: 15px;" (change)="oncatogorySelect($event)">
                          <option value=0>Select Catogory</option>
                          <!-- <option value="">Select State</option> -->
                          <option *ngFor="let role of this.catagoryDropDown" value="{{role.categoryId}}">
                            {{role.categoryName}}</option>
                        </select>

                      </div>
                      <div class="form-group " *ngIf="isPresent">
                        <label for="roleType">Sub Catagory <span style="color: red;"> *</span></label>
                        <select class="form-control" formControlName="subCategoryId" id="roleType"
                          placeholder="roleType" style="
                                                    padding-left: 15px;" (change)="onSubcatogorySelect($event)">
                          <option value=0>Select Sub Catogory</option>
                          <!-- <option value="">Select State</option> -->
                          <option *ngFor="let role of  this.subCatagoryDropDown" value="{{role.categoryId}}">
                            {{role.categoryName}}</option>
                        </select>


                        <!-- <div *ngIf="submitted && basicInfoControls.state.errors" class="ml-2">
                            <div *ngIf="basicInfoControls.state.errors.required" class="text-danger">
                              User Type required
                            </div>
                          </div> -->
                      </div>
                      <!-- <div class="form-group">
                  <label for="address">Address</label>
                  <input type="text" class="form-control" formControlName="address" id="address"
                    placeholder="Address" />
                  <div *ngIf="submitted && basicInfoControls.address.errors" class="ml-2">
                    <div *ngIf="basicInfoControls.address.errors.required" class="text-danger">
                      Address required
                    </div>
                    <div *ngIf="
                        basicInfoControls.address.errors.noWhiteSpace
                      " class="text-danger">
                      {{ basicInfoControls.address.errors.noWhiteSpace }}
                    </div>
                  </div>
                </div> -->

                      <!-- <div class="form-group">
                  <label for="city">City</label>
                  <input type="text" class="form-control" formControlName="city" id="city" placeholder="City" />
                  <div *ngIf="submitted && basicInfoControls.city.errors" class="ml-2">
                    <div *ngIf="basicInfoControls.city.errors.required" class="text-danger">
                      City required
                    </div>
                    <div *ngIf="
                        basicInfoControls.city.errors.noWhiteSpace
                      " class="text-danger">
                      {{ basicInfoControls.city.errors.noWhiteSpace }}
                    </div>
                  </div>
                </div> -->

                      <!-- <div class="form-group">
                  <label for="state">State</label>
                  <select class="form-control" formControlName="state" id="state" placeholder="state" style="height: 50px;
                      padding-left: 15px;">
                    <option value="">Select State</option>
                    <option *ngFor="let state of states" value="{{ state }}">{{
                      state
                      }}</option>
                  </select>
                  <div *ngIf="submitted && basicInfoControls.state.errors" class="ml-2">
                    <div *ngIf="basicInfoControls.state.errors.required" class="text-danger">
                      State required
                    </div>
                  </div>
                </div> -->

                      <!-- <div class="form-group">
                  <label for="zipCode">Zip Code</label>
                  <input type="text" class="form-control" id="zipCode" placeholder="Zip Code" mask="00000"
                    formControlName="zipCode" />
                  <div *ngIf="submitted && basicInfoControls.zipCode.errors" class="ml-2">
                    <div *ngIf="basicInfoControls.zipCode.errors.required" class="text-danger">
                      Zip Code required
                    </div>
                    <div *ngIf="basicInfoControls.zipCode.errors.pattern" class="text-danger">
                      Enter valid zip
                    </div>
                  </div>
                </div> -->
                      <div class="userTpe-footer" style="display: flex; justify-content: flex-end;">
                        <button class="btn button-color btn-md btn-block sync-btn" type="submit"
                          [disabled]="!checkAllFieldsEntereds()">
                          <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                          </span>
                          <span *ngIf="!isLoading">Save</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- Interest Add Form -->
                <div class="container" *ngIf="addInterest">
                  <div class="mb-3">
                    <label>Interest</label>
                    <ng-select [items]="interest$ | async" bindLabel="serviceName" [addTag]="true" addTagText=""
                      [multiple]="true" [hideSelected]="true" [trackByFn]="trackByInterestFn" [minTermLength]="2"
                      [loading]="interestLoading" typeToSearchText="Please enter 2 or more characters"
                      [typeahead]="interestInput$" [(ngModel)]="selectedInterests">
                    </ng-select>
                    <div *ngIf="interestFormValidator" class="text-danger ml-2">
                      Please select one or more interest
                    </div>
                  </div>
                  <button class="btn button-color btn-md btn-block" (click)="interestSubmit()"
                    [disabled]="checkAllFieldsEntereds">
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                    </span>
                    <span *ngIf="!isLoading">Save</span>
                  </button>
                </div>

                <!-- Skills Add Form -->
                <div class="container" *ngIf="addSkills">
                  <div class="mb-3">
                    <label>Skill</label>
                    <ng-select [items]="skills$ | async" bindLabel="serviceName" [addTag]="true" addTagText=""
                      [multiple]="true" [hideSelected]="true" [trackByFn]="trackBySkillFn" [minTermLength]="2"
                      [loading]="skillLoading" typeToSearchText="Please enter 2 or more characters"
                      [typeahead]="skillInput$" [(ngModel)]="selectedSkills">
                    </ng-select>
                    <div *ngIf="skillFormValidator" class="text-danger ml-2">
                      Please select one or more skill
                    </div>
                  </div>
                  <button class="btn button-color btn-md btn-block" (click)="skillSubmit()" [disabled]="isLoading">
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                    </span>
                    <span *ngIf="!isLoading">Save</span>
                  </button>
                </div>

                <!-- Add Experience Form -->
                <div class="container" *ngIf="addExperience">
                  <div>
                    <form [formGroup]="addExperienceForm" (ngSubmit)="addExperienceFormSubmit()">
                      <div class="form-group">
                        <label for="designation">Title</label>
                        <input type="text" class="form-control" formControlName="designation" id="designation"
                          placeholder="Title" />
                        <div *ngIf="
                      submitted && addExperienceFormControls.designation.errors
                    " class="ml-2">
                          <div *ngIf="
                        addExperienceFormControls.designation.errors.required
                      " class="text-danger">
                            Title required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="employmentType">Employment Type</label>
                        <select class="form-control" formControlName="employmentType" id="employmentType">
                          <option>Full-time</option>
                          <option>Part-time</option>
                          <option>Self-employed</option>
                          <option>Freelance</option>
                          <option>Contract</option>
                          <option>Apprenticeship</option>
                        </select>
                        <div *ngIf="
                      submitted &&
                      addExperienceFormControls.employmentType.errors
                    " class="ml-2">
                          <div *ngIf="
                        addExperienceFormControls.employmentType.errors.required
                      " class="text-danger">
                            Employment Type required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="organizationName">Company</label>
                        <input type="text" class="form-control" formControlName="organizationName" id="organizationName"
                          placeholder="Company" />
                        <div *ngIf="
                      submitted &&
                      addExperienceFormControls.organizationName.errors
                    " class="ml-2">
                          <div *ngIf="
                        addExperienceFormControls.organizationName.errors
                          .required
                      " class="text-danger">
                            Company required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="address">Location</label>
                        <input type="text" class="form-control" formControlName="address" id="address"
                          placeholder="Location" />
                        <div *ngIf="
                      submitted && addExperienceFormControls.address.errors
                    " class="ml-2">
                          <div *ngIf="addExperienceFormControls.address.errors.required" class="text-danger">
                            Location required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <input class="form-check-input ml-1" type="checkbox" value="" id="isCurrentlyWorking"
                          formControlName="isCurrentlyWorking" />
                        <label class="form-check-label ml-4" for="isCurrentlyWorking">
                          I currently work in this role
                        </label>
                      </div>
                      <div class="form-group">
                        <label for="startDate">Start Date</label>
                        <input type="date" class="form-control" formControlName="startDate" id="startDate" />
                        <div *ngIf="
                      submitted && addExperienceFormControls.startDate.errors
                    " class="ml-2">
                          <div *ngIf="
                        addExperienceFormControls.startDate.errors.required
                      " class="text-danger">
                            Start Date required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="endDate">End Date</label>
                        <input type="date" class="form-control" formControlName="endDate" id="endDate" />
                        <div *ngIf="
                      submitted && addExperienceFormControls.endDate.errors
                    " class="ml-2">
                          <div *ngIf="addExperienceFormControls.endDate.errors.required" class="text-danger">
                            End Date required
                          </div>
                          <div *ngIf="
                        addExperienceFormControls.endDate.errors.dateValidator
                      " class="text-danger">
                            Your end date can't be earlier than your start date
                          </div>
                        </div>
                      </div>
                      <button class="btn button-color btn-md btn-block" type="submit" [disabled]="isLoading">
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                        </span>
                        <span *ngIf="!isLoading">Save</span>
                      </button>
                    </form>
                  </div>
                </div>

                <!-- Experience Edit Form -->
                <div class="container" *ngIf="experienceEdit">
                  <div>
                    <form [formGroup]="memberExperienceForm" (ngSubmit)="experienceFormSubmit()">
                      <div class="form-group">
                        <input type="hidden" class="form-control" formControlName="memberId" id="memberId" />
                      </div>
                      <div class="form-group">
                        <input type="hidden" class="form-control" formControlName="experienceId" id="experienceId" />
                      </div>
                      <div class="form-group">
                        <label for="designation">Title</label>
                        <input type="text" class="form-control" formControlName="designation" id="designation"
                          placeholder="Title" />
                        <div *ngIf="
                      submitted && experienceFormControls.designation.errors
                    " class="ml-2">
                          <div *ngIf="experienceFormControls.designation.errors.required" class="text-danger">
                            Title required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="employmentType">Employment Type</label>
                        <select class="form-control" formControlName="employmentType" id="employmentType">
                          <option>Full-time</option>
                          <option>Part-time</option>
                          <option>Self-employed</option>
                          <option>Freelance</option>
                          <option>Contract</option>
                          <option>Apprenticeship</option>
                        </select>
                        <div *ngIf="
                      submitted && experienceFormControls.employmentType.errors
                    " class="ml-2">
                          <div *ngIf="
                        experienceFormControls.employmentType.errors.required
                      " class="text-danger">
                            Employment Type required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="organizationName">Company</label>
                        <input type="text" class="form-control" formControlName="organizationName" id="organizationName"
                          placeholder="Company" />
                        <div *ngIf="
                      submitted &&
                      experienceFormControls.organizationName.errors
                    " class="ml-2">
                          <div *ngIf="
                        experienceFormControls.organizationName.errors.required
                      " class="text-danger">
                            Company required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="address">Location</label>
                        <input type="text" class="form-control" formControlName="address" id="address"
                          placeholder="Location" />
                        <div *ngIf="submitted && experienceFormControls.address.errors" class="ml-2">
                          <div *ngIf="experienceFormControls.address.errors.required" class="text-danger">
                            Location required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <input class="form-check-input ml-1" type="checkbox" value="" id="isCurrentlyWorking"
                          formControlName="isCurrentlyWorking" />
                        <label class="form-check-label ml-4" for="isCurrentlyWorking">
                          I currently work in this role
                        </label>
                      </div>
                      <div class="form-group">
                        <label for="startDate">Start Date</label>
                        <input type="date" class="form-control" class="form-control" formControlName="startDate"
                          id="startDate" />
                        <div *ngIf="submitted && experienceFormControls.startDate.errors" class="ml-2">
                          <div *ngIf="experienceFormControls.startDate.errors.required" class="text-danger">
                            Start Date required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="endDate">End Date</label>
                        <input type="date" class="form-control" class="form-control" formControlName="endDate"
                          id="endDate" />
                        <div *ngIf="submitted && experienceFormControls.endDate.errors" class="ml-2">
                          <div *ngIf="experienceFormControls.endDate.errors.required" class="text-danger">
                            End Date required
                          </div>
                          <div *ngIf="
                        experienceFormControls.endDate.errors.dateValidator
                      " class="text-danger">
                            Your end date can't be earlier than your start date
                          </div>
                        </div>
                      </div>
                      <button class="btn button-color btn-md btn-block" type="submit" [disabled]="isLoading">
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                        </span>
                        <span *ngIf="!isLoading">Save</span>
                      </button>
                    </form>
                  </div>
                </div>

                <!-- Add Education Form -->
                <div class="container" *ngIf="addEducation">
                  <div>
                    <form [formGroup]="addEducationForm" (ngSubmit)="addEducationFormSubmit()">
                      <div class="form-group pb-2">
                        <label for="school">School</label>
                        <input type="text" class="form-control" formControlName="school" id="school"
                          placeholder="School" />
                        <div *ngIf="submitted && addEducationFormControls.school.errors" class="ml-2">
                          <div *ngIf="addEducationFormControls.school.errors.required" class="text-danger">
                            School required
                          </div>
                        </div>
                      </div>
                      <div class="form-group pb-2">
                        <label for="degree">Degree</label>
                        <input type="text" class="form-control" formControlName="degree" id="degree"
                          placeholder="Degree" required />
                        <div *ngIf="submitted && addEducationFormControls.degree.errors" class="ml-2">
                          <div *ngIf="addEducationFormControls.degree.errors.required" class="text-danger">
                            Degree required
                          </div>
                        </div>
                      </div>
                      <div class="form-group pb-2">
                        <label for="fieldOfStudy">Field of Study</label>
                        <input type="text" class="form-control" formControlName="fieldOfStudy" id="fieldOfStudy"
                          placeholder="Field Of Study" required />
                        <div *ngIf="
                      submitted && addEducationFormControls.fieldOfStudy.errors
                    " class="ml-2">
                          <div *ngIf="
                        addEducationFormControls.fieldOfStudy.errors.required
                      " class="text-danger">
                            Field of Study required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="startYear">Start Date</label>
                        <input type="date" class="form-control" class="form-control" formControlName="startYear"
                          id="startYear" />
                        <div *ngIf="
                      submitted && addEducationFormControls.startYear.errors
                    " class="ml-2">
                          <div *ngIf="addEducationFormControls.startYear.errors.required" class="text-danger">
                            Start Date required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="endYear">End Date</label>
                        <input type="date" class="form-control" class="form-control" formControlName="endYear"
                          id="endYear" required />
                        <div *ngIf="submitted && addEducationFormControls.endYear.errors" class="ml-2">
                          <div *ngIf="addEducationFormControls.endYear.errors.required" class="text-danger">
                            End Date required
                          </div>
                          <div *ngIf="
                        addEducationFormControls.endYear.errors.dateValidator
                      " class="text-danger">
                            Your end date can't be earlier than your start date
                          </div>
                        </div>
                      </div>
                      <button class="btn button-color btn-md btn-block" type="submit" [disabled]="isLoading">
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                        </span>
                        <span *ngIf="!isLoading">Save</span>
                      </button>
                    </form>
                  </div>
                </div>

                <!-- Education Edit Form -->
                <div class="container" *ngIf="educationEdit">
                  <div>
                    <form [formGroup]="memberEducationForm" (ngSubmit)="educationFormSubmit()">
                      <div class="form-group">
                        <input type="hidden" class="form-control" formControlName="memberId" id="memberId" />
                      </div>
                      <div class="form-group">
                        <input type="hidden" class="form-control" formControlName="educationId" id="educationId" />
                      </div>
                      <div class="form-group pb-2">
                        <label for="school">School</label>
                        <input type="text" class="form-control" formControlName="school" id="school"
                          placeholder="School" required />
                        <div *ngIf="submitted && educationFormControls.school.errors" class="ml-2">
                          <div *ngIf="educationFormControls.school.errors.required" class="text-danger">
                            School required
                          </div>
                        </div>
                      </div>
                      <div class="form-group pb-2">
                        <label for="degree">Degree</label>
                        <input type="text" class="form-control" formControlName="degree" id="degree"
                          placeholder="Degree" required />
                        <div *ngIf="submitted && educationFormControls.degree.errors" class="ml-2">
                          <div *ngIf="educationFormControls.degree.errors.required" class="text-danger">
                            Degree required
                          </div>
                        </div>
                      </div>
                      <div class="form-group pb-2">
                        <label for="fieldOfStudy">Field of Study</label>
                        <input type="text" class="form-control" formControlName="fieldOfStudy" id="fieldOfStudy"
                          placeholder="Field Of Study" required />
                        <div *ngIf="
                      submitted && educationFormControls.fieldOfStudy.errors
                    " class="ml-2">
                          <div *ngIf="educationFormControls.fieldOfStudy.errors.required" class="text-danger">
                            Field of Study required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="startYear">Start Date</label>
                        <input type="date" class="form-control" formControlName="startYear" id="startYear" />
                        <div *ngIf="submitted && educationFormControls.startYear.errors" class="ml-2">
                          <div *ngIf="educationFormControls.startYear.errors.required" class="text-danger">
                            Start Date required
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="endYear">End Date</label>
                        <input type="date" class="form-control" formControlName="endYear" id="endYear" />
                        <div *ngIf="submitted && educationFormControls.endYear.errors" class="ml-2">
                          <div *ngIf="educationFormControls.endYear.errors.required" class="text-danger">
                            End Date required
                          </div>
                          <div *ngIf="educationFormControls.endYear.errors.dateValidator" class="text-danger">
                            Your end date can't be earlier than your start date
                          </div>
                        </div>
                      </div>
                      <button class="btn button-color btn-md btn-block" type="submit" [disabled]="isLoading">
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                        </span>
                        <span *ngIf="!isLoading">Save</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Progress Modal -->
        <div class="modal fade" id="progressModel" data-backdrop="static" tabindex="-1" role="dialog"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header" style="padding: 10px 15px;">
                <h4 class="modal-title">Processing . . . .</h4>
              </div>
              <div class="modal-body text-center" style="padding: 25px 10px;">
                <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated bg-dark" role="progressbar"
                    style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ngx-spinner bdColor="rgba(0, 0, 0, .6)" class="spin-img" template="<img src='assets/img/logo-D.gif' />">
      </ngx-spinner>
      <!-- <nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>Member Connect</div>
</nav> -->