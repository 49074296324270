// Vendor Imports
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

// Local Imports
import { LoginService } from "../shared/services/login.service";
import { PasswordStrengthValidator } from "./password-strength.validators";
import {
  ResetPasswordResponse,
  ResetPasswordRequest
} from "../shared/models/user";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted = false;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group(
      {
        oldPassword: ["", [Validators.required]],
        newPassword: [
          "",
          [
            Validators.required,
            PasswordStrengthValidator,
            Validators.minLength(8)
          ]
        ],
        confirmNewPassword: [
          "",
          [
            Validators.required,
            PasswordStrengthValidator,
            Validators.minLength(8)
          ]
        ]
      },
      {
        validator: this.confirmedValidator("newPassword", "confirmNewPassword")
      }
    );
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.isLoading = true;
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;

    this.loginService
      .resetPassword(this.resetPasswordForm.value, token)
      .subscribe(
        (data: ResetPasswordResponse) => {
          if (data.statusCode === 200) {
            this.isLoading = false;
            Swal.fire({
              icon: "success",
              title: "Password Changed Successfully",
              allowOutsideClick: false,
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Continue",
              cancelButtonText: "Logout"
            }).then(result => {
              if (result.value) {
                this.router.navigate(["/members"]);
              }
              if (!result.value) {
                localStorage.clear();
                this.router.navigate(["/login"]);
              }
            });
            return;
          }
          this.isLoading = false;
          Swal.fire({
            icon: "warning",
            title: "Invalid Old Password",
            showCloseButton: true,
            allowOutsideClick: false
          });
        },
        err => {
          if (err.status === 401) {
            Swal.fire({
              icon: "warning",
              title: "Session Expired!",
              allowOutsideClick: false,
              confirmButtonColor: "#d33",
              confirmButtonText: "Logout"
            }).then(() => {
              localStorage.clear();
              this.router.navigate(["/login"]);
            });
            return;
          }
        }
      );
  }
}
