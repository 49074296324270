<div class="container custom-container">
  <div class="row">
    <div class="col-auto p-0">
      <app-sidenav></app-sidenav>
    </div>
    <div class="col p-0">
      <div class="p-0 mt-0" style="background-color: #fff;height: max-content;">
        <app-header></app-header>
        <div class="main-block">
          <div class="d-flex justify-content-between align-items-start wrap-block create-col">
            <div class="mt-2">
              <div class="split-button" style="display:flex;gap:8px">
                <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;"
                (click)="openAddMemberModal()">
                  <img src="assets/img/plus.png" class="btn-img"/> New Member
                </button>
                <button type="button" class="btn btn-secondary btn-sm new-btn" style="background-color: #5D3C6C;"
                (click)="openbulkInsert()">
                  Bulk User Import
                </button>
              </div>
            </div>
            <div class="mt-2 wrap-block forms-search" style="display: flex; align-items: center; margin-right: 20px;">
              <div style="margin-right: 20px;" class="form-search-sub" >
                <mat-form-field style="width: 100%; margin-top: auto;">
                  <mat-select [(value)]="selectedType" class="user-select" style="margin-top: auto;" placeholder="User Type"
                    (selectionChange)="onSelectChange($event)" panelClass="custom-dropdown-panel" disableOptionCentering>
                    <mat-option [value]="0">None</mat-option>
                    <mat-option *ngFor="let role of roleList?.roleType" [value]="role.id">{{role.role}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
      
              <div class="search-form-field searchType-input">
                <mat-form-field>
                  <input matInput (keyup)="filterEvent($event)" [(ngModel)]="searchKey" placeholder="Type to Search" />
                </mat-form-field>
                <mat-icon class="searchIcon" style="cursor: pointer;" (click)="applyFilter()">search</mat-icon>
              </div>
            </div>
          </div>
      
          <ngx-spinner
            bdColor="rgba(0, 0, 0, .6)"
            class="spin-img"
            template="<img src='assets/img/logo-D.gif' />"
          >
          </ngx-spinner>
      
          <div class="mat-elevation-z8 member-table table-container" style="margin-bottom: 60px">
            <table mat-table [dataSource]="dataSource" matSort>
        
              <ng-container matColumnDef="userName">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Member
                </th>
                <td mat-cell *matCellDef="let row">{{ row.userName }}</td>
              </ng-container>
        
              <ng-container matColumnDef="email">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Email
                </th>
                <td mat-cell *matCellDef="let row" style="width:17%; word-break: break-all;">{{ row.email }}</td>
              </ng-container>
        
              <ng-container matColumnDef="phone">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Phone
                </th>
                <td mat-cell *matCellDef="let row" style="width:8%;">
                  {{row.phone}}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="designation">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Designation
                </th>
                <td mat-cell *matCellDef="let row">{{ row.designation }}</td>
              </ng-container>
        
              <ng-container matColumnDef="companyName">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Company 
                </th>
                <td mat-cell *matCellDef="let row">{{ organizationName }}</td>
              </ng-container>
        
              <ng-container matColumnDef="message">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Chat
                </th>
                <td mat-cell *matCellDef="let row">
        
                  <!-- <div  style="width: 50px;">
                  </div> -->
                  <div *ngIf="row. isChatActive" class="dm-icon" matTooltip="message" (click)="openModal(row)"
                  style="cursor: pointer;" >
                    <!-- <mat-icon [matTooltip]="'message'" (click)="openModal(row)"
                      style="cursor: pointer;" class="forum-icon">forum_outline</mat-icon> -->
                      <img src="assets/img/dm.png" />
                  </div> 

                  
                </td>
              </ng-container>
        
              <ng-container matColumnDef="source">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Source
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.source }}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="syncdate">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Sync Date
                </th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="row.hubspotId != 0"> {{row.syncedToHubspot}}</span>
        
                </td>
              </ng-container>
        
              <!-- Color Column -->
              <ng-container matColumnDef="status">
                <th class="mat-table-header" style="width:6%;" mat-header-cell *matHeaderCellDef>
                  Status
                </th>
                <td mat-cell *matCellDef="let row">
                  <span *ngIf="row.status === 1" class="badge bg-success-subtle text-success">Active</span>
                  <span *ngIf="row.status === 0" class="badge bg-danger-subtle text-danger">Suspended</span>
                </td>
              </ng-container>
        
              <ng-container matColumnDef="date">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Signup Date
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.date }}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="usertype">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  User Type
                </th>
                <td mat-cell *matCellDef="let row" style="padding-left: 20px; padding-right: 20px;white-space: nowrap" >
                  <span class="circle-span" [ngStyle]="{'background-color': getBackgroundColor(row.userTypeId)}"></span>
                  <span style="margin-left: 8px;">{{ row.userTypeName }}</span>
                </td>
              </ng-container>
        
              <ng-container matColumnDef="actions">
                <th class="mat-table-header" mat-header-cell *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let row" style="width:11%;">
                  <a routerLink="/member-view/{{ row.id }}" class="view-icon" matTooltip="View" style="cursor: pointer;">
                    <!-- <mat-icon class="mr-2" matTooltip="View" class="view-icon" style="cursor: pointer;">open_in_new</mat-icon> -->
                     <img src="assets/img/view-light.png" />
                  </a>
                  <!-- <mat-icon class="mr-2" *ngIf="row.status === 0" matTooltip="Activate" style="cursor: pointer;"
                    (click)="activateUser(row)" class="suspend-icon">check_circle</mat-icon> -->
                    <div class="suspend-icon" *ngIf="row.status === 0" style="cursor: pointer;background-color:#F8D7DA;" matTooltip="Activate" 
                    (click)="activateUser(row)">
                      <img src="assets/img/suspend.png" />
                    </div>
                    
                  <!-- <mat-icon class="mr-2" *ngIf="row.status === 1" matTooltip="Suspend" style="cursor: pointer;"
                    (click)="suspendUser(row)" class="suspend-icon">cancel</mat-icon> -->
                    <div class="activate-icon" *ngIf="row.status === 1" matTooltip="Suspend" style="cursor: pointer; background-color:#D1E7DD;"
                    (click)="suspendUser(row)">
                      <img src="assets/img/activate.png" />
                  </div>
                  <!-- <mat-icon matTooltip="Delete" style="cursor: pointer;" class="delete-icon" (click)="deleteUser(row)">delete</mat-icon> -->
                  <div class="delete-icon" matTooltip="Delete" style="cursor: pointer;" (click)="deleteUser(row)"  >
                  <img src="assets/img/delete.png" />
                </div>
                </td>
              </ng-container>
        
              <!-- <ng-container matNoDataRow *ngIf="this.users?.length === 0;">
                <td colspan="12">No data to display</td>
              </ng-container> -->
        
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div *ngIf="this.users?.length == 0" style="text-align: center;padding: 50px;background-color: white;">
              <p>no records found </p>
            </div>
            <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons length="300"></mat-paginator> -->
            <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons [length]="totalItems" [(pageSize)]="pageSize"
              [(pageIndex)]="currentPage" (page)="onPaginatorClick($event)" class="metaPaginate">
            </mat-paginator>
            <!-- <nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
              <div>Member Connect</div>
            </nav> -->
          </div>
      
          <div class="modal fade" id="newMemberCreate" data-backdrop="static" tabindex="-1" role="dialog"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-dialog">
                <div class="modal-content">
                  <!-- Modal Header -->
                  <div class="modal-header">
                    <h4 class="modal-title">New Member</h4>
                    <button type="button" class="close" data-dismiss="modal" (click)="hide()">
                      <!-- <img src="assets/img/modal-close.png" /> -->
                      <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                    </button>
                  </div>
                  <!-- Modal body -->
                  <div class="modal-body">
                    <!-- Coupon Discount Form -->
                    <div class="container" *ngIf="addMemberForm">
                      <form [formGroup]="memberForm" (ngSubmit)="memberFormSubmit()">
                        <div class="form-group mb-3">
                          <label for="firstName">First Name<span style="color: red;"> *</span></label>
                          <input type="text" class="form-control signup-input" id="firstName" placeholder="First Name"
                            formControlName="firstName" />
                          <div *ngIf="memberSubmitted && memberControl.firstName.errors" class="ml-2">
                            <div *ngIf="memberControl.firstName.errors.required" class="text-danger">
                              First Name required
                            </div>
                            <div *ngIf="memberControl.firstName.errors.pattern" class="text-danger">
                              Avoid using special characters/Numbers and ensure that your first name more than 3 character
                            </div>
                            <div *ngIf="memberControl.firstName.errors.noWhiteSpace" class="text-danger">
                              First Name required
                            </div>
                          </div>
                        </div>
                        <div class="form-group mb-3">
                          <label for="lastName">Last Name<span style="color: red;"> *</span></label>
                          <input type="text" class="form-control signup-input" id="lastName" placeholder="Last Name"
                            formControlName="lastName" />
                          <div *ngIf="memberSubmitted && memberControl.lastName.errors" class="ml-2">
                            <div *ngIf="memberControl.lastName.errors.required" class="text-danger">
                              Last Name required
                            </div>
                            <div *ngIf="memberControl.lastName.errors.pattern" class="text-danger">
                              Avoid using special characters/Numbers and ensure that your last name more than one character
                            </div>
                            <div *ngIf="memberControl.lastName.errors.noWhiteSpace" class="text-danger">
                              {{ memberControl.lastName.errors.noWhiteSpace }}
                            </div>
                          </div>
                        </div>
        
                        <div class="form-group mb-3">
                          <label for="title">Title<span style="color: red;"> *</span></label>
                          <input type="text" class="form-control signup-input" id="title" placeholder="Title"
                            formControlName="title" />
                          <div *ngIf="memberSubmitted && memberControl.title.errors" class="ml-2">
                            <div *ngIf="memberControl.title.errors.required" class="text-danger">
                              Title required
                            </div>
        
                            <div
                              *ngIf="memberControl.title.hasError('minlength') && (memberControl.title.dirty || memberControl.title.touched)"
                              class="text-danger">
                              Please ensure that your title more than one character
                            </div>
                            <div *ngIf="memberControl.title.errors.pattern" class="text-danger">
                              Please ensure that your title have no special characters
                            </div>
                            <div *ngIf="memberControl.title.errors.noWhiteSpace" class="text-danger">
                              {{ memberControl.title.errors.noWhiteSpace }}
                            </div>
                          </div>
                        </div>
        
                        <!-- <div class="form-group mb-3">
                          <label for="organaisationName">Organization Name<span style="color: red;"> *</span></label>
                          <input type="text" class="form-control signup-input" id="organization" placeholder="Organization Name"
                            formControlName="organaisationName" />
                          <div *ngIf="
                              memberSubmitted && memberControl.organaisationName.errors
                            " class="ml-2">
                            <div *ngIf="memberControl.organaisationName.errors.required" class="text-danger">
                              Organization Name required
                            </div>
                            <div *ngIf="memberControl.organaisationName.errors.pattern" class="text-danger">
                              Please ensure that your Organization Name have no special characters
                            </div>
                            <div
                              *ngIf="memberControl.organaisationName.hasError('minlength') && (memberControl.organaisationName.dirty || memberControl.organaisationName.touched)"
                              class="text-danger">
                              Please ensure that your title more than one character
                            </div>
        
                            <div *ngIf="
                                memberControl.organaisationName.errors.noWhiteSpace
                              " class="text-danger">
                              {{ memberControl.organaisationName.errors.noWhiteSpace }}
                            </div>
                          </div>
                        </div> -->
        
                        <div class="form-group mb-3">
                          <label for="email">Email <span style="color: red;"> *</span></label>
                          <input type="email" class="form-control signup-input" id="email" placeholder="Email"
                            formControlName="email" />
                          <div *ngIf="memberSubmitted && memberControl.email.errors" class="ml-2">
                            <div *ngIf="memberControl.email.errors.required" class="text-danger">
                              Email required
                            </div>
                            <div *ngIf="memberControl.email.errors.pattern" class="text-danger">
                              Enter valid email address
                            </div>
                          </div>
                        </div>
        
                        <div class="form-group mb-3">
                          <label for="phoneNo">Phone# <span style="color: red;"> *</span></label>
                          <input type="text" class="form-control signup-input" id="phoneNo" placeholder="Phone"
                            mask="(000)-000-0000" (keydown)="handleKeyDown($event)" formControlName="phoneNo" />
                          <div *ngIf="memberSubmitted && memberControl.phoneNo.errors" class="ml-2">
                            <div *ngIf="memberControl.phoneNo.errors.required" class="text-danger">
                              Phone required
                            </div>
                            <div *ngIf="memberControl.phoneNo.errors.pattern" class="text-danger">
                              Enter valid phone number
                            </div>
                          </div>
                        </div>
        
                        <div class="form-group ">
                          <label for="roleType">User Type <span style="color: red;"> *</span></label>
                          <select class="form-control" formControlName="roleType" id="roleType" placeholder="roleType" style="
                                                  padding-left: 15px;" (change)="onListSelect($event)">
                            <option value="" disabled selected>Select User Type</option>
                            <!-- <option value="">Select State</option> -->
                            <option *ngFor="let role of this.roleTypes" value="{{role.id}}">{{role.role}}</option>
                          </select>
                          <div *ngIf="memberSubmitted && memberControl.roleType.errors" class="ml-2">
                            <div *ngIf="memberControl.roleType.errors.required" class="text-danger">
                              User Type required
                            </div>
                          </div>
                        </div>
        
                        <!-- <div class="form-group mb-3">
                          <label for="address">Address</label>
                          <input type="text" class="form-control signup-input" id="address" placeholder="Address"
                            formControlName="address" />
                          <div *ngIf="
                              memberSubmitted && memberControl.address.errors" class="ml-2">
                            <div *ngIf="memberControl.address.errors.required" class="text-danger">
                              Address required
                            </div>
                            <div *ngIf="
                                memberControl.address.errors.noWhiteSpace
                              " class="text-danger">
                              {{ memberControl.address.errors.noWhiteSpace }}
                            </div>
                          </div>
                        </div>
        
                        <div class="form-group mb-3">
                          <label for="city">City</label>
                          <input type="text" class="form-control signup-input" id="city" placeholder="City"
                            formControlName="city" />
                          <div *ngIf="
                              memberSubmitted && memberControl.city.errors" class="ml-2">
                            <div *ngIf="memberControl.city.errors.required" class="text-danger">
                              City required
                            </div>
                            <div *ngIf="
                                memberControl.city.errors.noWhiteSpace
                              " class="text-danger">
                              {{ memberControl.city.errors.noWhiteSpace }}
                            </div>
                          </div>
                        </div>
        
                      <div class="form-group mb-3">
                          <label for="state">State</label>
                          <select class="form-control" formControlName="state" id="state" placeholder="state" style="height: 50px;
                              padding-left: 15px;">
                            <option value="">Select State</option>
                            <option *ngFor="let state of states" value="{{ state }}">{{
                              state
                              }}</option>
                          </select>
                          <div *ngIf="
                              memberSubmitted && memberControl.state.errors" class="ml-2">
                            <div *ngIf="memberControl.state.errors.required" class="text-danger">
                              State required
                            </div>
                          </div>
                        </div>  
        
                        <div class="form-group mb-3">
                          <label for="zipCode">Zip Code</label>
                          <input type="text" class="form-control signup-input" id="zipCode" placeholder="Zip Code" mask="00000"
                            formControlName="zipCode" />
                          <div *ngIf="
                              memberSubmitted && memberControl.zipCode.errors" class="ml-2">
                            <div *ngIf="memberControl.zipCode.errors.required" class="text-danger">
                              Zip Code required
                            </div>
                            <div *ngIf="memberControl.zipCode.errors.pattern" class="text-danger">
                              Enter valid zip
                            </div>
                          </div>
                        </div>
        
                        <div class="form-group mb-3">
                          <label for="interval">Duration</label>
                          <select class="form-control" formControlName="interval" id="interval">
                            <option>year</option>
                            <option>month</option>
                          </select>
                        </div> -->
                        <div class="footer-modal" style="display: flex; justify-content: flex-end;">
                        <button class="btn button-color btn-md btn-block memberAdd-btn" type="submit" [disabled]="!checkAllFieldsEntered()">
                          <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
                          </span>
                          <span *ngIf="!isLoading">Add</span>
                        </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <!-- Progress Modal -->
          <div class="modal fade" id="progressModel" data-backdrop="static" tabindex="-1" role="dialog"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header" style="padding: 10px 15px;">
                  <h4 class="modal-title">Processing . . . .</h4>
                </div>
                <div class="modal-body text-center" style="padding: 25px 10px;">
                  <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-dark" role="progressbar"
                      style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Suspend FOrm -->
      
      <!-- SuspendFOrm -->
      <div class="usersChatBox" *ngIf="this.modalState == 'open'" [@modalAnimation]="modalState">
        <div class="userChatHead d-flex justify-content-between">
          <div class="chatUserName">{{this.userName}}</div>
            <div style="display: flex;">
              <div style="margin-right: 15px;">
                <!-- <img style="width: 18px;height: 18px;" src="assets/img/expand.png" alt="Close" /> -->
              </div>
              <div (click)="hideChatBox()" class="closeButton" style="cursor: pointer;">
                <img style="fill: green;" src="assets/svg/white-close.svg" alt="Close" />
              </div>
            </div>
          </div>
        
          <!-- <div class="chat-scroll" #chatListElement>
            <div #chatListElement class="chat-area">
              <ng-container>
                <div *ngFor="let message of msglists" (scroll)="onScroll()" #chatListElement class="message"
                  [ngClass]="{'sender':message.alignment === 'R'}" style="width:100%">
        
                  <div class="message-content" style="display: flex;flex-direction: column;">
                    <div class="messagetext" style="display: flex; justify-content: start">
                      <div class="view">
                        {{ message.messageText }}
                      </div>
                    </div>
                    <div class="time" style="display: flex; justify-content: flex-end">
        
                      <div class="view" style="padding-left:70px">
                        {{message.timeAgo}}
                      </div>
                    </div>
                  </div>
                </div>
                <div #endOfChat></div>
              </ng-container>
            </div>
          </div> -->
        <!-- Chat Module -->
          
        <div class="chat-scroll" #chatListElement>
          <div class="description" *ngFor="let messages of msglists;let i = index;">
            <div class="datedisplay" style="display: flex; justify-content: center; align-items: center;">
              <p >{{messages.dateDescription}}</p>
              <!-- <p *ngIf="i==msglists.length-2" style="padding: 5px; border: 2px solid whitesmoke; background-color: whitesmoke;border-radius: 5px; ">{{messages.day}}</p> -->
            </div>
            
            <div class="chat-area">
              <ng-container>
                <div *ngFor="let message of messages.messages" class="message" [ngClass]="{'sender': message.alignment === 'R'}" style="width:100%">
                  <div class="description" >   <p  [ngStyle]="{
                    'position': 'relative',
                    'top': '13px',
                    'font-size': 'small',
                    'display': 'flex',
                    'justify-content': message.alignment === 'R' ? 'end' : 'start'
                  }">{{formatDateTime(message.createdAt,messages.dateDescription) }}</p>
                    <div class="flex-end" style="display: flex;" [ngStyle]="{
                     
                      'justify-content': message.alignment === 'R' ? 'end' : 'start'}">
                      <div class="message-content" style="display: flex; flex-direction: column;word-break: break-all;overflow-wrap: break-word;" [ngStyle]="{
                        'background-color': message.alignment === 'R' ? '#bcadd1' : 'rgb(220, 216, 216)',
                        'margin-left':message.alignment === 'R'?'27px':'0px'
                      }">
                      
                        <div class="messagetext" style="display: flex; justify-content: start;" >
                          <div class="view">
                            {{ message.messageText }}
                          </div>
                        </div>
                        <!-- <div class="time" style="display: flex; justify-content: flex-end; font-size: small;">
                          <div class="dib" style="margin-bottom: 5px;">
                            <div class="view" style="padding-left:70px; position: relative; top: 15px; left: 8px;">
                              {{message.createdOnlyTime}}
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- The last element in the chat container -->
          <div #endOfChat></div>
        </div>
          
        <div class="d-flex flex-row" style="padding: 15px 10px 10px 10px;">
          <input type="text" class="form-control thread-input" placeholder="Type a message here" [(ngModel)]="chatText" />
          <div style="padding: 8px 8px;">
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="newChatLoading">
              <span class="sr-only">Loading...</span>
            </div>
            <img src="assets/svg/send.svg" (click)="sendMessage()" *ngIf="!newChatLoading" style="cursor: pointer" />
          </div>
        </div>
      </div>
      
      <div class="modal fade custom-modal" id="BulkInsert" data-backdrop="static" tabindex="-1" role="dialog"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Bulk Insert</h4>
              <button type="button" class="close" data-dismiss="modal" (click)="bulkHide()">
                <!-- <img src="assets/img/modal-close.png" /> -->
                <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
              </button>
            </div>
            <!-- <div class="overflow" style="overflow-y: scroll; height:550px;margin-top: -70px;width: 100%; "> -->
            <div class="overflow">
              <app-connect-members></app-connect-members>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="suspendModel" data-backdrop="static" tabindex="-1" role="dialog"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <!-- Modal Header -->
              <div class="modal-header">
                <h4 class="modal-title">{{formheading}}</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="SuspendHide()">
                  <!-- <img src="assets/img/modal-close.png" /> -->
                  <mat-icon class="inactive-icon" style="color: red;font-size: 27px;">cancel</mat-icon>
                </button>
              </div>
              <div class="form-group mb-3" style="padding-left: 20px;padding-right: 20px; margin:5px">
                <label >Reason <span style="color: red;">*</span></label>
                <form>
                  <textarea [(ngModel)]="textvalue" placeholder="Reason" name="remarks" class="textarea"
                  style="width: 100%; height:120px;border: 1px solid #e9edf5d1; padding: 10px;  transition: border 0.3s ease;"></textarea>
                </form>
                <div *ngIf="isEmpty() && isusersubmitted" class="text-danger">Please Enter Valid Reason</div>
              </div>
              <div class="button suspend-footer" style="padding-left: 20px;padding-right: 20px;">
                <button class="btn button-color btn-md btn-block sync-btn" type="submit" [disabled]="isEmpty()"   (click)="visible? SuspendMemberSubmit() : activeMemberSubmit()">
                  <span>Submit</span>
                </button>
              </div>
              <!-- <div class="space" style="margin: 10px;"></div> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

