// Vendor Imports
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Local Imports
import { AuthGuard } from "./shared/auth/auth.guard";
import { NoUserAuthGuard } from "./shared/auth/no-user-auth.guard";
import { LoginComponent } from "./login/login.component";
import { MembersComponent } from "./members/members.component";
import { HubspotMembersComponent } from "./hubspot-members/hubspot-members.component";
import { MemberViewComponent } from "./member-view/member-view.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { MemberSubscriptionDetailsComponent } from "./member-subscription-details/member-subscription-details.component";
import { RegisterationComponent } from "./registeration/registeration.component";
import { UpdatesComponent } from "./updates/updates.component";
import { UpdateCommentsComponent } from "./update-comments/update-comments.component";
import { CouponDiscountComponent } from "./coupon-discount/coupon-discount.component";
import { MemberRequestListComponent } from "./member-request-list/member-request-list.component";
import { ImaMembersComponent } from "./ima-members/ima-members.component";
import { ConnectMembersComponent } from "./connect-members/connect-members.component";
import { HelpComponent } from "./help/help.component";
import { TermsComponent } from "./terms/terms.component";
import { RolemanageComponent } from "./rolemanage/rolemanage.component";
import { AnnouncementComponent } from "./announcement/announcement.component";
import { SharepointComponent } from "./sharepoint/sharepoint.component";
import { ChatsComponent } from "./chats/chats.component";
import { SettingsComponent } from "./settings/settings.component";
import { OrganisationComponent } from "./organisation/organisation.component";
import { OrganizationDigitalIdcardComponent } from "./organization-digital-idcard/organization-digital-idcard.component";
import { OrganisationSmtpComponent } from "./organisation-smtp/organisation-smtp.component";
import { ManagesubscriptionComponent } from "./managesubscription/managesubscription.component";
import { SuperadminLoginComponent } from "./superadmin-login/superadmin-login.component";
import { event } from "jquery";
import { EventComponent } from "./event/event.component";
import { BlogComponent } from "./blog/blog.component";
import { ManageusertypeComponent } from "./manageusertype/manageusertype.component";
import { ModulemanageComponent } from "./modulemanage/modulemanage.component";
import { VideoUploadComponent } from "./video-upload/video-upload.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SuperAdminForgotPasswordComponent } from "./super-admin-forgot-password/super-admin-forgot-password.component";
import { AdminProfileComponent } from "./admin-profile/admin-profile.component";
import { BuysubscriptionComponent } from "./buysubscription/buysubscription.component";
import { HelpdeskComponent } from "./helpdesk/helpdesk.component";
import { VendorComponent } from "./vendor/vendor.component";
import { VendorGroupComponent } from "./vendor-group/vendor-group.component";
import { CatogoryComponent } from "./catogory/catogory.component";



const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "superadmin-login",
    component: SuperadminLoginComponent,
    

  },
  // {
  //   path: "registration",
  //   component: RegisterationComponent
  // },
  {
    path: "members",
    component: MembersComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },
  // {
  //   path: "hubspot-members",
  //   component: HubspotMembersComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: "rolemanage",
    component: RolemanageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "module/#012MemberDigitalIDCardView",
    component: OrganizationDigitalIdcardComponent,
    canActivate: [AuthGuard]
  },
  {


    path: "organizationSmtp",
    component: OrganisationSmtpComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "Event",
    component: EventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "News",
    component: BlogComponent,
    canActivate: [AuthGuard]
  },

  {
    path: "connect-members",
    component: ConnectMembersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "terms",
    component: TermsComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [2] }

  },
  {
  path:"admin-profile",
  component:AdminProfileComponent,
  canActivate: [AuthGuard]
  },
  {
    path: "Announcement",
    component: AnnouncementComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },
  {
    path: "Forms&Applications",
    component: SharepointComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },
  // {
  //   path: "member-subscription",
  //   component: MemberSubscriptionDetailsComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [NoUserAuthGuard]
  },
  {
    path:"SA-forgot-password",
    component:SuperAdminForgotPasswordComponent,
    canActivate:[NoUserAuthGuard]
  },
  {
    path: "organization",
    component: OrganisationComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [2] }
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: "updates",
  //   component: UpdatesComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "update-comments",
  //   component: UpdateCommentsComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: "member-view/:id",
    component: MemberViewComponent,
    canActivate: [AuthGuard],
    
    data: { allowedRoles: [1] }
  },
  // {
  //   path: "coupon-discounts",
  //   component: CouponDiscountComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "request-list",
  //   component: MemberRequestListComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: "manageSubscription",
    component: ManagesubscriptionComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [2] }
  },
  {
    path: "modulemanage",
    component: ModulemanageComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [2] }
  },
  {
    path: "manageusertype",
    component: ManageusertypeComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },

  {
    path: "manageusertype",
    component: ManageusertypeComponent,
    canActivate: [AuthGuard]
    ,
    data: { allowedRoles: [1] }
  },

  // {
  //   path: "dashboard",
  //   component: DashboardComponent,
  //   canActivate: [AuthGuard],
  //   data: { allowedRoles: [1, 2] }
  // },
  {
    path: "video-upload",
    component: VideoUploadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "chat",
    component: ChatsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "buysubscriptions",
    component: BuysubscriptionComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },
  {
    path: "vendor",
    component: VendorComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },
  {
    path: "helpdesk",
    component: HelpdeskComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },
  {
    path: "vendorgroup",
    component: VendorGroupComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },
  {
    path: "category",
    component: CatogoryComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: [1] }
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
