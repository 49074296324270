// Vendor Imports
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

// Local Imports
import { MemberService } from "../shared/services/member.service";

declare var $: any;

export interface UserData {
  id: Number;
  title: string;
  reportCount: Number;
  status: Number;
}

@Component({
  selector: "app-updates",
  templateUrl: "./updates.component.html",
  styleUrls: ["./updates.component.css"]
})
export class UpdatesComponent implements OnInit {
  displayedColumns: string[] = ["title", "reportCount", "status", "actions"];
  users: UserData[];
  dataSource: MatTableDataSource<UserData>;
  token: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private memberService: MemberService, private router: Router) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.token = token;
    // Assign the data to the data source for the table to render
    this.memberService.updateBlogList(token).subscribe(
      (data: any) => {
        let userData = this.getUpdatesListArray(data.blogReportSpamList);
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
    this.memberService.updateCommentList(token).subscribe(
      (data: any) => {
        // console.log(data);
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  getUpdatesListArray(updates: any[]) {
    return updates.map(update => ({
      id: update.blogId,
      title: update.blogTitle,
      reportCount: update.reportedMemberCount,
      status: update.blockedStatus
    }));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }
}
