import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { SharedalertService } from '../shared/services/sharedalert.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  sections: Section[] = [];
  html = '<p></p>';
  agreementType: string = "terms";
  agreementTitle: string = "Terms and Conditions";
  token: string;

  datas: any;
  isShowContent: boolean = false;
  constructor(private spinnerService: NgxSpinnerService, private sharedService: SharedalertService, private memberService: MemberService) {
    const newSection: Section = {
      subheading: '',
      content: '',
    };

    this.sections.push(newSection);
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;

    this.getAgreement(this.agreementType)
  }

  getAgreement(type) {
    let params = {
      "agreementType": type
    }
    this.memberService.getAgreement(params, this.token).subscribe(
      (data) => {
        this.spinnerService.hide()
        console.log(data)
        this.datas = data;
        this.html = this.datas.userAgreementList[0].content
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  onagreementTypeChange() {
    console.log(this.agreementType)
    if (this.agreementType == "terms") {
      this.spinnerService.show()
      this.getAgreement(this.agreementType)
      this.agreementTitle = "Terms and Conditions"
    }
    else if (this.agreementType == "privacy") {
      this.spinnerService.show()
      this.getAgreement(this.agreementType)
      this.agreementTitle = "Privacy and Policy"
    }

  }

  addSection() {
    const newSection: Section = {
      subheading: '',
      content: '',
    };

    this.sections.push(newSection);
  }

  saveBtn() {
    if (this.html == '') {
      this.isShowContent = true
      return
    }
    this.spinnerService.show();
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    let params = {
      "agreementType": this.agreementType,
      "content": this.html
    }
    this.memberService
      .updateAgreement(params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            this.spinnerService.hide();
            this.sharedService.customeSuccessAlert(() => {
              this.ngOnInit()
            });
            return;
          }
          else {
            this.spinnerService.hide();
            this.sharedService.errorAlert(() => {
              this.ngOnInit()
            });
            return;
          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
    });
    return;
  }
}


interface Section {
  subheading: string;
  content: string;
}
