import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { MemberService } from '../shared/services/member.service';
import { Router } from "@angular/router";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
declare var $: any;

export interface UserData {
  usertypeId: Number,
  Usertype: string,
  moduleList: any
}
// export class userModuleListResponse {
//   moduleList: any
// }

@Component({
  selector: 'app-manageusertype',
  templateUrl: './manageusertype.component.html',
  styleUrls: ['./manageusertype.component.css']
})
export class ManageusertypeComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginators!: MatPaginator;
  show: boolean = false;
  usertypeForm: FormGroup;
  ModuleList: any;
  userData:any[];
  isRequired:boolean=false;
  ModuleSubscriptionList:any[];
  visible: boolean;
  totalSize:Number;
  detailsHeading:"";
  maxDigits:number; 
  trueArray:boolean[]=[]
  markcolor: number[] = []; 
  isRepeaded:boolean;
  pageSize = 10;
  currentPage = 0;
  dataSources=new MatTableDataSource<any>([]);
  totalLength:number;
  userdataDetails:any[];
  displayedColumn: string[] = ['name', 'email', 'phone', 'designation', 'createdDate'];
  modules: any[] = [];
  formHeading: string;
  token: string;
  usertypeId: any;
  usertype: string;
  Set= new Set();
  isnegative:boolean=false;
  ErrorMessage="";
  count: number = 0;
  ChatSelect:number=-1;
  duplicate:boolean=false;
  addusertypeForm: boolean;
  eventFormOpen: boolean = false;
  usertypeSubmit: boolean;
  addUsertypeForm = false;
  filter: string;
  isusertype: string;
  ArrayList:any[]=[];
  ModulesList:any;
  checkedModuleIds: number[] = [];
  orderbyList:number[] = [];
  dataSource: MatTableDataSource<UserData>;
  moduleCreatedList: MatTableDataSource<any>;
  orderbyIds: any[] = [];
  users:any[];
  splitchar:string=", "
  spacechar:string="  ";
  subscriptionId:Number;
  TokenModuleList:any[];
  displayedColumns: string[] = [

    "User Type",
    "Modules",
    "Actions"
  ];
  userTypeList: any;

  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService, private formBuilder: FormBuilder, private router: Router) {

    this.usertypeForm = this.formBuilder.group({
      usertype: ['', [Validators.required, WhiteSpaceValidator]]
    });

  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.TokenModuleList=user.moduleList.filter((value=>value.isActive)).map((module=>module.moduleId));
    this.subscriptionId=user.subscriptionId;
    this.spinnerService.show();
    console.log('token', this.token);
    this.getUsertype();
    this.markcolor=[];
    this.isRepeaded=false;
    // this.dataSources.paginator = this.paginator; 
    // this.paginator.pageIndex = 0; // Initialize the paginator's page index
    // this.paginator.pageSize = 10;
    // this.paginators.pageIndex = 0; // Initialize the paginator's page index
    // this.paginators.pageSize = 10;
  }
  onPageChange(event: PageEvent) {
    console.log()
    const startIndex = event.pageIndex * event.pageSize;
    const endIndex = startIndex + event.pageSize;
    this.dataSources.data = this.userdataDetails.slice(startIndex, endIndex);
}
getBackgroundColor(id):string{
  if(id==161){
    return "#963bf1";
  }
  else if(id==160){
    return "#009EFB";
  }
  else if(id==170){
    return "#04B440";
  }
  else{
    return "red";
  }
}
  get dynamicMask() {
    this.maxDigits=this.ModuleList.length;
    return `0{0,${this.maxDigits}}`;  // Allow 0 to 40 digits
  }
  ispresent(role):boolean{
    console.log(role.module_id);
    // console.log(this.ModuleSubscriptionList);
    return this.TokenModuleList.includes(role.module_id);

  }

  getUsertype() {
    
    const response = this.memberService.GetUserTypeList(this.token)
      .subscribe((data: any) => {
        // console.log(data.organisationList);
        if (data.statusCode == 200) {
          console.log('Modules amp',this.TokenModuleList);
          this.dataSource=data.usertypeList;
          this.users=data.usertypeList;
          this.trueArray=this.orderbyList.map((value)=>false);
          // this.userData = data.usertypeList;
          this.totalSize=data.usertypeList.length;
          console.log('Userdata', data.usertypeList);
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
         
          if(data.usertypeList==null){
            data.usertypeList=[];
          }
          // setTimeout(() => {
          //   this.spinnerService.hide();
          // }, 100)
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          // this.addModuleForm = false;
          Swal.fire({
            icon: "warning",
            title: data.statusMessage,
            showCloseButton: true,
            allowOutsideClick: false
          }).then(() => {
            this.ngOnInit()
          });
          return;
        }

      }, (err) => {
        // this.unauthorizedException() ;
        if (err.status === 401) {
          this.unauthorizedException();
          this.spinnerService.hide();
        }
      });
          
     
  }

  getModuleBySubscription() {
    // this.ModuleList=[];
    this.spinnerService.show();
    let params = {
      "subscriptionId": this.subscriptionId,
    }
    
    this.memberService.getModuleList(params, this.token).subscribe(
      (data: any) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)
        console.log('data',data.moduleList);
        this.ModuleSubscriptionList= data.moduleList.filter((value=>value.isActive));
        // this.checkedModuleIds = this.ModuleList.moduleList
        //   .filter(module => module.isActive)
        //   .map(module => module.moduleId);
          console.log('ModuleList',this.ModuleSubscriptionList);
          this.spinnerService.hide();
          this.getUsertype();
      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );
  }

  getModules(usertypeId: any) {
    this.spinnerService.show();
    console.log()

    let params = {
      "usertypeId": usertypeId,
    }
    // let supertoken="Bearer" + " " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoie1wiYWRtaW5Vc2VySWRcIjowLFwic2FVc2VySWRcIjo4LFwibWVtYmVySWRcIjowLFwiZW1haWxcIjpcIm5hdGhhbkBkYXRhbmV0aWl4LmNvbVwiLFwib3JnYW5haXNhdGlvbklkXCI6MSxcInNlY3JldEtleVwiOm51bGwsXCJyb2xlSWRcIjoyfSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IlN1cGVyQWRtaW4iLCJleHAiOjE3MjEyOTY2MjR9.p8BHU_SXUSxn9bYrxWTnqRef-DRa_vsVqz2bk07GuKk";
    this.memberService.postUsertypeModuleMapping(params, this.token).subscribe(
      (data: any) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 800)

        console.log('Response ', data);
        this.ModuleList = data.moduleList;
        this.ModuleList.map((value,i)=>{

        if(this.ModuleList[i].moduleId==17 ){
          console.log(this.ModuleList[i]);
          if(this.ModuleList[i].isActive){
          const index = this.ModuleList.findIndex(module => module.moduleId === 18);
          if (index !== -1) {
            // this.ModuleList[i].isActive=false;
            this.ChatSelect=index;
            console.log(`Module ID 16 is found at index: ${index}`);
          } else {
            this.ChatSelect=-1
            console.log('Module ID 16 is not found.');
          }
          }
         
        }
        else if(this.ModuleList[i].moduleId==18){
          const index = this.ModuleList.findIndex(module => module.moduleId === 17);
          if(this.ModuleList[i].isActive){
          if (index !== -1) {
            // this.ModuleList[i].isActive=false;
            this.ChatSelect=index;
            console.log('chAT',this.ChatSelect);
            console.log(`Module ID 16 is found at index: ${index}`);
          } else {
            this.ChatSelect=-1
            console.log('Module ID 16 is not found.');
          }
        }
       
   
        }
      });
        this.orderbyList = data.moduleList.map(module => module.isActive ? module.orderById : null);
       
        console.log('OrderArray',this.orderbyList);
        // this.checkedModuleIds = data.moduleList
        //   .filter(module => module.isActive)
        //   .map(module => module.moduleId);
        this.checkedModuleIds = data.moduleList.map(module => module.isActive ? module.moduleId : null);

          // this.checkedModuleIds.sort((a, b) => a - b)
          console.log('Module Array',this.checkedModuleIds);
      },
      err => {
        this.spinnerService.hide();
        if (err.status === 401) {
          this.spinnerService.hide();
          this.unauthorizedException();
        }
      }
    );

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  get userFormControl() {
    return this.usertypeForm.controls;
  }
  checkAllFieldsEntered(): boolean {
    return Object.values(this.usertypeForm.controls).every(control => {
      // console.log(control.value !== '' && control.value !== null );
      return control.value !== '' && control.value !== null 
    });
  
  }
  // checkModuleLength(module:any):boolean{
  //   this.isRequired=true;
  //   let length=module.map((value)=>value.isActive).filter((value)=>value==false);
  //   console.log(length.length);
  //   this.isRequired=  this.checkAllFieldsEntered() && (length.length>=4);
  //   console.log('Length ',length.length>=4 );
  //   console.log('checkAllFieldsEntered ', this.checkAllFieldsEntered());
  //   console.log('isRequired',this.isRequired);
  //   return  this.isRequired;
  // }
  isSaveEnabledInsert(): boolean {
    // Check if usertype is not empty, null, or undefined
    const usertypeValid = this.userFormControl.usertype.value && this.userFormControl.usertype.value.trim() !== '';
  console.log('Module',this.ModuleSubscriptionList)
    // Count active modules (modules where isActive is true)
    const activeModulesCount = this.ModuleSubscriptionList ? this.ModuleSubscriptionList.filter(module => module.isActive==false).length:0;
    console.log(activeModulesCount);
    // Enable button if usertype is valid and there are at least 4 active modules
    return usertypeValid && activeModulesCount >= 4;
  }
  isSaveEnabled(): boolean {
    // Check if ModuleList is defined and is an array
    const activeModulesCount = this.ModuleList ? this.ModuleList.filter(module => module.isActive).length : 0;
    // Check if usertype is defined, not empty or whitespace, and active modules are at least 4
    return this.usertype && this.usertype.trim() !== '' && activeModulesCount >= 4 && !this.isRepeaded;
  }
  ValidationUpdate():boolean{
    const activeModulesCount = this.ModuleList ? this.ModuleList.filter(module => module.isActive).length : 0;
    return activeModulesCount >= 4 ;
  }
  Validation():boolean{
    const activeModulesCount = this.ModuleSubscriptionList ? this.ModuleSubscriptionList.filter(module => module.isActive==false).length:0;
    console.log(activeModulesCount);
    // Enable button if usertype is valid and there are at least 4 active modules
    return  activeModulesCount >= 4;
  }

  OpencreateUsertype() {
    this.isRequired=false;
    this.usertypeSubmit = false;
    this.getModuleBySubscription();
    this.usertypeForm = this.formBuilder.group({
    // organaisationId: ["", [Validators.required, WhiteSpaceValidator]],
    usertype: ['', [Validators.required, Validators.pattern(/\S/) ]]
    });
    this.visible = true;
    this.formHeading = 'Create User Type';
    $("#newUsertypeCreate").modal("show");
    this.eventFormOpen = true;
    
  }
  OpenUpdateUserType(data) {
    this.isRequired=false;
    this.usertypeSubmit = false;
    this.markcolor=[];
    this.isRepeaded=false;
    console.log('UserType ',data.usertypeId)
    this.duplicate=false;
    this.getModules(data.usertypeId);
    this.usertype = data.usertype;
    this.isusertype = data.usertype;
    this.usertypeId = data.usertypeId
    this.visible = false;
    this.formHeading = "Update User Type";
    this.eventFormOpen = true;
    $("#newUsertypeCreate").modal("show");
    this.usertypeForm = this.formBuilder.group({
      // organaisationId: ["", [Validators.required, WhiteSpaceValidator]],
      usertype: [data.usertype, [Validators.required,Validators.pattern(/\S/)]]
    });

  }

  hide() {
    $("#newUsertypeCreate").modal("hide");
    this.usertypeSubmit = false;
    this.eventFormOpen=false;
    this.usertypeSubmit = false;
    // this.addUsertypeForm = false;
    // this.moduleForm.reset();
    
  }

  createUserhide(){
    $("#newUsertypeCreate").modal("hide");
    $("#memberDetaiils").modal("hide");
    this.usertypeSubmit = false;
    this.eventFormOpen=false;
    this.usertypeSubmit = false;
    // this.totalLength=0;
    this.ngOnInit();
  }

  isEmpty(): boolean {
    if (this.usertype) {
      let a: string = this.usertype.trim();
      return a.length === 0;
    }
    return true;
  }

  onSubmitUsertype() {
 
    this.usertypeSubmit = true;
    if (this.usertypeForm.invalid) {
      return;
    }
    this.usertypeSubmit = false;
    console.log('moduleName',this.ModuleSubscriptionList.filter((value=>!value.isActive)).map((value=>value.moduleName)))
    console.log(this.ModuleSubscriptionList.filter((value=>!value.isActive)));
    let modules=this.ModuleSubscriptionList.filter((value=>!value.isActive)).map((value=>value.moduleId));
    if(modules.length<4){
      Swal.fire({
                  icon: "warning",
                  title: "Please select atleast 4 modules ",
                  showCloseButton: true,
                  allowOutsideClick: false
                })
              
        return;
    }
    this.spinnerService.show();
    let params={
      "usertype": this.usertypeForm.get('usertype').value.trim(),
      "moduleId":modules
    }
    console.log(params);
    this.memberService.postUsertypeInsert
      (params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 200) {
            this.spinnerService.hide();
            $("#newUsertypeCreate").modal("hide");
            this.addUsertypeForm = false;
            Swal.fire({
              icon: "success",
              title: "User Type Added Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;
          }
          else {

            this.spinnerService.hide();
            // this.addModuleForm = false;
            Swal.fire({
              icon: "warning",
              title: data.statusMessage,
              showCloseButton: true,
              allowOutsideClick: false
            })
            return;

          }

        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );
    
    
    // let params = {
    //   "usertype": this.usertypeForm.get('usertype').value.trim()
    // }
    // console.log(params);
    // // let user = JSON.parse(localStorage.getItem("currentUser"));
    // // let token = "Bearer" + " " + user.jwtToken;
    // this.spinnerService.show();
    // this.memberService.postUsertypeInsert
    //   (params, this.token)
    //   .subscribe(
    //     (data: { statusCode: Number; statusMessage: string }) => {

    //       if (data.statusCode === 200) {

    //         this.spinnerService.hide();
    //         $("#newUsertypeCreate").modal("hide");
    //         this.addUsertypeForm = false;
    //         Swal.fire({
    //           icon: "success",
    //           title: "User Type Added Successfully",
    //           showCloseButton: true,
    //           allowOutsideClick: false
    //         }).then(() => {
    //           this.ngOnInit()
    //         });
    //         return;
    //       }
    //       else {

    //         this.spinnerService.hide();
    //         // this.addModuleForm = false;
    //         Swal.fire({
    //           icon: "warning",
    //           title: this.toCapital(data.statusMessage),
    //           showCloseButton: true,
    //           allowOutsideClick: false
    //         }).then(() => {
    //           this.ngOnInit()
    //         });
    //         return;

    //       }

    //     },
    //     err => {
    //       if (err.status === 401) {
    //         this.unauthorizedException();
    //       }
    //     }
    //   );


  }
  DeleteUsertype(data: any) {
    let params = {
      "usertypeId": data.usertypeId

    };
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    // let token = "Bearer" + " " + user.jwtToken;

    Swal.fire({
      title: "Delete Member",
      text: "Are you sure? Do you want to delete user Type?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerService.show();
        this.memberService.postUsertypeDelete
          (params, this.token)
          .subscribe(
            (data: { statusCode: Number; statusMessage: string }) => {

              if (data.statusCode === 200) {

                this.spinnerService.hide();
                $("#newUsertypeCreate").modal("hide");
                this.addUsertypeForm = false;
                Swal.fire({
                  icon: "success",
                  title: "Deleted Sucessfully",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                  this.ngOnInit()
                });

                return;
              }
              else if (data.statusCode == 402) {

                this.spinnerService.hide();
                // this.addModuleForm = false;
                Swal.fire({
                  icon: "warning",
                  title: "Access Deined \n Can't Delete User Type Associated With Module ",
                  showCloseButton: true,
                  allowOutsideClick: false
                }).then(() => {
                  this.ngOnInit()
                });
                return;

              }
            },
            err => {
              if (err.status === 401) {
                this.unauthorizedException();
              }
            }
          );
        this.ngOnInit()
      }
      else {
        return;
      }
    });



  }
// // not Used 
//   toggleBox(orderbyIds: number,i:number,ispresent:boolean){
//   //  this.orderbyList[i]=orderbyIds;
//   //   console.log(orderbyIds);
//   //   console.log('Final Array',this.orderbyList);
//     this.ModuleList[i].moduleId=orderbyIds;
//     }
    
// Update Toggle Box
  
  toggleCheckbox(moduleId,i:number,ispresent:boolean,type) {
   console.log(this.ModuleList[i]);
   if(type == 'check'){
    if(this.ModuleList[i].moduleId==17 ){
      if(!this.ModuleList[i].isActive){
      const index = this.ModuleList.findIndex(module => module.moduleId === 18);
      if (index !== -1) {
        // this.ModuleList[i].isActive=false;
        this.ModuleList[index].orderById=null;
        this.ChatSelect=index;
        console.log(`Module ID 16 is found at index: ${index}`);
      } else {
        this.ChatSelect=-1
        console.log('Module ID 16 is not found.');
      }
      }
      else{
        this.ChatSelect=-1;
      }
    }
    else if(this.ModuleList[i].moduleId==18){
      const index = this.ModuleList.findIndex(module => module.moduleId === 17);
      if(!this.ModuleList[i].isActive){
      if (index !== -1) {
        // this.ModuleList[i].isActive=false;
        this.ChatSelect=index;
        this.ModuleList[index].orderById=null;
        console.log(`Module ID 16 is found at index: ${index}`);
      } else {
        this.ChatSelect=-1
        console.log('Module ID 16 is not found.');
      }
    }
    else{
      this.ChatSelect=-1
    }
    }
    this.ModuleList[i].isActive = !ispresent
  }
    else{
      let array=this.orderbyList.filter((value)=>value!=null);
      let fullarray = this.ModuleList
      .filter((value) => value.orderById != null )  // Filter by orderById and isActive
      .map((value) => value.orderById).filter((value)=>value!=null);
      console.log('Array',fullarray);
      let mySet = new Set();
      fullarray.map((value)=>mySet.add(parseInt(value)));
      console.log('ArraySize',fullarray.length);
      console.log('Set',mySet.size);
      console.log('Istrue',array.includes(parseInt(moduleId)));
      if(fullarray.length==mySet.size){
        console.log("Hey");
        this.trueArray=this.trueArray.map((value)=>false);
        this.isRepeaded=false;
        return;
      }
      console.log('M',this.orderbyList[i]);
      if(this.orderbyList[i]!=moduleId && this.orderbyList[i]!=null ){
        console.log("List");
        array=fullarray;
        if(array.includes(parseInt(moduleId))){
          // this.markcolor.push(i);
          this.trueArray[i]=true;
          console.log("maskcolor",this.markcolor);
         }
         else if( ! array.includes(parseInt(moduleId))){
          // this.markcolor=[];
          this.trueArray[i]=false;
          // this.trueArray.push(false);
          // this.isRepeaded=false;
         }
        console.log('ArrayList',array);
      }
      // if(this.ModuleList[i].orderById!=moduleId){
      //   console.log('ModuleList {i}',this.ModuleList[i].orderById!=moduleId)
      //   this.trueArray=this.trueArray.map((value)=>false);
      // }
       if(array.includes(parseInt(moduleId))){
        // this.markcolor.push(i);
        this.trueArray[i]=true;
        console.log("maskcolor",this.markcolor);
       }
       else if( ! array.includes(parseInt(moduleId))){
        // this.markcolor=[];
        this.trueArray[i]=false;
        // this.trueArray.push(false);
        // this.isRepeaded=false;
       }
        this.isRepeaded= this.trueArray.includes(true);
        console.log('this.trueArray.',this.trueArray);
        console.log('this.',this.trueArray.includes(true));
        // this.markcolor=[];
        if(! this.isRepeaded==true){
          this.markcolor=[];
        }
    //  this.isRepeaded=! trueArray.includes(false)
       this.ModuleList[i].orderById = moduleId
    }
    
  }
  // Insert Toggle Box
  toggleCheckboxInsert(i:number,ispresent:boolean) {
    this.ModuleSubscriptionList[i].isActive = !ispresent
    console.log(this.ModuleSubscriptionList[i].moduleId);
    if(this.ModuleSubscriptionList[i].moduleId==17 ){
      if(!this.ModuleSubscriptionList[i].isActive){
      const index = this.ModuleSubscriptionList.findIndex(module => module.moduleId === 18);
      if (index !== -1) {
        // this.ModuleSubscriptionList[index].isActive=true;
        this.ChatSelect=index;
        console.log(`Module ID 16 is found at index: ${index}`);
      } else {
        // this.ChatSelect=-1
        console.log('Module ID 16 is not found.');
      }
    }else{

      this.ChatSelect=-1;
    }
    
    }
    else if(this.ModuleSubscriptionList[i].moduleId==18 ){
      if(!this.ModuleSubscriptionList[i].isActive){
      const index = this.ModuleSubscriptionList.findIndex(module => module.moduleId === 17);
      if (index !== -1) {
        // this.ModuleSubscriptionList[index].isActive=true;
        this.ChatSelect=index;
        console.log(`Module ID 16 is found at index: ${index}`);
      } else {
        // this.ChatSelect=-1
        console.log('Module ID 16 is not found.');
      }
    }
    else{
      this.ChatSelect=-1;
    }
    
    }
    
  // this.isRequired=this.checkModuleLength(this.ModuleSubscriptionList);
  // console.log('Fn',(this.checkAllFieldsEntered() && false))
  // console.log('Req',!this.isRequired);
  
}
 
  onSubmitUsertypeUpdate() {
  console.log(this.ModuleList)
  this.duplicate=false;
  this.isnegative=false;
  // Form Validation
    this.usertypeSubmit = true;
    if (this.isEmpty()) {
    
      return;
    }
    this.usertypeSubmit = false;
    // Duplicate Validattion
    this.ArrayList=[];
    let set = new Set();
    this.ModuleList.filter(value => !Number.isNaN(value.orderById) ).map((value=>{
    if(value.orderById!=null){
      // console.log('Value OrderBy',value.orderById)
      let parsedValue = parseInt(value.orderById);
    
    if (!Number.isNaN(parsedValue)) {
      this.ArrayList.push(parsedValue);
      set.add(parsedValue);
      
      console.log('setvaue', set);
      console.log('ArrayValue', this.ArrayList);
      
      if (set.size != this.ArrayList.length) {
        this.duplicate = true;
      }
    }
      // console.log(parseInt(value.orderById)< 0);
      // // Validation for Not Negative Integer
      // if(parseInt(value.orderById)< 0){
      //   this.ErrorMessage="Please Enter The Sort Id  In Positive Integer";
      //   this.isnegative=true;
      //   }

    }
    }));
    if(this.duplicate){
      
     this.ErrorThrow("Duplicate Elements In Sort By Order")
      return;
    }
      // if(this.isnegative){
    //   this.ErrorThrow("Please Enter the Sort Id In Positive Integer ")
    //   return;
    
    // }
    this.modules = this.ModuleList
    .filter((item) => item.isActive)
    .map((item) => ({
    moduleIds: parseInt(item.moduleId),
    orderByIds: item.orderById!=null ? parseInt(item.orderById):item.orderById
  }));
  // New changes :
  
  if(this.modules.length<4){
    this.ErrorThrow("Please select atleast 4 modules");
       return;

  }


  // Execution  Of api starts Here
  let params = {
      "userTypeName": this.usertype,
      "userTypeId": this.usertypeId,
      "modules": this.modules
    }

    console.log('params',params);
    // return;
    // let user = JSON.parse(localStorage.getItem("currentUser"));
    // let token = "Bearer" + " " + user.jwtToken;
    this.spinnerService.show();
    this.memberService.postUserTypeModuleMapping
      (params, this.token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {

          if (data.statusCode === 200) {
            
            this.usertypeSubmit = false;
            this.spinnerService.hide();
            $("#newUsertypeCreate").modal("hide");
            this.addUsertypeForm = false;
            Swal.fire({
              icon: "success",
              title: "Updated  Successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;
          }
          else if (data.statusCode === 500){
            this.spinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Something Went Wrong On the Server End",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.ngOnInit()
            });
            return;
          }
          else {

            this.spinnerService.hide();
            // this.addModuleForm = false;
            Swal.fire({
              icon: "warning",
              title: data.statusMessage,
              showCloseButton: true,
              allowOutsideClick: false
            })
            return;

          }
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
          }
        }
      );


  }
  ErrorThrow(Errormessage){
   
    Swal.fire({
      icon: "warning",
      title: Errormessage,
      showCloseButton: true,
      allowOutsideClick: false

    });
  }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);

    });
    return;
  }
  UserTypememberDetails(row){
    // this.paginator.pageIndex = 0; // Initialize the paginator's page index
    // this.paginator.pageSize = 10;
    // this.paginators.pageIndex = 0; // Initialize the paginator's page index
    // this.paginators.pageSize = 10;
    console.log('totalSize',this.totalSize);
    this.spinnerService.show();
    // this.paginators.pageIndex = 0; // Initialize the paginator's page index
    // this.paginators.pageSize = 5;
    this.detailsHeading=row.usertype
    $("#memberDetaiils").modal("show");
    let params={
       "usertypeId": row.usertypeId
    }
    
    this.memberService.postUserTypeMemberdetails
    (params, this.token)
    .subscribe(
      (data: any) => {
    if (data.statusCode === 200) {
        console.log(data);
        this.userdataDetails=data.memberDetailsList;
        this.totalLength=this.userdataDetails.length;
        this.dataSources=data.memberDetailsList;
        this.dataSources = new MatTableDataSource(data.memberDetailsList);
        this.dataSources.paginator = this.paginators;
        this.spinnerService.hide();
        this.ngOnInit();
        }
        else if (data.statusCode === 500){
          this.spinnerService.hide();
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong On the Server End",
            showCloseButton: true,
            allowOutsideClick: false
          }).then(() => {
            this.ngOnInit()
          });
          return;
        }
        else {

          this.spinnerService.hide();
          // this.addModuleForm = false;
          Swal.fire({
            icon: "warning",
            title: data.statusMessage,
            showCloseButton: true,
            allowOutsideClick: false
          })
          return;

        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );


  }


}
