



// Vendor Imports
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

// Local Imports
import { LoginService } from "../shared/services/login.service";
import { LoginResponse } from "../shared/models/user";

@Component({
  selector: 'app-superadmin-login',
  templateUrl: './superadmin-login.component.html',
  styleUrls: ['./superadmin-login.component.css']
})
export class SuperadminLoginComponent implements OnInit {



  loginForm: FormGroup;
  isLoading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.loginService.isLoggedIn()) {
      this.router.navigate(["organization"]);
    }
    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      password: ["", Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
  checkAllFieldsEntered():boolean{
    return Object.values(this.loginForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }
  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    this.loginService
      .superAdminlogin(this.loginForm.value)
      .subscribe((data: LoginResponse) => {
        if (data.statusCode === 200) {
          localStorage.setItem("currentUser", JSON.stringify(data));
          this.router.navigate(["organization"]);
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
        Swal.fire({
          icon: "warning",
          title: "Invalid Email or Password",
          showCloseButton: true,
          allowOutsideClick: false
        });
      });
  }
}
