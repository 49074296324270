<app-header></app-header>

<div class="p-5 mt-5">
    <div>

        <div class="text-right">
            <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Type to Search" />
            </mat-form-field>
        </div>

        <div class="mat-elevation-z8" style="margin-bottom: 60px">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="profile">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Profile
                    </th>
                    <td mat-cell *matCellDef="let row" style="padding: 5px;"><img
                            src="{{ getProfileUrl(row.fromImage) }}" style="width: 50px;
                          height: 50px;" /></td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.fromName }}</td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Email
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.fromEmail }}</td>
                </ng-container>

                <ng-container matColumnDef="organisation">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Organization
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.fromCompany }}</td>
                </ng-container>

                <ng-container matColumnDef="toProfile">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Profile
                    </th>
                    <td mat-cell *matCellDef="let row" style="padding: 5px;"><img src="{{ getProfileUrl(row.toImage) }}"
                            style="width: 50px;
                      height: 50px;" /></td>
                </ng-container>

                <ng-container matColumnDef="toName">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.toName }}</td>
                </ng-container>

                <ng-container matColumnDef="toEmail">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Email
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.toEmail }}</td>
                </ng-container>

                <ng-container matColumnDef="toOrganisation">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Organization
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.toCompany }}</td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>

                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.date }}</td>
                </ng-container>

                <!-- Header row first group -->
                <ng-container matColumnDef="header-row-first-group">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="4"
                        style="text-align: center;">
                        MEMBER CONNECTION REQUEST - FROM
                    </th>
                </ng-container>

                <!-- Header row second group -->
                <ng-container matColumnDef="header-row-second-group">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="4"
                        style="text-align: center;"> MEMBER CONNECTION REQUEST - TO
                    </th>
                </ng-container>

                <!-- Header row second group -->
                <ng-container matColumnDef="header-row-third-group">
                    <th class="mat-table-header" mat-header-cell *matHeaderCellDef [attr.colspan]="1"
                        style="text-align: left;"> REQUEST DATE
                    </th>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group']">
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>

<nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
    <div>Member Connect</div>
</nav>