<app-header></app-header>

<div class="p-5 mt-5">
  <div>
    <div class="d-flex justify-content-between">
      <div class="mt-2">
        <button type="button" class="btn btn-secondary btn-sm" (click)="hubspot()">
          Sync - Hubspot Members
        </button>
      </div>
      <div class="text-right" style="width:85%;">
        <mat-form-field>
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filter" placeholder="Type to Search" />
        </mat-form-field>
      </div>
    </div>
    <!-- <div *ngIf="ishubspotLoading" class="sync-loader">
    </div> -->

    <ngx-spinner
      bdColor="rgba(0, 0, 0, .6)"
      class="spin-img"
      template="<img src='assets/img/logo-D.gif' />"
    >
    </ngx-spinner>


    <div class="mat-elevation-z8" style="margin-bottom: 60px">
      <table mat-table [dataSource]="dataSource" matSort class="hubspot-table">
        <ng-container matColumnDef="member">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Scheduler Date
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.schedulerDate | date: 'shortDate'}}
            <!-- 03/17/2023 -->
          </td>
        </ng-container>

        <ng-container matColumnDef="matchedMember">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Member Name
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.memberName }}

          </td>
        </ng-container>

        <ng-container matColumnDef="matchedMemberProfile">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Email
          </th>
          <td mat-cell *matCellDef="let row" style="padding: 5px;">
            {{ row.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="matchedTitle">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Phone#
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.phone }}

          </td>
        </ng-container>

        <ng-container matColumnDef="matchedOrganisation">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Company
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.company }}

          </td>
        </ng-container>

        <ng-container matColumnDef="matchedLocation">
          <th class="mat-table-header free-select" mat-header-cell *matHeaderCellDef>
            <div class="free">Free Subscription - Added by Admin <br /><span class="select-all">Select all</span>
              <mat-checkbox color="accent" [(ngModel)]="adminselectvalue" [value]="this.hubsportusersList.length > 0"
                (change)="adminselect($event)">
              </mat-checkbox>
            </div>
          </th>
          <td mat-cell *matCellDef="let row" style="text-align: center;">
            <mat-checkbox color="accent" [(ngModel)]="row.checked" (change)='showOptions($event,row)'
              [value]="row.checked"> </mat-checkbox>

          </td>
        </ng-container>

        <ng-container matColumnDef="matchedServiceCount">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef style="text-align: center;">
            <div class="free"> Paid Subscription - User Registration <br /><span class="select-all">Select all</span>
              <mat-checkbox color="accent" [(ngModel)]="paidadminselectvalue" (change)="paidselect($event)">
              </mat-checkbox>
            </div>

          </th>

          <td mat-cell *matCellDef="let row" style="text-align: center;">
            <!-- {{ row.matchedServiceCount }} -->
            <mat-checkbox color="accent" [(ngModel)]="row.paidchecked" (change)='showPaidOptions($event,row)'
              [value]="row.paidchecked"> </mat-checkbox>
            <!-- <input type="checkbox" [(ngModel)]="checkbox.checked" [value]="checkbox.value"><span>{{checkbox.name}}</span> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="review">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Review
          </th>
          <td mat-cell *matCellDef="let row">
            <button type="button" (click)="reviewUser(row)" class="btn btn-primary btn-sm">
              Review
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="approved">
          <th class="mat-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Approved
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-icon *ngIf="row.actionStatus === 'approved'" style="color:#28a745">check_box</mat-icon>
            <!-- <mat-icon
            *ngIf="row.actionStatus === null || row.actionStatus === 'revoked'"
            style="color:#28a745; cursor: pointer"
            (click)="approve(row.memberId, row.matchedMemberId)"
            >check_box_outline_blank</mat-icon
          > -->
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="revoked">
        <th
          class="mat-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          Revoked
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.actionStatus === 'revoked'" style="color:#dc3545"
            >check_box</mat-icon
          >
          <mat-icon
            *ngIf="row.actionStatus === null || row.actionStatus === 'approved'"
            style="color:#dc3545; cursor: pointer"
            (click)="revoke(row.memberId, row.matchedMemberId)"
            >check_box_outline_blank</mat-icon
          >
        </td>
      </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


      </table>

      <table class="hub-btn">
        <tr>
          <td style="width:30%;"></td>
          <td style="width: 0%;"></td>
          <td style="width: 10%;"></td>
          <td style="width: 10%;"></td>
          <td style="width: 8%;"></td>
          <td style="width: 20%;text-align: center;">
            <button class="free-btn" (click)="userFreeRegistration()" [disabled]="isLoading">
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
              </span>
              <span *ngIf="!isLoading">Free Subscription</span>
            </button>
          </td>
          <td style="width:22%;text-align: center;">
            <button class="paid-btn" (click)="userpaidRegistration()" [disabled]="isPaidLoading">
              <span *ngIf="isPaidLoading" class="spinner-border spinner-border-sm text-light" role="status">
              </span>
              <span *ngIf="!isPaidLoading">Paid Subscription</span>
            </button>
          </td>
        </tr>
      </table>


      <!--  <div class="hub-btn">
         <button class="submit-btn" type="button" (click)="userRegistration()">Submit</button> -->
      <!-- <button class="free-btn" (click)="userFreeRegistration()" [disabled]="isLoading">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm text-light" role="status">
          </span>
          <span *ngIf="!isLoading">Free Subscription</span>
        </button> -->

      <!-- <button class="submit-btn" type="button" (click)="userRegistration()">Submit</button>
        <button class="paid-btn" (click)="userpaidRegistration()" [disabled]="isPaidLoading">
          <span *ngIf="isPaidLoading" class="spinner-border spinner-border-sm text-light" role="status">
          </span>
          <span *ngIf="!isPaidLoading">Paid Subscription</span>
        </button>
      </div> -->

      <mat-paginator [pageSizeOptions]="[10, 25, 100]" (page)="getPaginatorData($event)" showFirstLastButtons>
      </mat-paginator>
      <!-- (page)="pageEvent = getPaginatorData($event)" -->
    </div>

  </div>
</div>

<nav class="navbar fixed-bottom footer-bar d-flex justify-content-center">
  <div>Member Connect</div>
</nav>

<!--Member Review Modal -->
<!-- <div class="modal fade" id="memberReviewModel" data-backdrop="static" tabindex="-1" role="dialog"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
     
      <div class="modal-header">
        <h4 class="modal-title">Member Match Details</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="hide()">
          &times;
        </button>
      </div> 
      <div class="modal-body mb-3" *ngIf="showModal">
        <div class="font-weight-bold" style="font-size: 17px">
          {{ currentUser.member }}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;{{
          currentUser.matchedMember
          }}
          -
          <span>{{ currentUser.matchedServiceCount }} value match(es)</span>
        </div>
        <div class="mt-2 text-center" *ngIf="serviceRequest">
          <span class="spinner-border text-dark" role="status"> </span>
        </div>
        <div class="mt-2" *ngIf="!serviceRequest">
          <ul *ngFor="let value of services">
            <li>{{ value.serviceName }}</li>
          </ul>
        </div>
        <hr />
        <div class="text-center mt-2">
          <button *ngIf="currentUser.actionStatus === null" type="button" class="btn btn-success btn-sm mr-2"
            style="padding: 5px 70px;" (click)="approve(currentUser.memberId, currentUser.matchedMemberId)"
            [disabled]="approveLoading">
            <span *ngIf="approveLoading" class="spinner-border text-light spinner-border-sm" role="status">
            </span>
            <span *ngIf="!approveLoading">Approve</span>
          </button>
          <button
            *ngIf="currentUser.actionStatus === null"
            type="button"
            class="btn btn-danger btn-sm mr-2 mb-2"
            style="padding: 5px 70px;"
            (click)="revoke(currentUser.memberId, currentUser.matchedMemberId)"
            [disabled]="revokeLoading"
          >
            <span
              *ngIf="revokeLoading"
              class="spinner-border text-light spinner-border-sm"
              role="status"
            >
            </span>
            <span *ngIf="!revokeLoading">Revoke</span>
          </button>
          <button type="button" class="btn btn-secondary btn-sm" style="padding: 5px 70px;" (click)="hide()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->