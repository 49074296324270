<app-header></app-header>

<div class="container main">
    <div class="card reset-psd-card">
        <div class="card-body reset-psd-card-body">
            <div class="reset-psd-header">
                <div class="reset-psd-text">
                    Email Configuration
                </div>
            </div>
            <!-- my-form.component.html -->
            <div>

                <div class="container1">
                    <div class="textareaStyle1" style="margin-bottom: 10px;">
                        <span class="text-danger">Please ensure that the emails are
                            separated by commas before entering
                            them
                            into the box field.</span>
                    </div>

                    <div class="textareaStyle1" style="color: black;">
                        <span>Email To <span class="text-danger"> *</span> </span>
                    </div>
                    <textarea [(ngModel)]="emailTo" type="email" class="textareaStyle" (input)="adjustTextareaHeight()"
                        placeholder="To email"></textarea>
                    <div class="textareaStyle1" *ngIf="!emailToValid">
                        <span>EmailTo mandatory </span>
                    </div>
                    <div class="textareaStyle1" style="color: black;">
                        <span>Email Cc<span class="text-danger"> *</span> </span>
                    </div>
                    <textarea [(ngModel)]="emailCc" type="email" class="textareaStyle" (input)="adjustTextareaHeight()"
                        placeholder="Cc email"></textarea>
                    <div class="textareaStyle1" *ngIf="!emailCcValid">
                        <span>EmailCc mandatory </span>
                    </div>
                </div>

                <div class="btn-style">
                    <button class="sync-btn" [disabled]="this.emailTo =='' || this.emailCc ==''"
                        (click)="submitForm()">Submit</button>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
    bdColor="rgba(0, 0, 0, .6)"
    class="spin-img"
    template="<img src='assets/img/logo-D.gif' />"
    >
    </ngx-spinner>
</div>