// Vendor Imports
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

// Local Imports
import { MemberService } from "../shared/services/member.service";
import {
  MemberConnectionListResponse,
  MemberConnectionServiceList,
  MemberConnectionResponse
} from "../shared/models/user";

declare var $: any;

export interface MemberConnectionList {
  memberId: Number;
  matchedMemberId: Number;
  member: string;
  matchedMember: string;
  matchedServiceCount: Number;
  matchedMemberDesignation: string;
  matchedMemberLocation: string;
  matchedMemberProfileImageUrl: string;
  matchedMemberorganizationName: string;
  actionStatus: string;
}

@Component({
  selector: 'app-ima-members',
  templateUrl: './ima-members.component.html',
  styleUrls: ['./ima-members.component.css']
})
export class ImaMembersComponent implements OnInit {
  showModal = false;
  approveLoading: boolean;
  revokeLoading: boolean;
  serviceRequest: boolean;
  services: MemberConnectionServiceList[];
  displayedColumns: string[] = [
    "member",
    "matchedMember",
    "matchedMemberProfile",
    "matchedTitle",
    "matchedOrganisation",
    "matchedLocation",
    "matchedServiceCount",
   //"review",
    //"approved"
  ];
  users: MemberConnectionList[];
  dataSource: MatTableDataSource<MemberConnectionList>;
  currentUser: MemberConnectionList;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private memberService: MemberService, private router: Router) {}

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    // Assign the data to the data source for the table to render
    this.memberService.getMemberConnections(token).subscribe(
      (data: { memberConnectList: MemberConnectionListResponse[] }) => {
        let userData = this.getMemberListArray(data.memberConnectList);
        this.users = userData;
        this.dataSource = new MatTableDataSource(userData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getMemberListArray(memberList: MemberConnectionListResponse[]) {
    return memberList.map(member => ({
      memberId: member.memberId,
      matchedMemberId: member.matchedMemberId,
      member: member.firstName + " " + member.lastName,
      matchedMember:
        member.matchedMemberfirstName + " " + member.matchedMemberlastName,
      matchedServiceCount: member.matchedServiceCount,
      matchedMemberDesignation: member.matchedMemberDesignation,
      matchedMemberLocation: member.matchedMemberLocation,
      matchedMemberProfileImageUrl: member.matchedMemberProfileImageUrl,
      matchedMemberorganizationName: member.matchedMemberorganizationName,
      actionStatus: member.actionStatus
    }));
  }

  reviewUser(currentUser: any) {
    this.serviceRequest = true;
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    let params = {
      memberId: currentUser.memberId,
      matchedMemberId: currentUser.matchedMemberId
    };
    $("#memberReviewModel").modal("show");
    this.currentUser = currentUser;
    this.showModal = true;
    // Assign the data to the data source for the table to render
    this.memberService.getMemberConnectionServices(params, token).subscribe(
      (data: { memberConnectServiceList: MemberConnectionServiceList[] }) => {
        this.services = data.memberConnectServiceList;
        this.serviceRequest = false;
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  //Bootstrap Modal Close event
  hide() {
    $("#memberReviewModel").modal("hide");
    this.showModal = false;
  }

  approve(id: Number, matchId: Number) {
    this.approveLoading = true;
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    let params = {
      memberId: id,
      matchedMemberId: matchId,
      actionStatus: "approved"
    };
    this.memberService.memberConnection(params, token).subscribe(
      (data: MemberConnectionResponse) => {
        if (
          data.statusCode === 200 &&
          data.statusMessage.toLowerCase() === "approved success"
        ) {
          this.users = this.users.map(user => {
            if (user.memberId === id && user.matchedMemberId === matchId) {
              return {
                memberId: user.memberId,
                matchedMemberId: user.matchedMemberId,
                member: user.member,
                matchedMember: user.matchedMember,
                matchedServiceCount: user.matchedServiceCount,
                matchedMemberDesignation: user.matchedMemberDesignation,
                matchedMemberLocation: user.matchedMemberLocation,
                matchedMemberProfileImageUrl: user.matchedMemberProfileImageUrl,
                matchedMemberorganizationName:
                  user.matchedMemberorganizationName,
                actionStatus: "approved"
              };
            }
            return user;
          });
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          $("#memberReviewModel").modal("hide");
          this.showModal = true;
          this.approveLoading = false;
          return;
        }
        if (
          data.statusCode === 200 &&
          data.statusMessage.toLowerCase() === "approved limit exceeded"
        ) {
          Swal.fire({
            icon: "warning",
            title: "Approve limit exceeded for this member",
            allowOutsideClick: false,
            showCloseButton: true
          }).then(() => {
            this.approveLoading = false;
          });
          return;
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }

  // revoke(id: Number, matchId: Number) {
  //   this.revokeLoading = true;
  //   let user = JSON.parse(localStorage.getItem("currentUser"));
  //   let token = "Bearer" + " " + user.jwtToken;
  //   let params = {
  //     memberId: id,
  //     matchedMemberId: matchId,
  //     actionStatus: "revoked"
  //   };
  //   this.memberService.memberConnection(params, token).subscribe(
  //     (data: MemberConnectionResponse) => {
  //       if (
  //         data.statusCode === 200 &&
  //         data.statusMessage.toLowerCase() === "revoke success"
  //       ) {
  //         this.users = this.users.map(user => {
  //           if (user.memberId === id && user.matchedMemberId === matchId) {
  //             return {
  //               memberId: user.memberId,
  //               matchedMemberId: user.matchedMemberId,
  //               member: user.member,
  //               matchedMember: user.matchedMember,
  //               matchedServiceCount: user.matchedServiceCount,
  //               actionStatus: "revoked"
  //             };
  //           }
  //           return user;
  //         });
  //         this.dataSource = new MatTableDataSource(this.users);
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //         $("#memberReviewModel").modal("hide");
  //         this.showModal = true;
  //         this.revokeLoading = false;
  //         return;
  //       }
  //       if (
  //         data.statusCode === 200 &&
  //         data.statusMessage.toLowerCase() === "approved limit exceeded"
  //       ) {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "Approve or Revoke limit exceeded",
  //           allowOutsideClick: false,
  //           showCloseButton: true
  //         }).then(() => {
  //           this.revokeLoading = false;
  //         });
  //         return;
  //       }
  //     },
  //     err => {
  //       if (err.status === 401) {
  //         this.unauthorizedException();
  //       }
  //     }
  //   );
  // }

  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }

  getProfileUrl(img: string) {
    if (img === null) {
      return "assets/img/user.png";
    } else {
      return img;
    }
  }
}
