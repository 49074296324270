// Vendor Imports
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { Workbook } from 'exceljs';
import { MembersComponent } from '../members/members.component';

import * as XLSX from 'xlsx';
type AOA = any[][];
// Local Imports
import { MemberService } from "../shared/services/member.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "../modal/modal.component";
import { FormControl, FormGroup } from "@angular/forms";
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from "../shared/services/file.service";

declare var $: any;

export interface RequestMemberList {
  memberId: Number;
  memberName: string;
  email: string;
  yetToConnectMemberList: []
}

export interface MemberConnectionList {
  memberName: string;
  date: Number;
}
@Component({
  selector: 'app-connect-members',
  templateUrl: './connect-members.component.html',
  styleUrls: ['./connect-members.component.css']
})
export class ConnectMembersComponent implements OnInit {
  showModal = false;
  private dynamicDropdownOptions = [
    'Regular',
    'Privileged',
    'Most Privileged',
    'Guest',
    'Admin'
  ];
  approveLoading: boolean;
  revokeLoading: boolean;
  serviceRequest: boolean;
  services: MemberConnectionList[];
  searchtext: "";
  token:string;
  organizationName:string;
  roleTypes = [];
  users: RequestMemberList[];
  currentUser: RequestMemberList;
  selectedmemberId: Number;
  @ViewChild(MembersComponent) memberComponent!: MembersComponent;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  tempsearchdata: any;
  ismemberconnect: boolean;
  teamInitial: any;
  filter: string;
  isMapped:any[];
  ismemberconnectlist: boolean = true;
  yetToConnectMemberList: any;
  data: any[] = [];
  roleList: any;
  pageSize = 10;
  currentPage = 0;
  displayedColumns: string[] = [];
  dataSource: any;
  specificFields: string[] = ['First Name', 'Last Name', 'Title', 'Email', 'Phone No', 'User Type'];
  selectAll: boolean = false;
  selection: MatTableDataSource<any>;
  isShowValidate: boolean = false;
  totalItems: number;

  public onFileChange(event) {
    console.log("call")
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const binaryData = e.target.result;
        const workbook = XLSX.read(binaryData, { type: 'binary' });
        const sheetName = workbook.SheetNames[0]; // Assuming you're interested in the first sheet
        const worksheet = workbook.Sheets[sheetName];
        this.data = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        event.target.value = '';
        this.validateAndFilterData();
      };
      reader.readAsBinaryString(file);
    }
  }

  // validateAndFilterData(): void {
  //   // Filter out rows with any empty values
  //   let filteredData = this.data.filter(item =>
  //     Object.values(item).some(value => value !== '')
  //   );
  //   console.log(filteredData);

  //   // Check if all required fields are present
  //   const hasRequiredFields = filteredData.every(row =>
  //     ['First Name*', 'Last Name*', 'Title*', 'Organisation*', 'Email*', 'Phone No*', 'User Type*'].every(field =>
  //       row.hasOwnProperty(field) && row[field] !== ''
  //     )
  //   );

  //   if (!hasRequiredFields) {
  //     this.errorMessage();
  //     return;
  //   }

  //   // Validate phone numbers and emails and add 'isValid' property to each row
  //   filteredData.forEach(row => {
  //     const isPhoneValid = /^\d{10}$/.test(row['Phone No*']);
  //     const isEmailValid = /\S+@\S+\.\S+/.test(row['Email*']);

  //     // Add 'isValid' property only if the condition is satisfied
  //     if (!isPhoneValid || !isEmailValid) {
  //       row['Message'] = false;
  //     } else {
  //       row['Message'] = true;
  //     }
  //   });

  //   function isValuePresent(array: any[], property: string, value: any): boolean {
  //     return array.some(obj => Object.values(obj).includes(value));
  //   }
  //   const propertyName = 'Message';
  //   const targetValue = false;

  //   const isPresent = isValuePresent(filteredData, propertyName, targetValue);
  //   if (isPresent) {
  //     this.isShowValidate = true
  //   }
  //   else {
  //     filteredData = filteredData.map(({ Message, ...rest }) => rest);
  //   }
  //   // Set up table display
  //   this.displayedColumns = Object.keys(filteredData[0] || {});
  //   this.dataSource = new MatTableDataSource(filteredData);
  //   // this.dataSource = filteredData;
  // }


  validateAndFilterData(): void {
    const isValidPhone = (phone: string) => /^\d{10}$/.test(phone);
    const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
    console.log('data',this.data);

    // Filter out rows with any empty values
    let filteredData = this.data.filter(item =>
      Object.values(item).some(value => value !== '')
    );

    // Check if all required fields are present
    const hasRequiredFields = filteredData.every(row =>
      ['First Name *', 'Last Name *', 'Title *', 'Email *', 'Phone No *', 'User Type *'].every(field =>
        row.hasOwnProperty(field) && row[field] !== ''
      )
    );

    if (!hasRequiredFields) {
      this.errorMessage();
      return;
    }

    // Validate phone numbers and emails and add 'isValid' property to each row
    filteredData.forEach(row => {
      row['Message'] = isValidPhone(row['Phone No *']) && isValidEmail(row['Email *']);
    });

    // Check if 'Message' property has a 'false' value
    const isPresent = filteredData.some(row => row['Message'] === false);

    this.isShowValidate = isPresent;

    // If 'Message' is 'false', remove the 'Message' property from each row
    if (!isPresent) {
      filteredData = filteredData.map(({ Message, ...rest }) => rest);
    }

    // Set up table display
    this.displayedColumns = Object.keys(filteredData[0] || {});
    this.dataSource = new MatTableDataSource(filteredData);
    this.totalItems = filteredData.length
    console.log('Data Source ',this.dataSource);
  }


  shouldShowColumn(column: string): boolean {
    // Replace this condition with your actual logic
    // For example, hide the 'isValid' column
    return column !== 'Error';
  }



  onCheckboxChange(row: any): void {
    console.log('Checkbox change for row:', row);
  }

  onPageChange(event: any): void {
    this.currentPage = event.pageIndex;
  }


  constructor(private memberService: MemberService, private spinnerService: NgxSpinnerService, private router: Router, private matdialog: MatDialog, private fileService: FileService) {
    // this.spinnerService.show();
  }
  form = new FormGroup({
    searchtext: new FormControl()
  });


  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    this.token = "Bearer" + " " + user.jwtToken;
    this.organizationName=user.organaisationName;
    console.log("lokgge");
    this.getRoles();
    
  }
  ngAfterViewInit(): void {
    // Ensure the MemberComponent is initialized before calling its methods
    if (this.memberComponent) {
      this.memberComponent.ngOnInit(); // Call the method in MemberComponent
    }
  }
  


  getRoles() {
    

    this.memberService
      .getRoleValues(this.token)
      .subscribe(
        data => {
          this.roleList = data;
          console.log(this.roleList);
          this.roleTypes = this.roleList.roleType
          
        },
        err => {
          if (err.status === 401) {
            this.unauthorizedException();
            this.spinnerService.hide();
          }
        }
      );
  }
  downloadExcels(){
    this.createExcelFile();
  }


public async createExcelFile() {
    
  // Create a new workbook and add worksheets
  const workbook = new Workbook();
  const dataSheet = workbook.addWorksheet('Data');
  // Data Sheet For Creating an Excel 
  const optionsSheet = workbook.addWorksheet('Options');
  // Define dropdown options(Mapping According To the Admin subscription Usertpe )
  const dynamicDropdownOptions = this.roleTypes.map((value=>value.role));
  console.log('Dynamic ',dynamicDropdownOptions);
  // Add header row to the data sheet
  // --------------->Heading -----------------<
  //dataSheet.addRow(['First Name', 'Last Name', 'Title', 'Organization', 'Email', 'Phone No','User Type']);
  // Add data rows to the data sheet
  //---------------->Define Empty Row--------------->
  //dataSheet.addRow([ '', '', '', '','','','']);

  // Rich Text Foematting 
  dataSheet.getCell('A1').value = {
  'richText': [
    {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'First Name'},
    {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
  ]
  }
  dataSheet.getCell('B1').value = {
    'richText': [
      {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Last Name'},
      {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
    ]
    }
    dataSheet.getCell('C1').value = {
      'richText': [
        {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Title'},
        {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
      ]
      }
      // dataSheet.getCell('D1').value = {
      //   'richText': [
      //     {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Organization'},
      //     {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
      //   ]
      //   }
        dataSheet.getCell('D1').value = {
          'richText': [
            {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Email'},
            {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
          ]
          }
          dataSheet.getCell('E1').value = {
            'richText': [
              {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'Phone No'},
              {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
            ]
            }
            dataSheet.getCell('F1').value = {
              'richText': [
                {'font': {'size': 12,'color': { argb: 'FF000000' },'name': 'Calibri','family': 2,'scheme': 'minor'},'text': 'User Type'},
                {'font': {'size': 12,'color': {'argb': 'FFFF6600'},'name': 'Calibri','family': 2,'scheme': 'minor'},'text': ' *'},
              ]
              }
  // #Excel Validation Code 
  // dataSheet.addRow(['Jane', 'Smith', 'jane@example.com', '0987654321', '']);
  // Add dropdown options to the options sheet
  // Dynamic Drop Down Applicable For First Row 
  // const validationFormulaNumber = 'AND(NOT(ISBLANK(F2)), ISNUMBER(F2), F2 >= 0, F2 <= 9999999999)';

  // for (let i = 2; i <= 10000; i++) {
  //   const cellAddress = `F${i}`;
  //   dataSheet.getCell(`F${i}`).dataValidation = {
  //       type: 'whole',
  //       operator: 'between',
  //       formulae: [validationFormulaNumber.replace('F2', cellAddress)], 
  //       showErrorMessage: true,
  //       errorTitle: 'Invalid input',
  //       error: 'Only numbers are allowed in this field.'
  //   };
  // }
  // Validation For Phone Email 
//   const validationFormulaEmail = 'AND(NOT(ISBLANK(E2)), ISNUMBER(FIND("@", E2)), ISNUMBER(FIND(".", E2)))';
//   for (let i = 2; i <= 10000; i++) {
//     const cellAddress = `E${i}`;
//     dataSheet.getCell(cellAddress).dataValidation = {
//         type: 'custom',
//         formulae: [validationFormulaEmail.replace('E2', cellAddress)], // Update the formula for each cell
//         showErrorMessage: true,
//         errorTitle: 'Invalid Email',
//         error: 'Please enter a valid email address that contains "@" and "." and is not empty.'
//     };
// }
// validation For not Null 
// const validationFormula = 'NOT(ISBLANK(A2))';
//   // Apply validation to columns A, B, C, and D for rows 2 to 10000
// for (let col of ['A', 'B', 'C', 'D']) {
//   for (let i = 2; i <= 10000; i++) {
//       const cellAddress = `${col}${i}`;
//       dataSheet.getCell(cellAddress).dataValidation = {
//           type: 'custom',
//           formulae: [validationFormula.replace('A2', cellAddress)], // Replace the cell reference dynamically
//           showErrorMessage: true,
//           errorTitle: 'Empty Field',
//           error: 'This field cannot be left blank. Please enter a value.'
//       };
//   }
// }

  // validation For not Null 

  // Adjust cell reference in formula as needed

// Apply validation to the first 7 rows and columns
// 
// #endregion

const columnsToValidate = ['A', 'B', 'C', 'D', 'E', 'F'];
const rowStart = 2; // Starting row for validation
  const rowEnd = 10000; // Ending row for validation
  // const nonEmptyList = ['Not empty'];

  for (let col of columnsToValidate) {
    for (let i = rowStart; i <= rowEnd; i++) { // Apply validation to rows 2 to 100
      const cellAddress = `${col}${i}`;
    
   if(col=='F'){
        dynamicDropdownOptions.forEach((option, index) => {
    
          optionsSheet.getCell(`A${index + 1}`).value = option;
        });
        
        // Define the range for dropdown options  ()
        const optionsRange = `Options!$A$1:$A$${dynamicDropdownOptions.length}`;
        
        
          dataSheet.getCell(cellAddress).dataValidation = {
              type: 'list',
              formulae: [optionsRange],
              showErrorMessage: true,
              allowBlank: true
          };
      
      }
      else if(col=='E'){
        dataSheet.getCell(cellAddress).dataValidation = {
        type: 'textLength',
        operator: 'equal',
        showErrorMessage: true,
        allowBlank: false,
        formulae: [10],
        errorStyle: 'error',
        error: 'Phone Number Must Be Numeric and  10 Digits'
        }
      }
      else if(col=='A'){
        dataSheet.getCell(cellAddress).dataValidation = {
          type: 'textLength',
          operator: 'greaterThanOrEqual',
          showErrorMessage: true,
          allowBlank: false,
          formulae: [3],
          errorStyle: 'error',
          error: 'First Name Should Be More Than 3 Char '
          }

      }

      
      else {
      dataSheet.getCell(cellAddress).dataValidation = {
        type: 'textLength',
        operator: 'greaterThanOrEqual',
        showErrorMessage: true,
        allowBlank: false,
        formulae: [1],
        errorStyle: 'error',
        error: 'Fields Should be More Than One Char'

    };
  }
    }
  }

  // dynamicDropdownOptions.forEach((option, index) => {
    
  //   optionsSheet.getCell(`A${index + 1}`).value = option;
  // });
  // dataSheet.getCell('H1').font = { color: { argb: 'FFFF0000' } };
  // // Define the range for dropdown options  ()
  // const optionsRange = `Options!$A$1:$A$${dynamicDropdownOptions.length}`;
//   dataSheet.getCell('G2').dataValidation = {
//     type: 'list',
//     formulae: [optionsRange],
//     showErrorMessage: true,
//     allowBlank: true
// };

// Apply data validation to a large range in the User Type column
// for (let i = 2; i <= 100000; i++) {
//     dataSheet.getCell(`G${i}`).dataValidation = {
//         type: 'list',
//         formulae: [optionsRange],
//         showErrorMessage: true,
//         allowBlank: true
//     };
// }

  // Apply data validation for the User Type column in the data sheet
  // dataSheet.getColumn(7).eachCell({ includeEmpty: true }, (cell, rowNumber) => {

  //   if (rowNumber > 1) { // Skip the header row
  //     cell.dataValidation = {
  //       type: 'list',
  //       formulae: [optionsRange],
  //       showErrorMessage: true, 
  //       allowBlank: true
  //     };
  //   }
  // });
    
  dataSheet.getRow(1).eachCell({ includeEmpty: true }, (cell,colnumber) => {
  dataSheet.getColumn(colnumber).width = 20;
   
});

  // dataSheet.columns = dataSheet.columns.slice(0,7);
  
  
  // Write to a buffer and trigger download using plain JavaScript
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Userdata.xlsx';
  document.body.appendChild(a);
  a.click();
  // Clean up
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 //Bootstrap Modal Close event
  hide() {
    $("#memberReviewModel").modal("hide");
    this.showModal = false;
  }

  downloadExcel() {
    this.fileService.getFile().subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'format.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  search(value: string, row: any) {
    // console.log(row.yetToConnectMemberList)
    //console.log(this.toppingList)
    let filter = value.toLowerCase();
    return row.yetToConnectMemberList.filter
      ((option) => option.memberName.startsWith(filter)
      );
  }

  sendEmail() {
    this.spinnerService.show();
    console.log(this.dataSource.data)
    if (this.isShowValidate) {
      this.validateMessage()
      return;
    }
    let userData = this.getMemberListArray(this.dataSource.data);
    console.log('params',userData);
    
    let set = new Set();
    let arrayList=[];
    let a=userData.map((value=>{
      set.add(value.email);
      set.add(value.phoneNo)
      arrayList.push(value.email);
      arrayList.push(value.phoneNo);
      console.log(set);
      console.log(arrayList);
    }));
    console.log('is true',set.size==arrayList.length);
    if(set.size!=arrayList.length){
      this.spinnerService.hide();
      Swal.fire({
        icon: "warning",
        title: "Duplicate Elements Found ",
        text: "Either Phone#/Email is Duplicate.",
        allowOutsideClick: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "OK"
      }).then(() => {
      });
   
      return;
    }

    let params = {
      "memberList": userData
    }
    
    // if(this.isMappedorNot(this.roleTypes)){
    //   console.log(false);
    //   return;
    // }

    
  //  return;
    console.log('params',params);
    if(params.memberList.length==0){
      this.spinnerService.hide();
      Swal.fire({
      icon: "warning",
      title: "Invalid Excel format",
      text: "Excel file has no data",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "OK"
      })
      return;
    }
  
    // return;
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let token = "Bearer" + " " + user.jwtToken;
    this.memberService
      .postAdminMemberRegister(params, token)
      .subscribe(
        (data: { statusCode: Number; statusMessage: string }) => {
          if (data.statusCode === 201) {
            this.spinnerService.hide();
            this.dataSource = ''
            Swal.fire({
              icon: "success",
              title: "User data added successfully",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
                window.location.reload();
             });
            return;
          }
          else if (data.statusCode === 401) {
            this.spinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Email ID already Exist",
              allowOutsideClick: false,
              showCloseButton: true
            }).then(() => {
              
            });
          }
          else if(data.statusCode === 403){
            this.spinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Invalid Request",
              text:data.statusMessage,
              allowOutsideClick: false,
              confirmButtonColor: "#d33",
             confirmButtonText: "OK"
            }).then(() => {
              
            });
          }
          else {
            this.spinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: data.statusMessage,
              allowOutsideClick: false,
              showCloseButton: true
            }).then(() => {
             
        
              
           
            });
          }
        },
        err => {
          if (err.status === 401) {
            this.spinnerService.hide();
            Swal.fire({
              icon: "warning",
              title: "Something went wrong",
              allowOutsideClick: false,
              showCloseButton: true
            }).then(() => {
            });
            this.unauthorizedException();
          }
        }
      );
  }
  isMappedorNot(rolemap){
   let a= rolemap.filter((value)=>this.isMapped.includes(value.roleId)
     
    );
    return a.length==0;

  }

  formatPhoneNumber(event: any) {
    // Remove non-digit characters from the input
    let value = event.replace(/\D/g, '');

    if (value.length <= 3) {
      value = value;
    } else if (value.length <= 6) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else {
      value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }

    // Update the form control value with the formatted value
    return value
  }

  getMemberListArray(memberList) {
    return memberList.map(member => ({
      'email': member['Email *'],
      'firstName': member['First Name *'],
      'lastName': member['Last Name *'],
      'title': member['Title *'],
      'phoneNo': member['Phone No *'].toString(),
      'organaisationName': this.organizationName,
      'roleId': this.mapwithroleId(member['User Type *'])
      // 'roleId': member['User Type*'] == "Regular" ? 4 : member['User Type*'] == "Privileged" ? 5 : member['User Type*'] == "Most privileged" ? 6 : 0
    }));
  }
  mapwithroleId(Search:any){
    let a=this.roleTypes.filter((value=>value.role==Search)).map((value)=>value.id);
    return a[0];
  }
  clearExcel() {
    this.dataSource = ''
  }

  errorMessage() {
    this.spinnerService.hide();
    Swal.fire({
      icon: "warning",
      title: "Invalid Excel format",
      text: "All Fields are Required!.",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "OK"
    }).then(() => {
    });
    return;
  }

  stripMask(phoneNumber) {
  
    const areaCode = phoneNumber.slice(0, 3);
    const centralOfficeCode = phoneNumber.slice(3, 6);
    const lineNumber = phoneNumber.slice(6, 10);
  
    return `(${areaCode})-${centralOfficeCode}-${lineNumber}`;
  }

  validateMessage() {
    this.spinnerService.hide();
    Swal.fire({
      icon: "warning",
      title: "Invalid Excel format",
      text: "Either Phone#/Email is invalid.",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "OK"
    }).then(() => {
    });
    return;
  }
  unauthorizedException() {
    this.spinnerService.hide();
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }

  getProfileUrl(img: string) {
    if (img === null) {
      return "assets/img/user.png";
    } else {
      return img;
    }
  }
}


