import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MemberService } from '../shared/services/member.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { WhiteSpaceValidator } from '../shared/validators/white-space-validator';
declare var $: any;
@Component({
  selector: 'app-catogory',
  templateUrl: './catogory.component.html',
  styleUrls: ['./catogory.component.css']
})
export class CatogoryComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginators!: MatPaginator;
  catagoryDropDown: any[];
  subCatagoryDropDown:any[];
  isPresent: boolean=false;
  subcatagoryForm:FormGroup;
  catagoryForm: FormGroup;
  submitControl:boolean;
  selectedType:string='';
  token: string='';
  categoryForm:FormControl;
  displayedColumns: string[] = ['categoryName', 'subCategoryList', 'Category Actions','Sub-Category Action'];
  dataSource: MatTableDataSource<any>;
  dataSourcefilter: MatTableDataSource<any>;
  pageNo:number=1;
  PageSize:Number=5;
  pageNos:number=1;
  PageSizes:Number=5;
  heading:string='';
  footer:string='';
  catogoryList:any[]=[];
  subcatogorydetails:string='';
  subdisplayedColumns:string[]=['Sub Category Name','Action'];
  catogorySubmitted: boolean;
  catagoryId: number;
  subcategoryId: number;
  subcatogorySubmit: boolean;
  object: any[]=[];
  rows: any[]=[];
  categoryName: any;
  constructor(private spinnerService: NgxSpinnerService, private memberService: MemberService,  private router: Router,
    private formBuilder: FormBuilder) {
      this.catagoryForm = this.formBuilder.group({
        CategoryNames: ['', [Validators.required,WhiteSpaceValidator]]
      });
      this.subcatagoryForm = this.formBuilder.group({
        CategoryName: ['', [Validators.required,WhiteSpaceValidator]]
      });
    

  }

  ngOnInit(): void {
  let user = JSON.parse(localStorage.getItem("currentUser"));
  this.token = "Bearer" + " " + user.jwtToken;
  // this.spinnerService.show()
  this.getCatogoryList();
  }
  GetsubcatogoryFilter(row,categoryName){
    this.categoryName=categoryName;
    this.spinnerService.show();
    // console.log()
    this.memberService
    .getCatogoryList( this.token)
    .subscribe(
      (data: any) => {
        if (data.statusCode === 200) {
        console.log('row',row[0].parentCategoryId);
        // this.object = data.categoryLists
        // .flatMap(category => category.subCategoryList || []) // Flatten subCategoryList arrays
        // .filter(subCategory => subCategory.parentCategoryId === row.parentCategoryId); 
        //   this.dataSourcefilter=new MatTableDataSource<any>(this.object);
        //   this.dataSourcefilter.paginator=this.paginator;
        //   console.log('objec',this.object)
        this.object=data.categoryLists
        .map(value => value.subCategoryList) // Extract subCategoryList arrays
        .flat() // Flatten the array of arrays into a single array
        .map(value => ({
          categoryName: value.categoryName,
          categoryId: parseInt(value.categoryId),
          parentCategoryId: parseInt(value.parentCategoryId)
        })); 
         this.object=this.object.filter(
          (x) => x.parentCategoryId == row[0].parentCategoryId
          );
          this.dataSourcefilter=new MatTableDataSource<any>(this.object);
          console.log('objet',this.object);
          this.rows=row;
          // this.spinnerService.show();
         console.log(this)
          this.subcatogorydetails="Category : "+categoryName;
          // this.dataSourcefilter=new MatTableDataSource<any>(row);
          //  this.dataSourcefilter.paginator=this.paginators;
          console.log('ROw',row);
          this.spinnerService.show();
          $("#categoryDetails").modal("show");
          this.spinnerService.hide();
          // this.object = this.catogoryList
          // .map(category => category.subCategoryList || []) // Map to subCategoryList arrays
          // .reduce((acc, subCategory) => acc.concat(subCategory), []); 
          // console.log('usb',data.categoryLists.subCategoryList);
          // console.log(this.object);
          // this.dataSourcefilter=new MatTableDataSource<any>(this.object);
          // this.dataSourcefilter.paginator=this.paginators;
          // this.spinnerService.hide();
        
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
   
  }
  hide(){
    $("#newcatogoryCreate").modal("hide");
    $("#newSubcatogoryCreate").modal("hide");
    this.catogorySubmitted=false;
    this.subcatogorySubmit=false;
    this.catagoryForm.reset();
    this.subcatagoryForm.reset();

    
  }
  hideCatogory(){
    $("#categoryDetails").modal("hide");
    this.ngOnInit();
  }
  checkAllFieldsEntered(){
    return Object.values(this.catagoryForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }
  getCatogoryList(){
    this.pageNo=1;
    this.PageSize=10;
    this.spinnerService.show();
    this.memberService
    .getCatogoryList( this.token)
    .subscribe(
      (data: any) => {
        if (data.statusCode === 200) {
          this.catogoryList=data.categoryLists;
          this.dataSource=new MatTableDataSource<any>(this.catogoryList);
          this.dataSource.paginator=this.paginator;
          // this.object = this.catogoryList
          // .map(category => category.subCategoryList || []) // Map to subCategoryList arrays
          // .reduce((acc, subCategory) => acc.concat(subCategory), []); 
          // console.log('usb',data.categoryLists.subCategoryList);
          // console.log(this.object);
          // this.dataSourcefilter=new MatTableDataSource<any>(this.object);
          // this.dataSourcefilter.paginator=this.paginators;
          this.spinnerService.hide();
          return;
        }
      },
      err => {
        if (err.status === 401) {
          this.unauthorizedException();
        }
      }
    );
  }
  onClickInsert(){
    this.catogorySubmitted=false;
    this.heading="Add Category";
    this.footer="Add";
    this.submitControl=true;
    this.catagoryForm = this.formBuilder.group({
      CategoryNames: ['', [Validators.required,WhiteSpaceValidator,Validators.pattern(/^[a-zA-Z0-9 ]{3,}$/),]]
    });
    $("#newcatogoryCreate").modal("show");
  }
  onDeleteSubmit(row){
    // console.log('row',row.groupId);
  Swal.fire({
    title: "Delete Category ",
    text: "Are you sure you want to delete this Category ? Doing so will also delete all history and data associated with this Category.",
    icon: "warning",
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",
    allowOutsideClick: false,
  }).then((result) => {
    if (result.value) {
      this.spinnerService.show();
   let params={
    "categoryId":row.categoryId
   };
   console.log(params);
      this.memberService
        .postcategoryDelete(params, this.token) // Assuming `row.id` is the correct memberId
        .subscribe(
          () => {
            this.spinnerService.hide();
            Swal.fire({
              title: "Deleted!",
              text: "Category is deleted now",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false,
            }).then(() => {
              this.spinnerService.hide();
              this.ngOnInit(); // Refresh data after deletion
            });
          },
          (err) => {
            this.spinnerService.hide();
            if (err.status === 401) {
              this.unauthorizedException();
            } else {
              Swal.fire({
                title: "Error!",
                text: "An error occurred while deleting the Category .",
                icon: "error",
                showCloseButton: true,
                allowOutsideClick: false,
              });
            }
          }
        );
    }
  });

  }
  onClickUpdate(element){
    this.submitControl=false;
    this.catagoryId=element.categoryId
    console.log(element);
    this.heading="Update Category";
    this.footer="Update";
    this.catagoryForm = this.formBuilder.group({
      CategoryNames: [element.categoryName, [Validators.required,WhiteSpaceValidator,Validators.pattern(/^[a-zA-Z0-9 ]{3,}$/),]]
    });
    $("#newcatogoryCreate").modal("show");
  }
  onClickSubcatogory(row){
    this.subcategoryId=row.categoryId;
    this.submitControl=true;
    this.heading='Add SubCategory';
    this.footer='Add';
    this.submitControl=true;
    $("#newSubcatogoryCreate").modal("show");
    this.subcatagoryForm = this.formBuilder.group({
      CategoryName: ['', [Validators.required,WhiteSpaceValidator]]
    });
   
  }
  checkAllFieldsEnteredSub(){
    return Object.values(this.subcatagoryForm.controls).every(control => {
      return control.value !== '' && control.value !== null;
    });
  }
  onUpdateSubcatogory(element){
   this.catagoryId=element.categoryId
    console.log(element);
    this.submitControl=false;
    this.heading='Update Subcategory';
    this.footer='Update';
    $("#newSubcatogoryCreate").modal("show");
    this.subcatagoryForm = this.formBuilder.group({
      CategoryName: [element.categoryName, [Validators.required,WhiteSpaceValidator]]
    });
    
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
   applyFilterIcon(){
    this.dataSource.filter = this.selectedType.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
   }

  submitcatogoryUpdate(){
    this.catogorySubmitted = true;
    console.log(this.catagoryForm)
    if (this.catagoryForm.invalid) {
      return;
    }
    let params={
     "categoryId": this.catagoryId,
     "categoryName": this.catagoryForm.value.CategoryNames.trim()
    }
    console.log('Params',params);
    
    this.spinnerService.show();
      this.memberService.postUpdateCategory(params,this.token).subscribe(
        (data: any) => {
          // this.ishubspotLoading = false;
          if (data.statusCode == 200) {
            $("#newcatogoryCreate").modal("hide");
            Swal.fire({
              title: "Updated!",
              text: "Category Updated Successfully",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false
            });
            this.ngOnInit();
            this.spinnerService.hide();
          
          }
          else {
            // this.isLoading = false;
            Swal.fire({
              title: data.statusMessage,
              icon: "warning",
              showCloseButton: true,
              allowOutsideClick: false
            });
            this.spinnerService.hide();
  
          }
        },
        err => {
          if (err.status === 401) {
            this.spinnerService.hide();
            this.unauthorizedException();
          }
        }
      );
  }
  submitcatogory(){
    this.catogorySubmitted = true;
    console.log(this.catagoryForm)
    if (this.catagoryForm.invalid) {
      return;
    }
    let params={
     "parentId": 0,
     "category": this.catagoryForm.value.CategoryNames.trim()
    }
    console.log('Params',params);
    
    this.spinnerService.show();
      this.memberService.postCreateCategory(params,this.token).subscribe(
        (data: any) => {
          // this.ishubspotLoading = false;
          if (data.statusCode == 200) {
            $("#newcatogoryCreate").modal("hide");
            Swal.fire({
              title: "Inserted!",
              text: "Category Created Successfully",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false,
              
            });
            this.ngOnInit();
            this.spinnerService.hide();
          
          }
          else {
            
            // this.isLoading = false;
            Swal.fire({
              title: data.statusMessage,
              icon: "warning",
              showCloseButton: true,
              allowOutsideClick: false
            });
            this.spinnerService.hide();
  
          }
        },
        err => {
          if (err.status === 401) {
            this.spinnerService.hide();
            this.unauthorizedException();
          }
        }
      );

  }
  submitSubcatogoryUpdate(){
    console.log("hey",this.subcatagoryForm.value.CategoryName);
    this.subcatogorySubmit = true;
    console.log(this.subcatagoryForm);
    
    if (this.subcatagoryForm.invalid) {
      return;
    }
    let params={
     "categoryId": this.catagoryId,
     "categoryName": this.subcatagoryForm.value.CategoryName.trim()
    }
    console.log('Params',params);
    
    this.subcatogorySubmit = false;
    this.spinnerService.show();
      this.memberService.postUpdateCategory(params,this.token).subscribe(
        (data: any) => {
          // this.ishubspotLoading = false;
          if (data.statusCode == 200) {
            $("#newSubcatogoryCreate").modal("hide");
            Swal.fire({
              title: "Updated!",
              text: "Sub Category Updated Successfully",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              this.spinnerService.hide();
              this.GetsubcatogoryFilter(this.rows,this.categoryName); 
              // this.GetsubcatogoryFilter(this.object,this.subcatagoryForm.value.CategoryName);
              // this.ngOnInit();// Refresh data after deletion
              this.spinnerService.hide();
            });
            this.spinnerService.hide();
          
          }
          else {
            // this.isLoading = false;
            $("#newSubcatogoryCreate").modal("hide");
            Swal.fire({
              title: data.statusMessage,
              icon: "warning",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              $("#newSubcatogoryCreate").modal("show");
            });
            this.spinnerService.hide();
  
          }
        },
        err => {
          if (err.status === 401) {
            this.spinnerService.hide();
            this.unauthorizedException();
          }
        }
      );
  }
  get subcatagoryFormrControl() {
    return this.subcatagoryForm.controls;
  }
  get catagoryFormrControl() {
    return this.catagoryForm.controls;
  }
  submitSubcatogory(){
    this.subcatogorySubmit=true;
    if (this.subcatagoryForm.invalid) {
      return;
    }
    let params={
     "parentId": this.subcategoryId,
     "category": this.subcatagoryForm.value.CategoryName.trim()
    }
    console.log('Params',params);
    this.subcatogorySubmit=false;
    this.spinnerService.show();
      this.memberService.postCreateCategory(params,this.token).subscribe(
        (data: any) => {
          // this.ishubspotLoading = false;
          if (data.statusCode == 200) {
            $("#newSubcatogoryCreate").modal("hide");
            Swal.fire({
              title: "Inserted!",
              text: "Sub Category Created Successfully",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false
            });
            this.ngOnInit();
            this.spinnerService.hide();
          
          }
          else {
            $("#newSubcatogoryCreate").modal("hide");
            // this.isLoading = false;
            Swal.fire({
              title: data.statusMessage,
              icon: "warning",
              showCloseButton: true,
              allowOutsideClick: false
            }).then(() => {
              $("#newSubcatogoryCreate").modal("show");
            });
            this.spinnerService.hide();
  
          }
        },
        err => {
          if (err.status === 401) {
            this.spinnerService.hide();
            this.unauthorizedException();
          }
        }
      );

  }
  onDeleteSubmitSubCatogory(row,categoryName){
    // console.log('row',row.groupId);
  Swal.fire({
    title: "Delete Sub  Category ",
    text: "Are you sure you want to delete this Sub Category ? Doing so will also delete all history and data associated with this Category.",
    icon: "warning",
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",
    allowOutsideClick: false,
  }).then((result) => {
    if (result.value) {
      this.spinnerService.show();
   let params={
    "categoryId":row.categoryId
   };
   console.log(params);
      this.memberService
        .postcategoryDelete(params, this.token) // Assuming `row.id` is the correct memberId
        .subscribe(
          () => {
            this.spinnerService.hide();
            Swal.fire({
              title: "Deleted!",
              text: " Sub Category is deleted now",
              icon: "success",
              showCloseButton: true,
              allowOutsideClick: false,
            }).then(() => {
              this.spinnerService.hide();
              
              this.GetsubcatogoryFilter(this.rows,this.categoryName); // Refresh data after deletion
            });
            // this.GetsubcatogoryFilter(this.object,this.subcatagoryForm.value.categoryName);
            // this.ngOnInit();
            // this.GetsubcatogoryFilter(this.object,this.subcatagoryForm.value.categoryName);
          },
          (err) => {
            this.spinnerService.hide();
            if (err.status === 401) {
              this.unauthorizedException();
            } else {
              Swal.fire({
                title: "Error!",
                text: "An error occurred while deleting the Sub Category .",
                icon: "error",
                showCloseButton: true,
                allowOutsideClick: false,
              });
            }
          }
        );
    }
  });

  }
  unauthorizedException() {
    Swal.fire({
      icon: "warning",
      title: "Session Expired!",
      allowOutsideClick: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Logout"
    }).then(() => {
      localStorage.clear();
      this.router.navigate(["/login"]);
    });
    return;
  }
   subcatogoryDisplay(row:any){
    let result="";
    row.map((value)=>{
     result=result+value.categoryName+",";
    });
   return result.substring(0,result.length-1);
   }
}
