// Vendor Imports
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

// Local Imports
import {
  LoginRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest
} from "../models/user";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  baseUrl = environment.baseUrl;
  constructor(private httpClient: HttpClient) { }

  login(params: LoginRequest) {
    return this.httpClient.post(
      `${this.baseUrl}/v1/Auth/postMasterLogin`,
      params
    );
  }

  superAdminlogin(params: LoginRequest) {
    return this.httpClient.post(
      `${this.baseUrl}/v1/Auth/postSALogin`,
      params
    );
  }

  forgetPassword(params: ForgotPasswordRequest) {
    return this.httpClient.post(
      `${this.baseUrl}/v1/Auth/postMasterForgotPassword`,
      params
    );
  }
  
  forgetPasswordSuperAdmin(params: ForgotPasswordRequest) {
    return this.httpClient.post(
      `${this.baseUrl}/v1/Auth/postSAForgotPassword`,
        params
    );
  }

  resetPassword(params: ResetPasswordRequest, token: string) {
    return this.httpClient
      .post(`${this.baseUrl}/v1/Auth/postMasterResetPassword`, params, {
        headers: new HttpHeaders().set("Authorization", token)
      })
      .pipe(catchError(this.handleError));
  }

  isLoggedIn(): boolean {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (user && user.jwtToken) {
      return true;
    } else {
      return false;
    }
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
